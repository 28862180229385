//GeneratorFlag:[NoChange]
import { BaseProductInfoImages } from '@/modules/shop/base/data/actions/product-store/get-product-info'
export default class ProductInfoImages extends BaseProductInfoImages
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			id: {
				...this.props.id,
				setConfig:{
					...this.props.id.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			imagePath: {
				...this.props.imagePath,
				setConfig:{
					...this.props.imagePath.setConfig
				}
			},
			productDocTypeId: {
				...this.props.productDocTypeId,
				setConfig:{
					...this.props.productDocTypeId.setConfig
				}
			},
		};
	}
}

