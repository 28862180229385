//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class ProductStoreService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(productStoreId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:productStoreId
		}

		return await _server.get('/shop/product-store/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/shop/product-store/save', params || {})
	};

	async delete(productStoreId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:productStoreId
		}

		return await _server.delete('/shop/product-store/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/shop/product-store/list', {'params':params})
	};
}
