//GeneratorFlag:[NoChange]
import { BaseProductDocType } from '../base/data'
export default class ProductDocType extends BaseProductDocType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productDocTypeId: {
				...this.props.productDocTypeId,
				setConfig:{
					...this.props.productDocTypeId.setConfig
				}
			},
			productDocTypeKey: {
				...this.props.productDocTypeKey,
				setConfig:{
					...this.props.productDocTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
