//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class ProductStoreService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[ProductStore.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-store/create', params || { });
	};

	//GeneratorFlag:[ProductStore.Delete]
	async delete(productStoreId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:productStoreId
		}
	
		return await _server.delete('/shop/product-store/delete', {'params':params})
	};

	//GeneratorFlag:[ProductStore.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/product-store/detail', {'params':params});
	};

	//GeneratorFlag:[ProductStore.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/product-store/list', {'params':params});
	};

	//GeneratorFlag:[ProductStore.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-store/update', params || { });
	};

	//GeneratorFlag:[ProductStore.GetProductInfo]
	async getProductInfo(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-store/get-product-info', params || { });
	};

//GeneratorFlag:[NewAction]
}