//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'


export default class BasePlanSetting extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'planSetting';
	schema = ENUMS.SCHEMA_TYPE.BASE_INFO;
	resourceKey = 'shop.planSetting.self';
	props = {
		planSettingId: {
			primarykey: true,
			name: 'planSettingId',
			resourceKey: 'shop.planSetting.planSettingId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		planMaximunPayOutPerLineValue: {
			name: 'planMaximunPayOutPerLineValue',
			resourceKey: 'shop.planSetting.planMaximunPayOutPerLineValue',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		planPayOutCommisionPrecent: {
			name: 'planPayOutCommisionPrecent',
			resourceKey: 'shop.planSetting.planPayOutCommisionPrecent',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		planPayOutVoacherPrecent: {
			name: 'planPayOutVoacherPrecent',
			resourceKey: 'shop.planSetting.planPayOutVoacherPrecent',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		planSaveExpiretionDayCount: {
			name: 'planSaveExpiretionDayCount',
			resourceKey: 'shop.planSetting.planSaveExpiretionDayCount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		planMaximunPayoutTotal: {
			name: 'planMaximunPayoutTotal',
			resourceKey: 'shop.planSetting.planMaximunPayoutTotal',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
