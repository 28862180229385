//GeneratorFlag:[NoChange]
export default {
	path: 'ticket-item',
	name: 'ticketing.ticketItem.layout',
	redirect: to => {
		return {name: 'ticketing.ticketItem.list'};
	},
	component: () => import('@/modules/ticketing/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ticketing/ticket-item"],
		resourceKey: 'ticketing.views.ticketItem.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TicketItemCreate]"
		{
			path: 'create',
			name: 'ticketing.ticketItem.create',
			component: () => import('@/modules/ticketing/views/ticket-item/ticket-item-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketItem.create',
				clientAccessKeys:["/ticketing/ticket-item/create"],
				serverAccessKeys:["/ticketing/ticket-item/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketItemCreateImport]"
		{
			path: 'create-import',
			name: 'ticketing.ticketItem.createImport',
			component: () => import('@/modules/ticketing/views/ticket-item/ticket-item-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketItem.createImport',
				clientAccessKeys:["/ticketing/ticket-item/create-import"],
				serverAccessKeys:["/ticketing/ticket-item/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketItemDelete]"
		{
			path: 'delete',
			name: 'ticketing.ticketItem.delete',
			component: () => import('@/modules/ticketing/views/ticket-item/ticket-item-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketItem.delete',
				clientAccessKeys:["/ticketing/ticket-item/delete"],
				serverAccessKeys:["/ticketing/ticket-item/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketItemDetail]"
		{
			path: 'detail',
			name: 'ticketing.ticketItem.detail',
			component: () => import('@/modules/ticketing/views/ticket-item/ticket-item-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketItem.detail',
				clientAccessKeys:["/ticketing/ticket-item/detail"],
				serverAccessKeys:["/ticketing/ticket-item/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketItemList]"
		{
			path: 'list',
			name: 'ticketing.ticketItem.list',
			component: () => import('@/modules/ticketing/views/ticket-item/ticket-item-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketItem.list',
				clientAccessKeys:["/ticketing/ticket-item/list"],
				serverAccessKeys:["/ticketing/ticket-item/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketItemUpdate]"
		{
			path: 'update',
			name: 'ticketing.ticketItem.update',
			component: () => import('@/modules/ticketing/views/ticket-item/ticket-item-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketItem.update',
				clientAccessKeys:["/ticketing/ticket-item/update"],
				serverAccessKeys:["/ticketing/ticket-item/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}