//GeneratorFlag:[NoChange]
export default {
	path: 'product-feature',
	name: 'shop.productFeature.layout',
	redirect: to => {
		return {name: 'shop.productFeature.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/product-feature"],
		resourceKey: 'shop.views.productFeature.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductFeatureDelete]"
		{
			path: 'delete',
			name: 'shop.productFeature.delete',
			component: () => import('@/modules/shop/views/product-feature/product-feature-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productFeature.delete',
				clientAccessKeys:["/shop/product-feature/delete"],
				serverAccessKeys:["/shop/product-feature/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductFeatureDetail]"
		{
			path: 'detail',
			name: 'shop.productFeature.detail',
			component: () => import('@/modules/shop/views/product-feature/product-feature-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productFeature.detail',
				clientAccessKeys:["/shop/product-feature/detail"],
				serverAccessKeys:["/shop/product-feature/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductFeatureList]"
		{
			path: 'list',
			name: 'shop.productFeature.list',
			component: () => import('@/modules/shop/views/product-feature/product-feature-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productFeature.list',
				clientAccessKeys:["/shop/product-feature/list"],
				serverAccessKeys:["/shop/product-feature/list","/shop/feature/list","/shop/product/list","/shop/culture/list","/shop/category/list","/shop/product-status/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductFeatureCreate]"
		{
			path: 'create',
			name: 'shop.productFeature.create',
			component: () => import('@/modules/shop/views/product-feature/product-feature-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productFeature.create',
				clientAccessKeys:["/shop/product-feature/create"],
				serverAccessKeys:["/shop/product-feature/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductFeatureImport]"
		{
			path: 'import',
			name: 'shop.productFeature.import',
			component: () => import('@/modules/shop/views/product-feature/product-feature-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productFeature.import',
				clientAccessKeys:["/shop/product-feature/import"],
				serverAccessKeys:["/shop/product-feature/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductFeatureUpdate]"
		{
			path: 'update',
			name: 'shop.productFeature.update',
			component: () => import('@/modules/shop/views/product-feature/product-feature-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productFeature.update',
				clientAccessKeys:["/shop/product-feature/update"],
				serverAccessKeys:["/shop/product-feature/save","/shop/feature/list","/shop/product/list","/shop/culture/list","/shop/category/list","/shop/product-status/list","/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






