<template>
	<div class="row" v-if="_entity">
		<div class="col-12">
			<form-collapse :use-validation="useValidation" :entity="_entity" />
		</div>

		<div class="col-12" v-if="entityHasError">
			<div class="alert alert-danger">
				{{ $loc.fromResource('error.chackForm') }}
			</div>
		</div>

		<div class="col-12">
			<Frc-button @click="submit" class="btn btn-dark btn-sm" :is-loading="loading">
				{{ $loc.fromResource(submitResourceKey) }}
			</Frc-button>
		</div>
	</div>
</template>

<script>
	import formCollapse from './form-collapse'

	export default {

		components:
		{
			'form-collapse': formCollapse
		},

		props:
		{
			'entity': {
				type: Function
			},

			'task': {
				type: Function
			},

			'value': {
				type: Object,
				default: null
			},

			'useValidation': {
				type: Boolean,
				default: true
			},

			'displayType': {
				type: String,
				default: 'collapse'
			},

			'submitResourceKey': {
				type: String,
				default: 'global.save'
			},
		},

		data()
		{
			return {
				ver: 0,
				loading: false,
				entityHasError: false,
				_entity: new this.entity({ value: this.value }),
			}
		},

		methods: {

			async submit()
			{
				if (this.task)
				{
					if (this.useValidation)
					{
						this._entity.cleanError();
						this._entity.parseFormError();
						if (this._entity.getFormErrorLength() > 0)
						{
							this.entityHasError = true
							return
						}
					}


					try
					{
						const value =
							this._entity.getFormValue()

						this.loading = true;
						await this.task(value);

					} catch (e)
					{
						console.log('form Error:' + e)
					}

					this.loading = false;
				}
			}
		}
	}
</script>
