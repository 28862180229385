//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Culture } from '../../data'; 

import { CultureService } from '../../services'; 

export default class BasePersonGender extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'personGender';
	schema = ENUMS.SCHEMA_TYPE.BASE_SYS;
	resourceKey = 'peopleNet.personGender.self';
	props = {
		personGenderId: {
			primarykey: true,
			name: 'personGenderId',
			resourceKey: 'peopleNet.personGender.personGenderId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personGenderKey: {
			name: 'personGenderKey',
			resourceKey: 'peopleNet.personGender.personGenderKey',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		cultureId: {
			foreignKey: true,
			name: 'cultureId',
			resourceKey: 'peopleNet.personGender.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Culture(),
			data: async (params) =>
			{
				const service =
					new CultureService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'cultureId',
				patterns: [],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'peopleNet.personGender.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: ['required'],
			}
		},
		isOrigin: {
			name: 'isOrigin',
			resourceKey: 'peopleNet.personGender.isOrigin',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		culture: {
			name: 'culture',
			resourceKey: 'peopleNet.personGender.culture',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Culture(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
