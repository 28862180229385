<template>
	<div class="Frc-task">



		<task-progress v-if="state == 'progress' || state == 'warning'"
			:totalTasksLength="totalTasksLength"
			:currentTasksLength="currentTaskIndex"
			:succeedTasksLength="succeedTasksLength"
			:failedTasksLength="failedTasksLength"
			:remainingMilliseconds="remainingMilliseconds" />

		<task-complete v-if="state == 'complete'"
					   :failed="failedTasksLength"
					   :succeed="succeedTasksLength"
					   v-on:close="close"/>

		<task-preview v-if="state == 'preview'"
					  v-bind:entity="entity"
					  v-bind:data="data"
					  v-bind:label="label"
					  v-on:doTasks="taskRun"
					  v-on:cancel="cancel"/>

		<task-warning v-if="state == 'warning'"
					  v-bind:entity="entity"
					  v-bind:status="failedTask.status"
					  v-bind:message="failedTask.message"
					  v-bind:taskIndex="failedTask.index"
					  v-bind:taskData="failedTask.data"
					  v-on:cancel="cancel"
					  v-on:skip="taskSkip"
					  v-on:repeat="taskRepeat"
					  v-on:replace="taskReplace" />
	</div>
</template>
<script>

	import taskPreview from './common/task-preview'
	import taskComplete from './common/task-complete'
	import taskProgress from './common/task-progress'
	import taskWarning from './common/task-warning'

	export default {
		emits: ['complete', 'cancel', 'close'],

		props:
		{
			'entity': {
				type: Function
			},

			'task': {
				type: Function
			},

			'data': {
				type: Array
			},

			'label': {
				type: String
			}
		},

		data()
		{
			return {
				skipStatus: [],
				state: 'preview',
				failedTasksLength: 0,
				succeedTasksLength: 0,
				remainingMilliseconds:0,
				currentTaskIndex: 0,
				totalTasksLength: (this.data || []).length,
				failedTask: {
					status: null, index: null, data: null
				}
			}
		},

		components: {
			'task-preview': taskPreview,
			'task-complete': taskComplete,
			'task-progress': taskProgress,
			'task-warning': taskWarning,
		},

		methods: {

			async taskSkip(status)
			{
				this.failedTasksLength = this.failedTasksLength + 1;

				await this.ManageSkipStatus(status);
				await this.next();
			},

			async taskRepeat()
			{
				await this.taskRun();
			},

			async taskReplace(status)
			{
				await this.ManageSkipStatus(status);
				await this.taskRun();
			},

			async ManageSkipStatus(status)
			{
				if (status && this.skipStatus.indexOf(status) == -1)
				{
					this.skipStatus.push(status)
				}
			},

			async taskRun(isReplace)
			{
				if (this.currentTaskIndex < this.totalTasksLength)
				{
					if (this.state != 'progress')
						this.state = 'progress';

					var config = {};
					if (isReplace)
					{
						config.header = {
							override: "1"
						}
					}

					try
					{
						const startDateTime = Date.now();
						const payload = this.data[this.currentTaskIndex];
						const res = await this.task(payload, config);


						await this.calcRemainingMilliseconds(startDateTime);

						if (res.status == 200)
						{
							return await this.taskSucceed(res.data);
						}

						return await this.taskFailed(res.data);

					} catch (e)
					{
						return await this.taskFailed(e);
					}
				}

				this.completed()
			},


			async taskSucceed()
			{
				this.succeedTasksLength = this.succeedTasksLength + 1;
				await this.next();
			},

			async taskFailed(result)
			{
				if (this.skipStatus.indexOf(result.status) == -1)
				{
					this.failedTask = {
						status: result.status,
						message: result.message,
						index: this.currentTaskIndex,
						data: this.data[this.currentTaskIndex],
					};

					this.state = "warning";
					return;
				}

				this.failedTasksLength = this.failedTasksLength + 1;
				await this.next();
			},


			async next()
			{
				if (this.currentTaskIndex < this.totalTasksLength)
				{
					this.currentTaskIndex = this.currentTaskIndex + 1;
					await this.taskRun();

				} else this.completed();
			},

			async calcRemainingMilliseconds(requestStartDateTime)
			{
				const timeDiff = (Date.now() - requestStartDateTime);
				const remainingTasksLength = (this.totalTasksLength - this.currentTaskIndex);

				this.remainingMilliseconds = (remainingTasksLength * timeDiff)
			},

			completed()
			{
				setTimeout(() =>
				{
					this.state = 'complete';
					this.$emit('complete', {
						succeed: this.succeedTasksLength,
						failed: this.failedTasksLength
					});

				}, 1000);
			},

			cancel()
			{
				this.state = "cancel";
				this.$emit('cancel', {
					succeed: this.succeedTasksLength,
					failed: this.failedTasksLength
				});
			},

			close()
			{
				this.$emit('close')
			}
		}
	}
</script>
