//GeneratorFlag:[NoChange]
import { BaseMilitaryType } from '../base/data'
export default class MilitaryType extends BaseMilitaryType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			militaryTypeId: {
				...this.props.militaryTypeId,
				setConfig:{
					...this.props.militaryTypeId.setConfig
				}
			},
			militaryTypeKey: {
				...this.props.militaryTypeKey,
				setConfig:{
					...this.props.militaryTypeKey.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			militaryTypeSubInfo: {
				...this.props.militaryTypeSubInfo,
				setConfig:{
					...this.props.militaryTypeSubInfo.setConfig
				}
			},

		};
	}
}
