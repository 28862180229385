//GeneratorFlag:[NoChange]
import { BasePersonAccessGenerate } from '@/modules/people-net/base/data/actions/person-access/generate'
export default class PersonAccessGenerate extends BasePersonAccessGenerate
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			userName: {
				...this.props.userName,
				setConfig:{
					...this.props.userName.setConfig
				}
			},
			password: {
				...this.props.password,
				setConfig:{
					...this.props.password.setConfig
				}
			},
			tokenType: {
				...this.props.tokenType,
				setConfig:{
					...this.props.tokenType.setConfig
				}
			},
			expirationDate: {
				...this.props.expirationDate,
				setConfig:{
					...this.props.expirationDate.setConfig
				}
			},
			token: {
				...this.props.token,
				setConfig:{
					...this.props.token.setConfig
				}
			},
			profileUrl: {
				...this.props.profileUrl,
				setConfig:{
					...this.props.profileUrl.setConfig
				}
			},
			rankName: {
				...this.props.rankName,
				setConfig:{
					...this.props.rankName.setConfig
				}
			},
			parentName: {
				...this.props.parentName,
				setConfig:{
					...this.props.parentName.setConfig
				}
			},
			rankLogo: {
				...this.props.rankLogo,
				setConfig:{
					...this.props.rankLogo.setConfig
				}
			},
		};
	}
}

