//GeneratorFlag:[NoChange]
import { BasePersonAppPurchaseCheckCredit } from '@/modules/people-net/base/data/actions/person-app-purchase/check-credit'
export default class PersonAppPurchaseCheckCredit extends BasePersonAppPurchaseCheckCredit
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			isValid: {
				...this.props.isValid,
				setConfig:{
					...this.props.isValid.setConfig
				}
			},
			expireDate: {
				...this.props.expireDate,
				setConfig:{
					...this.props.expireDate.setConfig
				}
			},
			displayExpireDate: {
				...this.props.displayExpireDate,
				setConfig:{
					...this.props.displayExpireDate.setConfig
				}
			},
		};
	}
}

