<template>
	<li :class="getClass()">
		<div class="meta">
			<span class="arrow" v-if="showExpand || hasChildren">
				<span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status">
					<span class="visually-hidden">Loading...</span>
				</span>
				<Frc-icon-bootstrap v-else @click="expand" :name="getArrow()" size="inherit" />
			</span>
			<slot></slot>
		</div>
		<slot name="children" v-if="isExpandLocal && hasChildren"></slot>
	</li>
</template>

<script>
	export default {
		emits: ['expand'],
		props: {

			isExpand: {
				type: Boolean,
				default: false,
			},

			isLoading: {
				type: Boolean,
				default: false,
			},

			showExpand: {
				type: Boolean,
				default: false,
			},

			data: {
				type: Object,
				default: null,
			}
		},

		data()
		{
			return {
				isExpandLocal: this.isExpand,
				expandEmited: false,
			}
		},

		beforeMount()
		{
			if (this.isExpand != undefined)
			{
				this.isExpandLocal = this.isExpand;
			}
		},

		computed:
		{
			hasChildren()
			{
				return this.$slots['children'] != undefined
			}
		},

		methods:
		{
			expand()
			{
				this.isExpandLocal =
					!this.isExpandLocal;

				if (this.isExpandLocal == true && this.expandEmited == false)
				{
					this.expandEmited = true;
					this.$emit('expand', this.data)
				}
			},

			getClass()
			{
				return `node${this.hasChildren ? ' has-child' : ''}`
			},

			getArrow()
			{
				return this.isExpandLocal == true ? 'bi-dash' : 'bi-plus'
			}
		}
	}
</script>
