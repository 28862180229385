//GeneratorFlag:[NoChange]
import { BaseProduct } from '../base/data'
export default class Product extends BaseProduct
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			productKey: {
				...this.props.productKey,
				setConfig:{
					...this.props.productKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			identifire: {
				...this.props.identifire,
				setConfig:{
					...this.props.identifire.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			sellCount: {
				...this.props.sellCount,
				setConfig:{
					...this.props.sellCount.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			firstPrice: {
				...this.props.firstPrice,
				setConfig:{
					...this.props.firstPrice.setConfig
				}
			},
			totalProductInventory: {
				...this.props.totalProductInventory,
				setConfig:{
					...this.props.totalProductInventory.setConfig
				}
			},
			productCode: {
				...this.props.productCode,
				setConfig:{
					...this.props.productCode.setConfig
				}
			},
			imagePath: {
				...this.props.imagePath,
				setConfig:{
					...this.props.imagePath.setConfig
				}
			},
			productStatusId: {
				...this.props.productStatusId,
				setConfig:{
					...this.props.productStatusId.setConfig
				}
			},
			productMonetaryFeatureTitle: {
				...this.props.productMonetaryFeatureTitle,
				setConfig:{
					...this.props.productMonetaryFeatureTitle.setConfig
				}
			},
			category: {
				...this.props.category,
				setConfig:{
					...this.props.category.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			productStatus: {
				...this.props.productStatus,
				setConfig:{
					...this.props.productStatus.setConfig
				}
			},
			productSpecification: {
				...this.props.productSpecification,
				setConfig:{
					...this.props.productSpecification.setConfig
				}
			},
			productSubInfo: {
				...this.props.productSubInfo,
				setConfig:{
					...this.props.productSubInfo.setConfig
				}
			},

		};
	}
}
