//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class ProductCategoryService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[ProductCategory.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-category/create', params || { });
	};

	//GeneratorFlag:[ProductCategory.Delete]
	async delete(productCategoryId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:productCategoryId
		}
	
		return await _server.delete('/shop/product-category/delete', {'params':params})
	};

	//GeneratorFlag:[ProductCategory.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/product-category/detail', {'params':params});
	};

	//GeneratorFlag:[ProductCategory.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/product-category/list', {'params':params});
	};

	//GeneratorFlag:[ProductCategory.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-category/update', params || { });
	};

	//GeneratorFlag:[ProductCategory.CategoryProductRelatedAction]
	async categoryProductRelatedAction(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-category/category-product-related-action', params || { });
	};

	//GeneratorFlag:[ProductCategory.GetProductCategory]
	async getProductCategory(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/product-category/get-product-category', {'params':params});
	};

	//GeneratorFlag:[ProductCategory.GetProductCategoryListAction]
	async getProductCategoryListAction(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-category/get-product-category-list-action', params || { });
	};

//GeneratorFlag:[NewAction]
}