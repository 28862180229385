//GeneratorFlag:[NoChange]
export default {
	path: 'shop-marketer',
	name: 'shopMarketer.action.layout',
	//redirect: to =>
	//{
	//	return { name: 'shopMarketer.action.tree' };
	//},
	component: () => import('@/modules/shop-marketer/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys: [],
		clientAccessKeys: ["/shop-marketer/action"],
		resourceKey: 'shopMarketer.views.action.layout',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'tree-geno',
			name: 'shopMarketer.action.treeGeno',
			component: () => import('@/modules/shop-marketer/views/action/action-tree-geno.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shopMarketer.views.action.treeGeno',
				clientAccessKeys: ["/shop-marketer/action/treeGeno"],
				serverAccessKeys: [],
				menuConfig: {
					isVisible: true,
					iconName: 'bi-link-45deg',
				}
			}
		},
		{
			path: 'ord-geno',
			name: 'shopMarketer.action.ordGeno',
			component: () => import('@/modules/shop-marketer/views/action/action-ord-geno.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shopMarketer.views.action.ordGeno',
				clientAccessKeys: ["/shop-marketer/action/ordGeno"],
				serverAccessKeys: [],
				menuConfig: {
					isVisible: true,
					iconName: 'bi-link-45deg',
				}
			}
		},
	]
}
