<template>
	<Frc-tree height="auth" :isRoot="isRoot" :key="key">
		<Frc-tree-node  @expand="handleExpand(node)" v-for="(node,index) in localItems" :key="index" :is-loading="node.isLoading" :is-expand="node.isExpand" :show-expand="node.memberCount > 0" :data="node" >
			<label class="cursor-pointer p-3 bg-light" style="border:#000000 dotted 1px;">

				<span class="ms-2 me-2">
					<span>{{node.fullName}}</span><br />
												  <span class="star" >
													  <i class="bi" v-bind:class="[node.companyRanId >= 1 ? 'bi-star-fill text-warning':'bi-star']"></i>
													  <i class="bi" v-bind:class="[node.companyRanId >= 2 ? 'bi-star-fill text-warning':'bi-star']"></i>
													  <i class="bi" v-bind:class="[node.companyRanId >= 3 ? 'bi-star-fill text-warning':'bi-star']"></i>
													  <i class="bi" v-bind:class="[node.companyRanId >= 4 ? 'bi-star-fill text-warning':'bi-star']"></i>
													  <i class="bi" v-bind:class="[node.companyRanId >= 5 ? 'bi-star-fill text-warning':'bi-star']"></i>
													  <i class="bi" v-bind:class="[node.companyRanId >= 6 ? 'bi-star-fill text-warning':'bi-star']"></i>
													  <i class="bi" v-bind:class="[node.companyRanId >= 7 ? 'bi-star-fill text-warning':'bi-star']"></i>
												  </span>
					<br />
					<small class="text-muted" v-if="node.memberCount > 0"> {{ `Member Count ${node.memberCount} Person`}}</small>
					<br /> 
					<small class="text-muted" v-if="node.level > 0"> Level : {{node.level}} </small>
					<br />
					<span v-if="node.totalSell != undefined">
						Total Investment : {{ node.totalSell }}
					</span>
				</span>

			</label>
			<template v-slot:children v-if="hasChildren(node)">
				<person-tree-node @change="handleChange"
								  :items="node.children"
								  :is-root="false" />
			</template>
		</Frc-tree-node>
	</Frc-tree>
</template>
<script>
	import { PersonService } from '@/modules/people-net/services'
	export default {
		emits: ['change'],
		props: {
			items: {
				type: Array
			},
            fromDate: {
                type: String,
            },
            toDate: {
                type: String
            }
		},

		data()
		{
			return {
				key:1,
				isRoot: true,
				localItems: []
			}
		},

		beforeMount()
		{
			console.log(this.items)
			this.localItems = this.items
		},

		methods:
		{
			hasChildren(node)
			{
				return (node.children || []).length > 0
			},

			handleChange(node)
			{
				this.$emit('change', node)
			},

			async handleExpand(node)
			{
				var items = [];
				var localItems = JSON.parse(JSON.stringify(this.localItems))
				var index = localItems.findIndex((x) =>
				{
					return x.personId == node.personId
				});

				localItems[index].isLoading = true;
				this.localItems = localItems
				this.key = this.key + 1


				try
				{
					//this.localItems[index].loading = true;
					var payload = { includeReportSell: false, personId: node.personId }
                     
					if (this.fromDate && this.toDate)
					{
						payload.includeReportSell = true
						payload.fromDateDisply = this.fromDate
						payload.toDateDisply = this.toDate
					}

					var response =
						await new PersonService().treeList(payload, { HSR: false });

					if (response.data.isSuccess)
					{
						items = response.data.meta.items
					}

				} catch (e) { console.log(e) }

				localItems[index].children = items;
				localItems[index].isLoading = false;
				localItems[index].isExpand = true;
				this.localItems = localItems
				this.key = this.key +1
			},

		}
	}
</script>
