//GeneratorFlag:[NoChange]
import { BaseCountry } from '../base/data'
export default class Country extends BaseCountry
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			countryId: {
				...this.props.countryId,
				setConfig:{
					...this.props.countryId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			countryCode: {
				...this.props.countryCode,
				setConfig:{
					...this.props.countryCode.setConfig
				}
			},
			flagUrl: {
				...this.props.flagUrl,
				setConfig:{
					...this.props.flagUrl.setConfig
				}
			},

		};
	}
}
