//GeneratorFlag:[NoChange]
import { BasePersonAppPurchaseType } from '../base/data'
export default class PersonAppPurchaseType extends BasePersonAppPurchaseType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personAppPurchaseTypeId: {
				...this.props.personAppPurchaseTypeId,
				setConfig:{
					...this.props.personAppPurchaseTypeId.setConfig
				}
			},
			personAppPurchaseTypeKey: {
				...this.props.personAppPurchaseTypeKey,
				setConfig:{
					...this.props.personAppPurchaseTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
