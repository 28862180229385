//GeneratorFlag:[NoChange]
import { BaseCulture } from '../base/data'
export default class Culture extends BaseCulture
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			cultureSymbol: {
				...this.props.cultureSymbol,
				setConfig:{
					...this.props.cultureSymbol.setConfig
				}
			},
			cultureFlagImagePath: {
				...this.props.cultureFlagImagePath,
				setConfig:{
					...this.props.cultureFlagImagePath.setConfig
				}
			},
			towSymbolCulture: {
				...this.props.towSymbolCulture,
				setConfig:{
					...this.props.towSymbolCulture.setConfig
				}
			},
			currency: {
				...this.props.currency,
				setConfig:{
					...this.props.currency.setConfig
				}
			},
			weightUnit: {
				...this.props.weightUnit,
				setConfig:{
					...this.props.weightUnit.setConfig
				}
			},
			widthUnit: {
				...this.props.widthUnit,
				setConfig:{
					...this.props.widthUnit.setConfig
				}
			},
			lengthUnit: {
				...this.props.lengthUnit,
				setConfig:{
					...this.props.lengthUnit.setConfig
				}
			},
			heightUnit: {
				...this.props.heightUnit,
				setConfig:{
					...this.props.heightUnit.setConfig
				}
			},
			standardTimeZoneId: {
				...this.props.standardTimeZoneId,
				setConfig:{
					...this.props.standardTimeZoneId.setConfig
				}
			},

		};
	}
}
