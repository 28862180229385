//GeneratorFlag:[NoChange]
import { BaseProductCategory } from '../base/data'
export default class ProductCategory extends BaseProductCategory
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productCategoryId: {
				...this.props.productCategoryId,
				setConfig:{
					...this.props.productCategoryId.setConfig
				}
			},
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			category: {
				...this.props.category,
				setConfig:{
					...this.props.category.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			product: {
				...this.props.product,
				setConfig:{
					...this.props.product.setConfig
				}
			},
			productCategorySubInfo: {
				...this.props.productCategorySubInfo,
				setConfig:{
					...this.props.productCategorySubInfo.setConfig
				}
			},

		};
	}
}
