//GeneratorFlag:[NoChange]
export default {
	path: 'wallet-currency',
	name: 'financial.baseSys.walletCurrency.layout',
	redirect: to => {
		return {name: 'financial.walletCurrency.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/base-sys/wallet-currency"],
		resourceKey: 'financial.views.walletCurrency.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[WalletCurrencyDetail]"
		{
			path: 'detail',
			name: 'financial.walletCurrency.detail',
			component: () => import('@/modules/financial/views/wallet-currency/wallet-currency-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletCurrency.detail',
				clientAccessKeys:["/financial/base-sys/wallet-currency/detail"],
				serverAccessKeys:["/financial/wallet-currency/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletCurrencyList]"
		{
			path: 'list',
			name: 'financial.walletCurrency.list',
			component: () => import('@/modules/financial/views/wallet-currency/wallet-currency-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletCurrency.list',
				clientAccessKeys:["/financial/base-sys/wallet-currency/list"],
				serverAccessKeys:["/financial/wallet-currency/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}