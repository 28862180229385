//GeneratorFlag:[NoChange]
import { BasePersonAccessChangePassword } from '@/modules/people-net/base/data/actions/person-access/change-password'
export default class PersonAccessChangePassword extends BasePersonAccessChangePassword
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			currentPassword: {
				...this.props.currentPassword,
				setConfig:{
					...this.props.currentPassword.setConfig
				}
			},
			newPassword: {
				...this.props.newPassword,
				setConfig:{
					...this.props.newPassword.setConfig
				}
			},
			confirmedPassword: {
				...this.props.confirmedPassword,
				setConfig:{
					...this.props.confirmedPassword.setConfig
				}
			},
			result: {
				...this.props.result,
				setConfig:{
					...this.props.result.setConfig
				}
			},
		};
	}
}

