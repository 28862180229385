//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Rank, CompanyRank, Company, PersonStatuse, PersonGender, Team, PersonAccess, PersonDetail } from '../../data'; 

import { RankService, CompanyRankService, CompanyService, PersonStatuseService, PersonGenderService, TeamService } from '../../services'; 

export default class BasePerson extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'person';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'peopleNet.person.self';
	props = {
		personId: {
			primarykey: true,
			name: 'personId',
			resourceKey: 'peopleNet.person.personId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		firstName: {
			name: 'firstName',
			resourceKey: 'peopleNet.person.firstName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: ['required'],
			}
		},
		lastName: {
			name: 'lastName',
			resourceKey: 'peopleNet.person.lastName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: ['required'],
			}
		},
		nationalNo: {
			name: 'nationalNo',
			resourceKey: 'peopleNet.person.nationalNo',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 10,
				isUnicode: false,
				patterns: [],
			}
		},
		passportNo: {
			name: 'passportNo',
			resourceKey: 'peopleNet.person.passportNo',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				isUnicode: false,
				patterns: [],
			}
		},
		parentId: {
			name: 'parentId',
			resourceKey: 'peopleNet.person.parentId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		genoString: {
			name: 'genoString',
			resourceKey: 'peopleNet.person.genoString',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				isUnicode: false,
				patterns: ['required'],
			}
		},
		rankId: {
			foreignKey: true,
			name: 'rankId',
			resourceKey: 'peopleNet.person.rankId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new Rank(),
			data: async (params) =>
			{
				const service =
					new RankService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'rankId',
				patterns: ['required'],
			}
		},
		companyRankId: {
			foreignKey: true,
			name: 'companyRankId',
			resourceKey: 'peopleNet.person.companyRankId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new CompanyRank(),
			data: async (params) =>
			{
				const service =
					new CompanyRankService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'companyRankId',
				patterns: ['required'],
			}
		},
		companyId: {
			foreignKey: true,
			name: 'companyId',
			resourceKey: 'peopleNet.person.companyId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Company(),
			data: async (params) =>
			{
				const service =
					new CompanyService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'companyId',
				patterns: ['required'],
			}
		},
		mobile: {
			name: 'mobile',
			resourceKey: 'peopleNet.person.mobile',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: ['mobile'],
			}
		},
		email: {
			name: 'email',
			resourceKey: 'peopleNet.person.email',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 100,
				patterns: [],
			}
		},
		personStauseId: {
			foreignKey: true,
			name: 'personStauseId',
			resourceKey: 'peopleNet.person.personStauseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonStatuse(),
			data: async (params) =>
			{
				const service =
					new PersonStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personStauseId',
				patterns: ['required'],
			}
		},
		personGenderId: {
			foreignKey: true,
			name: 'personGenderId',
			resourceKey: 'peopleNet.person.personGenderId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonGender(),
			data: async (params) =>
			{
				const service =
					new PersonGenderService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personGenderId',
				patterns: ['required'],
			}
		},
		teamId: {
			foreignKey: true,
			name: 'teamId',
			resourceKey: 'peopleNet.person.teamId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Team(),
			data: async (params) =>
			{
				const service =
					new TeamService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'teamId',
				patterns: ['required'],
			}
		},
		personCode: {
			name: 'personCode',
			resourceKey: 'peopleNet.person.personCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		levelOfHead: {
			name: 'levelOfHead',
			resourceKey: 'peopleNet.person.levelOfHead',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		creditValue: {
			name: 'creditValue',
			resourceKey: 'peopleNet.person.creditValue',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		company: {
			name: 'company',
			resourceKey: 'peopleNet.person.company',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Company(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		companyRank: {
			name: 'companyRank',
			resourceKey: 'peopleNet.person.companyRank',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new CompanyRank(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personGender: {
			name: 'personGender',
			resourceKey: 'peopleNet.person.personGender',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonGender(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personStause: {
			name: 'personStause',
			resourceKey: 'peopleNet.person.personStause',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonStatuse(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		rank: {
			name: 'rank',
			resourceKey: 'peopleNet.person.rank',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Rank(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		team: {
			name: 'team',
			resourceKey: 'peopleNet.person.team',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Team(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personAccess: {
			name: 'personAccess',
			resourceKey: 'peopleNet.person.personAccess',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonAccess(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{userName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		personDetail: {
			name: 'personDetail',
			resourceKey: 'peopleNet.person.personDetail',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonDetail(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		personSubInfo: {
			name: 'personSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
