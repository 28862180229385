export default {
    "name": "shop",
    "global": {
        "login": "ورود",
        "createAccont": "ایجاد حساب کاربری",
        "back": "بازگشت",
        "next": "مرحله بعد",
        "close": "بستن",
        "contactUs": "ارتباط با ما",
        "support": "پشتیبانی:",
        "send": "ارسال کردن",
        "category": "دسته بندی",
        "wait": "لطفا منتظر بمانید...",
        "addToCart": "افزودن به سبد خرید",
        "removeFromCart": "حذف از سبد خرید",
        "notExist": "این محصول در حال حاضر موجود نمی باشد",
        "logOut": "خروج",
        "wallet": "کیف پول",
        "search": "جستجو  کردن",
        "followUs": "ما را دنبال کنید",
        "welcome": "خوش آمدید"
    },
    "menu": {
        "name": "فهرست",
        "contactUs": "ارتباط با ما",
        "aboutUs": "درباره ما",
        "dashbord": "داشبورد",
        "category": "دسته بندی"
    },
    "views": {
        "layout": {
            "category": " دسته بندی محصولات",
            "title": "عنوان تست",
            "titleInstall": "راهنمای نصب",
            "loremFa": "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
            "loremFa2": "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد ",
            "discriptionInstall": "شما می توانید برای دسترسی بهتر به اپلیکیشن یکی از روش های نصب زیر را انتخاب نمائید",
            "android": "اندروید",
            "ios": "آیفون",
            "web": "وب اپلیکیشن",
            "installAnd": `
            <ul>
                <li>برای نصب اپلیکیشن  در اندروید</li>
                <li>با مرورگر کروم به google.com مراجعه نمائید.</li>
                <li>در حال انتظار برای نمایش پیشنهاد نصب کننده در صفحه شما (این مرحله باید صبور باشید و صفحه خود را در حین دانلود کامل برنامه در Backgournd روشن نگه دارید)</li>
            </ul>`,
            "installIos": `
            <ul>
                <li>در مرورگر safari به google.com مراجعه نمائید.</li>
                <li>بر روی دکمه Add To Home Screen کلیک نمائید.</li>
                <li>دکمه Add را انتخاب نمائید.</li>
                <li>آیکون اپ شما اکنون به صفحه برنامه های شما اضافه شده است.
                روی آن کلیک کنید تا برنامه باز شود</li>
            </ul>`,
            "installWeb": `
            <ul>
                <li>برای اضافه کردن اپلیکیشن برروی دسکتاپ</li>
                <li>با مرورگر کروم به google.com مراجعه نمائید.</li>
                <li>در حال انتظار برای نمایش پیشنهاد نصب کننده در اکسپلورر شما (این مرحله باید برای دانلود کامل برنامه در Backgournd صبور باشید)</li>
            </ul>`
        },
        "auth": {
            "AddressWalletDepisit": "ادرس کیف پول واریزی شما",
            "hashTarakonesh": "کد هش تراکنش را وارد نمایید",
            "AddressWallet": "آدرس کیف پول مبدا را وارد نمایید",
            "InvestmentAmount": "مبلغ سرمایه گذاری",
            "codeEmail": "کد ارسالی از طریق ایمیل را وارد نمایید",
            "userName": "نام کاربری",
            "password": "کلمه عبور",
            "repeatPassword": "تکرار کلمه عبور",
            "forgetPassword": "آیا کلمه عبور خود را فراموش کرده اید؟",
            "personCode": "کد معرف",
            "code": "لطفا کد پرسنلی خود را وارد نمائید",
            "email": "لطفا ایمیل خود را وارد نمائید",
            "recovery": "بازیابی",
            "recoverPassword": "لطفا ایمیل خود را بررسی کنید و روی لینک ارسال شده برای بازیابی رمز عبور کلیک کنید",
            "enterPassword": "لطفا کلمه عبور جدید خود را وارد کنید",
            "passwordRecoverySuccessfully": "عملیات بازیابی رمز عبور با موفقیت به پایان رسید",
            "end": "ورود به سیستم",
            "yourCode": "کد بازاریابی شما:",
            "backToHome": "بازگشت به صفحه اصلی",
            "personInfo": "اطلاعات معرف:",
            "fillCreateAccount": "برای ایجاد حساب کاربری فرم زیر را پر کنید",
            "firstName": "نام",
            "lastName": "نام خانوادگی",
            "selectGender": "لطفا جنسیت را انتخاب نمائید",
            "men": "آقا",
            "famel": "خانم",
            "mobile": "تلفن همراه",
            "emailTitle": "  ایمیل",
            "passportNo": "شماره پاسپورت",
            "confirmedPassword": "کلمه عبور تایید شده",
            "cityOfResidence": "شهر محل سکونت",
            "heirName": "لطفا نام وارث خود را وارد نمائید",
            "descriptionHeirName": "شخصی که قانونا بعد از شما مالک حساب کاربری شما خواهد بود.",
            "documentsRegistration": "لطفا مدارک مورد نیاز را برای تکمیل ثبت نام بارگذاری کنید"
        },
        "page": {
            "discriptionContact": "اگر به مشاوره نیاز دارید یا با مشکلی مواجه شدید، با ما تماس بگیرید",
            "direct": "ارتباط با مدیریت",
            "email": "ایمیل:",
            "firstName": "نام",
            "lastName": "نام خانوادگی",
            "phone": "شماره تلفن",
            "emailPerson": "ایمیل",
            "caption": "توضیحات",
            "price": "قیمت:",
            "inventory": "موجودی:",
            "colors": "رنگ ها:",
            "captionProduct": "توضیحات محصول"
        },
        "dashbord": {
            "name": "نام:",
            "marketerId": "کد بازاریابی:",
            "charge": "موجودی:",
            "account": "حساب کاربری",
            "dashbord": "داشبورد",
            "information": "اطلاعات",
            "changePass": "تغییر کلمه عبور",
            "chargeWallet": "شارژ کیف پول",
            "transactionDate": "تاریخ تراکنش",
            "team": "تیم",
            "directSell": "فروش مستقیم",
            "order": "سفارش",
            "myOrders": "سفارشات من",
            "award": "پاداش",
            "conect": "انتقال فاکتور",
            "voacher": "ووچر",
            "listVoacher": "لیست ووچرها",
            "passwordRecovery": "بازیابی کلمه عبور",
            "enterPass": "کلمه عبور خود را وارد نمائید",
            "repeatPass": "تکرار کلمه عبور",
            "creditValue": "میزان اعتبار",
            "refCode": "کد ارجاعی",
            "caption": "توضیحات",
            "myCreadit": "تراکنش های اعتباری من",
            "directSellInfo": "اطلاعات فروش مستقیم",
            "peopleRegister": "تعداد افرادی که ثبت نام می کنید:",
            "awardList": "لیست پاداش",
            "conectOther": "انتقال فاکتور به دیگری",
            "selectInvoice": "فاکتور را انتخاب کنید",
            "select person": "فرد را انتخاب کنید",
            "yourAccountConfirmed": "حساب شما در حالت انتظار است. لطفا تا تایید مدیر صبر کنید",
            "descriptionWallet": `
            <ul>
                <li>میزان اعتبار:مقدار ریالی که شارژ کرده اید(از برنامه زرین پال)</li>
                <li>کد ارجاعی:کد مرجعی که برای پیگیری به شما داده شده است(از برنامه زرین پال)</li>
                <li>تاریخ تراکنش:تاریخ واریز به حساب شرکت می باشد.</li>
                <li>توضیحات:توضیحات شما برای ادمین سیستم می باشد.</li>
            </ul>`,
            "descriptionCreadit": `
            <ul class="list-group">
							<li>توضیحات:شرح تراکنش شما</li>
							<li>کد ارجاعی:کد مرجعی که برای پیگیری به شما داده شده است.</li>
							<li>تاریخ: تاریخ ثبت</li>
							<li>اعتبار:مبلغ تراکنش شما به ریال است.</li>
							<li>
								وضعیت:<br />
								انواع وضعیت تراکنش شما:<br />
								1. (در انتظار تأیید) اگر در انتظار تأیید است (باید صبر کنید تا توسط مدیر سیستم تأیید شود)<br />
								2. (مورد تایید) در صورتی که مورد تایید مدیر یا سیستم تراکنش شما باشد
							</li>
							<li>
                            نوع:<br />
                            انواع نوع تراکنش شما: 2 حالت در اینجا گنجانده شده است<br />
                            1. (افزایش) اگر نوع تراکنش شما سپرده حساب است<br />
                            2. (کاهش) اگر نوع تراکنش شما برداشت از حساب است
							</li>
						</ul> `,
            "descriptionCreadit": `
            <ul class="list-group">
            <li>نام شخص:نام و نام خانوادگی که از طریق کد بازاریابی شما ثبت نام کرده اند</li>
            <li>کد شخصی:کد بازاریابی که از طریق کد بازاریابی شما ثبت نام کرده است</li>
            <li>فروش کل: کل فروش هایی که ثبت شده است</li>
            <li>مجموع امتیاز: کل امتیازی که ثبت شده است</li>
            <li>اعتبار ذخیره شده:مقدار ذخیره شده در حساب پس انداز شما به ریال است</li>
        </ul>`,
            "descriptionCreadit": `
        <ul class="list-group">
							<li>
                            نوع پرداخت:<br/>
                            نوع پرداخت شامل 2 حالت می باشد:<br />
                            1. (با اعتبار): زمانی که فاکتور را از کیف پول آنلاین خود پرداخت می کنید<br />
                            2. (پرداخت آنلاین) هنگام اتصال آنلاین به درگاه پرداخت و پرداخت مبلغ فاکتور
							</li>
							<li>فروشگاه: فروشگاه یا شعبه ای که خرید شما در آن ثبت شده است</li>
							<li>توضیحات: شرح فاکتور ثبت شده توسط شما</li>
							<li>
                            وضعیت:<br />
                            وضعیت فاکتور شما شامل 2 حالت است:<br />
                            1. (پرداخت) زمانی که مبلغ فاکتور خود را پرداخت کردید (از طریق پرداخت آنلاین یا پرداخت از کیف پول آنلاین خود)<br />
                            2. (بدون پرداخت): در این صورت فاکتور شما پرداخت نشده است. شما باید در اسرع وقت فاکتور خود را پرداخت کنید در غیر این صورت فاکتور شما حذف خواهد شد
							</li>
							<li>
                            جمع پرداختی:<br />
                            مبلغ قابل پرداخت: مبلغ نهایی که باید پرداخت کنید (پس از اعمال موارد مالی مانند تخفیف سیستم، مالیات و غیره)
							</li>
							<li>باقیمانده: مبلغ باقیمانده از فاکتور شما که هنوز پرداخت نشده است</li>
							<li>مبلغ کل: مجموع کل فاکتور بدون اعمال اقلام مالی</li>
							<li>واحد پول: ارز ثبت شده برای فاکتور شما</li>
							<li>هزینه حمل و نقل: هزینه حمل و نقل فاکتور شما</li>
							<li>سریال: یک زمینه منحصر به فرد است و گزینه بسیار مهمی برای پیگیری شماست</li>
						</ul>`,
            "descriptionAward": `
                        <ul class="list-group">
							<li>کل پورسانت: کل مبلغ پورسانت شما که توسط سیستم محاسبه می شود (این مبلغ نهایی نیست که باید به شما پرداخت شود)</li>
							<li>پورسانت نهایی: مبلغ نهایی پورسانت شما که به شما پرداخت می شود</li>
							<li>خط 1: کل فروش ثبت شده در بزرگترین شاخه شما</li>
							<li>خط 2: کل فروش ثبت شده در دومین شاخه بزرگ شما</li>
							<li>تاریخ: تاریخ محاسبه پورسانت شما توسط سیستم</li>
						</ul>`,
            "descriptionWallet": `
                        <ul class="list-group">
									<li>
                                    انتخاب فاکتور:<br/>
                                    در این لیست کشویی، لیستی از فاکتورهای قابل انتقال به شاخه های دیگر را مشاهده خواهید کرد<br />
                                    اگر گزینه ای برای انتخاب وجود ندارد به این معنی است که فاکتوری ندارید که شرایط انتقال را داشته باشد.
									</li>
									<li>
                                    انتخاب فرد:<br />
                                    در این لیست کشویی لیست افرادی را خواهید داشت که با کد شما در سامانه ثبت نام کرده اند (فقط یک نفر می تواند توسط شما انتخاب شود)<br />
                                    اگر فاکتوری را به شخصی متصل کنید، آن فاکتور از لیست فاکتورهای قابل انتقال حذف می شود (اما در لیست سفارشات من خواهد بود).<br />
                                    انتخاب فرد: در این لیست کشویی، لیست افرادی که ثبت نام کرده اند را خواهید داشت
									</li>
								</ul>`,
            "descriptionVoacher": `
                        <ul class="list-group">
                                   <li>ووچر نهایی:مبلغ ووچر در تاریخ مشخص شده محاسبه شده و به شما اختصاص داده می شود</li>
                                    <li>تاریخ: تاریخ محاسبه ووچر شما</li>
                        </ul>`
        }
    },
    "errors": {
        "heirName": "نام وارث اجباری  است",
        "confirmPassword": "تکرار رمز عبور اجباری  است",
        "password": "پسورد اجباری است",
        "userName": "نام کاربری اجباری است",
        "AddressWallet": "آدرس کیف پول اجباری است",
        "transactionHash": "کد هش تراکنش اجباری است",
        "invalidCode": "کد وارد شده صحیح نمی باشد",
        "notValidateCode": "کد وارد شده صحیح نیست",
        "validateCode": "کد ارسالی اجباری است",
        "waitApproval": "منتظر تائید مدیر باشید",
        "creditRequired": "میزان اعتبار الزامی است",
        "refRequired": "کد ارجاع الزامی است",
        "transactionDatRrequired": "تاریخ تراکنش الزامی می باشد ",
        "notLogged": "شما هنوز وارد نشده اید",
        "currentPassRequired": "کلمه عبور فعلی الزامی است",
        "newPassRequired": "کلمه عبور جدید الزامی است",
        "confirmedPassRequired": "تائید کلمه عبور الزامی است",
        "invalideCurrentPass": "کلمه عبور نامعتبر است",
        "notAccessChangePassword": "شما به این بخش دسترسی ندارید",
        "passWordRepetitionNotSame": "تکرار کلمه عبور نامعتبر است",
        "personRequid": "انتخاب فرد الزامی است",
        "invoiceRequid": "انتخاب فاکتور الزامی است",
        "transferSuccessful": "انتقال موفقیت آمیز بود",
        "paymentEncounteredError": "پرداخت شما با خطا مواجه شد",
        "pleaseChargeYourAccount": "لطفا حساب خود را شارژ کنید",
        "cahrgeAccont": "شارژ حساب",
        "nameIsRequired": "نام الزامی می باشد",
        "lastNameIsRequired": "نام خانوادگی الزامی می باشد",
        "mobilRequired": "تلفن همراه الزامی می باشد",
        "emeilRequired": "ایمیل الزامی می باشد",
        "emailCode": "کد ارسالی از طریق ایمیل اجباری است",
        "investmentAmount": "مبلغ شارژ کیف پول اجباری است",
        "passportNoRequired": "پاسپورت الزامی می باشد",
        "phoneNumberIsRequired": "شماره تلفن الزامی می باشد",
        "provinceIsRequired": "استان الزامی می باشد",
        "cityIsRequired": "شهر الزامی می باشد",
        "postalCodeIsRequired": "کدپستی الزامی می باشد",
        "personGenderIdRequired": "جنسیت فرد الزامی می باشد",
        "plaqueIsRequired": "پلاک الزامی می باشد",
        "addressIsRequired": "آدرس الزامی می باشد",
        "personCodeRequired": "کد بازاریابی الزامی می باشد",
        "heirNameRequired": "نام وارث الزامی می باشد",
        "invalidPersonCode": "کد بازاریابی نامعتبر است",
        "passwordRequired": "کلمه عبور الزامی باشد",
        "confirmedPasswordRequired": "تائید کلمه عبور الزامی می باشد",
        "passwordAndConfirmedPasswordNotMatch": "کلمه عبور و کلمه عبور تائیدشده مطابقت ندارند",
        "passportImageFileRequired": "عکس صفحه اول پاسپورت الزامی می باشد",
        "representativeNotFound": "معرف یافت نشد",
        "youHaveAlreadyRegisteredWithThisProfile": "شما قبلاً با این نمایه ثبت نام کرده اید",
        "invalidFile": "فایل نامعتبر است"


    },
    "card": {
        "card": "سبد خرید",
        "userInformation": "اطلاعات کاربر",
        "invoicePreview": "پیش نمایش فاکتور",
        "payment": "پرداخت",
        "shoppingEmpty": "سبد خرید شما خالی است",
        "returnMainPage": "بازگشت به صفحه اصلی",
        "yourShoppingCart": "سبد خرید شما",
        "Items": "موارد",
        "productName": "نام محصول:",
        "count": "تعداد:",
        "price": "قیمت:",
        "color": "رنگ:",
        "storeName": "نام فروشگاه:",
        "inventory": "موجودی:",
        "delivery": "تحویل:",
        "freeDelivery": "تحویل رایگان",
        "priceQuantity": "قیمت بر اساس تعداد:",
        "completingCart": "تکمیل سبد خرید",
        "numberShoppingCart": "تعداد محصولات موجود در سبد خرید:",
        "totalShoppingCart": "کل سبد خرید:",
        "shippingCostDescription": "هزینه ارسال بر اساس آدرس، زمان تحویل، وزن و حجم محموله شما محاسبه می شود.",
        "name": "نام:",
        "lastName": "نام خانوادگی:",
        "phoneNumber": "شماره تلفن:",
        "address": "آدرس:",
        "plaque": "پلاک:",
        "postalCode": "کدپستی:",
        "Selected": "انتخاب شد",
        "Select": "انتخاب کنید",
        "addNewAddress": "افزودن آدرس جدید",
        "receiverInformation": "اطلاعات گیرنده",
        "explanationDescribe": "در صورتی که فاکتور شما نیاز به توضیحات بیشتری برای ما دارد در کادر زیر وارد کنید",
        "goodsPurchased": "تعداد کالاهای خریداری شده:",
        "Item": "مورد",
        "postType": "نوع پست:",
        "normalDelivery": "تحویل عادی",
        "shippingCost": "هزینه حمل و نقل:",
        "free": "رایگان",
        "nameProduct": "نام محصول:",
        "secendPrice": "قیمت دوم:",
        "returnToCart": "بازگشت به سبد خرید",
        "finalPayment": "پرداخت نهایی",
        "personCode": "کد فرد",
        "customerInformation": "اطلاعات مشتری",
        "mobile": "تلفن همراه:",
        "address": "آدرس:",
        "invoiceInformation": "اطلاعات فاکتور",
        "paybleAmount": "مبلغ قابل پرداخت :",
        "totalAmont": "مبلغ کل:",
        "discountAmount": "میزان تخفیف",
        "paidAmount": "مبلغ پرداخت شده:",
        "totalRemainAmount": "کل مبلغ باقیمانده:",
        "transportCostAmount": "مبلغ هزینه حمل و نقل:",
        "packagingCostAmount": "مبلغ هزینه بسته بندی:",
        "taxAmount": "مبلغ مالیات:",
        "shopInformation": "اطلاعات فروشگاه",
        "serialFactor": "سریال:",
        "invoicePaymentTypeName": "نحوه پرداخت:",
        "orderList": "لیست سفارش",
        "titleProductName": "نام محصول",
        "productColor": "رنگ محصول",
        "titleSerialFactor": "سریال فاکتور",
        "productCount": "تعداد محصول",
        "basePrice": "قیمت پایه",
        "totalPrice": "قیمت کل",
        "paymentSuccessfullyCompleted": "پرداخت شما با موفقیت انجام شد",
        "showInvoice": "نمایش فاکتور",
        "dashbord": "داشبورد",
        "shop": "فروشگاه",
        "firstName": "نام",
        "lastName": "نام خانوادگی",
        "phoneNumber": "شماره تلفن",
        "receiverAddress": "آدرس گیرنده",
        "provinceOfResidence": "استان محل سکونت",
        "cityOfResidence": "شهر محل سکونت",
        "enterTheAddress": "آدرس را وارد نمائید",
        "titlePostalCode": "کدپستی",
        "titlePlaque": "پلاک",
        "add": "افزودن"
    }

}