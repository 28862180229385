

// initial state
const state = () => ({
	baseUrl: ''
})

// getters
const getters = {
}

// actions
const actions = {
	setBaseUrl({ commit }) {
		commit('setBaseUrl');
	}
}

// mutations
const mutations =
{
	async setBaseUrl(state) {

		const url = `${process.env.BASE_URL}config/server.json?v=${Date.now()}`
		const response = await fetch(url);
		const config = await response.json();

		const env = process.env.NODE_ENV
		const stage = process.env.VUE_APP_STAGE

		state.baseUrl = config[env][stage].baseUrl;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
