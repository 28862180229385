<template>
	<table class="table table-bordered table-striped">
		<thead>
			<tr>
				<th style="width:20px" v-if="selectable">
					<input type="checkbox" class="form-check-input"
						   v-on:change="checkAll" v-bind:checked="checkedLen() == items.length" />
				</th>
				<th v-if="showRowNumber">#</th>
				<th v-for="prop in getProps()" v-bind:key="prop.name">
					{{ $loc.fromResource(prop.resourceKey) }}
				</th>
			</tr>
		</thead>
		<tbody>
			<row-tree v-for="(item,index) in items"
					  v-on:checked="check"
					  :key="index"
					  :selectable="selectable"
					  :showRowNumber="showRowNumber"
					  :currentPage="currentPage"
					  :pageSize="pageSize"
					  :columns="columns"
					  :task="task"
					  :index="index"
					  :data="item" />
		</tbody>
	</table>

	<table-pagination :total="totalRows"
					  :pageSize="pageSize"
					  :current="currentPage"
					  :totalfilters="totalfilters"
					  v-on:change="(obj)=> $emit('changePage', obj )" />
</template>
<script>

	import rowTree from './rows/row-tree'
	import tablePagination from './common/table-pagination'

	export default {
		emits: ['changePage', 'checked'],
		components:
		{
			'row-tree': rowTree,
			'table-pagination': tablePagination
		},

		props:
		{
			'items': Array,
			'actions': Array,
			'entity': Function,
			'totalRows': Number,
			'totalfilters': Number,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'task': Function,

			'showRowNumber': {
				type: Boolean,
				default: true
			},

			'showError': {
				type: Boolean,
				default: false
			},

			'showForeignKey': {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				columns: []
			}
		},

		beforeMount()
		{
			this.initColumns()
		},

		beforeUpdate()
		{
			this.initColumns()
		},

		methods:
		{
			initColumns()
			{
				var columns = {}
				for (var item of this.items)
				{
					for (const key of Object.keys(item.value || {}).concat(this.showError ? Object.keys(item.error || {}) : []))
					{
						columns[key] = true
					}
				}

				this.columns = Object.keys(columns)
			},

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || this.columns.indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			check()
			{
				this.$emit('checked', this.getChecked())
			},

			checkAll(e)
			{
				for (const item of this.items)
				{
					item.checked = e.target.checked
				}

				this.$emit('checked', this.getChecked())
			},

			getChecked()
			{
				var items = []
				for (const item of this.items)
				{
					if (item.checked == true) { items.push(item) }
				}

				return items
			},

			checkedLen()
			{
				var len = 0
				for (const item of this.items) { len += item.checked ? 1 : 0 }

				return len
			}
		},
	}
</script>
