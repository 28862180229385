//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonBankAccountTypeService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonBankAccountType.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-bank-account-type/detail', {'params':params});
	};

	//GeneratorFlag:[PersonBankAccountType.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-bank-account-type/list', {'params':params});
	};

//GeneratorFlag:[NewAction]
}