<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { PersonDocumentType } from '../../data'
	import { PersonDocumentTypeFilter } from '../../data/filters'
	import { PersonDocumentTypeService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return PersonDocumentType;
			},
			
			filterEntity()
			{
				return PersonDocumentTypeFilter;
			},

			service()
			{
				return new PersonDocumentTypeService();
			},

			filterData()
			{
				const personDocumentTypeId =
					this.$route.query['personDocumentTypeId'];

				if (personDocumentTypeId)
				{
					return {
						'personDocumentTypeId': personDocumentTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
