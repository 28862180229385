//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class TicketService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Ticket.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket/create', params || { });
	};

	//GeneratorFlag:[Ticket.Delete]
	async delete(ticketId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:ticketId
		}
	
		return await _server.delete('/ticketing/ticket/delete', {'params':params})
	};

	//GeneratorFlag:[Ticket.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/ticketing/ticket/detail', {'params':params});
	};

	//GeneratorFlag:[Ticket.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/ticketing/ticket/list', {'params':params});
	};

	//GeneratorFlag:[Ticket.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket/update', params || { });
	};

	//GeneratorFlag:[Ticket.GetItems]
	async getItems(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/ticketing/ticket/get-items', {'params':params});
	};

	//GeneratorFlag:[Ticket.MyList]
	async myList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/ticketing/ticket/my-list', {'params':params});
	};

	//GeneratorFlag:[Ticket.Send]
	async send(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket/send', params || { });
	};

	//GeneratorFlag:[Ticket.Close]
	async close(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket/close', params || { });
	};

//GeneratorFlag:[NewAction]
}