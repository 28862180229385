//GeneratorFlag:[NoChange]
export default {
	path: 'rank',
	name: 'peopleNet.rank.layout',
	redirect: to => {
		return {name: 'peopleNet.rank.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.rank.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.rank.list',
			component: () => import('@/modules/people-net/views/rank/rank-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.rank.create',
			component: () => import('@/modules/people-net/views/rank/rank-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.rank.import',
			component: () => import('@/modules/people-net/views/rank/rank-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.rank.update',
			component: () => import('@/modules/people-net/views/rank/rank-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.rank.delete',
			component: () => import('@/modules/people-net/views/rank/rank-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.rank.detail',
			component: () => import('@/modules/people-net/views/rank/rank-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
