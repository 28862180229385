//GeneratorFlag:[NoChange]
export default {
	path: 'product-store',
	name: 'shop.productStore.layout',
	redirect: to => {
		return {name: 'shop.productStore.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/product-store"],
		resourceKey: 'shop.views.productStore.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductStoreDelete]"
		{
			path: 'delete',
			name: 'shop.productStore.delete',
			component: () => import('@/modules/shop/views/product-store/product-store-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStore.delete',
				clientAccessKeys:["/shop/product-store/delete"],
				serverAccessKeys:["/shop/product-store/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductStoreDetail]"
		{
			path: 'detail',
			name: 'shop.productStore.detail',
			component: () => import('@/modules/shop/views/product-store/product-store-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStore.detail',
				clientAccessKeys:["/shop/product-store/detail"],
				serverAccessKeys:["/shop/product-store/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductStoreList]"
		{
			path: 'list',
			name: 'shop.productStore.list',
			component: () => import('@/modules/shop/views/product-store/product-store-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStore.list',
				clientAccessKeys:["/shop/product-store/list"],
				serverAccessKeys:["/shop/product-store/list","/shop/product-detail/list","/shop/store/list","/shop/product/list","/shop/product-color/list","/shop/product-monetary-feature/list","/shop/culture/list","/shop/product-status/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductStoreCreate]"
		{
			path: 'create',
			name: 'shop.productStore.create',
			component: () => import('@/modules/shop/views/product-store/product-store-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStore.create',
				clientAccessKeys:["/shop/product-store/create"],
				serverAccessKeys:["/shop/product-store/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductStoreImport]"
		{
			path: 'import',
			name: 'shop.productStore.import',
			component: () => import('@/modules/shop/views/product-store/product-store-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStore.import',
				clientAccessKeys:["/shop/product-store/import"],
				serverAccessKeys:["/shop/product-store/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductStoreUpdate]"
		{
			path: 'update',
			name: 'shop.productStore.update',
			component: () => import('@/modules/shop/views/product-store/product-store-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStore.update',
				clientAccessKeys:["/shop/product-store/update"],
				serverAccessKeys:["/shop/product-store/save","/shop/product-detail/list","/shop/store/list","/shop/product/list","/shop/product-color/list","/shop/product-monetary-feature/list","/shop/culture/list","/shop/product-status/list","/shop/category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






