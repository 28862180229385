//GeneratorFlag:[NoChange]
import { BaseProductCategoryCategoryProductRelatedAction } from '@/modules/shop/base/data/actions/product-category/category-product-related-action'
export default class ProductCategoryCategoryProductRelatedAction extends BaseProductCategoryCategoryProductRelatedAction
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			productDetailId: {
				...this.props.productDetailId,
				setConfig:{
					...this.props.productDetailId.setConfig
				}
			},
			imagePath: {
				...this.props.imagePath,
				setConfig:{
					...this.props.imagePath.setConfig
				}
			},
			basePrice: {
				...this.props.basePrice,
				setConfig:{
					...this.props.basePrice.setConfig
				}
			},
			secondPrice: {
				...this.props.secondPrice,
				setConfig:{
					...this.props.secondPrice.setConfig
				}
			},
			categoryName: {
				...this.props.categoryName,
				setConfig:{
					...this.props.categoryName.setConfig
				}
			},
		};
	}
}

