import { createApp } from 'vue'

import './assets/fonts/iransans-x/fontiran.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'toastr/build/toastr.css'
import './assets/css/app.css'
import './assets/css/popper-theme.css'
import './registerServiceWorker'
import "toastify-js/src/toastify.css"
import './assets/css/toastr-custom.css'


import App from './App.vue'
import launcher from './launcher'
import FrcForm from './components/form/form'
import FrcTable from './components/table/table'
import FrcList from './components/list/list'
import FrcPopup from './components/popup/popup'
import FrcCollapse from './components/collapse/collapse'
import FrcButton from './components/button/button'
import FrcExcelImport from './components/excel/excel-import'
import FrcIconBootstrap from './components/icon/icon-bootstrap'
import FrcTaskManager from './components/task/task-manager'
import FrcOverlay from './components/overlay/overlay'
import FrcContent from './components/content/content'
import popper from 'vue3-popper'
import DatePicker from 'vue3-persian-datetime-picker'
import FrcOrd from './components/ord/ord-container'
import FrcTree from './components/tree/tree-base'
import FrcTreeNode from './components/tree/tree-node'
import FrcTextEditor from './components/text-editor/text-editor'
import FrcOrdNode from './components/ord/ord-node'
import FrcDropdown from './components/dropdown/dropdown.vue'
const app = createApp(App)

app.component('Frc-button', FrcButton)
app.component('Frc-dropdown', FrcDropdown)
app.component('Frc-table', FrcTable)
app.component('Frc-list', FrcList)
app.component('Frc-form', FrcForm)
app.component('Frc-popup', FrcPopup)
app.component('Frc-ord', FrcOrd)
app.component('Frc-ord-node', FrcOrdNode)
app.component('Frc-excel-import', FrcExcelImport)
app.component('Frc-task-manager', FrcTaskManager)
app.component('Frc-icon-bootstrap', FrcIconBootstrap)
app.component('Frc-content', FrcContent)
app.component('Frc-overlay', FrcOverlay)
app.component('Frc-collapse', FrcCollapse)
app.component('date-picker', DatePicker)
app.component('Frc-text-editor', FrcTextEditor)
app.component('Frc-tree', FrcTree)
app.component('Frc-tree-node', FrcTreeNode)
app.component('popper', popper)

launcher.launch(app)
