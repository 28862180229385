//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonAppPurchaseService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonAppPurchase.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-app-purchase/create', params || { });
	};

	//GeneratorFlag:[PersonAppPurchase.Delete]
	async delete(personAppPurchaseId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:personAppPurchaseId
		}
	
		return await _server.delete('/people-net/person-app-purchase/delete', {'params':params})
	};

	//GeneratorFlag:[PersonAppPurchase.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-app-purchase/detail', {'params':params});
	};

	//GeneratorFlag:[PersonAppPurchase.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-app-purchase/list', {'params':params});
	};

	//GeneratorFlag:[PersonAppPurchase.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-app-purchase/update', params || { });
	};

	//GeneratorFlag:[PersonAppPurchase.CheckCredit]
	async checkCredit(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-app-purchase/check-credit', params || { });
	};

//GeneratorFlag:[NewAction]
}