//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class InvoiceStatuseService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(invoiceStatuseId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:invoiceStatuseId
		}

		return await _server.get('/shop/invoice-statuse/find', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/shop/invoice-statuse/list', {'params':params})
	};
}
