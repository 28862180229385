//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Invoice, InvoiceTransactionType } from '../../data'; 

import { InvoiceService, InvoiceTransactionTypeService } from '../../services'; 

export default class BaseInvoiceTransaction extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'invoiceTransaction';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.invoiceTransaction.self';
	props = {
		invoiceTransactionId: {
			primarykey: true,
			name: 'invoiceTransactionId',
			resourceKey: 'shop.invoiceTransaction.invoiceTransactionId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceId: {
			foreignKey: true,
			name: 'invoiceId',
			resourceKey: 'shop.invoiceTransaction.invoiceId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Invoice(),
			data: async (params) =>
			{
				const service =
					new InvoiceService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'displayCheckoutDate',
				valueKey: 'invoiceId',
				patterns: [],
			}
		},
		amount: {
			name: 'amount',
			resourceKey: 'shop.invoiceTransaction.amount',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		createdDate: {
			name: 'createdDate',
			resourceKey: 'shop.invoiceTransaction.createdDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		displayCreatedDate: {
			name: 'displayCreatedDate',
			resourceKey: 'shop.invoiceTransaction.displayCreatedDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		invoiceTransactionTypeId: {
			foreignKey: true,
			name: 'invoiceTransactionTypeId',
			resourceKey: 'shop.invoiceTransaction.invoiceTransactionTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new InvoiceTransactionType(),
			data: async (params) =>
			{
				const service =
					new InvoiceTransactionTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'invoiceTransactionTypeId',
				patterns: [],
			}
		},
		invoice: {
			name: 'invoice',
			resourceKey: 'shop.invoiceTransaction.invoice',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Invoice(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{displayCheckoutDate}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceTransactionType: {
			name: 'invoiceTransactionType',
			resourceKey: 'shop.invoiceTransaction.invoiceTransactionType',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new InvoiceTransactionType(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceTransactionSubInfo: {
			name: 'invoiceTransactionSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
