//GeneratorFlag:[NoChange]
import { BasePersonGetMyTeamList } from '@/modules/people-net/base/data/actions/person/get-my-team-list'
export default class PersonGetMyTeamList extends BasePersonGetMyTeamList
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			firstName: {
				...this.props.firstName,
				setConfig:{
					...this.props.firstName.setConfig
				}
			},
			lastName: {
				...this.props.lastName,
				setConfig:{
					...this.props.lastName.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			parentName: {
				...this.props.parentName,
				setConfig:{
					...this.props.parentName.setConfig
				}
			},
			levelGenerationOfMe: {
				...this.props.levelGenerationOfMe,
				setConfig:{
					...this.props.levelGenerationOfMe.setConfig
				}
			},
			mobile: {
				...this.props.mobile,
				setConfig:{
					...this.props.mobile.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
		};
	}
}

