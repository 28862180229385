const PROP_TYPE =
{
	TIME: 'time',
	DATE: 'date',
	PERSIANDATE: 'pdate',
	STRING: 'string',
	NUMBER: 'number',
	BYTE: 'byte',
	INT16: 'int16',
	UINT16: 'uint16',
	INT32: 'int32',
	UINT32: 'uint32',
	INT64: 'int64',
	UINT64: 'uint64',
	SINGLE: 'single',
	DOUBLE: 'double',
	DECIMAL: 'decimal',
	OBJECT: 'object',
	ARRAY:'array',
	BOOLEAN:'boolean',
};


export default Object.freeze(PROP_TYPE);
