//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonAccessService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonAccess.ChangePassword]
	async changePassword(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-access/change-password', params || { });
	};

	//GeneratorFlag:[PersonAccess.CheckAccess]
	async checkAccess(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-access/check-access', params || { });
	};

	//GeneratorFlag:[PersonAccess.DeactiveToken]
	async deactiveToken(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-access/deactive-token', params || { });
	};

	//GeneratorFlag:[PersonAccess.Generate]
	async generate(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-access/generate', params || { });
	};

	//GeneratorFlag:[PersonAccess.FortgetPassword]
	async fortgetPassword(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-access/fortget-password', params || { });
	};

//GeneratorFlag:[NewAction]
}