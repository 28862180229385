<template>
	<div class="row">
		<div class="col">
			<input type="text" class="form-control border-0" v-model="filter" @keyup="filterChanged" :placeholder="$loc.fromResource('action.searchPoint')" />
		</div>
	</div>
	<div class="row mt-2">
		<Frc-overlay :is-loading="state == 'loading'" :is-empty="persones.length == 0" class="col">
			<person-tree-node :items="persones" @change="handleChange" :fromDate="fromDate" :toDate="toDate" />
		</Frc-overlay>
	</div>
</template>
<script>
	import { array } from '@/plugins'
	import { PersonService } from '@/modules/people-net/services'
	import PersonTreeNode from './person-tree-node'

	export default {
		emits: ['change'],
		components: {
			'person-tree-node': PersonTreeNode 
		},

		props: {
			fromDate: {
				type: String,
			},
			toDate: {
				type: String
			}
		},

		data()
		{
			return {
				filter: "",
				state: "loading",
				persones: [],
				flatPersones: [],
				flatHistoryPersones: [],
			}
		},

		beforeMount()
		{
			this.init()
		},

		methods: {

			async init(personId)
			{
                
				var items = [];// [{ "personId": 2, "personCode": "aaa", "firstName": "sadegh", "parentId": 1, "lastName": "No Last Name", "companyRankName": "One Star", "teamRankName": "Default", "companyRanId": 1, "teamRankId": 1, "memberCount": 9, "level": 1, "fullName": "No First Name No Last Name" }];

				try
				{
					this.state = 'loading';
                    var payload = { includeReportSell: false, personId: null }

					if (this.fromDate && this.toDate)
					{
						payload.includeReportSell = true
						payload.fromDateDisply = this.fromDate
						payload.toDateDisply = this.toDate
					}

					var response =
						await new PersonService().treeList(payload, { HSR: false });

					if (response.data.isSuccess)
					{
                        items = [...items, ...(response.data.meta.items || [])]
					}

				} catch (e) { this.state = "error"; }


				this.bindData(items)
				this.flatPersones = items;
				this.flatHistoryPersones = items;
			},

			bindData(items)
			{
				this.state = items.length == 0 ? "empty" : "ready";
				this.persones = array.toNested(items, 'personId', 'parentId');
			},

			bindCheckedParent(item, checked, items)
			{
				if (checked == false)
				{
					items.filter((x) =>
					{
						if (x.personId == item.parentId)
						{
							x.checked = checked;
							this.bindCheckedParent(x, checked, items)
						}
					});
				}
			},

			filterChanged(e)
			{
				const value = e.target.value
				const keys = ['firtName', 'lastName'];

				this.bindData(array.filter(this.flatHistoryPersones, value, keys))
			},

			handleChange(node)
			{
				var persones =
					JSON.parse(JSON.stringify(this.flatHistoryPersones));

				this.bindCheckedChildren(node, node.checked, persones)
				this.bindCheckedParent(node, node.checked, persones)
				this.bindData(persones)

				var checkeds = persones.filter((x) =>
				{
					return x.checked == true && x.personTypeId == 3;
				});

				this.flatHistoryPersones = persones;
				this.$emit('change', checkeds)
			},
		}
	}
</script>
