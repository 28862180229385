//GeneratorFlag:[NoChange]
import { BasePersonStatuse } from '../base/data'
export default class PersonStatuse extends BasePersonStatuse
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personStatuseId: {
				...this.props.personStatuseId,
				setConfig:{
					...this.props.personStatuseId.setConfig
				}
			},
			personStatuseKey: {
				...this.props.personStatuseKey,
				setConfig:{
					...this.props.personStatuseKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
