<template>
	<Frc-collapse :collapsed="isParent">
		<template v-slot:header>
			<span>{{ $loc.fromResource(resourceKey) }}</span>
			<small v-if="isRequired" class="text-danger"> * </small>
			<span v-if="showError" class="text-danger float-end me-2 ms-2">
				<Frc-icon-bootstrap size="17px" name="bi-exclamation-circle" />
			</span>
		</template>
		<template v-slot:body>
			<template :key="i" v-for="(prop,i) in props">

				<form-collapse v-if="prop.type == 'object' && prop.entity"
							   :is-parent="false"
							   :entity="prop.entity"
							   :prop-resource-key="prop.resourceKey"
							   :use-validation="useValidation"
							   :is-required="entity.propHasRequired(prop)" />


				<form-control v-else :prop="prop" v-on:submit="$parent.submit" :use-validation="useValidation" />

			</template>
		</template>
	</Frc-collapse>
</template>
<script>
	import formControl from './form-control'
	export default {

		components: {
			'form-control': formControl
		},

		props: {

			'entity': {
				type: Object
			},

			'isParent': {
				type: Boolean,
				default: true
			},

			'isRequired': {
				type: Boolean,
				default: false
			},

			'useValidation': {
				type: Boolean,
				default: true
			},

			'propResourceKey': {
				type: String,
				default: undefined
			},
		},

		computed:
		{
			showError()
			{
				if (this.entity && this.entity.type == 'object')
				{
					return (this.useValidation && this.entity.getFormErrorLength() > 0)
				}

				return false
			},

			props()
			{
				return this.entity.getFormProps();
			},

			resourceKey()
			{
				return this.propResourceKey || this.entity.resourceKey
			}
		}
	}
</script>
