<template>
	<div class="w-100" :key="ver">
		<div class="bg-light p-3">
			<div class="d-block alert alert-danger" v-html="$loc.fromResource('com.excel.fileHasError')"></div>
			<div class="d-block">
				<span v-for="item in errorIndexes" :key="item">
					[{{item + 1}}] 
				</span>
			</div>
			<div class="mt-3">
				<button type="button" @click="$emit('changeState','select')" class="btn btn-light border me-2">
					{{ $loc.fromResource('com.excel.backToSelectFile') }}
				</button>
				<button type="button" @click="$emit('review', data)" class="btn btn-dark border">
					{{ $loc.fromResource('com.excel.review') }}
				</button>
			</div>

			<div v-if="reviewLength > 0" class="mt-3 border p-3">
				<span>{{ $loc.fromResource('com.excel.NumberOfReviewsPerformed') }} = {{ reviewLength }}</span><br />
				<span class="text-muted">{{ $loc.fromResource('com.excel.reviewMessage')  }}</span>
			</div>
		</div>

		<div class="mt-3">
			<Frc-table :data="data" :entity="entity" :actions="actions" :showError="true" />
		</div>

		<Frc-popup v-if="dataToUpdate" :close="() => { dataToUpdate = null }">
			<excel-parser-modify v-on:complete="updateComplete" :entity="entity" :data="dataToUpdate" />
		</Frc-popup>
	</div>
</template>
<script>

	import excelParserModify from './excel-parser-modify'

	export default {
		emits: ['changeState', 'review'],

		components: {
			'excel-parser-modify': excelParserModify
		},

		props: {

			'entity': {
				type: Function
			},

			'data': {
				type: Array
			},

			'errorIndexes': {
				type: Array
			},

			'reviewLength': {
				type: Number,
				default: 0,
			}
		},

		data()
		{
			return {
				ver: 1,
				dataToUpdate: null
			}
		},

		computed:
		{
			actions()
			{
				var updateAction = {
					resourceKey: 'action.update',
					iconName: 'bi-pen',
					activeMode: 'single',
					task: (items) =>
					{
						this.dataToUpdate = items[0]
					}
				};

				var deleteAction = {
					resourceKey: 'action.delete',
					iconName: 'bi-trash',
					activeMode: 'multiple',
					task: (items) =>
					{
						for (var i = items.length - 1; i >= 0; i--)
						{
							this.data.splice(items[i].rowIndex, 1);
						}

						this.ver = this.ver + 1;
					}
				};


				return [updateAction, deleteAction]
			}
		},

		methods: {
			updateComplete(item)
			{
				const rowIndex =
					this.dataToUpdate.rowIndex;

				this.data[rowIndex] = item;
				this.dataToUpdate = null;
				this.ver = this.ver + 1;
			},
		}
	}
</script>
