//GeneratorFlag:[NoChange]
export default {
	path: 'person',
	name: 'peopleNet.person.layout',
	redirect: to => {
		return {name: 'peopleNet.person.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.person.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.person.list',
			component: () => import('@/modules/people-net/views/person/person-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.person.create',
			component: () => import('@/modules/people-net/views/person/person-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.person.import',
			component: () => import('@/modules/people-net/views/person/person-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.person.update',
			component: () => import('@/modules/people-net/views/person/person-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.person.delete',
			component: () => import('@/modules/people-net/views/person/person-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.person.detail',
			component: () => import('@/modules/people-net/views/person/person-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'person-access-update',
			name: 'peopleNet.person.personAccess.update',
			component: () => import('@/modules/people-net/views/person/person-access-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.updatePersonAccess',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'person-detail-update',
			name: 'peopleNet.person.personDetail.update',
			component: () => import('@/modules/people-net/views/person/person-detail-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.updatePersonDetail',
				menuConfig: {
					isVisible: false
				}
			}
		},

		{
			path: 'person-access-detail',
			name: 'peopleNet.person.personAccess.detail',
			component: () => import('@/modules/people-net/views/person/person-access-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.personAccessDetail',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'person-detail-detail',
			name: 'peopleNet.person.personDetail.detail',
			component: () => import('@/modules/people-net/views/person/person-detail-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.personDetailDetail',
				menuConfig: {
					isVisible: false
				}
			}
		},

	]
}
