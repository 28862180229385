//GeneratorFlag:[NoChange]
export default {
	path: 'bank-payment-statuse',
	name: 'shop.bankPaymentStatuse.layout',
	redirect: to => {
		return {name: 'shop.bankPaymentStatuse.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/BaseSys/bank-payment-statuse"],
		resourceKey: 'shop.views.bankPaymentStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[BankPaymentStatuseDetail]"
		{
			path: 'detail',
			name: 'shop.bankPaymentStatuse.detail',
			component: () => import('@/modules/shop/views/bank-payment-statuse/bank-payment-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPaymentStatuse.detail',
				clientAccessKeys:["/shop/BaseSys/bank-payment-statuse/detail"],
				serverAccessKeys:["/shop/bank-payment-statuse/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankPaymentStatuseList]"
		{
			path: 'list',
			name: 'shop.bankPaymentStatuse.list',
			component: () => import('@/modules/shop/views/bank-payment-statuse/bank-payment-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPaymentStatuse.list',
				clientAccessKeys:["/shop/BaseSys/bank-payment-statuse/list"],
				serverAccessKeys:["/shop/bank-payment-statuse/list","/shop/culture/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}


