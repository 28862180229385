<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-content width="800px">
		<Frc-form :key="ver" :entity="entity" :task="submit" />
	</Frc-content>
</template>
<script>
	import { Feature } from '../../data/feature/save'
	import { FeatureService } from '../../services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed: 
		{
			entity()
			{
				return Feature
			},
			service()
			{
				return new FeatureService()
			},
		},
		methods:
		{
			async submit(payload)
			{
				const res = 
					await this.service.save(payload);

				if (res.status == 200)
				{
					this.ver += 1
				}
			}
		}
	}
</script>
