<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { PersonAppPurchaseStatuse } from '../../data'
	import { PersonAppPurchaseStatuseFilter } from '../../data/filters'
	import { PersonAppPurchaseStatuseService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return PersonAppPurchaseStatuse;
			},
			
			filterEntity()
			{
				return PersonAppPurchaseStatuseFilter;
			},

			service()
			{
				return new PersonAppPurchaseStatuseService();
			},

			filterData()
			{
				const personAppPurchaseStatuseId =
					this.$route.query['personAppPurchaseStatuseId'];

				if (personAppPurchaseStatuseId)
				{
					return {
						'personAppPurchaseStatuseId': personAppPurchaseStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
