//GeneratorFlag:[NoChange]
export default {
	path: 'person-credit-transaction-type',
	name: 'peopleNet.personCreditTransactionType.layout',
	redirect: to => {
		return {name: 'peopleNet.personCreditTransactionType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.personCreditTransactionType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.personCreditTransactionType.list',
			component: () => import('@/modules/people-net/views/person-credit-transaction-type/person-credit-transaction-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransactionType.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
	]
}
