//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { WalletTypeService, WalletCurrencyService } from '@/modules/financial/services'; 
import { PersonService } from '@/modules/people-net/services'; 
export default class Wallet extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'wallet';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'financial.walletUpdate.self';
	props = {
		walletId: {
			primarykey: true,
			name: 'walletId',
			resourceKey: 'financial.walletUpdate.walletId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		walletTypeId: {
			foreignKey: true,
			name: 'walletTypeId',
			resourceKey: 'financial.walletUpdate.walletTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new WalletTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'walletTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'financial.walletUpdate.personId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'fullName',
				valueKey: 'personId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		walletAmount: {
			name: 'walletAmount',
			resourceKey: 'financial.walletUpdate.walletAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'financial.walletUpdate.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		walletCurrencyId: {
			foreignKey: true,
			name: 'walletCurrencyId',
			resourceKey: 'financial.walletUpdate.walletCurrencyId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new WalletCurrencyService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'walletCurrencyId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}