//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class TicketItemService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[TicketItem.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket-item/create', params || { });
	};

	//GeneratorFlag:[TicketItem.Delete]
	async delete(ticketItemId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:ticketItemId
		}
	
		return await _server.delete('/ticketing/ticket-item/delete', {'params':params})
	};

	//GeneratorFlag:[TicketItem.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/ticketing/ticket-item/detail', {'params':params});
	};

	//GeneratorFlag:[TicketItem.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/ticketing/ticket-item/list', {'params':params});
	};

	//GeneratorFlag:[TicketItem.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket-item/update', params || { });
	};

	//GeneratorFlag:[TicketItem.AdminResponse]
	async adminResponse(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket-item/admin-response', params || { });
	};

	//GeneratorFlag:[TicketItem.Response]
	async response(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/ticketing/ticket-item/response', params || { });
	};

//GeneratorFlag:[NewAction]
}