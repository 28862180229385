//GeneratorFlag:[NoChange]
import { BaseInvoiceStatuse } from '../base/data'
export default class InvoiceStatuse extends BaseInvoiceStatuse
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			invoiceStatuseId: {
				...this.props.invoiceStatuseId,
				setConfig:{
					...this.props.invoiceStatuseId.setConfig
				}
			},
			invoiceStatuseKey: {
				...this.props.invoiceStatuseKey,
				setConfig:{
					...this.props.invoiceStatuseKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
