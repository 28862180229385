<template>
 
    <Frc-ord-node v-for="(node,index) in localItems" :cildren-length="(node.children || []).length" :key="index" :fromDate="fromDate" :toDate="toDate">
        <template v-slot:body>
            <span class="ms-2 me-2">
                <span>{{node.fullName}}</span><br />
                <span>{{node.userName}}</span><br /> 
                <small class="text-muted" v-if="node.memberCount > 0"> {{ `Member Count ${node.memberCount} Person`}}</small>
                <br />
                <small class="text-muted" v-if="node.memberCount > 0"> Level : {{node.level}} </small>
                <br />
                <span v-if="node.personalSell != undefined">

                    Personal sale : {{ node.personalSell }}
                    <br />
                </span>

                <span v-if="node.totalSell != undefined">
                    Sales team : {{ node.totalSell }}
                </span>

                
            </span>
            <br />
            <Frc-button dir="ltr" v-if="node.memberCount > 0" @click="handleExpand(node)" className="btn btn-sm btn-light mt-2 clickable" :loading="node.isLoading">
                <span class="clickable" v-if="!node.isExpand"><i class="bi bi-node-plus clickable"></i> Open</span>
                <span class="clickable" v-else><i class="bi bi-node-minus-fill clickable"></i> Close</span>

            </Frc-button>

        </template>
        <template v-if="hasChildren(node) && node.isExpand" v-slot:node>
            <person-ord-node :nodes="node.children" :is-root="false" :fromDate="fromDate" :toDate="toDate" />
        </template>
    </Frc-ord-node>

</template>
<script>
    import { PersonService } from '@/modules/people-net/services'
    export default {
        emits: ['change'],
        props: {
            nodes: {
                type: Array
            },
            isRoot: {
                type: Boolean,
                default: true,
            },
            fromDate: {
                type: String,
            },
            toDate: {
                type: String
            }
        },

        data() {
            return {
                key: 1,
                localItems: []
            }
        },

        beforeMount() {
            this.localItems = this.nodes

            if (this.isRoot) {
                this.localItems[0].isExpand = true;
            }
        },

        methods:
        {
            hasChildren(node) {
                return (node.children || []).length > 0
            },

            handleChange(node) {
                this.$emit('change', node)
            },

            async handleExpand(node) {
                var items = [];
                var localItems = JSON.parse(JSON.stringify(this.localItems))
                var index = localItems.findIndex((x) => {
                    return x.personId == node.personId
                });

                if (node.children == undefined || (node.children || []).length == 0) {

                    localItems[index].isLoading = true;
                    this.localItems = localItems
                    this.key = this.key + 1


                    try {
                        var payload = { includeReportSell: false, personId: node.personId }

                        if (this.fromDate && this.toDate) {
                            payload.includeReportSell = true
                            payload.fromDateDisply = this.fromDate
                            payload.toDateDisply = this.toDate
                        }

                        var response =
                            await new PersonService().treeList(payload, { HSR: false });

                        if (response.status==200) {
                            items = response.data.items
                        }

                    } catch (e) { console.log(e) }

                    localItems[index].children = items;
                    localItems[index].isLoading = false;
                    localItems[index].isExpand = true;
                    this.localItems = [];
                    this.localItems = localItems
                }
                else {
                    localItems[index].isLoading = false;
                    localItems[index].isExpand = !localItems[index].isExpand;
                    this.localItems = [];
                    this.localItems = localItems
                }

                this.key = this.key + 1
            },
        }
    }
</script>
