//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'


export default class MilitaryTypeFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'militaryType';
	schema = ENUMS.SCHEMA_TYPE.BASEINFO;
	resourceKey = 'peopleNet.militaryType.self';
	props = {
		title: {
			name: 'title',
			resourceKey: 'peopleNet.militaryType.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		caption: {
			name: 'caption',
			resourceKey: 'peopleNet.militaryType.caption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 250,
				patterns: [],
			}
		},

	};
}
