//GeneratorFlag:[NoChange]
import { BaseFeature } from '../base/data'
export default class Feature extends BaseFeature
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			featureId: {
				...this.props.featureId,
				setConfig:{
					...this.props.featureId.setConfig
				}
			},
			featureKey: {
				...this.props.featureKey,
				setConfig:{
					...this.props.featureKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			featureSubInfo: {
				...this.props.featureSubInfo,
				setConfig:{
					...this.props.featureSubInfo.setConfig
				}
			},

		};
	}
}
