//GeneratorFlag:[NoChange]
import { BaseBank } from '../base/data'
export default class Bank extends BaseBank
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			bankId: {
				...this.props.bankId,
				setConfig:{
					...this.props.bankId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			iconUrl: {
				...this.props.iconUrl,
				setConfig:{
					...this.props.iconUrl.setConfig
				}
			},
			bankSubInfo: {
				...this.props.bankSubInfo,
				setConfig:{
					...this.props.bankSubInfo.setConfig
				}
			},

		};
	}
}
