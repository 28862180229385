//GeneratorFlag:[NoChange]
import { BasePersonAddress } from '../base/data'
export default class PersonAddress extends BasePersonAddress
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personAddressId: {
				...this.props.personAddressId,
				setConfig:{
					...this.props.personAddressId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			addressTitle: {
				...this.props.addressTitle,
				setConfig:{
					...this.props.addressTitle.setConfig
				}
			},
			reciverFirstName: {
				...this.props.reciverFirstName,
				setConfig:{
					...this.props.reciverFirstName.setConfig
				}
			},
			reciverLastName: {
				...this.props.reciverLastName,
				setConfig:{
					...this.props.reciverLastName.setConfig
				}
			},
			reciverMobile: {
				...this.props.reciverMobile,
				setConfig:{
					...this.props.reciverMobile.setConfig
				}
			},
			cityId: {
				...this.props.cityId,
				setConfig:{
					...this.props.cityId.setConfig
				}
			},
			fullAddress: {
				...this.props.fullAddress,
				setConfig:{
					...this.props.fullAddress.setConfig
				}
			},
			plaque: {
				...this.props.plaque,
				setConfig:{
					...this.props.plaque.setConfig
				}
			},
			postalCode: {
				...this.props.postalCode,
				setConfig:{
					...this.props.postalCode.setConfig
				}
			},
			city: {
				...this.props.city,
				setConfig:{
					...this.props.city.setConfig
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				}
			},
			personAddressSubInfo: {
				...this.props.personAddressSubInfo,
				setConfig:{
					...this.props.personAddressSubInfo.setConfig
				}
			},

		};
	}
}
