//GeneratorFlag:[NoChange]
import { BaseRank } from '../base/data'
export default class Rank extends BaseRank
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			rankId: {
				...this.props.rankId,
				setConfig:{
					...this.props.rankId.setConfig
				}
			},
			orderRank: {
				...this.props.orderRank,
				setConfig:{
					...this.props.orderRank.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			teamId: {
				...this.props.teamId,
				setConfig:{
					...this.props.teamId.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},
			status: {
				...this.props.status,
				setConfig:{
					...this.props.status.setConfig
				}
			},

		};
	}
}
