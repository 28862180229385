//GeneratorFlag:[NoChange]
import { BaseInvoiceGetMyList } from '@/modules/shop/base/data/actions/invoice/get-my-list'
export default class InvoiceGetMyList extends BaseInvoiceGetMyList
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			fromDisplayDate: {
				...this.props.fromDisplayDate,
				setConfig:{
					...this.props.fromDisplayDate.setConfig
				}
			},
			toDisplayDate: {
				...this.props.toDisplayDate,
				setConfig:{
					...this.props.toDisplayDate.setConfig
				}
			},
			invoiceProductItems: {
				...this.props.invoiceProductItems,
				setConfig:{
					...this.props.invoiceProductItems.setConfig
				}
			},
			invoicePaymentTypeId: {
				...this.props.invoicePaymentTypeId,
				setConfig:{
					...this.props.invoicePaymentTypeId.setConfig
				}
			},
			invoicePaymentTypeTitle: {
				...this.props.invoicePaymentTypeTitle,
				setConfig:{
					...this.props.invoicePaymentTypeTitle.setConfig
				}
			},
			storeTitle: {
				...this.props.storeTitle,
				setConfig:{
					...this.props.storeTitle.setConfig
				}
			},
			description: {
				...this.props.description,
				setConfig:{
					...this.props.description.setConfig
				}
			},
			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			invoiceStatusTitle: {
				...this.props.invoiceStatusTitle,
				setConfig:{
					...this.props.invoiceStatusTitle.setConfig
				}
			},
			totalPayble: {
				...this.props.totalPayble,
				setConfig:{
					...this.props.totalPayble.setConfig
				}
			},
			totalRemain: {
				...this.props.totalRemain,
				setConfig:{
					...this.props.totalRemain.setConfig
				}
			},
			totalAmount: {
				...this.props.totalAmount,
				setConfig:{
					...this.props.totalAmount.setConfig
				}
			},
			unitCurrency: {
				...this.props.unitCurrency,
				setConfig:{
					...this.props.unitCurrency.setConfig
				}
			},
			transportCost: {
				...this.props.transportCost,
				setConfig:{
					...this.props.transportCost.setConfig
				}
			},
			invoiceSerial: {
				...this.props.invoiceSerial,
				setConfig:{
					...this.props.invoiceSerial.setConfig
				}
			},
		};
	}
}

