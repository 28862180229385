import ext from './en-ext.js'
export default {
    "ext": ext,
    "name": "shop", 
    "invoiceGetContracts": {
        "self": "invoice",
        "invoiceId": "InvoiceID",
        "totalAmount": "Total Amount",
        "serial": "Contract Serial",
        "beginDate": "Begin Date",
        "endDate": "End Date",
        "remainTenTimes":"Remain*10"
    },
    "shopMarketer": {
        "ext": {
            "common": {
                "fromDate": "From Date",
                "toDate":"To Date"
            }
        }
    }

}