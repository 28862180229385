//GeneratorFlag:[NoChange]
import { BasePlanLineGetCommisionCurrentWeek } from '@/modules/shop/base/data/actions/plan-line/get-commision-current-week'
export default class PlanLineGetCommisionCurrentWeek extends BasePlanLineGetCommisionCurrentWeek
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			fromDisplayDate: {
				...this.props.fromDisplayDate,
				setConfig:{
					...this.props.fromDisplayDate.setConfig
				}
			},
			toDisplayDate: {
				...this.props.toDisplayDate,
				setConfig:{
					...this.props.toDisplayDate.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			rowID: {
				...this.props.rowID,
				setConfig:{
					...this.props.rowID.setConfig
				}
			},
			personName: {
				...this.props.personName,
				setConfig:{
					...this.props.personName.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
			totalSale: {
				...this.props.totalSale,
				setConfig:{
					...this.props.totalSale.setConfig
				}
			},
			finalTotalSale: {
				...this.props.finalTotalSale,
				setConfig:{
					...this.props.finalTotalSale.setConfig
				}
			},
			linePrecent: {
				...this.props.linePrecent,
				setConfig:{
					...this.props.linePrecent.setConfig
				}
			},
			commission: {
				...this.props.commission,
				setConfig:{
					...this.props.commission.setConfig
				}
			},
			savedValue: {
				...this.props.savedValue,
				setConfig:{
					...this.props.savedValue.setConfig
				}
			},
		};
	}
}

