//GeneratorFlag:[NoChange]
import { BaseFeatureItem } from '../base/data'
export default class FeatureItem extends BaseFeatureItem
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			featureItemId: {
				...this.props.featureItemId,
				setConfig:{
					...this.props.featureItemId.setConfig
				}
			},
			featureItemKey: {
				...this.props.featureItemKey,
				setConfig:{
					...this.props.featureItemKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			featureId: {
				...this.props.featureId,
				setConfig:{
					...this.props.featureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			feature: {
				...this.props.feature,
				setConfig:{
					...this.props.feature.setConfig
				}
			},
			featureItemSubInfo: {
				...this.props.featureItemSubInfo,
				setConfig:{
					...this.props.featureItemSubInfo.setConfig
				}
			},

		};
	}
}
