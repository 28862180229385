//GeneratorFlag:[NoChange]
import { BaseInvoiceTransactionType } from '../base/data'
export default class InvoiceTransactionType extends BaseInvoiceTransactionType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			invoiceTransactionTypeId: {
				...this.props.invoiceTransactionTypeId,
				setConfig:{
					...this.props.invoiceTransactionTypeId.setConfig
				}
			},
			invoiceTransactionTypeKey: {
				...this.props.invoiceTransactionTypeKey,
				setConfig:{
					...this.props.invoiceTransactionTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
