//GeneratorFlag:[NoChange]
export default {
	path: 'menu',
	name: 'peopleNet.menu.layout',
	redirect: to => {
		return {name: 'peopleNet.menu.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.menu.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.menu.list',
			component: () => import('@/modules/people-net/views/menu/menu-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menu.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.menu.create',
			component: () => import('@/modules/people-net/views/menu/menu-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menu.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.menu.import',
			component: () => import('@/modules/people-net/views/menu/menu-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menu.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.menu.update',
			component: () => import('@/modules/people-net/views/menu/menu-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menu.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.menu.delete',
			component: () => import('@/modules/people-net/views/menu/menu-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menu.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.menu.detail',
			component: () => import('@/modules/people-net/views/menu/menu-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menu.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
