<template>
	<div class="border mb-2 p-2 bg-light">
		<div class="row" v-if="showRowNumber">
			<div class="col mb-3">
				<div class="form-check form-switch" v-if="selectable">
					<input type="checkbox"
						   class="form-check-input"
						   v-bind:role="'switch'"
						   v-on:change="check"
						   v-model="data.checked"
						   v-bind:checked="data.checked" />
				</div>
			</div>
			<div class="col mb-3 text-end">
				<div class="badge text-dark border bg-light">
					{{ ((currentPage - 1) * pageSize) + (index + 1) }}
				</div>
			</div>
		</div>
		<div class="row" v-bind:class="{'fw-bold':data.checked}" v-for="(prop) in getProps(data)" :key="prop.name">
			<div class="col col-md-4 col-lg-3">
				<strong class="pe-4 d-block pb-1">
					{{ $loc.fromResource(prop.resourceKey) }}
				</strong>
			</div>
			<div class="col col-md-4 col-lg-9">
				<table-cell :data="prop" :showError="showError" />
			</div>
		</div>
	</div>
</template>
<script>

	import tableCell from '../cells/table-cell'
	export default {
		emits: ['checked'],
		components:
		{
			'table-cell': tableCell,
		},

		props:
		{
			'data': Object,
			'entity': Function,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'columns': Array,
			'index': Number,

			'showRowNumber': {
				type: Boolean,
				default: true
			},

			'showError': {
				type: Boolean,
				default: false
			},

			'showForeignKey': {
				type: Boolean,
				default: false
			}
		},


		methods:
		{

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || (this.columns || []).indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			check()
			{
				this.$emit('checked')
			},

		},
	}
</script>
