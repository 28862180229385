<template>
	<excel-parser v-if="items == null" v-on:complete="parseComplete" :entity="entity" />
	<task-manager v-else
				  v-on:complete="tasksComplete"
				  v-on:cancel="tasksCancel"
				  :entity="entity"
				  :task="task"
				  :data="items"
				  :label="label" />
</template>
<script>

	import taskManager from '@/components/task/task-manager'
	import excelParser from '@/components/excel/parser/excel-parser'

	export default {
		emits: ['complete','cancel'],

		components: {
			'task-manager': taskManager,
			'excel-parser': excelParser,
		},

		props: {

			'data': {
				type: Array,
				default: null
			},

			'entity': {
				type: Function
			},

			'task': {
				Function
			},
		},

		data()
		{
			return {
				items: null,
				label: this.$loc.fromResource('action.importFromExcel')
			}
		},


		methods: {

			parseComplete(data)
			{
				this.items = data
			},

			tasksComplete(data)
			{
				this.$emit('complete', data)
			},

			tasksCancel()
			{
				this.$emit('cancel')
			}
		}
	}
</script>
