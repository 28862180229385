//GeneratorFlag:[NoChange]
import { BaseProductCategoryGetProductCategory } from '@/modules/shop/base/data/actions/product-category/get-product-category'
export default class ProductCategoryGetProductCategory extends BaseProductCategoryGetProductCategory
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			productDetailId: {
				...this.props.productDetailId,
				setConfig:{
					...this.props.productDetailId.setConfig
				}
			},
			imagePath: {
				...this.props.imagePath,
				setConfig:{
					...this.props.imagePath.setConfig
				}
			},
			firstPrice: {
				...this.props.firstPrice,
				setConfig:{
					...this.props.firstPrice.setConfig
				}
			},
			secondPrice: {
				...this.props.secondPrice,
				setConfig:{
					...this.props.secondPrice.setConfig
				}
			},
			categoryName: {
				...this.props.categoryName,
				setConfig:{
					...this.props.categoryName.setConfig
				}
			},
			inverstory: {
				...this.props.inverstory,
				setConfig:{
					...this.props.inverstory.setConfig
				}
			},
			firstUnitPrice: {
				...this.props.firstUnitPrice,
				setConfig:{
					...this.props.firstUnitPrice.setConfig
				}
			},
			secondUnitPrice: {
				...this.props.secondUnitPrice,
				setConfig:{
					...this.props.secondUnitPrice.setConfig
				}
			},
			linePrice: {
				...this.props.linePrice,
				setConfig:{
					...this.props.linePrice.setConfig
				}
			},
			description: {
				...this.props.description,
				setConfig:{
					...this.props.description.setConfig
				}
			},
		};
	}
}

