//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class MilitaryTypeService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(militaryTypeId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:militaryTypeId
		}

		return await _server.get('/people-net/military-type/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/people-net/military-type/save', params || {})
	};

	async delete(militaryTypeId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:militaryTypeId
		}

		return await _server.delete('/people-net/military-type/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/people-net/military-type/list', {'params':params})
	};
}
