//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"financial",
	"ext": ext,
	"layout":{
		"baseInfo": "اطلاعات پایه", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "اطلاعات سیستمی", //GeneratorFlag:[BaseSysLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"document": {
			"layout": "Document",
			"create": "ثبت جدید Document", //GeneratorFlag:[Views.Document.Create]
			'createImport': 'ثبت Document از طریق اکسل', //GeneratorFlag:[Views.Document.CreateImport] 
 			'delete': 'حذف Document', //GeneratorFlag:[Views.Document.Delete] 
 			'detail': 'جزئیات اطلاعات Document', //GeneratorFlag:[Views.Document.Detail] 
 			'list': 'فهرست Document', //GeneratorFlag:[Views.Document.List] 
 			'update': 'ویرایش Document', //GeneratorFlag:[Views.Document.Update] 
 //GeneratorFlag:[Views.Document]
		},
		"wallet": {
			"layout": "کیف پول",
			"create": "ثبت جدید کیف پول", //GeneratorFlag:[Views.Wallet.Create]
			'createImport': 'ثبت کیف پول از طریق اکسل', //GeneratorFlag:[Views.Wallet.CreateImport] 
 			'delete': 'حذف کیف پول', //GeneratorFlag:[Views.Wallet.Delete] 
 			'detail': 'جزئیات اطلاعات کیف پول', //GeneratorFlag:[Views.Wallet.Detail] 
 			'list': 'فهرست کیف پول', //GeneratorFlag:[Views.Wallet.List] 
 			'update': 'ویرایش کیف پول', //GeneratorFlag:[Views.Wallet.Update] 
 			'getCredit': 'گرفتن مجموع اعتبار فرد', //GeneratorFlag:[Views.Wallet.GetCredit] 
 //GeneratorFlag:[Views.Wallet]
		},
		"walletTransaction": {
			"layout": "تراکنش های کیف پول",
			"create": "ثبت جدید تراکنش کیف پول", //GeneratorFlag:[Views.WalletTransaction.Create]
			'createImport': 'ثبت تراکنش های کیف پول از طریق اکسل', //GeneratorFlag:[Views.WalletTransaction.CreateImport] 
 			'delete': 'حذف تراکنش کیف پول', //GeneratorFlag:[Views.WalletTransaction.Delete] 
 			'detail': 'جزئیات اطلاعات تراکنش کیف پول', //GeneratorFlag:[Views.WalletTransaction.Detail] 
 			'list': 'فهرست تراکنش های کیف پول', //GeneratorFlag:[Views.WalletTransaction.List] 
 			'update': 'ویرایش تراکنش کیف پول', //GeneratorFlag:[Views.WalletTransaction.Update] 
 			'hashTransactionExists': 'بررسی ثبت کد هش تراکنش', //GeneratorFlag:[Views.WalletTransaction.HashTransactionExists] 
 			'myTransactionList': 'تراکنش های کیف پول', //GeneratorFlag:[Views.WalletTransaction.MyTransactionList] 
 //GeneratorFlag:[Views.WalletTransaction]
		},
		"walletWithDraw": {
			"layout": "درخواست های واریز",
			"create": "ثبت جدید درخواست واریز", //GeneratorFlag:[Views.WalletWithDraw.Create]
			'createImport': 'ثبت درخواست های واریز از طریق اکسل', //GeneratorFlag:[Views.WalletWithDraw.CreateImport] 
 			'delete': 'حذف درخواست واریز', //GeneratorFlag:[Views.WalletWithDraw.Delete] 
 			'detail': 'جزئیات اطلاعات درخواست واریز', //GeneratorFlag:[Views.WalletWithDraw.Detail] 
 			'list': 'فهرست درخواست های واریز', //GeneratorFlag:[Views.WalletWithDraw.List] 
 			'update': 'ویرایش درخواست واریز', //GeneratorFlag:[Views.WalletWithDraw.Update] 
 			'askForMoney': 'درخواست واریز', //GeneratorFlag:[Views.WalletWithDraw.AskForMoney] 
 			'cancel': 'حذف درخواست', //GeneratorFlag:[Views.WalletWithDraw.Cancel] 
 			'myList': 'درخواست واریز', //GeneratorFlag:[Views.WalletWithDraw.MyList] 
 			'totalCommitedWithdraw': 'مجموع برداشت های حساب', //GeneratorFlag:[Views.WalletWithDraw.TotalCommitedWithdraw] 
 			'watingList': 'لیست درخواست های در حال انتظار', //GeneratorFlag:[Views.WalletWithDraw.WatingList] 
 //GeneratorFlag:[Views.WalletWithDraw]
		},
		"documentCategory": {
			"layout": " دسته بندی اسناد",
			"create": "ثبت جدید دسته بندی سند", //GeneratorFlag:[Views.DocumentCategory.Create]
			'createImport': 'ثبت دسته بندی اسناد از طریق اکسل', //GeneratorFlag:[Views.DocumentCategory.CreateImport] 
 			'delete': 'حذف دسته بندی سند', //GeneratorFlag:[Views.DocumentCategory.Delete] 
 			'detail': 'جزئیات اطلاعات دسته بندی سند', //GeneratorFlag:[Views.DocumentCategory.Detail] 
 			'list': 'فهرست دسته بندی اسناد', //GeneratorFlag:[Views.DocumentCategory.List] 
 			'update': 'ویرایش دسته بندی سند', //GeneratorFlag:[Views.DocumentCategory.Update] 
 //GeneratorFlag:[Views.DocumentCategory]
		},
		"culture": {
			"layout": "زبان ها",
			"detail": "جزئیات اطلاعات زبان", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'فهرست زبان ها', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"documentSubmitType": {
			"layout": "انواع ثبت",
			"detail": "جزئیات اطلاعات نواع ثبت", //GeneratorFlag:[Views.DocumentSubmitType.Detail]
			'list': 'فهرست انواع ثبت', //GeneratorFlag:[Views.DocumentSubmitType.List] 
 //GeneratorFlag:[Views.DocumentSubmitType]
		},
		"documentType": {
			"layout": "انواع سند",
			"detail": "جزئیات اطلاعات نوع سند", //GeneratorFlag:[Views.DocumentType.Detail]
			'list': 'فهرست انواع سند', //GeneratorFlag:[Views.DocumentType.List] 
 //GeneratorFlag:[Views.DocumentType]
		},
		"walletCurrency": {
			"layout": "واحد پولی کیف پول",
			"detail": "جزئیات اطلاعات واحد پولی کیف پول", //GeneratorFlag:[Views.WalletCurrency.Detail]
			'list': 'فهرست واحد پولی کیف پول', //GeneratorFlag:[Views.WalletCurrency.List] 
 //GeneratorFlag:[Views.WalletCurrency]
		},
		"walletTransactionStatuse": {
			"layout": "انواع وضعیت تراکنش",
			"detail": "جزئیات اطلاعات وضعیت تراکنش", //GeneratorFlag:[Views.WalletTransactionStatuse.Detail]
			'list': 'فهرست انواع وضعیت تراکنش', //GeneratorFlag:[Views.WalletTransactionStatuse.List] 
 //GeneratorFlag:[Views.WalletTransactionStatuse]
		},
		"walletTransactionType": {
			"layout": "انواع تراکنش",
			"detail": "جزئیات اطلاعات نوع تراکنش", //GeneratorFlag:[Views.WalletTransactionType.Detail]
			'list': 'فهرست انواع تراکنش', //GeneratorFlag:[Views.WalletTransactionType.List] 
 //GeneratorFlag:[Views.WalletTransactionType]
		},
		"walletType": {
			"layout": "انواع کیف پول",
			"detail": "جزئیات اطلاعات نوع کیف پول", //GeneratorFlag:[Views.WalletType.Detail]
			'list': 'فهرست انواع کیف پول', //GeneratorFlag:[Views.WalletType.List] 
 //GeneratorFlag:[Views.WalletType]
		},
		"walletWithDrawStatuse": {
			"layout": "وضعیت درخواست وجه",
			"detail": "جزئیات اطلاعات وضعیت درخواست وجه", //GeneratorFlag:[Views.WalletWithDrawStatuse.Detail]
			'list': 'فهرست وضعیت درخواست وجه', //GeneratorFlag:[Views.WalletWithDrawStatuse.List] 
 //GeneratorFlag:[Views.WalletWithDrawStatuse]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[Document.Create] {
	"documentCreate": {
		"self": "Document",
		"documentId": "document id",
		"documentDate": "document date",
		"documentTime": "document time",
		"documentPersianDate": "document persian date",
		"amount": "amount",
		"descriptionKey": "description key",
		"documentDescription": "document description",
		"personId": "person",
		"documentTypeId": "document type",
		"documentSubmitTypeId": "document submit type",
		"documentCategoryId": "document category",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Document.Delete] {
	"documentDelete": {
		"self": "Document",
		"documentId": "document id",
	},
	//GeneratorFlag:[Document.Detail] {
	"documentDetail": {
		"self": "Document",
		"documentId": "document id",
		"documentDate": "document date",
		"documentTime": "document time",
		"documentPersianDate": "document persian date",
		"amount": "amount",
		"descriptionKey": "description key",
		"documentDescription": "document description",
		"personId": "person",
		"documentTypeId": "document type",
		"documentSubmitTypeId": "document submit type",
		"documentCategoryId": "document category",
		"documentCategory": {
			"self": "DocumentCategoryId",
			"documentCategoryId": "شناسه",
			"documentCategoryKey": "document category key",
			"parentId": "والد",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"documentSubmitType": {
			"self": "DocumentSubmitTypeId",
			"documentSubmitTypeId": "شناسه",
			"documentSubmitTypeKey": "کلید",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"documentType": {
			"self": "DocumentTypeId",
			"documentTypeId": "شناسه",
			"documentTypeKey": "کلید",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "PersonId",
			"personId": "شناسه",
			"fullName": "نام و نام خانوادگی",
			"nationalCode": "کد ملی",
			"mobile": "همراه",
		},
		"documentSubInfo": "document sub info",
	},
	//GeneratorFlag:[Document.List] {
	"documentList": {
		"self": "Document",
		"documentId": "document id",
		"documentDate": "document date",
		"documentTime": "document time",
		"documentPersianDate": "document persian date",
		"amount": "amount",
		"descriptionKey": "description key",
		"documentDescription": "document description",
		"personId": "person",
		"documentTypeId": "document type",
		"documentSubmitTypeId": "document submit type",
		"documentCategoryId": "document category",
		"documentCategory": {
			"self": "DocumentCategoryId",
			"documentCategoryId": "شناسه",
			"documentCategoryKey": "document category key",
			"parentId": "والد",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"documentSubmitType": {
			"self": "DocumentSubmitTypeId",
			"documentSubmitTypeId": "شناسه",
			"documentSubmitTypeKey": "کلید",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"documentType": {
			"self": "DocumentTypeId",
			"documentTypeId": "شناسه",
			"documentTypeKey": "کلید",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "PersonId",
			"personId": "شناسه",
			"fullName": "نام و نام خانوادگی",
			"nationalCode": "کد ملی",
			"mobile": "همراه",
		},
		"documentSubInfo": "document sub info",
	},
	//GeneratorFlag:[Document.Filter] {
	"documentFilter": {
		"self": "Document",
		"documentId": "document id",
		"documentDate": "document date",
		"documentTime": "document time",
		"documentPersianDate": "document persian date",
		"amount": "amount",
		"descriptionKey": "description key",
		"documentDescription": "document description",
		"personId": "person",
		"documentTypeId": "document type",
		"documentSubmitTypeId": "document submit type",
		"documentCategoryId": "document category",
		"documentCategory": {
			"self": "DocumentCategoryId",
			"documentCategoryId": "شناسه",
			"documentCategoryKey": "document category key",
			"parentId": "والد",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"documentSubmitType": {
			"self": "DocumentSubmitTypeId",
			"documentSubmitTypeId": "شناسه",
			"documentSubmitTypeKey": "کلید",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"documentType": {
			"self": "DocumentTypeId",
			"documentTypeId": "شناسه",
			"documentTypeKey": "کلید",
			"title": "عنوان",
			"caption": "توضیحات",
			"cultureId": "زبان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "PersonId",
			"personId": "شناسه",
			"fullName": "نام و نام خانوادگی",
			"nationalCode": "کد ملی",
			"mobile": "همراه",
		},
		"documentSubInfo": "document sub info",
	},
	//GeneratorFlag:[Document.Update] {
	"documentUpdate": {
		"self": "Document",
		"documentId": "document id",
		"documentDate": "document date",
		"documentTime": "document time",
		"documentPersianDate": "document persian date",
		"amount": "amount",
		"descriptionKey": "description key",
		"documentDescription": "document description",
		"personId": "person",
		"documentTypeId": "document type",
		"documentSubmitTypeId": "document submit type",
		"documentCategoryId": "document category",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Wallet.Create] {
	"walletCreate": {
		"self": "کیف پول",
		"walletId": "شناسه",
		"walletTypeId": "نوع",
		"personId": "عضو",
		"walletAmount": "موجودی",
		"title": "عنوان",
		"walletCurrencyId": "واحد کیف پول",
	},
	//GeneratorFlag:[Wallet.Delete] {
	"walletDelete": {
		"self": "کیف پول",
		"walletId": "شناسه",
	},
	//GeneratorFlag:[Wallet.Detail] {
	"walletDetail": {
		"self": "کیف پول",
		"walletId": "شناسه",
		"walletTypeId": "نوع",
		"personId": "عضو",
		"walletAmount": "موجودی",
		"title": "عنوان",
		"walletCurrencyId": "واحد کیف پول",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"fullName": "نام و نام خانوادگی",
			"nationalCode": "کد ملی",
			"mobile": "همراه",
		},
		"walletCurrency": {
			"self": "واحد کیف پول",
			"walletCurrencyId": "شناسه",
			"walletCurrencyKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletType": {
			"self": "نوع",
			"walletTypeId": "شناسه",
			"walletTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[Wallet.List] {
	"walletList": {
		"self": "کیف پول",
		"walletId": "شناسه",
		"walletTypeId": "نوع",
		"personId": "عضو",
		"walletAmount": "موجودی",
		"title": "عنوان",
		"walletCurrencyId": "واحد کیف پول",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"fullName": "نام و نام خانوادگی",
			"nationalCode": "کد ملی",
			"mobile": "همراه",
		},
		"walletCurrency": {
			"self": "واحد کیف پول",
			"walletCurrencyId": "شناسه",
			"walletCurrencyKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletType": {
			"self": "نوع",
			"walletTypeId": "شناسه",
			"walletTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[Wallet.Filter] {
	"walletFilter": {
		"self": "کیف پول",
		"walletId": "شناسه",
		"walletTypeId": "نوع",
		"personId": "عضو",
		"walletAmount": "موجودی",
		"title": "عنوان",
		"walletCurrencyId": "واحد کیف پول",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"fullName": "نام و نام خانوادگی",
			"nationalCode": "کد ملی",
			"mobile": "همراه",
		},
		"walletCurrency": {
			"self": "واحد کیف پول",
			"walletCurrencyId": "شناسه",
			"walletCurrencyKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletType": {
			"self": "نوع",
			"walletTypeId": "شناسه",
			"walletTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[Wallet.Update] {
	"walletUpdate": {
		"self": "کیف پول",
		"walletId": "شناسه",
		"walletTypeId": "نوع",
		"personId": "عضو",
		"walletAmount": "موجودی",
		"title": "عنوان",
		"walletCurrencyId": "واحد کیف پول",
	},
	//GeneratorFlag:[WalletTransaction.Create] {
	"walletTransactionCreate": {
		"self": "تراکنش کیف پول",
		"walletTransactionId": "شناسه",
		"title": "عنوان",
		"walletId": "کیف پول",
		"amount": "مقدار",
		"refCode": "کد ارجاع",
		"walletTransactionTypeId": "نوع",
		"walletTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"commitedDate": "تاریخ تایید",
		"displayCommitedDate": "تاریخ تایید",
		"fromResouce": "تراکنش از",
		"personId": "عضو",
	},
	//GeneratorFlag:[WalletTransaction.Delete] {
	"walletTransactionDelete": {
		"self": "تراکنش کیف پول",
		"walletTransactionId": "شناسه",
	},
	//GeneratorFlag:[WalletTransaction.Detail] {
	"walletTransactionDetail": {
		"self": "تراکنش کیف پول",
		"walletTransactionId": "شناسه",
		"title": "عنوان",
		"walletId": "کیف پول",
		"amount": "مقدار",
		"refCode": "کد ارجاع",
		"walletTransactionTypeId": "نوع",
		"walletTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"commitedDate": "تاریخ تایید",
		"displayCommitedDate": "تاریخ تایید",
		"fromResouce": "تراکنش از",
		"wallet": {
			"self": "کیف پول",
			"walletId": "شناسه",
			"walletTypeId": "نوع",
			"personId": "عضو",
			"walletAmount": "موجودی",
			"title": "عنوان",
			"walletCurrencyId": "واحد کیف پول",
		},
		"walletTransactionStatuse": {
			"self": "وضعیت",
			"walletTransactionStatuseId": "شناسه",
			"walletTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletTransactionType": {
			"self": "نوع",
			"walletTransactionTypeId": "شناسه",
			"walletTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletTransactionSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[WalletTransaction.List] {
	"walletTransactionList": {
		"self": "تراکنش کیف پول",
		"walletTransactionId": "شناسه",
		"title": "عنوان",
		"walletId": "کیف پول",
		"amount": "مقدار",
		"refCode": "کد ارجاع",
		"walletTransactionTypeId": "نوع",
		"walletTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"commitedDate": "تاریخ تایید",
		"displayCommitedDate": "تاریخ تایید",
		"fromResouce": "تراکنش از",
		"wallet": {
			"self": "کیف پول",
			"walletId": "شناسه",
			"walletTypeId": "نوع",
			"personId": "عضو",
			"walletAmount": "موجودی",
			"title": "عنوان",
			"walletCurrencyId": "واحد کیف پول",
		},
		"walletTransactionStatuse": {
			"self": "وضعیت",
			"walletTransactionStatuseId": "شناسه",
			"walletTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletTransactionType": {
			"self": "نوع",
			"walletTransactionTypeId": "شناسه",
			"walletTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletTransactionSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[WalletTransaction.Filter] {
	"walletTransactionFilter": {
		"self": "تراکنش کیف پول",
		"walletTransactionId": "شناسه",
		"title": "عنوان",
		"walletId": "کیف پول",
		"amount": "مقدار",
		"refCode": "کد ارجاع",
		"walletTransactionTypeId": "نوع",
		"walletTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"commitedDate": "تاریخ تایید",
		"displayCommitedDate": "تاریخ تایید",
		"fromResouce": "تراکنش از",
		"wallet": {
			"self": "کیف پول",
			"walletId": "شناسه",
			"walletTypeId": "نوع",
			"personId": "عضو",
			"walletAmount": "موجودی",
			"title": "عنوان",
			"walletCurrencyId": "واحد کیف پول",
		},
		"walletTransactionStatuse": {
			"self": "وضعیت",
			"walletTransactionStatuseId": "شناسه",
			"walletTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletTransactionType": {
			"self": "نوع",
			"walletTransactionTypeId": "شناسه",
			"walletTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletTransactionSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[WalletTransaction.Update] {
	"walletTransactionUpdate": {
		"self": "تراکنش کیف پول",
		"walletTransactionId": "شناسه",
		"title": "عنوان",
		"walletId": "کیف پول",
		"amount": "مقدار",
		"refCode": "کد ارجاع",
		"walletTransactionTypeId": "نوع",
		"walletTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"commitedDate": "تاریخ تایید",
		"displayCommitedDate": "تاریخ تایید",
		"fromResouce": "تراکنش از",
	},
	//GeneratorFlag:[WalletWithDraw.Create] {
	"walletWithDrawCreate": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
		"walletId": "کیف پول",
		"walletTransactionId": "تراکنش",
		"amount": "مقدار",
		"walletWithDrawStatuseId": "وضعیت",
		"caption": "توضیحات",
		"personBankAccountId": "شناسه حساب",
		"personWalletAddress": "آدرس کیف پول واریز",
		"confirmDate": "تاریخ میلادی تایید",
		"displayConfirmDate": "تاریخ تایید",
		"transactionHashCode": "هش تراکنش",
	},
	//GeneratorFlag:[WalletWithDraw.Delete] {
	"walletWithDrawDelete": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
	},
	//GeneratorFlag:[WalletWithDraw.Detail] {
	"walletWithDrawDetail": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
		"walletId": "کیف پول",
		"walletTransactionId": "تراکنش",
		"amount": "مقدار",
		"walletWithDrawStatuseId": "وضعیت",
		"caption": "توضیحات",
		"personBankAccountId": "شناسه حساب",
		"personWalletAddress": "آدرس کیف پول واریز",
		"confirmDate": "تاریخ میلادی تایید",
		"displayConfirmDate": "تاریخ تایید",
		"transactionHashCode": "هش تراکنش",
		"wallet": {
			"self": "کیف پول",
			"walletId": "شناسه",
			"walletTypeId": "نوع",
			"personId": "عضو",
			"walletAmount": "موجودی",
			"title": "عنوان",
			"walletCurrencyId": "واحد کیف پول",
		},
		"walletWithDrawStatuse": {
			"self": "وضعیت",
			"walletWithDrawStatuseId": "شناسه",
			"walletWithDrawStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletWithDrawSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[WalletWithDraw.List] {
	"walletWithDrawList": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
		"walletId": "کیف پول",
		"walletTransactionId": "تراکنش",
		"amount": "مقدار",
		"walletWithDrawStatuseId": "وضعیت",
		"caption": "توضیحات",
		"personBankAccountId": "شناسه حساب",
		"personWalletAddress": "آدرس کیف پول واریز",
		"confirmDate": "تاریخ میلادی تایید",
		"displayConfirmDate": "تاریخ تایید",
		"transactionHashCode": "هش تراکنش",
		"wallet": {
			"self": "کیف پول",
			"walletId": "شناسه",
			"walletTypeId": "نوع",
			"personId": "عضو",
			"walletAmount": "موجودی",
			"title": "عنوان",
			"walletCurrencyId": "واحد کیف پول",
		},
		"walletWithDrawStatuse": {
			"self": "وضعیت",
			"walletWithDrawStatuseId": "شناسه",
			"walletWithDrawStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletWithDrawSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[WalletWithDraw.Filter] {
	"walletWithDrawFilter": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
		"walletId": "کیف پول",
		"walletTransactionId": "تراکنش",
		"amount": "مقدار",
		"walletWithDrawStatuseId": "وضعیت",
		"caption": "توضیحات",
		"personBankAccountId": "شناسه حساب",
		"personWalletAddress": "آدرس کیف پول واریز",
		"confirmDate": "تاریخ میلادی تایید",
		"displayConfirmDate": "تاریخ تایید",
		"transactionHashCode": "هش تراکنش",
		"wallet": {
			"self": "کیف پول",
			"walletId": "شناسه",
			"walletTypeId": "نوع",
			"personId": "عضو",
			"walletAmount": "موجودی",
			"title": "عنوان",
			"walletCurrencyId": "واحد کیف پول",
		},
		"walletWithDrawStatuse": {
			"self": "وضعیت",
			"walletWithDrawStatuseId": "شناسه",
			"walletWithDrawStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletWithDrawSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[WalletWithDraw.Update] {
	"walletWithDrawUpdate": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
		"walletId": "کیف پول",
		"walletTransactionId": "تراکنش",
		"amount": "مقدار",
		"walletWithDrawStatuseId": "وضعیت",
		"caption": "توضیحات",
		"personBankAccountId": "شناسه حساب",
		"personWalletAddress": "آدرس کیف پول واریز",
		"confirmDate": "تاریخ میلادی تایید",
		"displayConfirmDate": "تاریخ تایید",
		"transactionHashCode": "هش تراکنش",
	},
	//GeneratorFlag:[DocumentCategory.Create] {
	"documentCategoryCreate": {
		"self": "دسته بندی سند",
		"documentCategoryId": "شناسه",
		"documentCategoryKey": "document category key",
		"parentId": "والد",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[DocumentCategory.Delete] {
	"documentCategoryDelete": {
		"self": "دسته بندی سند",
		"documentCategoryId": "شناسه",
	},
	//GeneratorFlag:[DocumentCategory.Detail] {
	"documentCategoryDetail": {
		"self": "دسته بندی سند",
		"documentCategoryId": "شناسه",
		"documentCategoryKey": "document category key",
		"parentId": "والد",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"documentCategorySubInfo": "document category sub info",
	},
	//GeneratorFlag:[DocumentCategory.List] {
	"documentCategoryList": {
		"self": "دسته بندی سند",
		"documentCategoryId": "شناسه",
		"documentCategoryKey": "document category key",
		"parentId": "والد",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"documentCategorySubInfo": "document category sub info",
	},
	//GeneratorFlag:[DocumentCategory.Filter] {
	"documentCategoryFilter": {
		"self": "دسته بندی سند",
		"documentCategoryId": "شناسه",
		"documentCategoryKey": "document category key",
		"parentId": "والد",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"documentCategorySubInfo": "document category sub info",
	},
	//GeneratorFlag:[DocumentCategory.Update] {
	"documentCategoryUpdate": {
		"self": "دسته بندی سند",
		"documentCategoryId": "شناسه",
		"documentCategoryKey": "document category key",
		"parentId": "والد",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "زبان",
		"cultureId": "شناسه",
		"title": "عنوان",
		"cultureSymbol": "نشانه زبان",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "اختصار",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "زبان",
		"cultureId": "شناسه",
		"title": "عنوان",
		"cultureSymbol": "نشانه زبان",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "اختصار",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "زبان",
		"cultureId": "شناسه",
		"title": "عنوان",
		"cultureSymbol": "نشانه زبان",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "اختصار",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[DocumentSubmitType.Detail] {
	"documentSubmitTypeDetail": {
		"self": "نواع ثبت",
		"documentSubmitTypeId": "شناسه",
		"documentSubmitTypeKey": "کلید",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[DocumentSubmitType.List] {
	"documentSubmitTypeList": {
		"self": "نواع ثبت",
		"documentSubmitTypeId": "شناسه",
		"documentSubmitTypeKey": "کلید",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[DocumentSubmitType.Filter] {
	"documentSubmitTypeFilter": {
		"self": "نواع ثبت",
		"documentSubmitTypeId": "شناسه",
		"documentSubmitTypeKey": "کلید",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[DocumentType.Detail] {
	"documentTypeDetail": {
		"self": "نوع سند",
		"documentTypeId": "شناسه",
		"documentTypeKey": "کلید",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[DocumentType.List] {
	"documentTypeList": {
		"self": "نوع سند",
		"documentTypeId": "شناسه",
		"documentTypeKey": "کلید",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[DocumentType.Filter] {
	"documentTypeFilter": {
		"self": "نوع سند",
		"documentTypeId": "شناسه",
		"documentTypeKey": "کلید",
		"title": "عنوان",
		"caption": "توضیحات",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[WalletCurrency.Detail] {
	"walletCurrencyDetail": {
		"self": "واحد پولی کیف پول",
		"walletCurrencyId": "شناسه",
		"walletCurrencyKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletCurrency.List] {
	"walletCurrencyList": {
		"self": "واحد پولی کیف پول",
		"walletCurrencyId": "شناسه",
		"walletCurrencyKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletCurrency.Filter] {
	"walletCurrencyFilter": {
		"self": "واحد پولی کیف پول",
		"walletCurrencyId": "شناسه",
		"walletCurrencyKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletTransactionStatuse.Detail] {
	"walletTransactionStatuseDetail": {
		"self": "وضعیت تراکنش",
		"walletTransactionStatuseId": "شناسه",
		"walletTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletTransactionStatuse.List] {
	"walletTransactionStatuseList": {
		"self": "وضعیت تراکنش",
		"walletTransactionStatuseId": "شناسه",
		"walletTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletTransactionStatuse.Filter] {
	"walletTransactionStatuseFilter": {
		"self": "وضعیت تراکنش",
		"walletTransactionStatuseId": "شناسه",
		"walletTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletTransactionType.Detail] {
	"walletTransactionTypeDetail": {
		"self": "نوع تراکنش",
		"walletTransactionTypeId": "شناسه",
		"walletTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletTransactionType.List] {
	"walletTransactionTypeList": {
		"self": "نوع تراکنش",
		"walletTransactionTypeId": "شناسه",
		"walletTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletTransactionType.Filter] {
	"walletTransactionTypeFilter": {
		"self": "نوع تراکنش",
		"walletTransactionTypeId": "شناسه",
		"walletTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletType.Detail] {
	"walletTypeDetail": {
		"self": "نوع کیف پول",
		"walletTypeId": "شناسه",
		"walletTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[WalletType.List] {
	"walletTypeList": {
		"self": "نوع کیف پول",
		"walletTypeId": "شناسه",
		"walletTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[WalletType.Filter] {
	"walletTypeFilter": {
		"self": "نوع کیف پول",
		"walletTypeId": "شناسه",
		"walletTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[WalletWithDrawStatuse.Detail] {
	"walletWithDrawStatuseDetail": {
		"self": "وضعیت درخواست وجه",
		"walletWithDrawStatuseId": "شناسه",
		"walletWithDrawStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletWithDrawStatuse.List] {
	"walletWithDrawStatuseList": {
		"self": "وضعیت درخواست وجه",
		"walletWithDrawStatuseId": "شناسه",
		"walletWithDrawStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[WalletWithDrawStatuse.Filter] {
	"walletWithDrawStatuseFilter": {
		"self": "وضعیت درخواست وجه",
		"walletWithDrawStatuseId": "شناسه",
		"walletWithDrawStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[Wallet.GetCredit] {
	"walletGetCredit": {
		"self": "گرفتن مجموع اعتبار فرد",
		"personId": "کد شخص",
		"creditAmount": "موجودی",
		"wallets": {
			"self": "کیف پول ها",
			"walletId": "شناسه کیف پول",
			"amount": "موجودی",
			"currency": "واحد پولی",
		},
	},
	//GeneratorFlag:[WalletTransaction.HashTransactionExists] {
	"walletTransactionHashTransactionExists": {
		"self": "بررسی ثبت کد هش تراکنش",
		"hashTransaction": "هش تراکنش",
		"isExists": "آیا وجود دارد",
	},
	//GeneratorFlag:[WalletTransaction.MyTransactionList] {
	"walletTransactionMyTransactionList": {
		"self": "تراکنش های کیف پول",
		"date": "تاریخ",
		"walletName": "کیف پول",
		"title": "عنوان",
		"amount": "مبلغ",
		"type": "نوع",
		"status": "وضعیت",
		"refCode": "کد",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[WalletWithDraw.AskForMoney] {
	"walletWithDrawAskForMoney": {
		"self": "درخواست واریز",
		"walletId": "کیف پول",
		"personBankAccountId": "کیف پول",
		"walletAddress": "کیف پول",
		"amount": "کیف پول",
		"walletWithDrawId": "شناسه",
	},
	//GeneratorFlag:[WalletWithDraw.Cancel] {
	"walletWithDrawCancel": {
		"self": "حذف درخواست",
		"walletWithDrawId": "شناسه",
	},
	//GeneratorFlag:[WalletWithDraw.MyList] {
	"walletWithDrawMyList": {
		"self": "درخواست واریز",
		"walletWithDrawId": "شناسه",
		"walletId": "کیف پول",
		"walletTransactionId": "تراکنش",
		"amount": "مقدار",
		"walletWithDrawStatuseId": "وضعیت",
		"caption": "توضیحات",
		"personBankAccountId": "شناسه حساب",
		"personWalletAddress": "آدرس کیف پول واریز",
		"confirmDate": "تاریخ میلادی تایید",
		"displayConfirmDate": "تاریخ تایید",
		"walletWithDrawStatuse": {
			"self": "وضعیت",
			"walletWithDrawStatuseId": "شناسه",
			"walletWithDrawStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"walletWithDrawSubInfo": {
			"self": "اطلاعات تکمیلی",
			"walletWithDrawId": "wallet with draw ",
			"creatorId": "creator id",
			"creatorIp": "creator ip",
			"creatorName": "creator name",
			"createDate": "create date",
			"displayCreateDate": "display create date",
			"createTime": "create time",
			"modifierId": "modifier id",
			"modifierIp": "modifier ip",
			"modifierName": "modifier name",
			"modifyDate": "modify date",
			"displayModifyDate": "display modify date",
			"modifyTime": "modify time",
			"archived": "archived",
		},
	},
	//GeneratorFlag:[WalletWithDraw.TotalCommitedWithdraw] {
	"walletWithDrawTotalCommitedWithdraw": {
		"self": "مجموع برداشت های حساب",
		"totalCommitedWithdraw": "مجموع برداشتی",
	},
	//GeneratorFlag:[WalletWithDraw.WatingList] {
	"walletWithDrawWatingList": {
		"self": "لیست درخواست های در حال انتظار",
		"walletWithDrawId": "شناسه",
		"walletName": "از کیف پول",
		"personWalletAddress": "به کیف پول",
		"registerDateTime": "تاریخ",
		"amount": "مقدار",
	},
//GeneratorFlag:[NewEntity]
}