//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class FeatureItemService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[FeatureItem.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/feature-item/create', params || { });
	};

	//GeneratorFlag:[FeatureItem.Delete]
	async delete(featureItemId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:featureItemId
		}
	
		return await _server.delete('/shop/feature-item/delete', {'params':params})
	};

	//GeneratorFlag:[FeatureItem.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/feature-item/detail', {'params':params});
	};

	//GeneratorFlag:[FeatureItem.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/feature-item/list', {'params':params});
	};

	//GeneratorFlag:[FeatureItem.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/feature-item/update', params || { });
	};

//GeneratorFlag:[NewAction]
}