<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { TicketStatuse, TicketStatuseFilter } from '@/modules/ticketing/data/ticket-statuse/list'
	import { TicketStatuseService } from '@/modules/ticketing/services'
	export default {
		computed:
		{
			entity()
			{
				return TicketStatuse;
			},
			
			filterEntity()
			{
				return TicketStatuseFilter;
			},

			service()
			{
				return new TicketStatuseService();
			},

			filterData()
			{
				const ticketStatuseId =
					this.$route.query['ticketStatuseId'];

				if (ticketStatuseId)
				{
					return {
						'ticketStatuseId': ticketStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>