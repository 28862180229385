//GeneratorFlag:[NoChange]
import { BaseCategory } from '../base/data'
export default class Category extends BaseCategory
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			categoryKey: {
				...this.props.categoryKey,
				setConfig:{
					...this.props.categoryKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			categoryParentId: {
				...this.props.categoryParentId,
				setConfig:{
					...this.props.categoryParentId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			categoryGenoString: {
				...this.props.categoryGenoString,
				setConfig:{
					...this.props.categoryGenoString.setConfig
				}
			},
			imagePath: {
				...this.props.imagePath,
				setConfig:{
					...this.props.imagePath.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			categorySubInfo: {
				...this.props.categorySubInfo,
				setConfig:{
					...this.props.categorySubInfo.setConfig
				}
			},

		};
	}
}
