//GeneratorFlag:[NoChange]
import { BaseInvoiceUplineFactorInfo } from '@/modules/shop/base/data/actions/invoice/upline-factor-info'
export default class InvoiceUplineFactorInfo extends BaseInvoiceUplineFactorInfo
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			count: {
				...this.props.count,
				setConfig:{
					...this.props.count.setConfig
				}
			},
			firstFactorAmount: {
				...this.props.firstFactorAmount,
				setConfig:{
					...this.props.firstFactorAmount.setConfig
				}
			},
			totalFactorAmount: {
				...this.props.totalFactorAmount,
				setConfig:{
					...this.props.totalFactorAmount.setConfig
				}
			},
		};
	}
}

