//GeneratorFlag:[NoChange]
import { BaseProductColorInfo } from '@/modules/shop/base/data/actions/product-store/get-product-info'
export default class ProductColorInfo extends BaseProductColorInfo
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			id: {
				...this.props.id,
				setConfig:{
					...this.props.id.setConfig
				}
			},
			productColorId: {
				...this.props.productColorId,
				setConfig:{
					...this.props.productColorId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			colorHexaCode: {
				...this.props.colorHexaCode,
				setConfig:{
					...this.props.colorHexaCode.setConfig
				}
			},
			firstPrice: {
				...this.props.firstPrice,
				setConfig:{
					...this.props.firstPrice.setConfig
				}
			},
			secondPrice: {
				...this.props.secondPrice,
				setConfig:{
					...this.props.secondPrice.setConfig
				}
			},
			productMonetaryFeatureId: {
				...this.props.productMonetaryFeatureId,
				setConfig:{
					...this.props.productMonetaryFeatureId.setConfig
				}
			},
			productStoreInventory: {
				...this.props.productStoreInventory,
				setConfig:{
					...this.props.productStoreInventory.setConfig
				}
			},
		};
	}
}

