//GeneratorFlag:[NoChange]
import { BaseMenu } from '../base/data'
export default class Menu extends BaseMenu
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			menuId: {
				...this.props.menuId,
				setConfig:{
					...this.props.menuId.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			moduleName: {
				...this.props.moduleName,
				setConfig:{
					...this.props.moduleName.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			menuOrder: {
				...this.props.menuOrder,
				setConfig:{
					...this.props.menuOrder.setConfig
				}
			},
			iconUrl: {
				...this.props.iconUrl,
				setConfig:{
					...this.props.iconUrl.setConfig
				}
			},
			menuUrl: {
				...this.props.menuUrl,
				setConfig:{
					...this.props.menuUrl.setConfig
				}
			},
			isVisible: {
				...this.props.isVisible,
				setConfig:{
					...this.props.isVisible.setConfig
				}
			},
			accessKey: {
				...this.props.accessKey,
				setConfig:{
					...this.props.accessKey.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			menuSubInfo: {
				...this.props.menuSubInfo,
				setConfig:{
					...this.props.menuSubInfo.setConfig
				}
			},

		};
	}
}
