
// initial state
const state = () => ({
	items: []
})

// getters
const getters = {
}

// actions
const actions = {

	add({ commit }, value) {

		var id = Date.now().toString()
		commit('pushToTemp', { id, value });

		return id
	},

	find({ state }, id) {

		return state.items.find(temp => temp.id === id);
	},

	findByQuery({ state }, query) {

		if (query && query['temp']) {
			return state.items.find(temp => temp.id === query['temp']);
		}

		return null;
	},
}

// mutations
const mutations = {
	pushToTemp(state, temp) {
		state.items.push(temp)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
