<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { PersonStatuse } from '../../data'
	import { PersonStatuseFilter } from '../../data/filters'
	import { PersonStatuseService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return PersonStatuse;
			},
			
			filterEntity()
			{
				return PersonStatuseFilter;
			},

			service()
			{
				return new PersonStatuseService();
			},

			filterData()
			{
				const personStatuseId =
					this.$route.query['personStatuseId'];

				if (personStatuseId)
				{
					return {
						'personStatuseId': personStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
