//GeneratorFlag:[NoChange]
import { BaseCity } from '../base/data'
export default class City extends BaseCity
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			cityId: {
				...this.props.cityId,
				setConfig:{
					...this.props.cityId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			provinceId: {
				...this.props.provinceId,
				setConfig:{
					...this.props.provinceId.setConfig
				}
			},
			province: {
				...this.props.province,
				setConfig:{
					...this.props.province.setConfig
				}
			},

		};
	}
}
