// initial state
const state = () => ({
	branch: null,
	branchId:0
})


// actions
const actions = {

	setBranch({ commit }, value)
	{
		commit('setBranch', value);
		commit('setLocalStorage', value);
	},

	setBranchFromStorage({ commit })
	{
		var value =
			JSON.parse(
				localStorage.getItem("Frc.automation.branch")
			);

		if (value)
		{
			commit('setBranch', value);
		}
	},

	resetState({ commit })
	{
		commit('resetState');
	}
}

// getters
const getters = {
}

// mutations
const mutations =
{
	setBranch(state, value)
	{
		state.branch = value
		state.branchId = value.branchId || 0
	},

	setLocalStorage(state, value)
	{
		localStorage.setItem('Frc.automation.branch', JSON.stringify(value))
	},

	resetState(state)
	{
		state.branch = null;
		state.branchId = 0;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
