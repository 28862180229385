//GeneratorFlag:[NoChange]
import ProductDetail from './product-detail';
import Culture from './culture';
import Product from './product';
import ProductColor from './product-color';
import ProductMonetaryFeature from './product-monetary-feature';
import ProductStatus from './product-status';
import ProductDetailFilter from './product-detail-filter';

export default {ProductDetail, Culture, Product, ProductColor, ProductMonetaryFeature, ProductStatus, ProductDetailFilter}
export{ProductDetail, Culture, Product, ProductColor, ProductMonetaryFeature, ProductStatus, ProductDetailFilter}
