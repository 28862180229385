//GeneratorFlag:[NoChange]
export default {
	path: 'document-category',
	name: 'financial.baseInfo.documentCategory.layout',
	redirect: to => {
		return {name: 'financial.documentCategory.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/base-info/document-category"],
		resourceKey: 'financial.views.documentCategory.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DocumentCategoryCreate]"
		{
			path: 'create',
			name: 'financial.documentCategory.create',
			component: () => import('@/modules/financial/views/document-category/document-category-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentCategory.create',
				clientAccessKeys:["/financial/base-info/document-category/create"],
				serverAccessKeys:["/financial/document-category/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentCategoryCreateImport]"
		{
			path: 'create-import',
			name: 'financial.documentCategory.createImport',
			component: () => import('@/modules/financial/views/document-category/document-category-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentCategory.createImport',
				clientAccessKeys:["/financial/base-info/document-category/create-import"],
				serverAccessKeys:["/financial/document-category/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentCategoryDelete]"
		{
			path: 'delete',
			name: 'financial.documentCategory.delete',
			component: () => import('@/modules/financial/views/document-category/document-category-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentCategory.delete',
				clientAccessKeys:["/financial/base-info/document-category/delete"],
				serverAccessKeys:["/financial/document-category/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentCategoryDetail]"
		{
			path: 'detail',
			name: 'financial.documentCategory.detail',
			component: () => import('@/modules/financial/views/document-category/document-category-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentCategory.detail',
				clientAccessKeys:["/financial/base-info/document-category/detail"],
				serverAccessKeys:["/financial/document-category/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentCategoryList]"
		{
			path: 'list',
			name: 'financial.documentCategory.list',
			component: () => import('@/modules/financial/views/document-category/document-category-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentCategory.list',
				clientAccessKeys:["/financial/base-info/document-category/list"],
				serverAccessKeys:["/financial/document-category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentCategoryUpdate]"
		{
			path: 'update',
			name: 'financial.documentCategory.update',
			component: () => import('@/modules/financial/views/document-category/document-category-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentCategory.update',
				clientAccessKeys:["/financial/base-info/document-category/update"],
				serverAccessKeys:["/financial/document-category/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}