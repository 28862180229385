//GeneratorFlag:[NoChange]
import DocumentService from './document-service'; //GeneratorFlag:[Document]
import WalletService from './wallet-service'; //GeneratorFlag:[Wallet]
import WalletTransactionService from './wallet-transaction-service'; //GeneratorFlag:[WalletTransaction]
import WalletWithDrawService from './wallet-with-draw-service'; //GeneratorFlag:[WalletWithDraw]
import DocumentCategoryService from './document-category-service'; //GeneratorFlag:[DocumentCategory]
import CultureService from './culture-service'; //GeneratorFlag:[Culture]
import DocumentSubmitTypeService from './document-submit-type-service'; //GeneratorFlag:[DocumentSubmitType]
import DocumentTypeService from './document-type-service'; //GeneratorFlag:[DocumentType]
import WalletCurrencyService from './wallet-currency-service'; //GeneratorFlag:[WalletCurrency]
import WalletTransactionStatuseService from './wallet-transaction-statuse-service'; //GeneratorFlag:[WalletTransactionStatuse]
import WalletTransactionTypeService from './wallet-transaction-type-service'; //GeneratorFlag:[WalletTransactionType]
import WalletTypeService from './wallet-type-service'; //GeneratorFlag:[WalletType]
import WalletWithDrawStatuseService from './wallet-with-draw-statuse-service'; //GeneratorFlag:[WalletWithDrawStatuse]
//GeneratorFlag:[NewImport]
export default {
DocumentService, //GeneratorFlag:[Document]
WalletService, //GeneratorFlag:[Wallet]
WalletTransactionService, //GeneratorFlag:[WalletTransaction]
WalletWithDrawService, //GeneratorFlag:[WalletWithDraw]
DocumentCategoryService, //GeneratorFlag:[DocumentCategory]
CultureService, //GeneratorFlag:[Culture]
DocumentSubmitTypeService, //GeneratorFlag:[DocumentSubmitType]
DocumentTypeService, //GeneratorFlag:[DocumentType]
WalletCurrencyService, //GeneratorFlag:[WalletCurrency]
WalletTransactionStatuseService, //GeneratorFlag:[WalletTransactionStatuse]
WalletTransactionTypeService, //GeneratorFlag:[WalletTransactionType]
WalletTypeService, //GeneratorFlag:[WalletType]
WalletWithDrawStatuseService, //GeneratorFlag:[WalletWithDrawStatuse]
//GeneratorFlag:[NewExport]
}
export{
DocumentService, //GeneratorFlag:[Document]
WalletService, //GeneratorFlag:[Wallet]
WalletTransactionService, //GeneratorFlag:[WalletTransaction]
WalletWithDrawService, //GeneratorFlag:[WalletWithDraw]
DocumentCategoryService, //GeneratorFlag:[DocumentCategory]
CultureService, //GeneratorFlag:[Culture]
DocumentSubmitTypeService, //GeneratorFlag:[DocumentSubmitType]
DocumentTypeService, //GeneratorFlag:[DocumentType]
WalletCurrencyService, //GeneratorFlag:[WalletCurrency]
WalletTransactionStatuseService, //GeneratorFlag:[WalletTransactionStatuse]
WalletTransactionTypeService, //GeneratorFlag:[WalletTransactionType]
WalletTypeService, //GeneratorFlag:[WalletType]
WalletWithDrawStatuseService, //GeneratorFlag:[WalletWithDrawStatuse]
//GeneratorFlag:[NewExport]
}