//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Team, Company } from '../../data'; 

import { TeamService, CompanyService } from '../../services'; 

export default class RankFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'rank';
	schema = ENUMS.SCHEMA_TYPE.BASEINFO;
	resourceKey = 'peopleNet.rank.self';
	props = {
		title: {
			name: 'title',
			resourceKey: 'peopleNet.rank.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		teamId: {
			foreignKey: true,
			name: 'teamId',
			resourceKey: 'peopleNet.rank.teamId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Team(),
			data: async (params) =>
			{
				const service =
					new TeamService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'teamId',
				isMultiple: true,
				patterns: [],
			}
		},
		companyId: {
			foreignKey: true,
			name: 'companyId',
			resourceKey: 'peopleNet.rank.companyId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Company(),
			data: async (params) =>
			{
				const service =
					new CompanyService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'companyId',
				isMultiple: true,
				patterns: [],
			}
		},

	};
}
