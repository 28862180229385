//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Culture } from '../../data'; 

import { CultureService } from '../../services'; 

export default class BaseCompanyRank extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'companyRank';
	schema = ENUMS.SCHEMA_TYPE.BASE_INFO;
	resourceKey = 'peopleNet.companyRank.self';
	props = {
		companyRankId: {
			primarykey: true,
			name: 'companyRankId',
			resourceKey: 'peopleNet.companyRank.companyRankId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		companyRankKey: {
			name: 'companyRankKey',
			resourceKey: 'peopleNet.companyRank.companyRankKey',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		cultureId: {
			foreignKey: true,
			name: 'cultureId',
			resourceKey: 'peopleNet.companyRank.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Culture(),
			data: async (params) =>
			{
				const service =
					new CultureService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'cultureId',
				patterns: [],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'peopleNet.companyRank.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		companyId: {
			name: 'companyId',
			resourceKey: 'peopleNet.companyRank.companyId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		culture: {
			name: 'culture',
			resourceKey: 'peopleNet.companyRank.culture',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Culture(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		companyRankSubInfo: {
			name: 'companyRankSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
