//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { City } from '@/modules/people-net/data'; 

import { CityService } from '@/modules/people-net/services'; 

export default class PersonDetailFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'personDetail';
	schema = ENUMS.SCHEMA_TYPE.MAININFO;
	resourceKey = 'peopleNet.personDetail.self';
	props = {
		cityId: {
			foreignKey: true,
			name: 'cityId',
			resourceKey: 'peopleNet.personDetail.cityId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new City(),
			data: async (params) =>
			{
				const service =
					new CityService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'cityId',
				isMultiple: true,
				patterns: [],
			}
		},

	};
}
