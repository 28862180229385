//GeneratorFlag:[NoChange]
import { BaseInvoice } from '../base/data'
export default class Invoice extends BaseInvoice
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			totalAmount: {
				...this.props.totalAmount,
				setConfig:{
					...this.props.totalAmount.setConfig
				}
			},
			discountAmount: {
				...this.props.discountAmount,
				setConfig:{
					...this.props.discountAmount.setConfig
				}
			},
			paybleAmount: {
				...this.props.paybleAmount,
				setConfig:{
					...this.props.paybleAmount.setConfig
				}
			},
			paidAmount: {
				...this.props.paidAmount,
				setConfig:{
					...this.props.paidAmount.setConfig
				}
			},
			totalRemainAmount: {
				...this.props.totalRemainAmount,
				setConfig:{
					...this.props.totalRemainAmount.setConfig
				}
			},
			invoiceStatuseId: {
				...this.props.invoiceStatuseId,
				setConfig:{
					...this.props.invoiceStatuseId.setConfig
				}
			},
			createdDate: {
				...this.props.createdDate,
				setConfig:{
					...this.props.createdDate.setConfig
				}
			},
			transportCostAmount: {
				...this.props.transportCostAmount,
				setConfig:{
					...this.props.transportCostAmount.setConfig
				}
			},
			packagingCostAmount: {
				...this.props.packagingCostAmount,
				setConfig:{
					...this.props.packagingCostAmount.setConfig
				}
			},
			taxAmount: {
				...this.props.taxAmount,
				setConfig:{
					...this.props.taxAmount.setConfig
				}
			},
			checkoutDate: {
				...this.props.checkoutDate,
				setConfig:{
					...this.props.checkoutDate.setConfig
				}
			},
			displayCheckoutDate: {
				...this.props.displayCheckoutDate,
				setConfig:{
					...this.props.displayCheckoutDate.setConfig
				}
			},
			invoicePaymentTypeId: {
				...this.props.invoicePaymentTypeId,
				setConfig:{
					...this.props.invoicePaymentTypeId.setConfig
				}
			},
			storeId: {
				...this.props.storeId,
				setConfig:{
					...this.props.storeId.setConfig
				}
			},
			personAddressId: {
				...this.props.personAddressId,
				setConfig:{
					...this.props.personAddressId.setConfig
				}
			},
			invoiceSerial: {
				...this.props.invoiceSerial,
				setConfig:{
					...this.props.invoiceSerial.setConfig
				}
			},
			isConnected: {
				...this.props.isConnected,
				setConfig:{
					...this.props.isConnected.setConfig
				}
			},
			invoicePaymentType: {
				...this.props.invoicePaymentType,
				setConfig:{
					...this.props.invoicePaymentType.setConfig
				}
			},
			invoiceStatuse: {
				...this.props.invoiceStatuse,
				setConfig:{
					...this.props.invoiceStatuse.setConfig
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				}
			},
			personAddress: {
				...this.props.personAddress,
				setConfig:{
					...this.props.personAddress.setConfig
				}
			},
			store: {
				...this.props.store,
				setConfig:{
					...this.props.store.setConfig
				}
			},
			invoiceSubInfo: {
				...this.props.invoiceSubInfo,
				setConfig:{
					...this.props.invoiceSubInfo.setConfig
				}
			},
			shopPerson: {
				...this.props.shopPerson,
				setConfig:{
					...this.props.shopPerson.setConfig
				}
			},

		};
	}
}
