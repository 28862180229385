<template>
	<label :for="prop.name" v-if="!isHidden">
		<span>{{ label }}</span>
		<span class="text-danger ms-2" v-if="isRequired">
			<Frc-icon-bootstrap name="bi-circle-fill" class="position-relative" style="top:-5px;" size="5px" />
		</span>
	</label>
</template>
<script>
	export default {
		props:
		{
			'prop': {
				type: Object,
				default: null
			},

			'useValidation': {
				type: Boolean,
				default: true
			}
		},

		computed: {
			label()
			{
				return this.$loc.fromResource(this.prop.resourceKey)
			},

			isHidden()
			{
				return this.prop.setConfig.inputType == 'hidden'
			},

			isRequired()
			{
				return this.useValidation && this.prop.setConfig.patterns.includes('required')
			}
		}
	}
</script>
