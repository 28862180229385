//GeneratorFlag:[NoChange]
import { BaseProductStoreGetProductInfo } from '@/modules/shop/base/data/actions/product-store/get-product-info'
export default class ProductStoreGetProductInfo extends BaseProductStoreGetProductInfo
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			storeId: {
				...this.props.storeId,
				setConfig:{
					...this.props.storeId.setConfig
				}
			},
			productStoreId: {
				...this.props.productStoreId,
				setConfig:{
					...this.props.productStoreId.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			productMonetaryFeatureTitle: {
				...this.props.productMonetaryFeatureTitle,
				setConfig:{
					...this.props.productMonetaryFeatureTitle.setConfig
				}
			},
			firstPrice: {
				...this.props.firstPrice,
				setConfig:{
					...this.props.firstPrice.setConfig
				}
			},
			scondPrice: {
				...this.props.scondPrice,
				setConfig:{
					...this.props.scondPrice.setConfig
				}
			},
			productStoreInventory: {
				...this.props.productStoreInventory,
				setConfig:{
					...this.props.productStoreInventory.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			currency: {
				...this.props.currency,
				setConfig:{
					...this.props.currency.setConfig
				}
			},
			maxSellCount: {
				...this.props.maxSellCount,
				setConfig:{
					...this.props.maxSellCount.setConfig
				}
			},
			minSellCount: {
				...this.props.minSellCount,
				setConfig:{
					...this.props.minSellCount.setConfig
				}
			},
			productColorId: {
				...this.props.productColorId,
				setConfig:{
					...this.props.productColorId.setConfig
				}
			},
			productMonetaryFeatureId: {
				...this.props.productMonetaryFeatureId,
				setConfig:{
					...this.props.productMonetaryFeatureId.setConfig
				}
			},
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			categoryName: {
				...this.props.categoryName,
				setConfig:{
					...this.props.categoryName.setConfig
				}
			},
			storeName: {
				...this.props.storeName,
				setConfig:{
					...this.props.storeName.setConfig
				}
			},
			width: {
				...this.props.width,
				setConfig:{
					...this.props.width.setConfig
				}
			},
			weight: {
				...this.props.weight,
				setConfig:{
					...this.props.weight.setConfig
				}
			},
			height: {
				...this.props.height,
				setConfig:{
					...this.props.height.setConfig
				}
			},
			lenght: {
				...this.props.lenght,
				setConfig:{
					...this.props.lenght.setConfig
				}
			},
			productStatusId: {
				...this.props.productStatusId,
				setConfig:{
					...this.props.productStatusId.setConfig
				}
			},
			productStatusTitle: {
				...this.props.productStatusTitle,
				setConfig:{
					...this.props.productStatusTitle.setConfig
				}
			},
			images: {
				...this.props.images,
				setConfig:{
					...this.props.images.setConfig
				}
			},
			colors: {
				...this.props.colors,
				setConfig:{
					...this.props.colors.setConfig
				}
			},
			monetaryFeatures: {
				...this.props.monetaryFeatures,
				setConfig:{
					...this.props.monetaryFeatures.setConfig
				}
			},
		};
	}
}

