//GeneratorFlag:[NoChange]
import ProductDoc from './product-doc';
import Category from './category';
import Culture from './culture';
import ProductStatus from './product-status';
import ProductSpecification from './product-specification';
import Product from './product';
import ProductDocType from './product-doc-type';
import LsStaticFile from './ls-static-file';

export default {ProductDoc, Category, Culture, ProductStatus, ProductSpecification, Product, ProductDocType, LsStaticFile}
export{ProductDoc, Category, Culture, ProductStatus, ProductSpecification, Product, ProductDocType, LsStaticFile}
