//GeneratorFlag:[NoChange]
import { BasePersonGender } from '../base/data'
export default class PersonGender extends BasePersonGender
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personGenderId: {
				...this.props.personGenderId,
				setConfig:{
					...this.props.personGenderId.setConfig
				}
			},
			personGenderKey: {
				...this.props.personGenderKey,
				setConfig:{
					...this.props.personGenderKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
