//GeneratorFlag:[NoChange]
export default {
	path: 'person-document',
	name: 'peopleNet.personDocument.layout',
	redirect: to => {
		return {name: 'peopleNet.personDocument.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.personDocument.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.personDocument.list',
			component: () => import('@/modules/people-net/views/person-document/person-document-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.personDocument.create',
			component: () => import('@/modules/people-net/views/person-document/person-document-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.personDocument.import',
			component: () => import('@/modules/people-net/views/person-document/person-document-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.personDocument.update',
			component: () => import('@/modules/people-net/views/person-document/person-document-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.personDocument.delete',
			component: () => import('@/modules/people-net/views/person-document/person-document-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.personDocument.detail',
			component: () => import('@/modules/people-net/views/person-document/person-document-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
