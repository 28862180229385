//GeneratorFlag:[NoChange]
import { BaseProductSpecification } from '../base/data'
export default class ProductSpecification extends BaseProductSpecification
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			weight: {
				...this.props.weight,
				setConfig:{
					...this.props.weight.setConfig
				}
			},
			width: {
				...this.props.width,
				setConfig:{
					...this.props.width.setConfig
				}
			},
			length: {
				...this.props.length,
				setConfig:{
					...this.props.length.setConfig
				}
			},
			height: {
				...this.props.height,
				setConfig:{
					...this.props.height.setConfig
				}
			},
			sentCost: {
				...this.props.sentCost,
				setConfig:{
					...this.props.sentCost.setConfig
				}
			},
			isTaxable: {
				...this.props.isTaxable,
				setConfig:{
					...this.props.isTaxable.setConfig
				}
			},
			productTransportCostTypeId: {
				...this.props.productTransportCostTypeId,
				setConfig:{
					...this.props.productTransportCostTypeId.setConfig
				}
			},
			productTransportCostType: {
				...this.props.productTransportCostType,
				setConfig:{
					...this.props.productTransportCostType.setConfig
				}
			},

		};
	}
}
