//GeneratorFlag:[NoChange]
import { BaseProductMonetaryFeature } from '../base/data'
export default class ProductMonetaryFeature extends BaseProductMonetaryFeature
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productMonetaryFeatureId: {
				...this.props.productMonetaryFeatureId,
				setConfig:{
					...this.props.productMonetaryFeatureId.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			category: {
				...this.props.category,
				setConfig:{
					...this.props.category.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			productMonetaryFeatureSubInfo: {
				...this.props.productMonetaryFeatureSubInfo,
				setConfig:{
					...this.props.productMonetaryFeatureSubInfo.setConfig
				}
			},

		};
	}
}
