<template>
	<popper v-if="data.value" class="popper-light m-0 border-0" arrow>
		<span class="pointer truncate" @click="isOpen = true">
			{{data.displayValue}}
			<Frc-icon-bootstrap name="bi-circle-fill" color="gray" class="position-relative ms-1" style="top:-2px;" size="3px" />
		</span>
		<template #content>
			<div class="cell-popup-box">
				<table class="table table-sm table-borderless mb-0" style="font-size:13px;" v-if="isOpen">
					<tbody>
						<tr v-for="prop in getProps()" :key="prop.name">
							<th>
								<span class="pe-4">{{ $loc.fromResource(prop.resourceKey) }}</span>
							</th>
							<td>
								<table-cell :data="prop" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</popper>
</template>
<script>
	import { defineAsyncComponent } from 'vue'
	export default {
		components:
		{
			'table-cell': defineAsyncComponent(() => import('./table-cell.vue'))
		},

		props:
		{
			data: Object
		},

		data()
		{
			return {
				isOpen: false
			}
		},

		methods:
		{
			getProps(entity)
			{
				var props = []
				entity = entity || this.data.entity

				if (entity)
				{
					for (const key of Object.keys(entity.value))
					{
						if (!entity.props[key].fk) { props.push(entity.props[key]) }
					}
				}

				return props
			},
		}
	}
</script>
<style scoped>
	.cell-popup-box {
		width: 100%;
		padding: 5px 5px 5px 20px;
		max-height: 200px;
		overflow-y: auto;
	}
</style>
