//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { PersonPreTransactionStatuse } from '@/modules/shop/data/person-pre-transaction/save'; 
import { PersonPreTransactionStatuseService, PersonService } from '@/modules/shop/services'; 
export default class BasePersonPreTransaction extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'personPreTransaction';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.personPreTransactionSave.self';
	props = {
		personPreTransactionId: {
			primarykey: true,
			name: 'personPreTransactionId',
			resourceKey: 'shop.personPreTransactionSave.personPreTransactionId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		transactionFrom: {
			name: 'transactionFrom',
			resourceKey: 'shop.personPreTransactionSave.transactionFrom',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 150,
				isUnicode: false,
				patterns: ['required'],
			}
		},
		amount: {
			name: 'amount',
			resourceKey: 'shop.personPreTransactionSave.amount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		refCode: {
			name: 'refCode',
			resourceKey: 'shop.personPreTransactionSave.refCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 150,
				isUnicode: false,
				patterns: ['required'],
			}
		},
		personPreTransactionStatuseId: {
			foreignKey: true,
			name: 'personPreTransactionStatuseId',
			resourceKey: 'shop.personPreTransactionSave.personPreTransactionStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonPreTransactionStatuse(),
			data: async (params) =>
			{
				const service =
					new PersonPreTransactionStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personPreTransactionStatuseId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'shop.personPreTransactionSave.personId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'firstName',
				valueKey: 'personId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		personPreTransactionStatuse: {
			name: 'personPreTransactionStatuse',
			resourceKey: 'shop.personPreTransactionSave.personPreTransactionStatuse.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonPreTransactionStatuse(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

