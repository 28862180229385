<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { ProductStore, ProductStoreFilter } from '../../data/product-store/list'
	import { ProductStoreService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return ProductStore;
			},
			
			filterEntity()
			{
				return ProductStoreFilter;
			},

			service()
			{
				return new ProductStoreService();
			},

			filterData()
			{
				const productStoreId =
					this.$route.query['productStoreId'];

				if (productStoreId)
				{
					return {
						'productStoreId': productStoreId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'shop.productStore.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'shop.productStore.import',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'shop.productStore.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'shop.productStore.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'shop.productStore.detail',
							resourceKey: 'shop.views.productStore.detail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
