//GeneratorFlag:[NoChange]
import BaseBankService from './base-bank-service';
import BaseBankPaymentService from './base-bank-payment-service';
import BaseCategoryService from './base-category-service';
import BaseCultureService from './base-culture-service';
import BaseFeatureService from './base-feature-service';
import BaseFeatureItemService from './base-feature-item-service';
import BaseInvoiceService from './base-invoice-service';
import BaseInvoicePaymentTypeService from './base-invoice-payment-type-service';
import BaseInvoiceProductService from './base-invoice-product-service';
import BaseInvoiceStatuseService from './base-invoice-statuse-service';
import BaseInvoiceTransactionService from './base-invoice-transaction-service';
import BaseInvoiceTransactionTypeService from './base-invoice-transaction-type-service';
import BasePersonAddressService from './base-person-address-service';
import BasePersonCreaditTransactionTypeService from './base-person-creadit-transaction-type-service';
import BasePlanLineService from './base-plan-line-service';
import BasePlanPrecentService from './base-plan-precent-service';
import BasePlanSettingService from './base-plan-setting-service';
import BaseProductService from './base-product-service';
import BaseProductCategoryService from './base-product-category-service';
import BaseProductColorService from './base-product-color-service';
import BaseProductDetailService from './base-product-detail-service';
import BaseProductDocService from './base-product-doc-service';
import BaseProductDocTypeService from './base-product-doc-type-service';
import BaseProductFeatureService from './base-product-feature-service';
import BaseProductMonetaryFeatureService from './base-product-monetary-feature-service';
import BaseProductSpecificationService from './base-product-specification-service';
import BaseProductStatusService from './base-product-status-service';
import BaseProductStoreService from './base-product-store-service';
import BaseProductTransportCostTypeService from './base-product-transport-cost-type-service';
import BaseStoreService from './base-store-service';
 
export default {BaseBankService, BaseBankPaymentService, BaseCategoryService, BaseCultureService, BaseFeatureService, BaseFeatureItemService, BaseInvoiceService, BaseInvoicePaymentTypeService, BaseInvoiceProductService, BaseInvoiceStatuseService, BaseInvoiceTransactionService, BaseInvoiceTransactionTypeService, BasePersonAddressService, BasePersonCreaditTransactionTypeService, BasePlanLineService, BasePlanPrecentService, BasePlanSettingService, BaseProductService, BaseProductCategoryService, BaseProductColorService, BaseProductDetailService, BaseProductDocService, BaseProductDocTypeService, BaseProductFeatureService, BaseProductMonetaryFeatureService, BaseProductSpecificationService, BaseProductStatusService, BaseProductStoreService, BaseProductTransportCostTypeService, BaseStoreService}
export{BaseBankService, BaseBankPaymentService, BaseCategoryService, BaseCultureService, BaseFeatureService, BaseFeatureItemService, BaseInvoiceService, BaseInvoicePaymentTypeService, BaseInvoiceProductService, BaseInvoiceStatuseService, BaseInvoiceTransactionService, BaseInvoiceTransactionTypeService, BasePersonAddressService, BasePersonCreaditTransactionTypeService, BasePlanLineService, BasePlanPrecentService, BasePlanSettingService, BaseProductService, BaseProductCategoryService, BaseProductColorService, BaseProductDetailService, BaseProductDocService, BaseProductDocTypeService, BaseProductFeatureService, BaseProductMonetaryFeatureService, BaseProductSpecificationService, BaseProductStatusService, BaseProductStoreService, BaseProductTransportCostTypeService, BaseStoreService}
