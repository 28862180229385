//GeneratorFlag:[NoChange]
import { BasePerson } from '../base/data'
export default class Person extends BasePerson
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			firstName: {
				...this.props.firstName,
				setConfig:{
					...this.props.firstName.setConfig
				}
			},
			lastName: {
				...this.props.lastName,
				setConfig:{
					...this.props.lastName.setConfig
				}
			},
			nationalNo: {
				...this.props.nationalNo,
				setConfig:{
					...this.props.nationalNo.setConfig
				}
			},
			passportNo: {
				...this.props.passportNo,
				setConfig:{
					...this.props.passportNo.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			genoString: {
				...this.props.genoString,
				setConfig:{
					...this.props.genoString.setConfig
				}
			},
			rankId: {
				...this.props.rankId,
				setConfig:{
					...this.props.rankId.setConfig
				}
			},
			companyRankId: {
				...this.props.companyRankId,
				setConfig:{
					...this.props.companyRankId.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},
			mobile: {
				...this.props.mobile,
				setConfig:{
					...this.props.mobile.setConfig
				}
			},
			email: {
				...this.props.email,
				setConfig:{
					...this.props.email.setConfig
				}
			},
			personStauseId: {
				...this.props.personStauseId,
				setConfig:{
					...this.props.personStauseId.setConfig
				}
			},
			personGenderId: {
				...this.props.personGenderId,
				setConfig:{
					...this.props.personGenderId.setConfig
				}
			},
			teamId: {
				...this.props.teamId,
				setConfig:{
					...this.props.teamId.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
			levelOfHead: {
				...this.props.levelOfHead,
				setConfig:{
					...this.props.levelOfHead.setConfig
				}
			},
			creditValue: {
				...this.props.creditValue,
				setConfig:{
					...this.props.creditValue.setConfig
				}
			},
			company: {
				...this.props.company,
				setConfig:{
					...this.props.company.setConfig
				}
			},
			companyRank: {
				...this.props.companyRank,
				setConfig:{
					...this.props.companyRank.setConfig
				}
			},
			personGender: {
				...this.props.personGender,
				setConfig:{
					...this.props.personGender.setConfig
				}
			},
			personStause: {
				...this.props.personStause,
				setConfig:{
					...this.props.personStause.setConfig
				}
			},
			rank: {
				...this.props.rank,
				setConfig:{
					...this.props.rank.setConfig
				}
			},
			team: {
				...this.props.team,
				setConfig:{
					...this.props.team.setConfig
				}
			},
			personAccess: {
				...this.props.personAccess,
				setConfig:{
					...this.props.personAccess.setConfig
				}
			},
			personDetail: {
				...this.props.personDetail,
				setConfig:{
					...this.props.personDetail.setConfig
				}
			},
			personSubInfo: {
				...this.props.personSubInfo,
				setConfig:{
					...this.props.personSubInfo.setConfig
				}
			},

		};
	}
}
