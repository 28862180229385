//GeneratorFlag:[NoChange]
export default {
	path: 'person-credit-transaction',
	name: 'peopleNet.personCreditTransaction.layout',
	redirect: to => {
		return {name: 'peopleNet.personCreditTransaction.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.personCreditTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.personCreditTransaction.list',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.personCreditTransaction.create',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.personCreditTransaction.import',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.personCreditTransaction.update',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.personCreditTransaction.delete',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.personCreditTransaction.detail',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
