//GeneratorFlag:[NoChange]
export default {
	path: 'store-profit-rate',
	name: 'shop.storeProfitRate.layout',
	redirect: to => {
		return {name: 'shop.storeProfitRate.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/BaseInfo/store-profit-rate"],
		resourceKey: 'shop.views.storeProfitRate.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[StoreProfitRateDelete]"
		{
			path: 'delete',
			name: 'shop.storeProfitRate.delete',
			component: () => import('@/modules/shop/views/store-profit-rate/store-profit-rate-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.storeProfitRate.delete',
				clientAccessKeys:["/shop/BaseInfo/store-profit-rate/delete"],
				serverAccessKeys:["/shop/store-profit-rate/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreProfitRateDetail]"
		{
			path: 'detail',
			name: 'shop.storeProfitRate.detail',
			component: () => import('@/modules/shop/views/store-profit-rate/store-profit-rate-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.storeProfitRate.detail',
				clientAccessKeys:["/shop/BaseInfo/store-profit-rate/detail"],
				serverAccessKeys:["/shop/store-profit-rate/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreProfitRateList]"
		{
			path: 'list',
			name: 'shop.storeProfitRate.list',
			component: () => import('@/modules/shop/views/store-profit-rate/store-profit-rate-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.storeProfitRate.list',
				clientAccessKeys:["/shop/BaseInfo/store-profit-rate/list"],
				serverAccessKeys:["/shop/store-profit-rate/list","/shop/store/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreProfitRateCreate]"
		{
			path: 'create',
			name: 'shop.storeProfitRate.create',
			component: () => import('@/modules/shop/views/store-profit-rate/store-profit-rate-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.storeProfitRate.create',
				clientAccessKeys:["/shop/BaseInfo/store-profit-rate/create"],
				serverAccessKeys:["/shop/store-profit-rate/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreProfitRateImport]"
		{
			path: 'import',
			name: 'shop.storeProfitRate.import',
			component: () => import('@/modules/shop/views/store-profit-rate/store-profit-rate-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.storeProfitRate.import',
				clientAccessKeys:["/shop/BaseInfo/store-profit-rate/import"],
				serverAccessKeys:["/shop/store-profit-rate/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreProfitRateUpdate]"
		{
			path: 'update',
			name: 'shop.storeProfitRate.update',
			component: () => import('@/modules/shop/views/store-profit-rate/store-profit-rate-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.storeProfitRate.update',
				clientAccessKeys:["/shop/BaseInfo/store-profit-rate/update"],
				serverAccessKeys:["/shop/store-profit-rate/save","/shop/store/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






