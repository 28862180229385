//GeneratorFlag:[NoChange]
import { BaseInvoiceProduct } from '../base/data'
export default class InvoiceProduct extends BaseInvoiceProduct
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			invoiceProductId: {
				...this.props.invoiceProductId,
				setConfig:{
					...this.props.invoiceProductId.setConfig
				}
			},
			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			count: {
				...this.props.count,
				setConfig:{
					...this.props.count.setConfig
				}
			},
			basePrice: {
				...this.props.basePrice,
				setConfig:{
					...this.props.basePrice.setConfig
				}
			},
			totalPrice: {
				...this.props.totalPrice,
				setConfig:{
					...this.props.totalPrice.setConfig
				}
			},
			productStoreId: {
				...this.props.productStoreId,
				setConfig:{
					...this.props.productStoreId.setConfig
				}
			},
			invoice: {
				...this.props.invoice,
				setConfig:{
					...this.props.invoice.setConfig
				}
			},
			productStore: {
				...this.props.productStore,
				setConfig:{
					...this.props.productStore.setConfig
				}
			},
			invoiceProductSubInfo: {
				...this.props.invoiceProductSubInfo,
				setConfig:{
					...this.props.invoiceProductSubInfo.setConfig
				}
			},

		};
	}
}
