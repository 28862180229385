//GeneratorFlag:[NoChange]
import { BaseStore } from '../base/data'
export default class Store extends BaseStore
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			storeId: {
				...this.props.storeId,
				setConfig:{
					...this.props.storeId.setConfig
				}
			},
			storeName: {
				...this.props.storeName,
				setConfig:{
					...this.props.storeName.setConfig
				}
			},
			valueAddedTaxPrecent: {
				...this.props.valueAddedTaxPrecent,
				setConfig:{
					...this.props.valueAddedTaxPrecent.setConfig
				}
			},
			complicationsTaxPrecent: {
				...this.props.complicationsTaxPrecent,
				setConfig:{
					...this.props.complicationsTaxPrecent.setConfig
				}
			},
			transportCost: {
				...this.props.transportCost,
				setConfig:{
					...this.props.transportCost.setConfig
				}
			},
			transportCostFreeFrom: {
				...this.props.transportCostFreeFrom,
				setConfig:{
					...this.props.transportCostFreeFrom.setConfig
				}
			},
			storeSubInfo: {
				...this.props.storeSubInfo,
				setConfig:{
					...this.props.storeSubInfo.setConfig
				}
			},

		};
	}
}
