//GeneratorFlag:[NoChange]
export default {
	path: 'invoice-product',
	name: 'shop.invoiceProduct.layout',
	redirect: to => {
		return {name: 'shop.invoiceProduct.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'shop.views.invoiceProduct.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'shop.invoiceProduct.list',
			component: () => import('@/modules/shop/views/invoice-product/invoice-product-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceProduct.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'shop.invoiceProduct.create',
			component: () => import('@/modules/shop/views/invoice-product/invoice-product-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceProduct.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'shop.invoiceProduct.import',
			component: () => import('@/modules/shop/views/invoice-product/invoice-product-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceProduct.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'shop.invoiceProduct.update',
			component: () => import('@/modules/shop/views/invoice-product/invoice-product-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceProduct.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'shop.invoiceProduct.delete',
			component: () => import('@/modules/shop/views/invoice-product/invoice-product-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceProduct.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'shop.invoiceProduct.detail',
			component: () => import('@/modules/shop/views/invoice-product/invoice-product-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceProduct.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
