//GeneratorFlag:[NoChange]
export default {
	path: 'bank-payment',
	name: 'shop.bankPayment.layout',
	redirect: to => {
		return {name: 'shop.bankPayment.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/bank-payment"],
		resourceKey: 'shop.views.bankPayment.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[BankPaymentDelete]"
		{
			path: 'delete',
			name: 'shop.bankPayment.delete',
			component: () => import('@/modules/shop/views/bank-payment/bank-payment-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPayment.delete',
				clientAccessKeys:["/shop/bank-payment/delete"],
				serverAccessKeys:["/shop/bank-payment/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankPaymentDetail]"
		{
			path: 'detail',
			name: 'shop.bankPayment.detail',
			component: () => import('@/modules/shop/views/bank-payment/bank-payment-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPayment.detail',
				clientAccessKeys:["/shop/bank-payment/detail"],
				serverAccessKeys:["/shop/bank-payment/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankPaymentList]"
		{
			path: 'list',
			name: 'shop.bankPayment.list',
			component: () => import('@/modules/shop/views/bank-payment/bank-payment-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPayment.list',
				clientAccessKeys:["/shop/bank-payment/list"],
				serverAccessKeys:["/shop/bank-payment/list","/shop/store/list","/shop/bank/list","/shop/bank-payment-statuse/list","/shop/culture/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankPaymentCreate]"
		{
			path: 'create',
			name: 'shop.bankPayment.create',
			component: () => import('@/modules/shop/views/bank-payment/bank-payment-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPayment.create',
				clientAccessKeys:["/shop/bank-payment/create"],
				serverAccessKeys:["/shop/bank-payment/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankPaymentImport]"
		{
			path: 'import',
			name: 'shop.bankPayment.import',
			component: () => import('@/modules/shop/views/bank-payment/bank-payment-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPayment.import',
				clientAccessKeys:["/shop/bank-payment/import"],
				serverAccessKeys:["/shop/bank-payment/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankPaymentUpdate]"
		{
			path: 'update',
			name: 'shop.bankPayment.update',
			component: () => import('@/modules/shop/views/bank-payment/bank-payment-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bankPayment.update',
				clientAccessKeys:["/shop/bank-payment/update"],
				serverAccessKeys:["/shop/bank-payment/save","/shop/store/list","/shop/bank/list","/shop/bank-payment-statuse/list","/shop/culture/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






