//GeneratorFlag:[NoChange]
import { BaseInvoiceProductService } from '../base/services'
export default class InvoiceProductService extends BaseInvoiceProductService
{
	constructor()
	{
		super();
	}

//GeneratorFlag:[NewAction]
}
