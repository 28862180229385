//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import {  } from '@/modules/financial/data/wallet/get-credit'; 
export default class Wallet extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'wallet';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'financial.walletGetCredit.self';
	props = {
		personId: {
			name: 'personId',
			resourceKey: 'financial.walletGetCredit.personId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		creditAmount: {
			name: 'creditAmount',
			resourceKey: 'financial.walletGetCredit.creditAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		wallets: {
			name: 'wallets',
			resourceKey: 'financial.walletGetCredit.wallets.self',
			type: ENUMS.PROP_TYPE.ARRAY,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{currency}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: []
		},
	};
}