//GeneratorFlag:[NoChange]
import CultureService from './culture-service'; //GeneratorFlag:[Culture]
import TicketService from './ticket-service'; //GeneratorFlag:[Ticket]
import TicketDepartmentService from './ticket-department-service'; //GeneratorFlag:[TicketDepartment]
import TicketItemService from './ticket-item-service'; //GeneratorFlag:[TicketItem]
import TicketStatuseService from './ticket-statuse-service'; //GeneratorFlag:[TicketStatuse]
//GeneratorFlag:[NewImport]
export default {
CultureService, //GeneratorFlag:[Culture]
TicketService, //GeneratorFlag:[Ticket]
TicketDepartmentService, //GeneratorFlag:[TicketDepartment]
TicketItemService, //GeneratorFlag:[TicketItem]
TicketStatuseService, //GeneratorFlag:[TicketStatuse]
//GeneratorFlag:[NewExport]
}
export{
CultureService, //GeneratorFlag:[Culture]
TicketService, //GeneratorFlag:[Ticket]
TicketDepartmentService, //GeneratorFlag:[TicketDepartment]
TicketItemService, //GeneratorFlag:[TicketItem]
TicketStatuseService, //GeneratorFlag:[TicketStatuse]
//GeneratorFlag:[NewExport]
}