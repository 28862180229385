<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-excel-import :key="ver" :entity="entity" :task="import" @close="reset" @cancel="reset" />
</template>
<script>
	import { DocumentCategory } from '@/modules/financial/data/document-category/create'
	import { DocumentCategoryService } from '@/modules/financial/services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed:
		{
			entity()
			{
				return DocumentCategory;
			},

			service()
			{
				return new DocumentCategoryService();
			}
		},
		methods:
		{
			async import(payload)
			{
				return await this.service.create(payload, {HSR:false, HER:false});
			},

			reset()
			{
				this.ver = this.ver + 1;
			}
		}
	}
</script>