//GeneratorFlag:[NoChange]
import { BaseProductDetail } from '../base/data'
export default class ProductDetail extends BaseProductDetail
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productDetailId: {
				...this.props.productDetailId,
				setConfig:{
					...this.props.productDetailId.setConfig
				}
			},
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			productColorId: {
				...this.props.productColorId,
				setConfig:{
					...this.props.productColorId.setConfig
				}
			},
			productMonetaryFeatureId: {
				...this.props.productMonetaryFeatureId,
				setConfig:{
					...this.props.productMonetaryFeatureId.setConfig
				}
			},
			adminDisplayName: {
				...this.props.adminDisplayName,
				setConfig:{
					...this.props.adminDisplayName.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			isDefaultProduct: {
				...this.props.isDefaultProduct,
				setConfig:{
					...this.props.isDefaultProduct.setConfig
				}
			},
			productStatusId: {
				...this.props.productStatusId,
				setConfig:{
					...this.props.productStatusId.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			product: {
				...this.props.product,
				setConfig:{
					...this.props.product.setConfig
				}
			},
			productColor: {
				...this.props.productColor,
				setConfig:{
					...this.props.productColor.setConfig
				}
			},
			productMonetaryFeature: {
				...this.props.productMonetaryFeature,
				setConfig:{
					...this.props.productMonetaryFeature.setConfig
				}
			},
			productStatus: {
				...this.props.productStatus,
				setConfig:{
					...this.props.productStatus.setConfig
				}
			},
			productDetailSubInfo: {
				...this.props.productDetailSubInfo,
				setConfig:{
					...this.props.productDetailSubInfo.setConfig
				}
			},

		};
	}
}
