<template>
	<div class="row" v-if="localEntity">
		<div class="col-12">
			<list-collapse :entity="localEntity" />
		</div>
	</div>
</template>

<script>
	import listCollapse from './list-collapse'

	export default {
		components:
		{
			'list-collapse': listCollapse
		},

		props:
		{
			'entity': {
				type: Function
			},

			'data': {
				type: Object
			},
		},

		data()
		{
			return {
				localEntity: null
			}
		},

		beforeMount()
		{
			this.init();
		},

		methods: {
			init()
			{
				if (this.data)
				{
					this.localEntity = new this.entity({
						value: this.data
					})
				}
			}
		}
	}
</script>
