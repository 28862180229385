//GeneratorFlag:[NoChange]

export default {
	"name":"peopleNet",
	"layout":{
		"baseInfo": "Basic Information", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "System information", //GeneratorFlag:[BaseSysLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"news": {
			"layout": "News",
			"create": "Create New News", //GeneratorFlag:[Views.News.Create]
			'createImport': 'Imports News From Excel', //GeneratorFlag:[Views.News.CreateImport] 
 			'delete': 'Delete News', //GeneratorFlag:[Views.News.Delete] 
 			'detail': 'Detail of News', //GeneratorFlag:[Views.News.Detail] 
 			'list': 'List of News', //GeneratorFlag:[Views.News.List] 
 			'update': 'Edit News', //GeneratorFlag:[Views.News.Update] 
 //GeneratorFlag:[Views.News]
		},
		"person": {
			"layout": "Person",
			"create": "Create New Person", //GeneratorFlag:[Views.Person.Create]
			'createImport': 'Imports Person From Excel', //GeneratorFlag:[Views.Person.CreateImport] 
 			'delete': 'Delete Person', //GeneratorFlag:[Views.Person.Delete] 
 			'detail': 'Detail of Person', //GeneratorFlag:[Views.Person.Detail] 
 			'list': 'List of Person', //GeneratorFlag:[Views.Person.List] 
 			'update': 'Edit Person', //GeneratorFlag:[Views.Person.Update] 
 			'changeEmail': 'Cnange Email', //GeneratorFlag:[Views.Person.ChangeEmail] 
 			'changeSecurityCode': 'Cnange Email', //GeneratorFlag:[Views.Person.ChangeSecurityCode] 
 			'checkUpline': 'Check Upline', //GeneratorFlag:[Views.Person.CheckUpline] 
 			'getCredit': 'Get Credit of Person', //GeneratorFlag:[Views.Person.GetCredit] 
 			'getPersonByData': 'Get Person By Data', //GeneratorFlag:[Views.Person.GetPersonByData] 
 			'getProfile': 'Get Profile', //GeneratorFlag:[Views.Person.GetProfile] 
 			'getUplineInfo': 'person', //GeneratorFlag:[Views.Person.GetUplineInfo] 
 			'registerByEmail': 'person', //GeneratorFlag:[Views.Person.RegisterByEmail] 
 			'register': 'Get Person By Data', //GeneratorFlag:[Views.Person.Register] 
 			'registerShop': 'person', //GeneratorFlag:[Views.Person.RegisterShop] 
 			'searchTeam': 'Get My Team List', //GeneratorFlag:[Views.Person.SearchTeam] 
 			'treeList': 'Tree View Team', //GeneratorFlag:[Views.Person.TreeList] 
 //GeneratorFlag:[Views.Person]
		},
		"personAppPurchase": {
			"layout": "PersonAppPurchase",
			"create": "Create New PersonAppPurchase", //GeneratorFlag:[Views.PersonAppPurchase.Create]
			'createImport': 'Imports PersonAppPurchase From Excel', //GeneratorFlag:[Views.PersonAppPurchase.CreateImport] 
 			'delete': 'Delete PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.Delete] 
 			'detail': 'Detail of PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.Detail] 
 			'list': 'List of PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.List] 
 			'update': 'Edit PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.Update] 
 			'checkCredit': 'person app purchase', //GeneratorFlag:[Views.PersonAppPurchase.CheckCredit] 
 //GeneratorFlag:[Views.PersonAppPurchase]
		},
		"personCreditTransaction": {
			"layout": "PersonCreditTransaction",
			"create": "Create New PersonCreditTransaction", //GeneratorFlag:[Views.PersonCreditTransaction.Create]
			'createImport': 'Imports PersonCreditTransaction From Excel', //GeneratorFlag:[Views.PersonCreditTransaction.CreateImport] 
 			'delete': 'Delete PersonCreditTransaction', //GeneratorFlag:[Views.PersonCreditTransaction.Delete] 
 			'detail': 'Detail of PersonCreditTransaction', //GeneratorFlag:[Views.PersonCreditTransaction.Detail] 
 			'list': 'List of PersonCreditTransaction', //GeneratorFlag:[Views.PersonCreditTransaction.List] 
 			'update': 'Edit PersonCreditTransaction', //GeneratorFlag:[Views.PersonCreditTransaction.Update] 
 			'charge': 'Charge Wallet', //GeneratorFlag:[Views.PersonCreditTransaction.Charge] 
 			'myList': 'Credit Transactions', //GeneratorFlag:[Views.PersonCreditTransaction.MyList] 
 //GeneratorFlag:[Views.PersonCreditTransaction]
		},
		"personDocument": {
			"layout": "PersonDocument",
			"create": "Create New PersonDocument", //GeneratorFlag:[Views.PersonDocument.Create]
			'createImport': 'Imports PersonDocument From Excel', //GeneratorFlag:[Views.PersonDocument.CreateImport] 
 			'delete': 'Delete PersonDocument', //GeneratorFlag:[Views.PersonDocument.Delete] 
 			'detail': 'Detail of PersonDocument', //GeneratorFlag:[Views.PersonDocument.Detail] 
 			'list': 'List of PersonDocument', //GeneratorFlag:[Views.PersonDocument.List] 
 			'update': 'Edit PersonDocument', //GeneratorFlag:[Views.PersonDocument.Update] 
 //GeneratorFlag:[Views.PersonDocument]
		},
		"personRequestDipost": {
			"layout": "PersonRequestDipost",
			"create": "Create New PersonRequestDipost", //GeneratorFlag:[Views.PersonRequestDipost.Create]
			'createImport': 'Imports PersonRequestDipost From Excel', //GeneratorFlag:[Views.PersonRequestDipost.CreateImport] 
 			'delete': 'Delete PersonRequestDipost', //GeneratorFlag:[Views.PersonRequestDipost.Delete] 
 			'detail': 'Detail of PersonRequestDipost', //GeneratorFlag:[Views.PersonRequestDipost.Detail] 
 			'list': 'List of PersonRequestDipost', //GeneratorFlag:[Views.PersonRequestDipost.List] 
 			'update': 'Edit PersonRequestDipost', //GeneratorFlag:[Views.PersonRequestDipost.Update] 
 //GeneratorFlag:[Views.PersonRequestDipost]
		},
		"city": {
			"layout": "Create City",
			"create": "Create New City", //GeneratorFlag:[Views.City.Create]
			'createImport': 'Imports City From Excel', //GeneratorFlag:[Views.City.CreateImport] 
 			'delete': 'Delete City', //GeneratorFlag:[Views.City.Delete] 
 			'detail': 'Detail of City', //GeneratorFlag:[Views.City.Detail] 
 			'list': 'List of City', //GeneratorFlag:[Views.City.List] 
 			'update': 'Edit City', //GeneratorFlag:[Views.City.Update] 
 //GeneratorFlag:[Views.City]
		},
		"company": {
			"layout": "Create Company",
			"create": "Create New Company", //GeneratorFlag:[Views.Company.Create]
			'createImport': 'Imports Company From Excel', //GeneratorFlag:[Views.Company.CreateImport] 
 			'delete': 'Delete Company', //GeneratorFlag:[Views.Company.Delete] 
 			'detail': 'Detail of Company', //GeneratorFlag:[Views.Company.Detail] 
 			'list': 'List of Company', //GeneratorFlag:[Views.Company.List] 
 			'update': 'Edit Company', //GeneratorFlag:[Views.Company.Update] 
 //GeneratorFlag:[Views.Company]
		},
		"companyRank": {
			"layout": "Create CompanyRank",
			"create": "Create New CompanyRank", //GeneratorFlag:[Views.CompanyRank.Create]
			'createImport': 'Imports CompanyRank From Excel', //GeneratorFlag:[Views.CompanyRank.CreateImport] 
 			'delete': 'Delete CompanyRank', //GeneratorFlag:[Views.CompanyRank.Delete] 
 			'detail': 'Detail of CompanyRank', //GeneratorFlag:[Views.CompanyRank.Detail] 
 			'list': 'List of CompanyRank', //GeneratorFlag:[Views.CompanyRank.List] 
 			'update': 'Edit CompanyRank', //GeneratorFlag:[Views.CompanyRank.Update] 
 //GeneratorFlag:[Views.CompanyRank]
		},
		"country": {
			"layout": "Create Country",
			"create": "Create New Country", //GeneratorFlag:[Views.Country.Create]
			'createImport': 'Imports Country From Excel', //GeneratorFlag:[Views.Country.CreateImport] 
 			'delete': 'Delete Country', //GeneratorFlag:[Views.Country.Delete] 
 			'detail': 'Detail of Country', //GeneratorFlag:[Views.Country.Detail] 
 			'list': 'List of Country', //GeneratorFlag:[Views.Country.List] 
 			'update': 'Edit Country', //GeneratorFlag:[Views.Country.Update] 
 //GeneratorFlag:[Views.Country]
		},
		"militaryType": {
			"layout": "Create MilitaryType",
			"create": "Create New MilitaryType", //GeneratorFlag:[Views.MilitaryType.Create]
			'createImport': 'Imports MilitaryType From Excel', //GeneratorFlag:[Views.MilitaryType.CreateImport] 
 			'delete': 'Delete MilitaryType', //GeneratorFlag:[Views.MilitaryType.Delete] 
 			'detail': 'Detail of MilitaryType', //GeneratorFlag:[Views.MilitaryType.Detail] 
 			'list': 'List of MilitaryType', //GeneratorFlag:[Views.MilitaryType.List] 
 			'update': 'Edit MilitaryType', //GeneratorFlag:[Views.MilitaryType.Update] 
 //GeneratorFlag:[Views.MilitaryType]
		},
		"nationalityType": {
			"layout": "Create NationalityType",
			"create": "Create New NationalityType", //GeneratorFlag:[Views.NationalityType.Create]
			'createImport': 'Imports NationalityType From Excel', //GeneratorFlag:[Views.NationalityType.CreateImport] 
 			'delete': 'Delete NationalityType', //GeneratorFlag:[Views.NationalityType.Delete] 
 			'detail': 'Detail of NationalityType', //GeneratorFlag:[Views.NationalityType.Detail] 
 			'list': 'List of NationalityType', //GeneratorFlag:[Views.NationalityType.List] 
 			'update': 'Edit NationalityType', //GeneratorFlag:[Views.NationalityType.Update] 
 //GeneratorFlag:[Views.NationalityType]
		},
		"personBankAccount": {
			"layout": "Create PersonBankAccount",
			"create": "Create New PersonBankAccount", //GeneratorFlag:[Views.PersonBankAccount.Create]
			'createImport': 'Imports PersonBankAccount From Excel', //GeneratorFlag:[Views.PersonBankAccount.CreateImport] 
 			'delete': 'Delete PersonBankAccount', //GeneratorFlag:[Views.PersonBankAccount.Delete] 
 			'detail': 'Detail of PersonBankAccount', //GeneratorFlag:[Views.PersonBankAccount.Detail] 
 			'list': 'List of PersonBankAccount', //GeneratorFlag:[Views.PersonBankAccount.List] 
 			'update': 'Edit PersonBankAccount', //GeneratorFlag:[Views.PersonBankAccount.Update] 
 			'getWallet': 'Wallet Info', //GeneratorFlag:[Views.PersonBankAccount.GetWallet] 
 			'registerBank': 'Register Bank', //GeneratorFlag:[Views.PersonBankAccount.RegisterBank] 
 //GeneratorFlag:[Views.PersonBankAccount]
		},
		"province": {
			"layout": "Create Province",
			"create": "Create New Province", //GeneratorFlag:[Views.Province.Create]
			'createImport': 'Imports Province From Excel', //GeneratorFlag:[Views.Province.CreateImport] 
 			'delete': 'Delete Province', //GeneratorFlag:[Views.Province.Delete] 
 			'detail': 'Detail of Province', //GeneratorFlag:[Views.Province.Detail] 
 			'list': 'List of Province', //GeneratorFlag:[Views.Province.List] 
 			'update': 'Edit Province', //GeneratorFlag:[Views.Province.Update] 
 //GeneratorFlag:[Views.Province]
		},
		"rank": {
			"layout": "Create Rank",
			"create": "Create New Rank", //GeneratorFlag:[Views.Rank.Create]
			'createImport': 'Imports Rank From Excel', //GeneratorFlag:[Views.Rank.CreateImport] 
 			'delete': 'Delete Rank', //GeneratorFlag:[Views.Rank.Delete] 
 			'detail': 'Detail of Rank', //GeneratorFlag:[Views.Rank.Detail] 
 			'list': 'List of Rank', //GeneratorFlag:[Views.Rank.List] 
 			'update': 'Edit Rank', //GeneratorFlag:[Views.Rank.Update] 
 //GeneratorFlag:[Views.Rank]
		},
		"team": {
			"layout": "Create Team",
			"create": "Create New Team", //GeneratorFlag:[Views.Team.Create]
			'createImport': 'Imports Team From Excel', //GeneratorFlag:[Views.Team.CreateImport] 
 			'delete': 'Delete Team', //GeneratorFlag:[Views.Team.Delete] 
 			'detail': 'Detail of Team', //GeneratorFlag:[Views.Team.Detail] 
 			'list': 'List of Team', //GeneratorFlag:[Views.Team.List] 
 			'update': 'Edit Team', //GeneratorFlag:[Views.Team.Update] 
 //GeneratorFlag:[Views.Team]
		},
		"culture": {
			"layout": "Culture",
			"detail": "Detail of Culture", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'List of Culture', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"personAppPurchaseStatuse": {
			"layout": "PersonAppPurchaseStatuse",
			"detail": "Detail of PersonAppPurchaseStatuse", //GeneratorFlag:[Views.PersonAppPurchaseStatuse.Detail]
			'list': 'List of PersonAppPurchaseStatuse', //GeneratorFlag:[Views.PersonAppPurchaseStatuse.List] 
 //GeneratorFlag:[Views.PersonAppPurchaseStatuse]
		},
		"personAppPurchaseType": {
			"layout": "PersonAppPurchaseType",
			"detail": "Detail of PersonAppPurchaseType", //GeneratorFlag:[Views.PersonAppPurchaseType.Detail]
			'list': 'List of PersonAppPurchaseType', //GeneratorFlag:[Views.PersonAppPurchaseType.List] 
 //GeneratorFlag:[Views.PersonAppPurchaseType]
		},
		"personBankAccountType": {
			"layout": "PersonBankAccountType",
			"detail": "Detail of PersonBankAccountType", //GeneratorFlag:[Views.PersonBankAccountType.Detail]
			'list': 'List of PersonBankAccountType', //GeneratorFlag:[Views.PersonBankAccountType.List] 
 //GeneratorFlag:[Views.PersonBankAccountType]
		},
		"personCreditTransactionStatuse": {
			"layout": "PersonCreditTransactionStatuse",
			"detail": "Detail of PersonCreditTransactionStatuse", //GeneratorFlag:[Views.PersonCreditTransactionStatuse.Detail]
			'list': 'List of PersonCreditTransactionStatuse', //GeneratorFlag:[Views.PersonCreditTransactionStatuse.List] 
 //GeneratorFlag:[Views.PersonCreditTransactionStatuse]
		},
		"personCreditTransactionType": {
			"layout": "PersonCreditTransactionType",
			"detail": "Detail of PersonCreditTransactionType", //GeneratorFlag:[Views.PersonCreditTransactionType.Detail]
			'list': 'List of PersonCreditTransactionType', //GeneratorFlag:[Views.PersonCreditTransactionType.List] 
 //GeneratorFlag:[Views.PersonCreditTransactionType]
		},
		"personDocumentType": {
			"layout": "PersonDocumentType",
			"detail": "Detail of PersonDocumentType", //GeneratorFlag:[Views.PersonDocumentType.Detail]
			'list': 'List of PersonDocumentType', //GeneratorFlag:[Views.PersonDocumentType.List] 
 //GeneratorFlag:[Views.PersonDocumentType]
		},
		"personGender": {
			"layout": "PersonGender",
			"detail": "Detail of PersonGender", //GeneratorFlag:[Views.PersonGender.Detail]
			'list': 'List of PersonGender', //GeneratorFlag:[Views.PersonGender.List] 
 //GeneratorFlag:[Views.PersonGender]
		},
		"personRequestDipostStatuse": {
			"layout": "PersonRequestDipostStatuse",
			"detail": "Detail of PersonRequestDipostStatuse", //GeneratorFlag:[Views.PersonRequestDipostStatuse.Detail]
			'list': 'List of PersonRequestDipostStatuse', //GeneratorFlag:[Views.PersonRequestDipostStatuse.List] 
 //GeneratorFlag:[Views.PersonRequestDipostStatuse]
		},
		"personRequestDipostType": {
			"layout": "PersonRequestDipostType",
			"detail": "Detail of PersonRequestDipostType", //GeneratorFlag:[Views.PersonRequestDipostType.Detail]
			'list': 'List of PersonRequestDipostType', //GeneratorFlag:[Views.PersonRequestDipostType.List] 
 //GeneratorFlag:[Views.PersonRequestDipostType]
		},
		"personStatuse": {
			"layout": "PersonStatuse",
			"detail": "Detail of PersonStatuse", //GeneratorFlag:[Views.PersonStatuse.Detail]
			'list': 'List of PersonStatuse', //GeneratorFlag:[Views.PersonStatuse.List] 
 //GeneratorFlag:[Views.PersonStatuse]
		},
		"accessAgent": {
			"layout": "Access Agent",
			"save": "Access Agent", //GeneratorFlag:[Views.AccessAgent.Save]
//GeneratorFlag:[Views.AccessAgent]
		},
		"accessToken": {
			"layout": "access token",
			"myFunc": "access token", //GeneratorFlag:[Views.AccessToken.MyFunc]
//GeneratorFlag:[Views.AccessToken]
		},
		"personAccess": {
			"layout": "person access",
			"changePassword": "person access", //GeneratorFlag:[Views.PersonAccess.ChangePassword]
			'checkAccess': 'person access', //GeneratorFlag:[Views.PersonAccess.CheckAccess] 
 			'deactiveToken': 'person access', //GeneratorFlag:[Views.PersonAccess.DeactiveToken] 
 			'fortgetPassword': 'person access', //GeneratorFlag:[Views.PersonAccess.FortgetPassword] 
 			'generate': 'person access', //GeneratorFlag:[Views.PersonAccess.Generate] 
 //GeneratorFlag:[Views.PersonAccess]
		},
		"personValidation": {
			"layout": "person validation",
			"validate": "person validation", //GeneratorFlag:[Views.PersonValidation.Validate]
			'validateCode': 'person validation', //GeneratorFlag:[Views.PersonValidation.ValidateCode] 
 //GeneratorFlag:[Views.PersonValidation]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[News.Create] {
	"newsCreate": {
		"self": "news",
		"newsId": "news id",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[News.Delete] {
	"newsDelete": {
		"self": "news",
		"newsId": "news id",
	},
	//GeneratorFlag:[News.Detail] {
	"newsDetail": {
		"self": "news",
		"newsId": "news id",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
		"newsSubInfo": "news sub info",
	},
	//GeneratorFlag:[News.List] {
	"newsList": {
		"self": "news",
		"newsId": "news id",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
		"newsSubInfo": "news sub info",
	},
	//GeneratorFlag:[News.Filter] {
	"newsFilter": {
		"self": "news",
		"newsId": "news id",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
		"newsSubInfo": "news sub info",
	},
	//GeneratorFlag:[News.Update] {
	"newsUpdate": {
		"self": "news",
		"newsId": "news id",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[Person.Create] {
	"personCreate": {
		"self": "person",
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"parentId": "parent",
		"genoString": "geno string",
		"rankId": "rank",
		"companyRankId": "company rank",
		"companyId": "company",
		"mobile": "mobile",
		"email": "email",
		"personStatuseId": "person statuse",
		"personGenderId": "person gender",
		"teamId": "team",
		"personCode": "person code",
		"levelOfHead": "level of head",
		"creditValue": "credit value",
		"personRoleId": "person role id",
		"personAccess": {
			"self": "person access",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
	},
	//GeneratorFlag:[Person.Delete] {
	"personDelete": {
		"self": "person",
		"personId": "person id",
	},
	//GeneratorFlag:[Person.Detail] {
	"personDetail": {
		"self": "person",
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"parentId": "parent",
		"genoString": "geno string",
		"rankId": "rank",
		"companyRankId": "company rank",
		"companyId": "company",
		"mobile": "mobile",
		"email": "email",
		"personStatuseId": "person statuse",
		"personGenderId": "person gender",
		"teamId": "team",
		"personCode": "person code",
		"levelOfHead": "level of head",
		"creditValue": "credit value",
		"personRoleId": "person role id",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"companyRank": {
			"self": "company rank",
			"companyRankId": "شناسه",
			"companyRankKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"companyId": "شرکت",
			"fromRange": "از رنج",
			"toRange": "تا رنج",
			"maxLevelCommision": "سقف نسل",
			"dailyIncomeLimit": "سقف درامد روزانه",
		},
		"personGender": {
			"self": "person gender",
			"personGenderId": "شناسه",
			"personGenderKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personStatuse": {
			"self": "person statuse",
			"personStatuseId": "شناسه",
			"personStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"rank": {
			"self": "rank",
			"rankId": "شناسه",
			"orderRank": "ردیف",
			"title": "عنوان",
			"teamId": "تیم",
			"companyId": "شرکت",
			"status": "وضعیت فعال",
		},
		"team": {
			"self": "team",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"personAccess": {
			"self": "person access",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
		"personSubInfo": "person sub info",
	},
	//GeneratorFlag:[Person.List] {
	"personList": {
		"self": "person",
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"parentId": "parent",
		"genoString": "geno string",
		"rankId": "rank",
		"companyRankId": "company rank",
		"companyId": "company",
		"mobile": "mobile",
		"email": "email",
		"personStatuseId": "person statuse",
		"personGenderId": "person gender",
		"teamId": "team",
		"personCode": "person code",
		"levelOfHead": "level of head",
		"creditValue": "credit value",
		"personRoleId": "person role id",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"companyRank": {
			"self": "company rank",
			"companyRankId": "شناسه",
			"companyRankKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"companyId": "شرکت",
			"fromRange": "از رنج",
			"toRange": "تا رنج",
			"maxLevelCommision": "سقف نسل",
			"dailyIncomeLimit": "سقف درامد روزانه",
		},
		"personGender": {
			"self": "person gender",
			"personGenderId": "شناسه",
			"personGenderKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personStatuse": {
			"self": "person statuse",
			"personStatuseId": "شناسه",
			"personStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"rank": {
			"self": "rank",
			"rankId": "شناسه",
			"orderRank": "ردیف",
			"title": "عنوان",
			"teamId": "تیم",
			"companyId": "شرکت",
			"status": "وضعیت فعال",
		},
		"team": {
			"self": "team",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"personAccess": {
			"self": "person access",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
		"personSubInfo": "person sub info",
	},
	//GeneratorFlag:[Person.Filter] {
	"personFilter": {
		"self": "person",
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"parentId": "parent",
		"genoString": "geno string",
		"rankId": "rank",
		"companyRankId": "company rank",
		"companyId": "company",
		"mobile": "mobile",
		"email": "email",
		"personStatuseId": "person statuse",
		"personGenderId": "person gender",
		"teamId": "team",
		"personCode": "person code",
		"levelOfHead": "level of head",
		"creditValue": "credit value",
		"personRoleId": "person role id",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"companyRank": {
			"self": "company rank",
			"companyRankId": "شناسه",
			"companyRankKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"companyId": "شرکت",
			"fromRange": "از رنج",
			"toRange": "تا رنج",
			"maxLevelCommision": "سقف نسل",
			"dailyIncomeLimit": "سقف درامد روزانه",
		},
		"personGender": {
			"self": "person gender",
			"personGenderId": "شناسه",
			"personGenderKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personStatuse": {
			"self": "person statuse",
			"personStatuseId": "شناسه",
			"personStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"rank": {
			"self": "rank",
			"rankId": "شناسه",
			"orderRank": "ردیف",
			"title": "عنوان",
			"teamId": "تیم",
			"companyId": "شرکت",
			"status": "وضعیت فعال",
		},
		"team": {
			"self": "team",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"personAccess": {
			"self": "person access",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
		"personSubInfo": "person sub info",
	},
	//GeneratorFlag:[Person.Update] {
	"personUpdate": {
		"self": "person",
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"parentId": "parent",
		"genoString": "geno string",
		"rankId": "rank",
		"companyRankId": "company rank",
		"companyId": "company",
		"mobile": "mobile",
		"email": "email",
		"personStatuseId": "person statuse",
		"personGenderId": "person gender",
		"teamId": "team",
		"personCode": "person code",
		"levelOfHead": "level of head",
		"creditValue": "credit value",
		"personRoleId": "person role id",
		"personAccess": {
			"self": "person access",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
	},
	//GeneratorFlag:[PersonAppPurchase.Create] {
	"personAppPurchaseCreate": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[PersonAppPurchase.Delete] {
	"personAppPurchaseDelete": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
	},
	//GeneratorFlag:[PersonAppPurchase.Detail] {
	"personAppPurchaseDetail": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": {
			"self": "person app purchase statuse",
			"personAppPurchaseStatuseId": "شناسه",
			"personAppPurchaseStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseType": {
			"self": "person app purchase type",
			"personAppPurchaseTypeId": "شناسه",
			"personAppPurchaseTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseSubInfo": "person app purchase sub info",
	},
	//GeneratorFlag:[PersonAppPurchase.List] {
	"personAppPurchaseList": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": {
			"self": "person app purchase statuse",
			"personAppPurchaseStatuseId": "شناسه",
			"personAppPurchaseStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseType": {
			"self": "person app purchase type",
			"personAppPurchaseTypeId": "شناسه",
			"personAppPurchaseTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseSubInfo": "person app purchase sub info",
	},
	//GeneratorFlag:[PersonAppPurchase.Filter] {
	"personAppPurchaseFilter": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": {
			"self": "person app purchase statuse",
			"personAppPurchaseStatuseId": "شناسه",
			"personAppPurchaseStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseType": {
			"self": "person app purchase type",
			"personAppPurchaseTypeId": "شناسه",
			"personAppPurchaseTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseSubInfo": "person app purchase sub info",
	},
	//GeneratorFlag:[PersonAppPurchase.Update] {
	"personAppPurchaseUpdate": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[PersonCreditTransaction.Create] {
	"personCreditTransactionCreate": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
		"personId": "person",
		"personCreditTransactionTypeId": "person credit transaction type",
		"personCreditTransactionStatuseId": "person credit transaction statuse",
		"caption": "caption",
		"transactionRefCode": "transaction ref code",
		"displayTransactionDate": "display transaction date",
		"transactionDate": "transaction date",
		"creditValue": "credit value",
		"personCommiter": "person commiter",
		"commiterDescription": "commiter description",
	},
	//GeneratorFlag:[PersonCreditTransaction.Delete] {
	"personCreditTransactionDelete": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
	},
	//GeneratorFlag:[PersonCreditTransaction.Detail] {
	"personCreditTransactionDetail": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
		"personId": "person",
		"personCreditTransactionTypeId": "person credit transaction type",
		"personCreditTransactionStatuseId": "person credit transaction statuse",
		"caption": "caption",
		"transactionRefCode": "transaction ref code",
		"displayTransactionDate": "display transaction date",
		"transactionDate": "transaction date",
		"creditValue": "credit value",
		"personCommiter": "person commiter",
		"commiterDescription": "commiter description",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personCreditTransactionStatuse": {
			"self": "person credit transaction statuse",
			"personCreditTransactionStatuseId": "شناسه",
			"personCreditTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionType": {
			"self": "person credit transaction type",
			"personCreditTransactionTypeId": "شناسه",
			"personCreditTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionSubInfo": "person credit transaction sub info",
	},
	//GeneratorFlag:[PersonCreditTransaction.List] {
	"personCreditTransactionList": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
		"personId": "person",
		"personCreditTransactionTypeId": "person credit transaction type",
		"personCreditTransactionStatuseId": "person credit transaction statuse",
		"caption": "caption",
		"transactionRefCode": "transaction ref code",
		"displayTransactionDate": "display transaction date",
		"transactionDate": "transaction date",
		"creditValue": "credit value",
		"personCommiter": "person commiter",
		"commiterDescription": "commiter description",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personCreditTransactionStatuse": {
			"self": "person credit transaction statuse",
			"personCreditTransactionStatuseId": "شناسه",
			"personCreditTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionType": {
			"self": "person credit transaction type",
			"personCreditTransactionTypeId": "شناسه",
			"personCreditTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionSubInfo": "person credit transaction sub info",
	},
	//GeneratorFlag:[PersonCreditTransaction.Filter] {
	"personCreditTransactionFilter": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
		"personId": "person",
		"personCreditTransactionTypeId": "person credit transaction type",
		"personCreditTransactionStatuseId": "person credit transaction statuse",
		"caption": "caption",
		"transactionRefCode": "transaction ref code",
		"displayTransactionDate": "display transaction date",
		"transactionDate": "transaction date",
		"creditValue": "credit value",
		"personCommiter": "person commiter",
		"commiterDescription": "commiter description",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personCreditTransactionStatuse": {
			"self": "person credit transaction statuse",
			"personCreditTransactionStatuseId": "شناسه",
			"personCreditTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionType": {
			"self": "person credit transaction type",
			"personCreditTransactionTypeId": "شناسه",
			"personCreditTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionSubInfo": "person credit transaction sub info",
	},
	//GeneratorFlag:[PersonCreditTransaction.Update] {
	"personCreditTransactionUpdate": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
		"personId": "person",
		"personCreditTransactionTypeId": "person credit transaction type",
		"personCreditTransactionStatuseId": "person credit transaction statuse",
		"caption": "caption",
		"transactionRefCode": "transaction ref code",
		"displayTransactionDate": "display transaction date",
		"transactionDate": "transaction date",
		"creditValue": "credit value",
		"personCommiter": "person commiter",
		"commiterDescription": "commiter description",
	},
	//GeneratorFlag:[PersonDocument.Create] {
	"personDocumentCreate": {
		"self": "personDocument",
		"personDocumentId": "person document id",
		"personId": "person",
		"personDocumentTypeId": "person document type",
		"base64FileString": "base64 file string",
		"addressFile": "address file",
		"fullAddressFile": "full address file",
	},
	//GeneratorFlag:[PersonDocument.Delete] {
	"personDocumentDelete": {
		"self": "personDocument",
		"personDocumentId": "person document id",
	},
	//GeneratorFlag:[PersonDocument.Detail] {
	"personDocumentDetail": {
		"self": "personDocument",
		"personDocumentId": "person document id",
		"personId": "person",
		"personDocumentTypeId": "person document type",
		"base64FileString": "base64 file string",
		"addressFile": "address file",
		"fullAddressFile": "full address file",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personDocumentType": {
			"self": "person document type",
			"personDocumentTypeId": "شناسه",
			"personDocumentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personDocumentSubInfo": "person document sub info",
	},
	//GeneratorFlag:[PersonDocument.List] {
	"personDocumentList": {
		"self": "personDocument",
		"personDocumentId": "person document id",
		"personId": "person",
		"personDocumentTypeId": "person document type",
		"base64FileString": "base64 file string",
		"addressFile": "address file",
		"fullAddressFile": "full address file",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personDocumentType": {
			"self": "person document type",
			"personDocumentTypeId": "شناسه",
			"personDocumentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personDocumentSubInfo": "person document sub info",
	},
	//GeneratorFlag:[PersonDocument.Filter] {
	"personDocumentFilter": {
		"self": "personDocument",
		"personDocumentId": "person document id",
		"personId": "person",
		"personDocumentTypeId": "person document type",
		"base64FileString": "base64 file string",
		"addressFile": "address file",
		"fullAddressFile": "full address file",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personDocumentType": {
			"self": "person document type",
			"personDocumentTypeId": "شناسه",
			"personDocumentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personDocumentSubInfo": "person document sub info",
	},
	//GeneratorFlag:[PersonDocument.Update] {
	"personDocumentUpdate": {
		"self": "personDocument",
		"personDocumentId": "person document id",
		"personId": "person",
		"personDocumentTypeId": "person document type",
		"base64FileString": "base64 file string",
		"addressFile": "address file",
		"fullAddressFile": "full address file",
	},
	//GeneratorFlag:[PersonRequestDipost.Create] {
	"personRequestDipostCreate": {
		"self": "personRequestDipost",
		"personRequestDipostId": "person request dipost id",
		"personRequestDipostTypeId": "person request dipost type",
		"personId": "person",
		"amount": "amount",
		"transactionHashCode": "transaction hash code",
		"fromSourceWallet": "from source wallet",
		"toSourceWallet": "to source wallet",
		"personRequestDipostStatuseId": "person request dipost statuse",
		"financialTransactionWalletId": "financial transaction wallet id",
	},
	//GeneratorFlag:[PersonRequestDipost.Delete] {
	"personRequestDipostDelete": {
		"self": "personRequestDipost",
		"personRequestDipostId": "person request dipost id",
	},
	//GeneratorFlag:[PersonRequestDipost.Detail] {
	"personRequestDipostDetail": {
		"self": "personRequestDipost",
		"personRequestDipostId": "person request dipost id",
		"personRequestDipostTypeId": "person request dipost type",
		"personId": "person",
		"amount": "amount",
		"transactionHashCode": "transaction hash code",
		"fromSourceWallet": "from source wallet",
		"toSourceWallet": "to source wallet",
		"personRequestDipostStatuseId": "person request dipost statuse",
		"financialTransactionWalletId": "financial transaction wallet id",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personRequestDipostStatuse": {
			"self": "person request dipost statuse",
			"personRequestDipostStatuseId": "شناسه",
			"personRequestDipostStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostType": {
			"self": "person request dipost type",
			"personRequestDipostTypeId": "شناسه",
			"personRequestDipostTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostSubInfo": "person request dipost sub info",
	},
	//GeneratorFlag:[PersonRequestDipost.List] {
	"personRequestDipostList": {
		"self": "personRequestDipost",
		"personRequestDipostId": "person request dipost id",
		"personRequestDipostTypeId": "person request dipost type",
		"personId": "person",
		"amount": "amount",
		"transactionHashCode": "transaction hash code",
		"fromSourceWallet": "from source wallet",
		"toSourceWallet": "to source wallet",
		"personRequestDipostStatuseId": "person request dipost statuse",
		"financialTransactionWalletId": "financial transaction wallet id",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personRequestDipostStatuse": {
			"self": "person request dipost statuse",
			"personRequestDipostStatuseId": "شناسه",
			"personRequestDipostStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostType": {
			"self": "person request dipost type",
			"personRequestDipostTypeId": "شناسه",
			"personRequestDipostTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostSubInfo": "person request dipost sub info",
	},
	//GeneratorFlag:[PersonRequestDipost.Filter] {
	"personRequestDipostFilter": {
		"self": "personRequestDipost",
		"personRequestDipostId": "person request dipost id",
		"personRequestDipostTypeId": "person request dipost type",
		"personId": "person",
		"amount": "amount",
		"transactionHashCode": "transaction hash code",
		"fromSourceWallet": "from source wallet",
		"toSourceWallet": "to source wallet",
		"personRequestDipostStatuseId": "person request dipost statuse",
		"financialTransactionWalletId": "financial transaction wallet id",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personRequestDipostStatuse": {
			"self": "person request dipost statuse",
			"personRequestDipostStatuseId": "شناسه",
			"personRequestDipostStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostType": {
			"self": "person request dipost type",
			"personRequestDipostTypeId": "شناسه",
			"personRequestDipostTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostSubInfo": "person request dipost sub info",
	},
	//GeneratorFlag:[PersonRequestDipost.Update] {
	"personRequestDipostUpdate": {
		"self": "personRequestDipost",
		"personRequestDipostId": "person request dipost id",
		"personRequestDipostTypeId": "person request dipost type",
		"personId": "person",
		"amount": "amount",
		"transactionHashCode": "transaction hash code",
		"fromSourceWallet": "from source wallet",
		"toSourceWallet": "to source wallet",
		"personRequestDipostStatuseId": "person request dipost statuse",
		"financialTransactionWalletId": "financial transaction wallet id",
	},
	//GeneratorFlag:[City.Create] {
	"cityCreate": {
		"self": "city",
		"cityId": "city id",
		"title": "title",
		"provinceId": "province",
	},
	//GeneratorFlag:[City.Delete] {
	"cityDelete": {
		"self": "city",
		"cityId": "city id",
	},
	//GeneratorFlag:[City.Detail] {
	"cityDetail": {
		"self": "city",
		"cityId": "city id",
		"title": "title",
		"provinceId": "province",
		"province": {
			"self": "province",
			"provinceId": "شناسه",
			"countryId": "کشور",
			"title": "عنوان",
		},
		"citySubInfo": "city sub info",
	},
	//GeneratorFlag:[City.List] {
	"cityList": {
		"self": "city",
		"cityId": "city id",
		"title": "title",
		"provinceId": "province",
		"province": {
			"self": "province",
			"provinceId": "شناسه",
			"countryId": "کشور",
			"title": "عنوان",
		},
		"citySubInfo": "city sub info",
	},
	//GeneratorFlag:[City.Filter] {
	"cityFilter": {
		"self": "city",
		"cityId": "city id",
		"title": "title",
		"provinceId": "province",
		"province": {
			"self": "province",
			"provinceId": "شناسه",
			"countryId": "کشور",
			"title": "عنوان",
		},
		"citySubInfo": "city sub info",
	},
	//GeneratorFlag:[City.Update] {
	"cityUpdate": {
		"self": "city",
		"cityId": "city id",
		"title": "title",
		"provinceId": "province",
	},
	//GeneratorFlag:[Company.Create] {
	"companyCreate": {
		"self": "company",
		"companyId": "company id",
		"title": "title",
	},
	//GeneratorFlag:[Company.Delete] {
	"companyDelete": {
		"self": "company",
		"companyId": "company id",
	},
	//GeneratorFlag:[Company.Detail] {
	"companyDetail": {
		"self": "company",
		"companyId": "company id",
		"title": "title",
		"companySubInfo": "company sub info",
	},
	//GeneratorFlag:[Company.List] {
	"companyList": {
		"self": "company",
		"companyId": "company id",
		"title": "title",
		"companySubInfo": "company sub info",
	},
	//GeneratorFlag:[Company.Filter] {
	"companyFilter": {
		"self": "company",
		"companyId": "company id",
		"title": "title",
		"companySubInfo": "company sub info",
	},
	//GeneratorFlag:[Company.Update] {
	"companyUpdate": {
		"self": "company",
		"companyId": "company id",
		"title": "title",
	},
	//GeneratorFlag:[CompanyRank.Create] {
	"companyRankCreate": {
		"self": "companyRank",
		"companyRankId": "company rank id",
		"companyRankKey": "company rank key",
		"cultureId": "culture",
		"title": "title",
		"companyId": "company id",
		"fromRange": "from range",
		"toRange": "to range",
		"maxLevelCommision": "max level commision",
		"dailyIncomeLimit": "daily income limit",
	},
	//GeneratorFlag:[CompanyRank.Delete] {
	"companyRankDelete": {
		"self": "companyRank",
		"companyRankId": "company rank id",
	},
	//GeneratorFlag:[CompanyRank.Detail] {
	"companyRankDetail": {
		"self": "companyRank",
		"companyRankId": "company rank id",
		"companyRankKey": "company rank key",
		"cultureId": "culture",
		"title": "title",
		"companyId": "company id",
		"fromRange": "from range",
		"toRange": "to range",
		"maxLevelCommision": "max level commision",
		"dailyIncomeLimit": "daily income limit",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"companyRankSubInfo": "company rank sub info",
	},
	//GeneratorFlag:[CompanyRank.List] {
	"companyRankList": {
		"self": "companyRank",
		"companyRankId": "company rank id",
		"companyRankKey": "company rank key",
		"cultureId": "culture",
		"title": "title",
		"companyId": "company id",
		"fromRange": "from range",
		"toRange": "to range",
		"maxLevelCommision": "max level commision",
		"dailyIncomeLimit": "daily income limit",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"companyRankSubInfo": "company rank sub info",
	},
	//GeneratorFlag:[CompanyRank.Filter] {
	"companyRankFilter": {
		"self": "companyRank",
		"companyRankId": "company rank id",
		"companyRankKey": "company rank key",
		"cultureId": "culture",
		"title": "title",
		"companyId": "company id",
		"fromRange": "from range",
		"toRange": "to range",
		"maxLevelCommision": "max level commision",
		"dailyIncomeLimit": "daily income limit",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"companyRankSubInfo": "company rank sub info",
	},
	//GeneratorFlag:[CompanyRank.Update] {
	"companyRankUpdate": {
		"self": "companyRank",
		"companyRankId": "company rank id",
		"companyRankKey": "company rank key",
		"cultureId": "culture",
		"title": "title",
		"companyId": "company id",
		"fromRange": "from range",
		"toRange": "to range",
		"maxLevelCommision": "max level commision",
		"dailyIncomeLimit": "daily income limit",
	},
	//GeneratorFlag:[Country.Create] {
	"countryCreate": {
		"self": "country",
		"countryId": "country id",
		"title": "title",
		"countryCode": "country code",
		"flagUrl": "flag url",
	},
	//GeneratorFlag:[Country.Delete] {
	"countryDelete": {
		"self": "country",
		"countryId": "country id",
	},
	//GeneratorFlag:[Country.Detail] {
	"countryDetail": {
		"self": "country",
		"countryId": "country id",
		"title": "title",
		"countryCode": "country code",
		"flagUrl": "flag url",
		"countrySubInfo": "country sub info",
	},
	//GeneratorFlag:[Country.List] {
	"countryList": {
		"self": "country",
		"countryId": "country id",
		"title": "title",
		"countryCode": "country code",
		"flagUrl": "flag url",
		"countrySubInfo": "country sub info",
	},
	//GeneratorFlag:[Country.Filter] {
	"countryFilter": {
		"self": "country",
		"countryId": "country id",
		"title": "title",
		"countryCode": "country code",
		"flagUrl": "flag url",
		"countrySubInfo": "country sub info",
	},
	//GeneratorFlag:[Country.Update] {
	"countryUpdate": {
		"self": "country",
		"countryId": "country id",
		"title": "title",
		"countryCode": "country code",
		"flagUrl": "flag url",
	},
	//GeneratorFlag:[MilitaryType.Create] {
	"militaryTypeCreate": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
		"militaryTypeKey": "military type key",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[MilitaryType.Delete] {
	"militaryTypeDelete": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
	},
	//GeneratorFlag:[MilitaryType.Detail] {
	"militaryTypeDetail": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
		"militaryTypeKey": "military type key",
		"title": "title",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",
	},
	//GeneratorFlag:[MilitaryType.List] {
	"militaryTypeList": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
		"militaryTypeKey": "military type key",
		"title": "title",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",
	},
	//GeneratorFlag:[MilitaryType.Filter] {
	"militaryTypeFilter": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
		"militaryTypeKey": "military type key",
		"title": "title",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",
	},
	//GeneratorFlag:[MilitaryType.Update] {
	"militaryTypeUpdate": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
		"militaryTypeKey": "military type key",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NationalityType.Create] {
	"nationalityTypeCreate": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NationalityType.Delete] {
	"nationalityTypeDelete": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
	},
	//GeneratorFlag:[NationalityType.Detail] {
	"nationalityTypeDetail": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",
	},
	//GeneratorFlag:[NationalityType.List] {
	"nationalityTypeList": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",
	},
	//GeneratorFlag:[NationalityType.Filter] {
	"nationalityTypeFilter": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",
	},
	//GeneratorFlag:[NationalityType.Update] {
	"nationalityTypeUpdate": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonBankAccount.Create] {
	"personBankAccountCreate": {
		"self": "personBankAccount",
		"personBankAccountId": "person bank account id",
		"personBankAccountTypeId": "person bank account type",
		"personId": "person",
		"accountName": "account name",
		"accountId": "account id",
		"shabaCode": "shaba code",
		"cardNumber": "card number",
		"walletAddress": "wallet address",
	},
	//GeneratorFlag:[PersonBankAccount.Delete] {
	"personBankAccountDelete": {
		"self": "personBankAccount",
		"personBankAccountId": "person bank account id",
	},
	//GeneratorFlag:[PersonBankAccount.Detail] {
	"personBankAccountDetail": {
		"self": "personBankAccount",
		"personBankAccountId": "person bank account id",
		"personBankAccountTypeId": "person bank account type",
		"personId": "person",
		"accountName": "account name",
		"accountId": "account id",
		"shabaCode": "shaba code",
		"cardNumber": "card number",
		"walletAddress": "wallet address",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personBankAccountType": {
			"self": "person bank account type",
			"personBankAccountTypeId": "شناسه",
			"personBankAccountTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personBankAccountSubInfo": "person bank account sub info",
	},
	//GeneratorFlag:[PersonBankAccount.List] {
	"personBankAccountList": {
		"self": "personBankAccount",
		"personBankAccountId": "person bank account id",
		"personBankAccountTypeId": "person bank account type",
		"personId": "person",
		"accountName": "account name",
		"accountId": "account id",
		"shabaCode": "shaba code",
		"cardNumber": "card number",
		"walletAddress": "wallet address",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personBankAccountType": {
			"self": "person bank account type",
			"personBankAccountTypeId": "شناسه",
			"personBankAccountTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personBankAccountSubInfo": "person bank account sub info",
	},
	//GeneratorFlag:[PersonBankAccount.Filter] {
	"personBankAccountFilter": {
		"self": "personBankAccount",
		"personBankAccountId": "person bank account id",
		"personBankAccountTypeId": "person bank account type",
		"personId": "person",
		"accountName": "account name",
		"accountId": "account id",
		"shabaCode": "shaba code",
		"cardNumber": "card number",
		"walletAddress": "wallet address",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personBankAccountType": {
			"self": "person bank account type",
			"personBankAccountTypeId": "شناسه",
			"personBankAccountTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personBankAccountSubInfo": "person bank account sub info",
	},
	//GeneratorFlag:[PersonBankAccount.Update] {
	"personBankAccountUpdate": {
		"self": "personBankAccount",
		"personBankAccountId": "person bank account id",
		"personBankAccountTypeId": "person bank account type",
		"personId": "person",
		"accountName": "account name",
		"accountId": "account id",
		"shabaCode": "shaba code",
		"cardNumber": "card number",
		"walletAddress": "wallet address",
	},
	//GeneratorFlag:[Province.Create] {
	"provinceCreate": {
		"self": "province",
		"provinceId": "province id",
		"countryId": "country",
		"title": "title",
	},
	//GeneratorFlag:[Province.Delete] {
	"provinceDelete": {
		"self": "province",
		"provinceId": "province id",
	},
	//GeneratorFlag:[Province.Detail] {
	"provinceDetail": {
		"self": "province",
		"provinceId": "province id",
		"countryId": "country",
		"title": "title",
		"country": {
			"self": "country",
			"countryId": "شناسه",
			"title": "عنوان",
			"countryCode": "کد کشور",
			"flagUrl": "پرچم",
		},
		"provinceSubInfo": "province sub info",
	},
	//GeneratorFlag:[Province.List] {
	"provinceList": {
		"self": "province",
		"provinceId": "province id",
		"countryId": "country",
		"title": "title",
		"country": {
			"self": "country",
			"countryId": "شناسه",
			"title": "عنوان",
			"countryCode": "کد کشور",
			"flagUrl": "پرچم",
		},
		"provinceSubInfo": "province sub info",
	},
	//GeneratorFlag:[Province.Filter] {
	"provinceFilter": {
		"self": "province",
		"provinceId": "province id",
		"countryId": "country",
		"title": "title",
		"country": {
			"self": "country",
			"countryId": "شناسه",
			"title": "عنوان",
			"countryCode": "کد کشور",
			"flagUrl": "پرچم",
		},
		"provinceSubInfo": "province sub info",
	},
	//GeneratorFlag:[Province.Update] {
	"provinceUpdate": {
		"self": "province",
		"provinceId": "province id",
		"countryId": "country",
		"title": "title",
	},
	//GeneratorFlag:[Rank.Create] {
	"rankCreate": {
		"self": "rank",
		"rankId": "rank id",
		"orderRank": "order rank",
		"title": "title",
		"teamId": "team",
		"companyId": "company",
		"status": "status",
	},
	//GeneratorFlag:[Rank.Delete] {
	"rankDelete": {
		"self": "rank",
		"rankId": "rank id",
	},
	//GeneratorFlag:[Rank.Detail] {
	"rankDetail": {
		"self": "rank",
		"rankId": "rank id",
		"orderRank": "order rank",
		"title": "title",
		"teamId": "team",
		"companyId": "company",
		"status": "status",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"team": {
			"self": "team",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"rankSubInfo": "rank sub info",
	},
	//GeneratorFlag:[Rank.List] {
	"rankList": {
		"self": "rank",
		"rankId": "rank id",
		"orderRank": "order rank",
		"title": "title",
		"teamId": "team",
		"companyId": "company",
		"status": "status",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"team": {
			"self": "team",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"rankSubInfo": "rank sub info",
	},
	//GeneratorFlag:[Rank.Filter] {
	"rankFilter": {
		"self": "rank",
		"rankId": "rank id",
		"orderRank": "order rank",
		"title": "title",
		"teamId": "team",
		"companyId": "company",
		"status": "status",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"team": {
			"self": "team",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"rankSubInfo": "rank sub info",
	},
	//GeneratorFlag:[Rank.Update] {
	"rankUpdate": {
		"self": "rank",
		"rankId": "rank id",
		"orderRank": "order rank",
		"title": "title",
		"teamId": "team",
		"companyId": "company",
		"status": "status",
	},
	//GeneratorFlag:[Team.Create] {
	"teamCreate": {
		"self": "team",
		"teamId": "team id",
		"title": "title",
		"teamGenoString": "team geno string",
		"companyId": "company",
	},
	//GeneratorFlag:[Team.Delete] {
	"teamDelete": {
		"self": "team",
		"teamId": "team id",
	},
	//GeneratorFlag:[Team.Detail] {
	"teamDetail": {
		"self": "team",
		"teamId": "team id",
		"title": "title",
		"teamGenoString": "team geno string",
		"companyId": "company",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"teamSubInfo": "team sub info",
	},
	//GeneratorFlag:[Team.List] {
	"teamList": {
		"self": "team",
		"teamId": "team id",
		"title": "title",
		"teamGenoString": "team geno string",
		"companyId": "company",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"teamSubInfo": "team sub info",
	},
	//GeneratorFlag:[Team.Filter] {
	"teamFilter": {
		"self": "team",
		"teamId": "team id",
		"title": "title",
		"teamGenoString": "team geno string",
		"companyId": "company",
		"company": {
			"self": "company",
			"companyId": "شناسه",
			"title": "نام",
		},
		"teamSubInfo": "team sub info",
	},
	//GeneratorFlag:[Team.Update] {
	"teamUpdate": {
		"self": "team",
		"teamId": "team id",
		"title": "title",
		"teamGenoString": "team geno string",
		"companyId": "company",
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "culture",
		"cultureId": "culture id",
		"cultureKey": "culture key",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "culture",
		"cultureId": "culture id",
		"cultureKey": "culture key",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "culture",
		"cultureId": "culture id",
		"cultureKey": "culture key",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[PersonAppPurchaseStatuse.Detail] {
	"personAppPurchaseStatuseDetail": {
		"self": "personAppPurchaseStatuse",
		"personAppPurchaseStatuseId": "person app purchase statuse id",
		"personAppPurchaseStatuseKey": "person app purchase statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseStatuse.List] {
	"personAppPurchaseStatuseList": {
		"self": "personAppPurchaseStatuse",
		"personAppPurchaseStatuseId": "person app purchase statuse id",
		"personAppPurchaseStatuseKey": "person app purchase statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseStatuse.Filter] {
	"personAppPurchaseStatuseFilter": {
		"self": "personAppPurchaseStatuse",
		"personAppPurchaseStatuseId": "person app purchase statuse id",
		"personAppPurchaseStatuseKey": "person app purchase statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseType.Detail] {
	"personAppPurchaseTypeDetail": {
		"self": "personAppPurchaseType",
		"personAppPurchaseTypeId": "person app purchase type id",
		"personAppPurchaseTypeKey": "person app purchase type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseType.List] {
	"personAppPurchaseTypeList": {
		"self": "personAppPurchaseType",
		"personAppPurchaseTypeId": "person app purchase type id",
		"personAppPurchaseTypeKey": "person app purchase type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseType.Filter] {
	"personAppPurchaseTypeFilter": {
		"self": "personAppPurchaseType",
		"personAppPurchaseTypeId": "person app purchase type id",
		"personAppPurchaseTypeKey": "person app purchase type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonBankAccountType.Detail] {
	"personBankAccountTypeDetail": {
		"self": "personBankAccountType",
		"personBankAccountTypeId": "person bank account type id",
		"personBankAccountTypeKey": "person bank account type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonBankAccountType.List] {
	"personBankAccountTypeList": {
		"self": "personBankAccountType",
		"personBankAccountTypeId": "person bank account type id",
		"personBankAccountTypeKey": "person bank account type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonBankAccountType.Filter] {
	"personBankAccountTypeFilter": {
		"self": "personBankAccountType",
		"personBankAccountTypeId": "person bank account type id",
		"personBankAccountTypeKey": "person bank account type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonCreditTransactionStatuse.Detail] {
	"personCreditTransactionStatuseDetail": {
		"self": "personCreditTransactionStatuse",
		"personCreditTransactionStatuseId": "person credit transaction statuse id",
		"personCreditTransactionStatuseKey": "person credit transaction statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionStatuse.List] {
	"personCreditTransactionStatuseList": {
		"self": "personCreditTransactionStatuse",
		"personCreditTransactionStatuseId": "person credit transaction statuse id",
		"personCreditTransactionStatuseKey": "person credit transaction statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionStatuse.Filter] {
	"personCreditTransactionStatuseFilter": {
		"self": "personCreditTransactionStatuse",
		"personCreditTransactionStatuseId": "person credit transaction statuse id",
		"personCreditTransactionStatuseKey": "person credit transaction statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionType.Detail] {
	"personCreditTransactionTypeDetail": {
		"self": "personCreditTransactionType",
		"personCreditTransactionTypeId": "person credit transaction type id",
		"personCreditTransactionTypeKey": "person credit transaction type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionType.List] {
	"personCreditTransactionTypeList": {
		"self": "personCreditTransactionType",
		"personCreditTransactionTypeId": "person credit transaction type id",
		"personCreditTransactionTypeKey": "person credit transaction type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionType.Filter] {
	"personCreditTransactionTypeFilter": {
		"self": "personCreditTransactionType",
		"personCreditTransactionTypeId": "person credit transaction type id",
		"personCreditTransactionTypeKey": "person credit transaction type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonDocumentType.Detail] {
	"personDocumentTypeDetail": {
		"self": "personDocumentType",
		"personDocumentTypeId": "person document type id",
		"personDocumentTypeKey": "person document type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonDocumentType.List] {
	"personDocumentTypeList": {
		"self": "personDocumentType",
		"personDocumentTypeId": "person document type id",
		"personDocumentTypeKey": "person document type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonDocumentType.Filter] {
	"personDocumentTypeFilter": {
		"self": "personDocumentType",
		"personDocumentTypeId": "person document type id",
		"personDocumentTypeKey": "person document type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonGender.Detail] {
	"personGenderDetail": {
		"self": "personGender",
		"personGenderId": "person gender id",
		"personGenderKey": "person gender key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonGender.List] {
	"personGenderList": {
		"self": "personGender",
		"personGenderId": "person gender id",
		"personGenderKey": "person gender key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonGender.Filter] {
	"personGenderFilter": {
		"self": "personGender",
		"personGenderId": "person gender id",
		"personGenderKey": "person gender key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostStatuse.Detail] {
	"personRequestDipostStatuseDetail": {
		"self": "personRequestDipostStatuse",
		"personRequestDipostStatuseId": "person request dipost statuse id",
		"personRequestDipostStatuseKey": "person request dipost statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostStatuse.List] {
	"personRequestDipostStatuseList": {
		"self": "personRequestDipostStatuse",
		"personRequestDipostStatuseId": "person request dipost statuse id",
		"personRequestDipostStatuseKey": "person request dipost statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostStatuse.Filter] {
	"personRequestDipostStatuseFilter": {
		"self": "personRequestDipostStatuse",
		"personRequestDipostStatuseId": "person request dipost statuse id",
		"personRequestDipostStatuseKey": "person request dipost statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostType.Detail] {
	"personRequestDipostTypeDetail": {
		"self": "personRequestDipostType",
		"personRequestDipostTypeId": "person request dipost type id",
		"personRequestDipostTypeKey": "person request dipost type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonRequestDipostType.List] {
	"personRequestDipostTypeList": {
		"self": "personRequestDipostType",
		"personRequestDipostTypeId": "person request dipost type id",
		"personRequestDipostTypeKey": "person request dipost type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonRequestDipostType.Filter] {
	"personRequestDipostTypeFilter": {
		"self": "personRequestDipostType",
		"personRequestDipostTypeId": "person request dipost type id",
		"personRequestDipostTypeKey": "person request dipost type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonStatuse.Detail] {
	"personStatuseDetail": {
		"self": "personStatuse",
		"personStatuseId": "person statuse id",
		"personStatuseKey": "person statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonStatuse.List] {
	"personStatuseList": {
		"self": "personStatuse",
		"personStatuseId": "person statuse id",
		"personStatuseKey": "person statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonStatuse.Filter] {
	"personStatuseFilter": {
		"self": "personStatuse",
		"personStatuseId": "person statuse id",
		"personStatuseKey": "person statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[AccessAgent.Save] {
	"accessAgentSave": {
		"self": "accessAgent",
		"personId": "Person",
		"personRoleId": "Person Role",
		"accessKeys": {
			"self": "Access Keys",
			"keyName": "",
			"groupName": "",
		},
		"isSuccess": "Result",
	},
	//GeneratorFlag:[AccessToken.MyFunc] {
	"accessTokenMyFunc": {
		"self": "accessToken",
		"personId": "",
		"currentPassword": "",
		"newPassword": "",
		"confirmedPassword": "",
		"result": "",
	},
	//GeneratorFlag:[Person.ChangeEmail] {
	"personChangeEmail": {
		"self": "person",
		"newEmail": "Email",
		"personValidationId": "Varification ID",
		"varificationCode": "Varification Code",
	},
	//GeneratorFlag:[Person.ChangeSecurityCode] {
	"personChangeSecurityCode": {
		"self": "person",
		"isConfirmed": "Is Confirmed",
	},
	//GeneratorFlag:[Person.CheckUpline] {
	"personCheckUpline": {
		"self": "person",
		"isValid": "Is Valid",
		"parentId": "Parent ID",
		"uplineInfo": "Name & Family",
		"personCode": "Person Code",
	},
	//GeneratorFlag:[Person.GetCredit] {
	"personGetCredit": {
		"self": "person",
		"creditValue": "Credit Value",
		"currency": "Currency",
	},
	//GeneratorFlag:[Person.GetPersonByData] {
	"personGetPersonByData": {
		"self": "person",
		"firstName": "First Name",
		"lastName": "Last Name",
		"parentId": "Parent",
		"parentName": "Parent",
		"levelGenerationOfMe": "Level",
		"mobile": "Mobile",
		"personId": "ID",
		"personCode": "Person Code",
	},
	//GeneratorFlag:[Person.GetProfile] {
	"personGetProfile": {
		"self": "person",
		"imagePath": "Address",
		"email": "Email",
		"userName": "User Name",
		"parentCode": "Parent Code",
		"createDate": "Register Date",
	},
	//GeneratorFlag:[Person.GetUplineInfo] {
	"personGetUplineInfo": {
		"self": "person",
		"isValid": "Is Valid",
		"parentId": "Parent ID",
		"uplineInfo": "Name & Family",
		"personCode": "Person Code",
	},
	//GeneratorFlag:[Person.RegisterByEmail] {
	"personRegisterByEmail": {
		"self": "person",
		"userName": "",
		"email": "",
		"heirName": "Heir Name",
		"fromSourceWallet": "Source Wallet Address",
		"toSourceWallet": "Source Wallet Address",
		"investmentAmount": "InvestmentAmount",
		"parentId": "",
		"parentIdInvoice": "",
		"password": "",
		"confirmedPassword": "",
		"transactionHash": "Transaction Hash",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
	},
	//GeneratorFlag:[Person.Register] {
	"personRegister": {
		"self": "person",
		"firstName": "First Name",
		"lastName": "Last Name",
		"parentId": "Person Code",
		"nationalNo": "",
		"passportNo": "",
		"companyId": "",
		"countryId": "",
		"mobile": "",
		"email": "",
		"personGenderId": "",
		"cityId": "",
		"password": "",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
	},
	//GeneratorFlag:[Person.RegisterShop] {
	"personRegisterShop": {
		"self": "person",
		"firstName": "",
		"lastName": "",
		"heirName": "Heir Name",
		"parentId": "",
		"nationalNo": "",
		"passportNo": "",
		"companyId": "",
		"countryId": "",
		"mobile": "",
		"email": "",
		"personGenderId": "",
		"cityId": "",
		"password": "",
		"confirmedPassword": "",
		"passportImageFile": "",
		"parentIdCode": "",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
	},
	//GeneratorFlag:[Person.SearchTeam] {
	"personSearchTeam": {
		"self": "person",
		"firstName": "First Name",
		"lastName": "Last Name",
		"parentId": "Parent ID",
		"parentName": "Upline Info",
		"levelGenerationOfMe": "Level",
		"mobile": "Mobile",
		"personId": "Person ID",
		"personCode": "Person Code",
		"teamRankName": "Team Rank",
		"companyRankName": "Company Rank",
	},
	//GeneratorFlag:[Person.TreeList] {
	"personTreeList": {
		"self": "person",
		"personId": "Person ID",
		"fromDateDisply": "From Date",
		"toDateDisply": "To Date",
		"includeReportSell": "Include Report Sell",
		"items": {
			"self": "Member List",
			"personId": "شناسه",
			"personCode": "کد",
			"userName": "نام کاربری",
			"firstName": "نام",
			"parentId": "والد",
			"lastName": "نام خانوادگی",
			"avatarUrl": "آواتار",
			"companyRankName": "رنک شرکت",
			"teamRankName": "رنک تیمی",
			"G": "G",
			"companyRanId": "رنک شرکت",
			"teamRankId": "رنک تیمی",
			"memberCount": "تعداد مجموعه",
			"level": "سطح",
			"totalSell": "فروش کل",
			"personalSell": "فروش شخصی",
			"savedValue": "مقدار سیو",
			"totalScore": "امتیاز کل",
			"totalInvoice": "تعداد فاکتور",
			"fullName": "مشخصات",
		},
	},
	//GeneratorFlag:[PersonAccess.ChangePassword] {
	"personAccessChangePassword": {
		"self": "personAccess",
		"personId": "",
		"currentPassword": "",
		"newPassword": "",
		"confirmedPassword": "",
		"result": "",
	},
	//GeneratorFlag:[PersonAccess.CheckAccess] {
	"personAccessCheckAccess": {
		"self": "personAccess",
		"key": "",
		"result": "",
	},
	//GeneratorFlag:[PersonAccess.DeactiveToken] {
	"personAccessDeactiveToken": {
		"self": "personAccess",
		"isDone": "",
	},
	//GeneratorFlag:[PersonAccess.FortgetPassword] {
	"personAccessFortgetPassword": {
		"self": "personAccess",
		"email": "",
		"securityCode": "",
		"result": "",
	},
	//GeneratorFlag:[PersonAccess.Generate] {
	"personAccessGenerate": {
		"self": "personAccess",
		"userName": "User Name",
		"password": "Password",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
		"profileUrl": "",
		"rankName": "",
		"parentName": "",
		"rankLogo": "",
	},
	//GeneratorFlag:[PersonAppPurchase.CheckCredit] {
	"personAppPurchaseCheckCredit": {
		"self": "personAppPurchase",
		"isValid": "",
		"expireDate": "",
		"displayExpireDate": "",
	},
	//GeneratorFlag:[PersonBankAccount.GetWallet] {
	"personBankAccountGetWallet": {
		"self": "personBankAccount",
		"walletAddress": "Wallet Address",
		"accountName": "Account Name",
		"personBankAccountId": "ID",
	},
	//GeneratorFlag:[PersonBankAccount.RegisterBank] {
	"personBankAccountRegisterBank": {
		"self": "personBankAccount",
		"walletAddress": "Wallet Address",
		"accountName": "Account Name",
		"secuirtyCode": "Security Code",
		"personBankAccountId": "ID",
	},
	//GeneratorFlag:[PersonCreditTransaction.Charge] {
	"personCreditTransactionCharge": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "Id",
		"creaditValue": "Creadit",
		"transactionHash": "Transaction Hash",
		"caption": "Caption",
	},
	//GeneratorFlag:[PersonCreditTransaction.MyList] {
	"personCreditTransactionMyList": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "ID",
		"personCreditTransactionTypeName": "Type",
		"personCreditTransactionStatuseName": "Status",
		"caption": "caption",
		"transactionRefCode": "transaction ref code",
		"transactionDate": "transaction date",
		"creditValue": "credit value",
		"personCommiter": "commiter",
		"commiterDescription": "commiter description",
	},
	//GeneratorFlag:[PersonValidation.Validate] {
	"personValidationValidate": {
		"self": "personValidation",
		"mobile": "",
		"email": "",
		"countryCode": "",
		"personValidationId": "",
	},
	//GeneratorFlag:[PersonValidation.ValidateCode] {
	"personValidationValidateCode": {
		"self": "personValidation",
		"personValidationId": "",
		"userValidationCode": "",
		"validationResult": "",
	},
//GeneratorFlag:[NewEntity]
}