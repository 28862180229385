//GeneratorFlag:[NoChange]
import { BasePersonAppPurchase } from '../base/data'
export default class PersonAppPurchase extends BasePersonAppPurchase
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personAppPurchaseId: {
				...this.props.personAppPurchaseId,
				setConfig:{
					...this.props.personAppPurchaseId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			createDate: {
				...this.props.createDate,
				setConfig:{
					...this.props.createDate.setConfig
				}
			},
			personAppPurchaseStatuseId: {
				...this.props.personAppPurchaseStatuseId,
				setConfig:{
					...this.props.personAppPurchaseStatuseId.setConfig
				}
			},
			factorNumber: {
				...this.props.factorNumber,
				setConfig:{
					...this.props.factorNumber.setConfig
				}
			},
			factorValue: {
				...this.props.factorValue,
				setConfig:{
					...this.props.factorValue.setConfig
				}
			},
			personAppPurchaseTypeId: {
				...this.props.personAppPurchaseTypeId,
				setConfig:{
					...this.props.personAppPurchaseTypeId.setConfig
				}
			},
			expireDate: {
				...this.props.expireDate,
				setConfig:{
					...this.props.expireDate.setConfig
				}
			},
			displayExpireDate: {
				...this.props.displayExpireDate,
				setConfig:{
					...this.props.displayExpireDate.setConfig
				}
			},
			personAppPurchaseStatuse: {
				...this.props.personAppPurchaseStatuse,
				setConfig:{
					...this.props.personAppPurchaseStatuse.setConfig
				}
			},
			personAppPurchaseType: {
				...this.props.personAppPurchaseType,
				setConfig:{
					...this.props.personAppPurchaseType.setConfig
				}
			},
			personAppPurchaseSubInfo: {
				...this.props.personAppPurchaseSubInfo,
				setConfig:{
					...this.props.personAppPurchaseSubInfo.setConfig
				}
			},

		};
	}
}
