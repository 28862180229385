<template>
	<span v-if="hasValue" class="truncate">
		{{ data.value }}
	</span>
</template>
<script>
	export default {
		props:
		{
			data: Object
		},

		computed:
		{
			hasValue()
			{
				return this.data.value != null && this.data.value != undefined
			}
		}
	}
</script>
