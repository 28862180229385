//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Invoice, ProductStore } from '../../data'; 

import { InvoiceService, ProductStoreService } from '../../services'; 

export default class BaseInvoiceProduct extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'invoiceProduct';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.invoiceProduct.self';
	props = {
		invoiceProductId: {
			primarykey: true,
			name: 'invoiceProductId',
			resourceKey: 'shop.invoiceProduct.invoiceProductId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceId: {
			foreignKey: true,
			name: 'invoiceId',
			resourceKey: 'shop.invoiceProduct.invoiceId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Invoice(),
			data: async (params) =>
			{
				const service =
					new InvoiceService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'displayCheckoutDate',
				valueKey: 'invoiceId',
				patterns: ['required'],
			}
		},
		count: {
			name: 'count',
			resourceKey: 'shop.invoiceProduct.count',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		basePrice: {
			name: 'basePrice',
			resourceKey: 'shop.invoiceProduct.basePrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		totalPrice: {
			name: 'totalPrice',
			resourceKey: 'shop.invoiceProduct.totalPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		productStoreId: {
			foreignKey: true,
			name: 'productStoreId',
			resourceKey: 'shop.invoiceProduct.productStoreId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new ProductStore(),
			data: async (params) =>
			{
				const service =
					new ProductStoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: '',
				valueKey: 'productStoreId',
				patterns: ['required'],
			}
		},
		invoice: {
			name: 'invoice',
			resourceKey: 'shop.invoiceProduct.invoice',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Invoice(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{displayCheckoutDate}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		productStore: {
			name: 'productStore',
			resourceKey: 'shop.invoiceProduct.productStore',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductStore(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceProductSubInfo: {
			name: 'invoiceProductSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
