//GeneratorFlag:[NoChange]
import NewsService from './news-service'; //GeneratorFlag:[News]
import PersonService from './person-service'; //GeneratorFlag:[Person]
import PersonAppPurchaseService from './person-app-purchase-service'; //GeneratorFlag:[PersonAppPurchase]
import PersonCreditTransactionService from './person-credit-transaction-service'; //GeneratorFlag:[PersonCreditTransaction]
import PersonDocumentService from './person-document-service'; //GeneratorFlag:[PersonDocument]
import PersonRequestDipostService from './person-request-dipost-service'; //GeneratorFlag:[PersonRequestDipost]
import CityService from './city-service'; //GeneratorFlag:[City]
import CompanyService from './company-service'; //GeneratorFlag:[Company]
import CompanyRankService from './company-rank-service'; //GeneratorFlag:[CompanyRank]
import CountryService from './country-service'; //GeneratorFlag:[Country]
import MilitaryTypeService from './military-type-service'; //GeneratorFlag:[MilitaryType]
import NationalityTypeService from './nationality-type-service'; //GeneratorFlag:[NationalityType]
import PersonBankAccountService from './person-bank-account-service'; //GeneratorFlag:[PersonBankAccount]
import ProvinceService from './province-service'; //GeneratorFlag:[Province]
import RankService from './rank-service'; //GeneratorFlag:[Rank]
import TeamService from './team-service'; //GeneratorFlag:[Team]
import CultureService from './culture-service'; //GeneratorFlag:[Culture]
import PersonAppPurchaseStatuseService from './person-app-purchase-statuse-service'; //GeneratorFlag:[PersonAppPurchaseStatuse]
import PersonAppPurchaseTypeService from './person-app-purchase-type-service'; //GeneratorFlag:[PersonAppPurchaseType]
import PersonBankAccountTypeService from './person-bank-account-type-service'; //GeneratorFlag:[PersonBankAccountType]
import PersonCreditTransactionStatuseService from './person-credit-transaction-statuse-service'; //GeneratorFlag:[PersonCreditTransactionStatuse]
import PersonCreditTransactionTypeService from './person-credit-transaction-type-service'; //GeneratorFlag:[PersonCreditTransactionType]
import PersonDocumentTypeService from './person-document-type-service'; //GeneratorFlag:[PersonDocumentType]
import PersonGenderService from './person-gender-service'; //GeneratorFlag:[PersonGender]
import PersonRequestDipostStatuseService from './person-request-dipost-statuse-service'; //GeneratorFlag:[PersonRequestDipostStatuse]
import PersonStatuseService from './person-statuse-service'; //GeneratorFlag:[PersonStatuse]
import AccessAgentService from './access-agent-service'; //GeneratorFlag:[AccessAgent]
import AccessTokenService from './access-token-service'; //GeneratorFlag:[AccessToken]
import PersonAccessService from './person-access-service'; //GeneratorFlag:[PersonAccess]
import PersonValidationService from './person-validation-service'; //GeneratorFlag:[PersonValidation]
import PersonRequestDipostTypeService from './person-request-dipost-type-service'; //GeneratorFlag:[PersonRequestDipostType]
//GeneratorFlag:[NewImport]
export default {
NewsService, //GeneratorFlag:[News]
PersonService, //GeneratorFlag:[Person]
PersonAppPurchaseService, //GeneratorFlag:[PersonAppPurchase]
PersonCreditTransactionService, //GeneratorFlag:[PersonCreditTransaction]
PersonDocumentService, //GeneratorFlag:[PersonDocument]
PersonRequestDipostService, //GeneratorFlag:[PersonRequestDipost]
CityService, //GeneratorFlag:[City]
CompanyService, //GeneratorFlag:[Company]
CompanyRankService, //GeneratorFlag:[CompanyRank]
CountryService, //GeneratorFlag:[Country]
MilitaryTypeService, //GeneratorFlag:[MilitaryType]
NationalityTypeService, //GeneratorFlag:[NationalityType]
PersonBankAccountService, //GeneratorFlag:[PersonBankAccount]
ProvinceService, //GeneratorFlag:[Province]
RankService, //GeneratorFlag:[Rank]
TeamService, //GeneratorFlag:[Team]
CultureService, //GeneratorFlag:[Culture]
PersonAppPurchaseStatuseService, //GeneratorFlag:[PersonAppPurchaseStatuse]
PersonAppPurchaseTypeService, //GeneratorFlag:[PersonAppPurchaseType]
PersonBankAccountTypeService, //GeneratorFlag:[PersonBankAccountType]
PersonCreditTransactionStatuseService, //GeneratorFlag:[PersonCreditTransactionStatuse]
PersonCreditTransactionTypeService, //GeneratorFlag:[PersonCreditTransactionType]
PersonDocumentTypeService, //GeneratorFlag:[PersonDocumentType]
PersonGenderService, //GeneratorFlag:[PersonGender]
PersonRequestDipostStatuseService, //GeneratorFlag:[PersonRequestDipostStatuse]
PersonStatuseService, //GeneratorFlag:[PersonStatuse]
AccessAgentService, //GeneratorFlag:[AccessAgent]
AccessTokenService, //GeneratorFlag:[AccessToken]
PersonAccessService, //GeneratorFlag:[PersonAccess]
PersonValidationService, //GeneratorFlag:[PersonValidation]
PersonRequestDipostTypeService, //GeneratorFlag:[PersonRequestDipostType]
//GeneratorFlag:[NewExport]
}
export{
NewsService, //GeneratorFlag:[News]
PersonService, //GeneratorFlag:[Person]
PersonAppPurchaseService, //GeneratorFlag:[PersonAppPurchase]
PersonCreditTransactionService, //GeneratorFlag:[PersonCreditTransaction]
PersonDocumentService, //GeneratorFlag:[PersonDocument]
PersonRequestDipostService, //GeneratorFlag:[PersonRequestDipost]
CityService, //GeneratorFlag:[City]
CompanyService, //GeneratorFlag:[Company]
CompanyRankService, //GeneratorFlag:[CompanyRank]
CountryService, //GeneratorFlag:[Country]
MilitaryTypeService, //GeneratorFlag:[MilitaryType]
NationalityTypeService, //GeneratorFlag:[NationalityType]
PersonBankAccountService, //GeneratorFlag:[PersonBankAccount]
ProvinceService, //GeneratorFlag:[Province]
RankService, //GeneratorFlag:[Rank]
TeamService, //GeneratorFlag:[Team]
CultureService, //GeneratorFlag:[Culture]
PersonAppPurchaseStatuseService, //GeneratorFlag:[PersonAppPurchaseStatuse]
PersonAppPurchaseTypeService, //GeneratorFlag:[PersonAppPurchaseType]
PersonBankAccountTypeService, //GeneratorFlag:[PersonBankAccountType]
PersonCreditTransactionStatuseService, //GeneratorFlag:[PersonCreditTransactionStatuse]
PersonCreditTransactionTypeService, //GeneratorFlag:[PersonCreditTransactionType]
PersonDocumentTypeService, //GeneratorFlag:[PersonDocumentType]
PersonGenderService, //GeneratorFlag:[PersonGender]
PersonRequestDipostStatuseService, //GeneratorFlag:[PersonRequestDipostStatuse]
PersonStatuseService, //GeneratorFlag:[PersonStatuse]
AccessAgentService, //GeneratorFlag:[AccessAgent]
AccessTokenService, //GeneratorFlag:[AccessToken]
PersonAccessService, //GeneratorFlag:[PersonAccess]
PersonValidationService, //GeneratorFlag:[PersonValidation]
PersonRequestDipostTypeService, //GeneratorFlag:[PersonRequestDipostType]
//GeneratorFlag:[NewExport]
}