//GeneratorFlag:[NoChange]
import { BasePersonCreditTransactionStatuse } from '../base/data'
export default class PersonCreditTransactionStatuse extends BasePersonCreditTransactionStatuse
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personCreditTransactionStatuseId: {
				...this.props.personCreditTransactionStatuseId,
				setConfig:{
					...this.props.personCreditTransactionStatuseId.setConfig
				}
			},
			personCreditTransactionStatuseKey: {
				...this.props.personCreditTransactionStatuseKey,
				setConfig:{
					...this.props.personCreditTransactionStatuseKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
