//GeneratorFlag:[NoChange]
import { BaseMenuPersonGetListByUser } from '@/modules/people-net/base/data/actions/menu-person/get-list-by-user'
export default class MenuPersonGetListByUser extends BaseMenuPersonGetListByUser
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			menuItems: {
				...this.props.menuItems,
				setConfig:{
					...this.props.menuItems.setConfig
				}
			},
		};
	}
}

