//GeneratorFlag:[NoChange]
import { BaseCompany } from '../base/data'
export default class Company extends BaseCompany
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			companySubInfo: {
				...this.props.companySubInfo,
				setConfig:{
					...this.props.companySubInfo.setConfig
				}
			},

		};
	}
}
