<template>
	<i class="bi" v-bind:class="name"
	   :style="{
       'font-size': size ,
       'color': color,
       'cursor':cursor
       }"></i>
</template>
<script>

	export default {
		props: {
			name: {
				type: String,
				default: ''
			},

			size: {
				type: String,
				default: '25px'
			},

			color: {
				type: String,
				default: 'inherit'
			},

			cursor: {
				type: String,
				default: 'inherit'
			}
		}
	}
</script>
