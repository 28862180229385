

// initial state
const state = () => ({
	token: undefined,
	accessKeys: [],
	name: '',
	role: '',
	id: 0,
})

// getters
const getters = {
}

// actions
const actions = {

	setToken({ commit }, value)
	{
		commit('setToken', value);
		commit('setClientInfo', value);
	},
	setDashboard({ commit }, value) {
		commit('setDashboard', value);
		commit('setDashboard', value);
	},
	setAccessKeys({ commit }, value) {
		commit('setAccessKeys', value);
	},

	setTokenFromStorage({ commit })
	{
		var accessToken =
			localStorage.getItem("Frc.hub.accessToken");

		if (accessToken)
		{
			commit('setToken', accessToken);
			commit('setClientInfo', accessToken);
		}
	},

	setAccessKeysFromStorage({ commit }) {
		var accessKeys =
			localStorage.getItem("Frc.hub.accessKeys");

		if (accessKeys) {
			commit('setAccessKeys', JSON.parse(accessKeys));
		}
	},

	resetState({ commit })
	{
		commit('resetState');
	}
}

// mutations
const mutations =
{
	setToken(state, value)
	{
		state.token = value
		localStorage.setItem("Frc.hub.accessToken", value);
	},

	setAccessKeys(state, value) {
		state.accessKeys = value
		localStorage.setItem("Frc.hub.accessKeys", JSON.stringify(value));
	},

	setClientInfo(state, token)
	{
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c)
		{
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		const payload = JSON.parse(jsonPayload);
		const keys = Object.keys(payload);
        for (var i = 0; i < keys.length; i++) {
			const _key = keys[i];
			state[_key] = payload[_key];
        }
		//state.id = payload.id;
		//state.name = payload.name;
		//state.role = payload.role;
	},

	resetState(state)
	{
		state.token = undefined;
		state.name = '';
		state.role = '';
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
