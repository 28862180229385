//GeneratorFlag:[NoChange]
export default {
	path: 'person-address',
	name: 'shop.personAddress.layout',
	redirect: to => {
		return {name: 'shop.personAddress.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/person-address"],
		resourceKey: 'shop.views.personAddress.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonAddressDelete]"
		{
			path: 'delete',
			name: 'shop.personAddress.delete',
			component: () => import('@/modules/shop/views/person-address/person-address-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personAddress.delete',
				clientAccessKeys:["/shop/person-address/delete"],
				serverAccessKeys:["/shop/person-address/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAddressDetail]"
		{
			path: 'detail',
			name: 'shop.personAddress.detail',
			component: () => import('@/modules/shop/views/person-address/person-address-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personAddress.detail',
				clientAccessKeys:["/shop/person-address/detail"],
				serverAccessKeys:["/shop/person-address/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAddressList]"
		{
			path: 'list',
			name: 'shop.personAddress.list',
			component: () => import('@/modules/shop/views/person-address/person-address-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personAddress.list',
				clientAccessKeys:["/shop/person-address/list"],
				serverAccessKeys:["/shop/person-address/list","/shop/person/list","/shop/city/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAddressCreate]"
		{
			path: 'create',
			name: 'shop.personAddress.create',
			component: () => import('@/modules/shop/views/person-address/person-address-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personAddress.create',
				clientAccessKeys:["/shop/person-address/create"],
				serverAccessKeys:["/shop/person-address/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAddressImport]"
		{
			path: 'import',
			name: 'shop.personAddress.import',
			component: () => import('@/modules/shop/views/person-address/person-address-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personAddress.import',
				clientAccessKeys:["/shop/person-address/import"],
				serverAccessKeys:["/shop/person-address/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAddressUpdate]"
		{
			path: 'update',
			name: 'shop.personAddress.update',
			component: () => import('@/modules/shop/views/person-address/person-address-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personAddress.update',
				clientAccessKeys:["/shop/person-address/update"],
				serverAccessKeys:["/shop/person-address/save","/shop/person/list","/shop/city/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






