//GeneratorFlag:[NoChange]
import { BasePlanTransactionGetListCommision } from '@/modules/shop/base/data/actions/plan-transaction/get-list-commision'
export default class PlanTransactionGetListCommision extends BasePlanTransactionGetListCommision
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			fromDisplayDate: {
				...this.props.fromDisplayDate,
				setConfig:{
					...this.props.fromDisplayDate.setConfig
				}
			},
			toDisplayDate: {
				...this.props.toDisplayDate,
				setConfig:{
					...this.props.toDisplayDate.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			totalCommision: {
				...this.props.totalCommision,
				setConfig:{
					...this.props.totalCommision.setConfig
				}
			},
			finalCommision: {
				...this.props.finalCommision,
				setConfig:{
					...this.props.finalCommision.setConfig
				}
			},
			savedPerson: {
				...this.props.savedPerson,
				setConfig:{
					...this.props.savedPerson.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			secondPersonLine: {
				...this.props.secondPersonLine,
				setConfig:{
					...this.props.secondPersonLine.setConfig
				}
			},
			firstLineTotalSell: {
				...this.props.firstLineTotalSell,
				setConfig:{
					...this.props.firstLineTotalSell.setConfig
				}
			},
			secondLineTotalSell : {
				...this.props.secondLineTotalSell ,
				setConfig:{
					...this.props.secondLineTotalSell .setConfig
				}
			},
			displayDate: {
				...this.props.displayDate,
				setConfig:{
					...this.props.displayDate.setConfig
				}
			},
		};
	}
}

