<template>
	<div class="Frc-excel-parser">
		<excel-parser-loading v-if="state == 'loading'" />
		<excel-parser-epmty v-if="state == 'empty'" v-on:changeState="changeState" />
		<excel-parser-select v-if="state == 'select'" :entity="entity" v-on:select="selectFile" />

		<excel-parser-error v-if="state == 'error'"
							:entity="entity"
							:data="items"
							:errorIndexes="errorIndexes"
							:reviewLength="reviewLength"
							v-on:changeState="changeState"
							v-on:review="review" />
	</div>
</template>
<script>

	import XLSX from 'xlsx'
	import excelParserEmpty from './excel-parser-epmty'
	import excelParserError from './excel-parser-error'
	import excelParserLoading from './excel-parser-loading'
	import excelParserSelect from './excel-parser-select'

	export default {
		emits: ['complete'],
		props: {
			'entity': {
				type: Function
			}
		},

		data()
		{
			return {
				items: [],
				errorIndexes:[],
				reviewLength: 0,
				state: 'select',
			}
		},

		components: {
			'excel-parser-epmty': excelParserEmpty,
			'excel-parser-error': excelParserError,
			'excel-parser-select': excelParserSelect,
			'excel-parser-loading': excelParserLoading
		},

		methods: {

			selectFile(file)
			{
				this.state = 'loading';
				this.readFileAsBinary(file).then((binaryFile) =>
				{
					this.binaryFileToSheet(binaryFile).then((data) =>
					{
						if (data.isEmpty)
						{
							this.state = 'empty'; return;
						}


						this.decodeSheetData(data.sheets).then((data) =>
						{
							if (data.isEmpty)
							{
								this.state = 'empty'; return;
							}

							this.parseSheetData(data.sheets).then((parsed) =>
							{
								this.convertDataToEntities(parsed).then((result) =>
								{
									if (result.errorLength == 0)
									{
										this.state = 'complete';
										this.$emit('complete', result.items); return;
									}

									this.state = 'error';
									this.items = result.items;
									this.errorIndexes = result.errorIndexes;
								});
							});
						});
					});
				});
			},

			changeState(state)
			{
				this.state = state;
			},

			review(data)
			{
				this.state = 'loading';
				this.convertDataToEntities(data).then((result) =>
				{
					if (result.errorLength == 0)
					{
						this.state = 'complete';
						this.$emit('complete', data); return;
					}

					this.state = 'error';
					this.reviewLength = this.reviewLength + 1
					this.items = data;
				});
			},


			readFileAsBinary(file)
			{
				return new Promise((resolve, reject) =>
				{
					var reader = new FileReader();
					reader.onload = () =>
					{
						resolve(reader.result)
					};
					reader.onerror = reject;
					reader.readAsBinaryString(file);
				});
			},

			binaryFileToSheet(bfile)
			{
				return new Promise((resolve, reject) =>
				{

					var sheets = [];
					var wb = XLSX.read(bfile, { type: 'binary' });

					for (const item in wb.Sheets)
					{
						sheets.push(XLSX.utils.sheet_to_row_object_array(wb.Sheets[item], {
							skipHeader: true
						}))
					}

					resolve({
						sheets: sheets,
						isEmpty: sheets[0].length == 0
					})
				});
			},

			decodeSheetData(sheets)
			{
				return new Promise((resolve, reject) =>
				{
					var result = []
					var getColumnName = (name, index) =>
					{
						if (name && name.includes("."))
						{
							return name.split('.')[index]
						}
					};

					var getSheetName = (row) =>
					{
						const keys = Object.keys(row)
						if (keys.length > 0)
						{
							return getColumnName(keys[0], 0)
						}
					};


					for (var i = 0; i < sheets.length; i++)
					{
						var rows = sheets[i];
						if (rows.length > 0)
						{
							var sheet = {
								name: getSheetName(rows[0]),
								rows: [],
								index: i
							};

							for (var row = 0; row < rows.length; row++)
							{
								if (row > 0)
								{
									var data = {};
									for (var key of Object.keys(rows[row]))
									{
										var columnName =
											getColumnName(key, 1)

										if (columnName)
										{
											data[columnName] = rows[row][key]
										}
									}

									sheet.rows.push(data)
								}
							}
							result.push(sheet)
						}
					}

					resolve({
						sheets: result,
						isEmpty: result[0].rows.length == 0
					})
				});
			},

			parseSheetData(sheets)
			{
				return new Promise((resolve, reject) =>
				{
					var primarySheet = sheets[0];

					for (var primaryRow of primarySheet.rows)
					{
						if (primaryRow.uniquekey)
						{
							for (var sheet of sheets)
							{
								if (sheet.index == 0) continue;
								for (var row of sheet.rows)
								{
									if (row.uniquekey == primaryRow.uniquekey)
									{
										primaryRow[sheet.name] = row
									}
								}
							}
						}
					}

					resolve(primarySheet.rows)
				});
			},


			convertDataToEntities(rows)
			{
				return new Promise((resolve, reject) =>
				{
					var result = {
						items: [],
						clearItems:[],
						errorLength: 0,
						errorIndexes:[],
					};

					for (var i = 0; i < rows.length; i++)
					{
						var row = rows[i];
						var entity = new this.entity({
							value: row
						});

						entity.parseExcelError();
						var errorLength = entity.getExcelErrorLength()

						result.items.push(entity.value);
						result.errorLength += errorLength;

						if (errorLength > 0)
						{
							result.errorIndexes.push(i)
						}
					}


					resolve(result)
				});
			}
		}
	}
</script>
