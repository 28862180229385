//GeneratorFlag:[NoChange]
import { BasePlanPrecent } from '../base/data'
export default class PlanPrecent extends BasePlanPrecent
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			planPrecentId: {
				...this.props.planPrecentId,
				setConfig:{
					...this.props.planPrecentId.setConfig
				}
			},
			lineOrder: {
				...this.props.lineOrder,
				setConfig:{
					...this.props.lineOrder.setConfig
				}
			},
			linePrecent: {
				...this.props.linePrecent,
				setConfig:{
					...this.props.linePrecent.setConfig
				}
			},

		};
	}
}
