import ENUMS from '../enums'
import validator from '@/core/validator'
import Localization from '@/core/localization'

const loc = new Localization()

export default class BaseEntity
{
	constructor() { }

	prepair(arg)
	{
		if ((arg || {}).value)
		{
			this.setValue(this, arg.value)
		}
	}

	setValue(entity, value)
	{
		entity = entity || this;
		entity.value = {};
		entity.displayValue = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];

			if (value[key] != null && value[key] != undefined)
			{
				if (prop.type == "object" && prop.entity)
				{
					this.setValue(prop.entity, value[key])

					entity.value[prop.name] = prop.entity.value;
					entity.props[prop.name].value = prop.entity.value;
				}
				else
				{
					var convertedValue = value[key];
					if (prop.type == ENUMS.PROP_TYPE.STRING)
					{
						convertedValue = String(value[key])
					}

					entity.value[prop.name] = convertedValue;
					entity.props[prop.name].value = convertedValue;
				}

				let displayValue =
					this.getDisplayValue(prop);

				if (displayValue && prop.display.isVisible)
				{
					entity.displayValue[prop.name] = displayValue;
					entity.props[prop.name].displayValue = displayValue;
				}
			}
		}
	}
	getValue(entity)
	{
		var value = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			var prop = entity.props[key];

			var _value =
				(prop.type == "object" && prop.entity) ?
					this.getValue(prop.entity) : prop.value;

			if (prop.type == "object" && Object.keys(_value).length == 0)
				continue;

			if (validator.hasValue(_value))
			{
				value[key] = _value
			}
		}

		return value
	}
	getFormValue(entity)
	{
		var value = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			var prop = entity.props[key];

			var _value =
				(prop.type == "object" && prop.entity) ?
					this.getFormValue(prop.entity) : prop.value;

			if (prop.type == "object" && Object.keys(_value).length == 0)
				continue;

			if (validator.hasValue(_value) && prop.setConfig.canUseInForm == true)
			{
				value[key] = _value
			}
		}

		return value
	}
	getExcelValue(entity)
	{
		var value = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			var prop = entity.props[key];

			var _value =
				(prop.type == "object" && prop.entity) ?
					this.getExcelValue(prop.entity) : prop.value;

			if (prop.type == "object" && Object.keys(_value).length == 0)
				continue;

			if (validator.hasValue(_value) && prop.setConfig.canUseInExcel == true)
			{
				value[key] = _value
			}
		}

		return value
	}
	parseValue(entity)
	{
		entity = entity || this;
		entity.value = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];

			if (prop.type == "object" && prop.entity)
			{
				prop.value = this.parseValue(prop.entity);
			}

			if (prop.value)
			{
				if (prop.type == "object" && Object.keys(prop.value).length == 0)
					continue;

				entity.props[prop.name].value = prop.value;
				entity.value[prop.name] = prop.value
			}
		}

		return entity.value
	}

	parseAllError(entity)
	{
		entity = entity || this;
		entity.error = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInForm == true || prop.setConfig.canUseInExcel == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					var value = this.getFormValue(prop.entity);
					if (Object.keys(value).length > 0)
					{
						this.parseFormError(prop.entity);
					}
				}
				else
				{
					let errors = validator.getPropErrors(prop)
					if (errors && errors.length > 0)
					{
						entity.props[prop.name].errors = errors;
						entity.error[prop.name] = errors
					}
				}
			}
		}
	}

	parseFormError(entity)
	{
		entity = entity || this;
		entity.error = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInForm == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					var value = this.getFormValue(prop.entity);
					if (Object.keys(value).length > 0)
					{
						this.parseFormError(prop.entity);
					}
				}
				else
				{
					let errors = validator.getPropErrors(prop)
					if (errors && errors.length > 0)
					{
						entity.props[prop.name].errors = errors;
						entity.error[prop.name] = errors
					}
				}
			}
		}
	}

	getFormError(entity)
	{
		var error = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInForm == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					let _error =
						this.getFormError(prop.entity);

					if (Object.keys(_error).length > 0)
					{
						error[key] = _error
					}
				}
				else
				{
					let _errors = prop.errors

					if (_errors && _errors.length > 0)
					{
						error[key] = _errors
					}
				}
			}
		}

		return error
	}

	getFormErrorLength(entity)
	{
		return Object.keys(this.getFormError(entity)).length
	}

	parseExcelError(entity)
	{
		entity = entity || this;
		entity.error = {};

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInExcel == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					var value = this.getFormValue(prop.entity);
					if (Object.keys(value).length > 0)
					{
						this.parseExcelError(prop.entity);
					}
				}
				else
				{
					let errors = validator.getPropErrors(prop)
					if (errors && errors.length > 0)
					{
						entity.props[prop.name].errors = errors;
						entity.error[prop.name] = errors
					}
				}

			}
		}
	}

	getExcelError(entity)
	{
		var error = {};
		entity = entity || this;

		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.setConfig.canUseInExcel == true)
			{
				if (prop.type == "object" && prop.entity)
				{
					let _error =
						this.getExcelError(prop.entity);

					if (Object.keys(_error).length > 0)
					{
						error[key] = _error
					}
				}
				else
				{
					let _errors = prop.errors

					if (_errors && _errors.length > 0)
					{
						error[key] = _errors
					}
				}
			}
		}

		return error
	}

	getExcelErrorLength(entity)
	{
		return Object.keys(this.getExcelError(entity)).length
	}

	getCleanProps()
	{
		var props = {};
		var biProps = {};
		var bsProps = {};
		var etcProps = {};

		const keys = Object.keys(this.props || {});
		for (var i = 0; i < keys.length; i++)
		{
			const prop = this.props[keys[i]];
			if (prop.type == 'object' && prop.entity)
			{
				if (prop.entity.schema == 'baseInfo')
				{
					biProps[prop.name] = prop
				}

				else if (prop.entity.schema == 'baseSys')
				{
					bsProps[prop.name] = prop
				}

				else
				{
					etcProps[prop.name] = prop
				}
			}
			else
			{
				props[prop.name] = prop
			}
		}


		return { ...props, ...bsProps, ...biProps, ...etcProps };
	}

	cleanError(entity)
	{
		entity = entity || this;
		for (const key in entity.props)
		{
			let prop = entity.props[key];
			if (prop.type == "object" && prop.entity)
			{
				this.cleanError(prop.entity);
			}
			else
			{
				prop.errors = undefined
			}
		}
	}

	getFormProps()
	{
		var props = []
		for (var key in this.props)
		{
			var prop = this.props[key];
			if (prop.setConfig.canUseInForm == true)
			{
				props.push(this.props[key])
			}
		}

		return props
	}

	getExcelProps()
	{
		var props = []
		for (var key in this.props)
		{
			var prop = this.props[key];
			if (prop.setConfig.canUseInExcel == true)
			{
				props.push(this.props[key])
			}
		}

		return props
	}

	propHasRequired(prop)
	{
		return prop.setConfig.patterns.includes('required')
	}

	getDisplayValue(prop)
	{
		if (prop != undefined && prop.display != undefined)
		{
			var defaultValue = prop.display.defaultValue;
			if (defaultValue)
			{
				return loc.fromResource(defaultValue, prop.value)
			}
		}

		return prop.value
	}
}
