//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Product, ProductColor, Culture, ProductStatus } from '../../data'; 

import { ProductService, ProductColorService, CultureService, ProductStatusService } from '../../services'; 

export default class ProductDetailFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'productDetail';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productDetail.self';
	props = {
		productId: {
			foreignKey: true,
			name: 'productId',
			resourceKey: 'shop.productDetail.productId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Product(),
			data: async (params) =>
			{
				const service =
					new ProductService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productId',
				isMultiple: true,
				patterns: [],
			}
		},
		productColorId: {
			foreignKey: true,
			name: 'productColorId',
			resourceKey: 'shop.productDetail.productColorId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new ProductColor(),
			data: async (params) =>
			{
				const service =
					new ProductColorService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productColorId',
				isMultiple: true,
				patterns: [],
			}
		},
		adminDisplayName: {
			name: 'adminDisplayName',
			resourceKey: 'shop.productDetail.adminDisplayName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 200,
				patterns: [],
			}
		},
		cultureId: {
			foreignKey: true,
			name: 'cultureId',
			resourceKey: 'shop.productDetail.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Culture(),
			data: async (params) =>
			{
				const service =
					new CultureService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'cultureId',
				isMultiple: true,
				patterns: [],
			}
		},
		productStatusId: {
			foreignKey: true,
			name: 'productStatusId',
			resourceKey: 'shop.productDetail.productStatusId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new ProductStatus(),
			data: async (params) =>
			{
				const service =
					new ProductStatusService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productStatusId',
				isMultiple: true,
				patterns: [],
			}
		},

	};
}
