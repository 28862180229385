//GeneratorFlag:[NoChange]
import Product from './product';
import Culture from './culture';
import Category from './category';
import ProductStatus from './product-status';
import ProductTransportCostType from './product-transport-cost-type';
import ProductSpecification from './product-specification';
import LsStaticFile from './ls-static-file';

export default {Product, Culture, Category, ProductStatus, ProductTransportCostType, ProductSpecification, LsStaticFile}
export{Product, Culture, Category, ProductStatus, ProductTransportCostType, ProductSpecification, LsStaticFile}
