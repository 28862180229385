<template>
	<div v-if="isError && !loading ">
		<span style="font-size:large;cursor:pointer" @click="getOptions">
			<i class="bi bi-arrow-clockwise"></i> تلاش مجدد
		</span>
	</div>
	<v-select v-else dir="rtl"
			  v-model="value"
			  :loading="loading"
			  :reduce="o => o.value"
			  :options="options"
			  :clearable="false"
			  label="text"
			  :multiple="prop.setConfig.isMultiple"
			  @search="searchChange"
			  @option:selected="onHandelChange"></v-select>
</template>
<script>
	import vSelect from "vue-select";
	import "vue-select/dist/vue-select.css";
import { fa } from "../../../core/resources";
	import './form-select.css'


	export default {
		emits: ['change','keyup'],
		components: {
			'v-select': vSelect
		},

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				query: '',
				value: null,
				options: [],
				loading: false,
				isError:false
			}
		},

		async beforeMount()
		{
			await this.getOptions()
			this.value = this.prop.value
		},

		methods: {

			async getOptions()
			{
				var options = []
				var prop = this.prop;
				var textKey = this.prop.setConfig.textKey;
				var parentProperty = this.prop.parentProperty;

				var params = {
					start: 0, length: 50, filter: {}
				}

				if (this.query)
				{
					params.filter[textKey] = this.query
				}


				if (parentProperty)
				{
					if (!parentProperty.value)
					{
						this.options = options
						return;
					}

					params.filter[parentProperty.name] = parentProperty.value
				}


				try
				{
					this.loading = true;
					const res = await prop.data(params)

					if (res.data.items)
					{
						for (var item of res.data.items)
						{
							options.push({
								text: item[prop.setConfig.textKey],
								value: item[prop.setConfig.valueKey]
							})
						}
					}

				} catch (e)
				{
					this.isError = true;
					this.loading = false;
					console.log('form-select', e)
				}

				this.loading = false;
				this.options = options
			},

			async searchChange(query)
			{
				this.query = query
				await this.getOptions()
			},

			onHandelChange(payload)
			{
				var _value;
				if (payload.length != undefined)
				{
					var _value = [];
					for (var i = 0; i < payload.length; i++)
					{
						_value.push(payload[i].value)
					}
				}
				else
				{
					_value = payload.value
				}

				this.$emit('change', _value, _value == null)
			},
		},

		async mounted()
		{
			var self = this
			self.$emitter.on('formChange', async (prop) =>
			{
				var parentProperty = this.prop.parentProperty;
				if (parentProperty && prop.name == parentProperty.name)
				{
					this.value = null
					this.onHandelChange(null)
					await this.getOptions()
				}
			})
		}
	}
</script>
