import mitt from 'mitt'
import store from '@/core/store'
import Router from '@/core/router'
import Localization from '@/core/localization'
import plugins from '@/plugins'
import resources from '@/core/resources'


const requireModules = function (modules) {
    const result = [];
    for (var item of modules || []) {
        let module = require(`@/modules/${item}`);

        if (module) {
            result.push(module.default);
        }
    }

    return result;
}

const getResource = function (modules) {
    for (var item of modules || []) {
        if (item && item.name && item.resources) {
            const keys = Object.keys(item.resources);
            for (var i = 0; i < keys.length; i++) {
                const lang = keys[i]
                resources[lang][item.name] = item.resources[lang]
            }
        }
    }

    return resources;
}

const getToolbar = function (modules) {
    var toolbarItems = []
    for (var module of modules || []) {
        if (module.toolbar) {
            toolbarItems = [...toolbarItems, ...module.toolbar.items]
        }
    }

    return {
        items: toolbarItems
    };
}


const setRoutesAccess = (routes) => {

    for (var i = 0; i < routes.length; i++) {

        const route = routes[i];
        const clientId = store.state.client.id;
        const accessKeys = store.state.client.accessKeys;
        var clientAccessKey = ''

        if (route.meta.clientAccessKeys && route.meta.clientAccessKeys.length > 0) {
            clientAccessKey = route.meta.clientAccessKeys[0]
        }


        route.meta.clientHasAccess = clientId == 1 || accessKeys.findIndex((x) => {
            return x.keyName == clientAccessKey && x.groupName == 'client-web'
        }) > -1

        if (route.children) {
            setRoutesAccess(route.children)
        }
    }
}


const launch = async function (app) {
    try {
        //console.log(localStorage.getItem("Frc.app.language"));
        const moduleNames = JSON.parse(process.env.VUE_APP_MODULES)
        const language = process.env.VUE_APP_LANGUEGE;
        const direction = process.env.VUE_APP_DIRECTION;
        const color = process.env.VUE_APP_COLOR;
        const layout = process.env.VUE_APP_LAYOUT;

        //const moduleNames = JSON.parse(process.env.VUE_APP_MODULES)
        //const language = localStorage.getItem("Frc.app.language") || process.env.VUE_APP_LANGUEGE;
        //const direction = localStorage.getItem("Frc.app.direction") || process.env.VUE_APP_DIRECTION;
        //const color = localStorage.getItem("Frc.app.color") || process.env.VUE_APP_COLOR;
        //const layout = localStorage.getItem("Frc.app.layout") || process.env.VUE_APP_LAYOUT;

        const modules = requireModules(moduleNames);
        const toolbar = getToolbar(modules);
        const resource = getResource(modules);

        await store.dispatch('app/setLanguage', language);
        await store.dispatch('app/setLayout', layout);
        await store.dispatch('app/setColor', color);
        await store.dispatch('app/setDirection', direction);
        await store.dispatch('app/setModules', moduleNames);
        await store.dispatch('app/setResource', resource);
        await store.dispatch('client/setTokenFromStorage');
        await store.dispatch('client/setAccessKeysFromStorage');
        await store.dispatch('server/setBaseUrl');
        await store.dispatch('automation/setBranchFromStorage');

        app.config.globalProperties.$emitter = mitt()
        app.config.globalProperties.$toolbar = toolbar
        app.config.globalProperties.$plugins = plugins
        app.config.globalProperties.$loc = new Localization()

        const router = new Router(modules).create()
        setRoutesAccess(router.options.routes[0].children)

        app.use(store)
        app.use(router)
        app.mount('#app')
    } catch (e) {
        for (var key in localStorage) {
            if (typeof key == "string") {
                if (!key.includes("Frc.app") && key.includes("Frc")) {
                    localStorage.removeItem(key);

                }
            }
        }
        window.location.reload();
    }


}

export default { launch }
