//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Person.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/create', params || { });
	};

	//GeneratorFlag:[Person.Delete]
	async delete(personId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:personId
		}
	
		return await _server.delete('/people-net/person/delete', {'params':params})
	};

	//GeneratorFlag:[Person.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/detail', {'params':params});
	};

	//GeneratorFlag:[Person.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/list', {'params':params});
	};

	//GeneratorFlag:[Person.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/update', params || { });
	};

	//GeneratorFlag:[Person.ChangeEmail]
	async changeEmail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/change-email', params || { });
	};

	//GeneratorFlag:[Person.ChangeSecurityCode]
	async changeSecurityCode(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/change-security-code', params || { });
	};

	//GeneratorFlag:[Person.CheckUpline]
	async checkUpline(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/check-upline', params || { });
	};

	//GeneratorFlag:[Person.GetAvatar]
	async getAvatar(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/get-avatar', {'params':params});
	};

	//GeneratorFlag:[Person.GetCredit]
	async getCredit(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/get-credit', {'params':params});
	};

	//GeneratorFlag:[Person.GetPersonByData]
	async getPersonByData(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/get-person-by-data', params || { });
	};

	//GeneratorFlag:[Person.GetProfile]
	async getProfile(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/get-profile', {'params':params});
	};

	//GeneratorFlag:[Person.GetUplineInfo]
	async getUplineInfo(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/get-upline-info', params || { });
	};

	//GeneratorFlag:[Person.RegisterByEmail]
	async registerByEmail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/register-by-email', params || { });
	};

	//GeneratorFlag:[Person.Register]
	async register(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/register', params || { });
	};

	//GeneratorFlag:[Person.RegisterShop]
	async registerShop(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/register-shop', params || { });
	};

	//GeneratorFlag:[Person.SearchTeam]
	async searchTeam(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/search-team', {'params':params});
	};

	//GeneratorFlag:[Person.TreeList]
	async treeList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person/tree-list', {'params':params});
	};

	//GeneratorFlag:[Person.UploadAvatar]
	async uploadAvatar(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person/upload-avatar', params || { });
	};

//GeneratorFlag:[NewAction]
}