//GeneratorFlag:[NoChange]
export default {
	path: 'product-detail',
	name: 'shop.productDetail.layout',
	redirect: to => {
		return {name: 'shop.productDetail.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/product-detail"],
		resourceKey: 'shop.views.productDetail.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductDetailDelete]"
		{
			path: 'delete',
			name: 'shop.productDetail.delete',
			component: () => import('@/modules/shop/views/product-detail/product-detail-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDetail.delete',
				clientAccessKeys:["/shop/product-detail/delete"],
				serverAccessKeys:["/shop/product-detail/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetailDetail]"
		{
			path: 'detail',
			name: 'shop.productDetail.detail',
			component: () => import('@/modules/shop/views/product-detail/product-detail-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDetail.detail',
				clientAccessKeys:["/shop/product-detail/detail"],
				serverAccessKeys:["/shop/product-detail/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetailList]"
		{
			path: 'list',
			name: 'shop.productDetail.list',
			component: () => import('@/modules/shop/views/product-detail/product-detail-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDetail.list',
				clientAccessKeys:["/shop/product-detail/list"],
				serverAccessKeys:["/shop/product-detail/list","/shop/product/list","/shop/product-color/list","/shop/product-monetary-feature/list","/shop/culture/list","/shop/product-status/list","/shop/category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetailCreate]"
		{
			path: 'create',
			name: 'shop.productDetail.create',
			component: () => import('@/modules/shop/views/product-detail/product-detail-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDetail.create',
				clientAccessKeys:["/shop/product-detail/create"],
				serverAccessKeys:["/shop/product-detail/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetailImport]"
		{
			path: 'import',
			name: 'shop.productDetail.import',
			component: () => import('@/modules/shop/views/product-detail/product-detail-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDetail.import',
				clientAccessKeys:["/shop/product-detail/import"],
				serverAccessKeys:["/shop/product-detail/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetailUpdate]"
		{
			path: 'update',
			name: 'shop.productDetail.update',
			component: () => import('@/modules/shop/views/product-detail/product-detail-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDetail.update',
				clientAccessKeys:["/shop/product-detail/update"],
				serverAccessKeys:["/shop/product-detail/save","/shop/product/list","/shop/product-color/list","/shop/product-monetary-feature/list","/shop/culture/list","/shop/product-status/list","/shop/category/list","/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






