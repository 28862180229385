//GeneratorFlag:[NoChange]
import { BasePersonAppPurchaseStatuse } from '../base/data'
export default class PersonAppPurchaseStatuse extends BasePersonAppPurchaseStatuse
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personAppPurchaseStatuseId: {
				...this.props.personAppPurchaseStatuseId,
				setConfig:{
					...this.props.personAppPurchaseStatuseId.setConfig
				}
			},
			personAppPurchaseStatuseKey: {
				...this.props.personAppPurchaseStatuseKey,
				setConfig:{
					...this.props.personAppPurchaseStatuseKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
