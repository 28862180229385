//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class ProductSpecificationService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[ProductSpecification.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-specification/create', params || { });
	};

	//GeneratorFlag:[ProductSpecification.Delete]
	async delete(productId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:productId
		}
	
		return await _server.delete('/shop/product-specification/delete', {'params':params})
	};

	//GeneratorFlag:[ProductSpecification.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/product-specification/detail', {'params':params});
	};

	//GeneratorFlag:[ProductSpecification.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/product-specification/update', params || { });
	};

//GeneratorFlag:[NewAction]
}