import Toastify from 'toastify-js'
import Localization from '@/core/localization'
const loc = new Localization()

const toast =
{
	error(res, msg, options) {

		Toastify({
			text: msg || loc.fromResource(res),
			duration: 5000,
			newWindow: true,
			close: true,
			gravity: "bottom",
			position: "center",
			stopOnFocus: true,
			style: {
				direction: 'rtl',
				background: "#b02a37",
			},
		}).showToast();
	},

	success(res, msg) {

		Toastify({
			text: msg || loc.fromResource(res),
			duration: 3000,
			newWindow: true,
			close: true,
			gravity: "bottom",
			position: "center",
			stopOnFocus: true,
			style: {
				direction: 'rtl',
				background: "#146c43",
			},
		}).showToast();
	},

	warning(res, msg) {

		Toastify({
			text: msg || loc.fromResource(res),
			duration: 5000,
			newWindow: true,
			close: true,
			gravity: "bottom",
			position: "center",
			stopOnFocus: true,
			style: {
				direction: 'rtl',
				background: "#ffe69c",
				color: "#000"
			},
		}).showToast();
	}
}

export default toast
