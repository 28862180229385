import toast from './toast'
import security from './security'
import deviceType from './device-type'
import string from './string'
import array from './array'
import url from './url'
import persian from './persian'

export default {
	toast,
	security,
	deviceType,
	string,
	array,
	persian
}
	
export { toast, security, deviceType, string, array, url, persian }
