//GeneratorFlag:[NoChange]
export default {
	path: 'invoice',
	name: 'shop.invoice.layout',
	redirect: to => {
		return {name: 'shop.invoice.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/invoice"],
		resourceKey: 'shop.views.invoice.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[InvoiceDelete]"
		{
			path: 'delete',
			name: 'shop.invoice.delete',
			component: () => import('@/modules/shop/views/invoice/invoice-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.delete',
				clientAccessKeys:["/shop/invoice/delete"],
				serverAccessKeys:["/shop/invoice/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceDetail]"
		{
			path: 'detail',
			name: 'shop.invoice.detail',
			component: () => import('@/modules/shop/views/invoice/invoice-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.detail',
				clientAccessKeys:["/shop/invoice/detail"],
				serverAccessKeys:["/shop/invoice/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceDetailDetail]"
		{
			path: 'invoice-detail-detail',
			name: 'shop.invoice.invoiceDetailDetail',
			component: () => import('@/modules/shop/views/invoice/invoice-detail-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.invoiceDetailDetail',
				clientAccessKeys:["/shop/invoice/invoice-detail-detail"],
				serverAccessKeys:["/shop/invoice-detail/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceList]"
		{
			path: 'list',
			name: 'shop.invoice.list',
			component: () => import('@/modules/shop/views/invoice/invoice-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.list',
				clientAccessKeys:["/shop/invoice/list"],
				serverAccessKeys:["/shop/invoice/list","/shop/person/list","/shop/invoice-statuse/list","/shop/invoice-payment-type/list","/shop/store/list","/shop/person-address/list","/shop/culture/list","/shop/city/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCreate]"
		{
			path: 'create',
			name: 'shop.invoice.create',
			component: () => import('@/modules/shop/views/invoice/invoice-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.create',
				clientAccessKeys:["/shop/invoice/create"],
				serverAccessKeys:["/shop/invoice/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceImport]"
		{
			path: 'import',
			name: 'shop.invoice.import',
			component: () => import('@/modules/shop/views/invoice/invoice-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.import',
				clientAccessKeys:["/shop/invoice/import"],
				serverAccessKeys:["/shop/invoice/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceUpdate]"
		{
			path: 'update',
			name: 'shop.invoice.update',
			component: () => import('@/modules/shop/views/invoice/invoice-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.update',
				clientAccessKeys:["/shop/invoice/update"],
				serverAccessKeys:["/shop/invoice/save","/shop/person/list","/shop/invoice-statuse/list","/shop/invoice-payment-type/list","/shop/store/list","/shop/person-address/list","/shop/culture/list","/shop/city/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceDetailUpdate]"
		{
			path: 'invoice-detail-update',
			name: 'shop.invoice.invoiceDetailUpdate',
			component: () => import('@/modules/shop/views/invoice/invoice-detail-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.invoiceDetailUpdate',
				clientAccessKeys:["/shop/invoice/invoice-detail-update"],
				serverAccessKeys:["/shop/invoice-detail/save","/shop/person/list","/shop/invoice-statuse/list","/shop/invoice-payment-type/list","/shop/store/list","/shop/person-address/list","/shop/culture/list","/shop/city/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]








	]
}








