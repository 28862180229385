<template>
	<div class="row border-bottom pt-2 pb-2" v-if="item.displayValue">
		<div class="col">
			<span>{{ getLabel() }}</span>
		</div>
		<div class="col">
			<span>{{ item.displayValue }}</span>
		</div>
	</div>
</template>

<script>
	export default {

		props: {

			'item': {
				type: Object
			},
		},

		methods:
		{
			getLabel()
			{
				return this.$loc.fromResource(this.item.resourceKey)
			},
		}
	}
</script>
