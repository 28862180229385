//GeneratorFlag:[NoChange]
export default {
	path: 'invoice-transaction-type',
	name: 'shop.invoiceTransactionType.layout',
	redirect: to => {
		return {name: 'shop.invoiceTransactionType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/BaseSys/invoice-transaction-type"],
		resourceKey: 'shop.views.invoiceTransactionType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[InvoiceTransactionTypeDetail]"
		{
			path: 'detail',
			name: 'shop.invoiceTransactionType.detail',
			component: () => import('@/modules/shop/views/invoice-transaction-type/invoice-transaction-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransactionType.detail',
				clientAccessKeys:["/shop/BaseSys/invoice-transaction-type/detail"],
				serverAccessKeys:["/shop/invoice-transaction-type/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceTransactionTypeList]"
		{
			path: 'list',
			name: 'shop.invoiceTransactionType.list',
			component: () => import('@/modules/shop/views/invoice-transaction-type/invoice-transaction-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransactionType.list',
				clientAccessKeys:["/shop/BaseSys/invoice-transaction-type/list"],
				serverAccessKeys:["/shop/invoice-transaction-type/list","/shop/culture/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}


