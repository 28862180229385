//GeneratorFlag:[NoChange]
import { BaseCategoryListAction } from '@/modules/shop/base/data/actions/category/list-action'
export default class CategoryListAction extends BaseCategoryListAction
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			items: {
				...this.props.items,
				setConfig:{
					...this.props.items.setConfig
				}
			},
		};
	}
}

