//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class InvoiceProductService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(invoiceProductId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:invoiceProductId
		}

		return await _server.get('/shop/invoice-product/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/shop/invoice-product/save', params || {})
	};

	async delete(invoiceProductId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:invoiceProductId
		}

		return await _server.delete('/shop/invoice-product/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/shop/invoice-product/list', {'params':params})
	};
}
