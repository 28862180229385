<template>
	<div class="row row-cols-auto">
		<div class="col" v-if="total > 5">
			<ul class="pagination pagination-sm">
				<li class="page-item">
					<span class="page-link text-dark rounded-0" @click="gotToPage(current - 1)">&#8594;</span>
				</li>
				<li class="page-item">
					<span class="page-link text-dark" @click="gotToPage(1)">1</span>
				</li>
				<li class="page-item me-2 disabled">
					<span class="page-link text-dark">...</span>
				</li>
				<template v-for="(page,index) in getPagination()" :key="index">
					<li class="page-item" v-bind:class="{'active': isCurrent(page) }">
						<span class="page-link" @click="gotToPage(page)">{{ page }}</span>
					</li>
				</template>
				<li class="page-item ms-2 disabled">
					<span class="page-link text-dark">...</span>
				</li>
				<li class="page-item">
					<span class="page-link text-dark" @click="gotToPage(totalPages)">{{ totalPages }}</span>
				</li>
				<li class="page-item">
					<span class="page-link text-dark rounded-0" @click="gotToPage(current + 1)">&#8592;</span>
				</li>
			</ul>
		</div>
		<div class="col" v-if="total > 5">
			<select class="form-select form-select-sm rounded-0" @change="totalPerPageChange" v-model="totalPerPage">
				<option v-for="num in numbers" :value="num" :key="num">{{ num }}</option>
			</select>
		</div>
		<div class="col text-muted pt-1">
			<span>{{$loc.fromResource('com.table.pageInfo',{'currentPage': current,'totalPages':totalPages})}}</span>
			<span v-if="totalfilters > 0"> ({{ $loc.fromResource('com.table.filterResult',{'totalfilters': totalfilters})}})</span>
		</div>
	</div>

</template>
<script>

	export default {
		emits: ['change'],

		props: {
			total: {
				type: Number,
				default: 0
			},

			totalfilters: {
				type: Number,
				default: 0
			},

			pageSize: {
				type: Number,
				default: 10
			},

			current: {
				type: Number,
				default: 1
			}
		},

		data() {
			return {
				totalPerPage: 10,
				numbers: [5, 10, 20, 50, 100, 200, 300, 400, 500]
			}
		},

		beforeMount() {
			this.totalPerPage = this.pageSize;
		},

		computed:
		{
			totalPages()
			{
				return Math.ceil(this.total / this.totalPerPage);
			}
		},

		methods: {

			getPagination() {

				var startIndex =
					(this.current > 3)
						? this.current - 3 : 1; var pagination = []

				for (var i = startIndex; i < (startIndex + 7); i++) {
					if (i <= this.totalPages) { pagination.push(i) } else if (pagination[0] > 1) {
						pagination.unshift(pagination[0] - 1)
					}
				}

				return pagination
			},

			async totalPerPageChange() {
				const totalPerPage = Number(this.totalPerPage)

				const query = {
					...this.$route.query,
					pageSize: totalPerPage, currentPage: 1
				};

				await this.$router.replace({ query });

				this.$emit('change', {
					currentPage: 1,
					pageSize: totalPerPage,
				});
			},

			async gotToPage(page) {

				if (page > 0 && page != this.current && page <= this.totalPages)
				{
					const query = {
						...this.$route.query,
						pageSize: this.totalPerPage, currentPage: page
					};

					await this.$router.replace({ query });

					this.$emit('change', {
						currentPage: page,
						pageSize: this.totalPerPage,
					});
				}
			},

			isCurrent(page) {
				return page == this.current
			}
		}
	}
</script>
<style>
	.page-item > .page-link {
		color: var(--Frc-dark);
		user-select: none;
		cursor: pointer;
	}

		.page-item.active > .page-link {
			background: var(--Frc-base-color-dark) !important;
			border-color:transparent;
		}
</style>
