//GeneratorFlag:[NoChange]
import { BaseCategoryListItem } from '@/modules/shop/base/data/actions/category/list-action'
export default class CategoryListItem extends BaseCategoryListItem
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			categoryId: {
				...this.props.categoryId,
				setConfig:{
					...this.props.categoryId.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			imagePath: {
				...this.props.imagePath,
				setConfig:{
					...this.props.imagePath.setConfig
				}
			},
			categoryGenoString: {
				...this.props.categoryGenoString,
				setConfig:{
					...this.props.categoryGenoString.setConfig
				}
			},
		};
	}
}

