//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class InvoiceCancelService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[InvoiceCancel.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice-cancel/create', params || { });
	};

	//GeneratorFlag:[InvoiceCancel.Delete]
	async delete(invoiceCancelId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:invoiceCancelId
		}
	
		return await _server.delete('/shop/invoice-cancel/delete', {'params':params})
	};

	//GeneratorFlag:[InvoiceCancel.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice-cancel/detail', {'params':params});
	};

	//GeneratorFlag:[InvoiceCancel.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice-cancel/list', {'params':params});
	};

	//GeneratorFlag:[InvoiceCancel.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice-cancel/update', params || { });
	};

	//GeneratorFlag:[InvoiceCancel.ContractInfo]
	async contractInfo(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice-cancel/contract-info', {'params':params});
	};

	//GeneratorFlag:[InvoiceCancel.RemoveContract]
	async removeContract(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice-cancel/remove-contract', params || { });
	};

//GeneratorFlag:[NewAction]
}