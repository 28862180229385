<template>
	<div class="Frc-text-editor">
		<div class="header">
			<div class="btn" @click="onClickAction('removeFormat')">
				<Frc-icon-bootstrap name="bi-eraser-fill" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('justifyRight')">
				<Frc-icon-bootstrap name="bi-text-right" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('justifyCenter')">
				<Frc-icon-bootstrap name="bi-text-center" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('justifyLeft')">
				<Frc-icon-bootstrap name="bi-text-left" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('insertOrderedList')">
				<Frc-icon-bootstrap name="bi-list-ol" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('insertUnorderedList')">
				<Frc-icon-bootstrap name="bi-list-ul" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('strikethrough')" v-bind:class="{'btn-light': states.includes('strikethrough')}">
				<Frc-icon-bootstrap name="bi-type-strikethrough" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('italic')" v-bind:class="{'btn-light': states.includes('italic')}">
				<Frc-icon-bootstrap name="bi-type-italic" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('underline')" v-bind:class="{'btn-light': states.includes('underline')}">
				<Frc-icon-bootstrap name="bi-type-underline" size="20px" />
			</div>
			<div class="btn" @click="onClickAction('bold')" v-bind:class="{'btn-light': states.includes('bold')}">
				<Frc-icon-bootstrap name="bi-type-bold" size="20px" />
			</div>
		</div>
		<div class="content" contenteditable="true" @input="onInput" @paste="onPaste" @keydown="onKeydown" @keyup="onHandelKeyup" ref="Editor"></div>
	</div>
</template>
<script>
	export default {
		emit: ['change','keyup', 'update:modelValue'],
		data()
		{
			return {
				states: []
			}
		},

		mounted()
		{
			this.execCommand('styleWithCSS')
		},

		methods: {

			execCommand(command, value)
			{
				document.execCommand(command, false, value || null);
			},

			onInput(event)
			{
				var editor = this.$refs.Editor;
				var firstChild = event.target.firstChild;

				if (firstChild && firstChild.nodeType === 3)
				{
					this.execCommand('formatBlock', '<div>');
				}
				else if (editor.innerHTML === '<br>')
				{
					editor.innerHTML = '';
				}

				this.$emit('change', editor.innerHTML)
				this.$emit('update:modelValue', editor.innerHTML);
			},

			onKeydown(event)
			{
				if (event.key === 'Tab')
				{
					event.preventDefault();
				}
				else if (event.key === 'Enter' && document.queryCommandValue('formatBlock') === 'blockquote')
				{
					return this.execCommand('formatBlock', '<div>');
				}
			},

			onHandelKeyup()
			{
				this.onHandelState();

				var editor = this.$refs.Editor;
				this.$emit('keyup', editor.innerHTML)
				this.$emit('update:modelValue', editor.innerHTML);
			},

			onPaste(e)
			{
				e.preventDefault();

				var html = ""
				var text = (e.originalEvent || e).clipboardData.getData('text/plain');
				var lines = text.split("\n");

				for (var i = 0; i < lines.length; i++)
				{
					const line =
						lines[i].trim();

					html += `<div>${(line != '' ? line : '<br/>')}</div>`;
				}

				this.execCommand("insertHTML", html)
			},

			onClickAction(command)
			{
				this.execCommand(command);
				this.onHandelState();
				this.$refs.Editor.focus();
			},

			onHandelState()
			{
				var actives = [];
				const states = ['bold', 'underline', 'italic', 'strikethrough'];

				for (var i = 0; i < states.length; i++)
				{
					if (document.queryCommandState(states[i]))
					{
						actives.push(states[i]);
					}
				}

				this.states = actives;
			},

			getValue()
			{
				return this.$refs.Editor.innerHTML
			},

			setValue(value)
			{
				this.$refs.Editor.innerHTML = value
				this.$emit('update:modelValue', value);
			}
		},
	}
</script>
<style>
	.Frc-text-editor {
		border: #ddd solid 1px;
		background: #fff;
	}

		.Frc-text-editor .header {
			border-bottom: #ddd solid 1px;
			text-align: center;
		}

		.Frc-text-editor .content {
			height: 300px;
			outline: 0;
			overflow-y: auto;
			padding: 10px
		}
</style>
