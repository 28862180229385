//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { PersonAppPurchaseStatuse, PersonAppPurchaseType } from '../../data'; 

import { PersonAppPurchaseStatuseService, PersonAppPurchaseTypeService } from '../../services'; 

export default class BasePersonAppPurchase extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'personAppPurchase';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'peopleNet.personAppPurchase.self';
	props = {
		personAppPurchaseId: {
			primarykey: true,
			name: 'personAppPurchaseId',
			resourceKey: 'peopleNet.personAppPurchase.personAppPurchaseId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personId: {
			name: 'personId',
			resourceKey: 'peopleNet.personAppPurchase.personId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		createDate: {
			name: 'createDate',
			resourceKey: 'peopleNet.personAppPurchase.createDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personAppPurchaseStatuseId: {
			foreignKey: true,
			name: 'personAppPurchaseStatuseId',
			resourceKey: 'peopleNet.personAppPurchase.personAppPurchaseStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonAppPurchaseStatuse(),
			data: async (params) =>
			{
				const service =
					new PersonAppPurchaseStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personAppPurchaseStatuseId',
				patterns: [],
			}
		},
		factorNumber: {
			name: 'factorNumber',
			resourceKey: 'peopleNet.personAppPurchase.factorNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		factorValue: {
			name: 'factorValue',
			resourceKey: 'peopleNet.personAppPurchase.factorValue',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		personAppPurchaseTypeId: {
			foreignKey: true,
			name: 'personAppPurchaseTypeId',
			resourceKey: 'peopleNet.personAppPurchase.personAppPurchaseTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonAppPurchaseType(),
			data: async (params) =>
			{
				const service =
					new PersonAppPurchaseTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personAppPurchaseTypeId',
				patterns: [],
			}
		},
		expireDate: {
			name: 'expireDate',
			resourceKey: 'peopleNet.personAppPurchase.expireDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		displayExpireDate: {
			name: 'displayExpireDate',
			resourceKey: 'peopleNet.personAppPurchase.displayExpireDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		personAppPurchaseStatuse: {
			name: 'personAppPurchaseStatuse',
			resourceKey: 'peopleNet.personAppPurchase.personAppPurchaseStatuse',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonAppPurchaseStatuse(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personAppPurchaseType: {
			name: 'personAppPurchaseType',
			resourceKey: 'peopleNet.personAppPurchase.personAppPurchaseType',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonAppPurchaseType(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personAppPurchaseSubInfo: {
			name: 'personAppPurchaseSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
