//GeneratorFlag:[NoChange]
import { BasePlanSetting } from '../base/data'
export default class PlanSetting extends BasePlanSetting
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			planSettingId: {
				...this.props.planSettingId,
				setConfig:{
					...this.props.planSettingId.setConfig
				}
			},
			planMaximunPayOutPerLineValue: {
				...this.props.planMaximunPayOutPerLineValue,
				setConfig:{
					...this.props.planMaximunPayOutPerLineValue.setConfig
				}
			},
			planPayOutCommisionPrecent: {
				...this.props.planPayOutCommisionPrecent,
				setConfig:{
					...this.props.planPayOutCommisionPrecent.setConfig
				}
			},
			planPayOutVoacherPrecent: {
				...this.props.planPayOutVoacherPrecent,
				setConfig:{
					...this.props.planPayOutVoacherPrecent.setConfig
				}
			},
			planSaveExpiretionDayCount: {
				...this.props.planSaveExpiretionDayCount,
				setConfig:{
					...this.props.planSaveExpiretionDayCount.setConfig
				}
			},
			planMaximunPayoutTotal: {
				...this.props.planMaximunPayoutTotal,
				setConfig:{
					...this.props.planMaximunPayoutTotal.setConfig
				}
			},

		};
	}
}
