//GeneratorFlag:[NoChange]
import { BaseMaritalType } from '../base/data'
export default class MaritalType extends BaseMaritalType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			maritalTypeId: {
				...this.props.maritalTypeId,
				setConfig:{
					...this.props.maritalTypeId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},

		};
	}
}
