<template>
	<div class="d-inline-block me-1 mb-2">
		<button class="btn bg-base-color-dark border-0"
				@click="displayChange('grid')"
				v-bind:class="{'text-light': displayType == 'grid' }">
			<Frc-icon-bootstrap name="bi-grid-3x3" size="15px" />
		</button>
		<button class="btn bg-base-color-dark border-0"
				@click="displayChange('grid-list')"
				v-bind:class="{'text-light': displayType == 'grid-list' }">
			<Frc-icon-bootstrap name="bi-list" size="15px" />
		</button>
	</div>
</template>
<script>
	export default {
		emits: ['displayChange'],

		props:
		{
			'filterEntity': Function,
			'displayType': String
		},

		data()
		{
			return {
				length: 0,
				isOpen: false,
				filterValue: null,
			}
		},

		methods:
		{
			displayChange(displayType)
			{
				this.$emit('displayChange', displayType)
			}
		}
	}
</script>
<style scoped>
	.filter-box {
		width: 400px;
		margin-right: 10px;
	}
</style>
