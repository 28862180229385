import qs from 'qs';
import _ from 'lodash';
import axios from 'axios'
import plugins from '@/plugins'
import store from '@/core/store'

export default class Server {
    constructor(config) {
        this.gateway = 'public';
        this.config = config || {};

        var server = axios.create({
            baseURL: store.state.server.baseUrl
        });

        server.interceptors.request.use((config) => {
            config.headers['Authorization'] = this.getAuthorization();
            config.paramsSerializer = params => {
                return qs.stringify(params, {
                    arrayFormat: "brackets",
                    allowDots: true,
                    encode: false
                });
            };

            return config;

        }, (error) => { return Promise.reject(error) });


        server.interceptors.response.use((response) => {
            if (this.config.HSR) {
                this.handelSuccessResponse(response);
            }

            return response;


            if (response == undefined) {
                plugins.toast.error('status.noResponse');
                return;
            }


            alert(response.data.status);
            if (this.config.HER) {
                if (response.data.status == "InvalidArgument") {
                    plugins.toast.error(null, response.data.message);
                }
                else {
                    plugins.toast.error('status.' + _.camelCase(response.data.status));

                }
            }


            return;

        }, (error) => {

            try {

                let strdataError = error.response.data;
                //console.log(strdataError);
                //console.log('s:'+error.status);
                //console.log('m'+error.response.message);
                if (error.response.data.status == "PermissionDenied") {
                    if (error.response.data.message == "Your Account Is Not Active.Please Active it") {
                        //dashbord / pending
                        if (window.location.href.indexOf("/dashbord/pending")==-1) {
                        window.location.href = "/dashbord/pending"
                        }
                    }
                }
                if (strdataError.search("Unauthenticated") != -1) {
                    for (var key in localStorage) {
                        if (typeof key == "string") if (!key.includes("Frc.app") && key.includes("Frc")) localStorage.removeItem(key);

                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("profileImagePath");
                    }
                    
                    window.location.href = "/auth/auth-login"
                }

            } catch {

            }
            if (this.config.HER) {
                
                this.handleErrorResponse(error);
            }

            return Promise.reject(error)
        });

        this.get = server.get;
        this.post = server.post;
        this.delete = server.delete;
    }

    getAuthorization() {
        const accessToken = localStorage.getItem('Frc.hub.accessToken');
        return accessToken ? `Bearer ${accessToken}` : "";
    };

    getBaseURL() {
        for (var gate of this.gateways) {
            if (gate.name == this.gateway) {
                return gate.baseURL;
            }
        }
    };

    handelSuccessResponse() {
        plugins.toast.success('status.ok');
    }

    handleErrorResponse(error) { 

        if (error.response == undefined) { 
            plugins.toast.error('status.noResponse');
            return;
        }

        if (error.response.status == 400) {
            if (error.response.data.status == 'InvalidArgument') {
                plugins.toast.error(error.response.data.message);
                return;
            }
        } 
        switch (error.response.status) {

            case 400:
                plugins.toast.error('status.badRequest');
                break;
            case 401:
                for (var key in localStorage) {
                    if (typeof key == "string") if (!key.includes("Frc.app") && key.includes("Frc")) localStorage.removeItem(key);

                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("profileImagePath");
                }

                window.location.href = "/auth/auth-login"
                plugins.toast.error('status.unauthorized');
                break;
            case 404:
                plugins.toast.error('status.notFound');
                break;
            case 500:
                //console.log(error.response.message);
                plugins.toast.error('status.serverError');
                break;
            case 400:
                plugins.toast.error('status.badRequest');
                break;
            default:
                plugins.toast.error('status.networkError');
        }
    };
}
