//GeneratorFlag:[NoChange]
export default {
	path: 'company-rank',
	name: 'peopleNet.companyRank.layout',
	redirect: to => {
		return {name: 'peopleNet.companyRank.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.companyRank.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.companyRank.list',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.companyRank.create',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.companyRank.import',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.companyRank.update',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.companyRank.delete',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.companyRank.detail',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
