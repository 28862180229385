//GeneratorFlag:[NoChange]
import { BaseMenuPerson } from '../base/data'
export default class MenuPerson extends BaseMenuPerson
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			menuPersonId: {
				...this.props.menuPersonId,
				setConfig:{
					...this.props.menuPersonId.setConfig
				}
			},
			menuId: {
				...this.props.menuId,
				setConfig:{
					...this.props.menuId.setConfig
				}
			},
			titleMenu: {
				...this.props.titleMenu,
				setConfig:{
					...this.props.titleMenu.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},
			teamId: {
				...this.props.teamId,
				setConfig:{
					...this.props.teamId.setConfig
				}
			},
			rankId: {
				...this.props.rankId,
				setConfig:{
					...this.props.rankId.setConfig
				}
			},
			companyRankId: {
				...this.props.companyRankId,
				setConfig:{
					...this.props.companyRankId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			company: {
				...this.props.company,
				setConfig:{
					...this.props.company.setConfig
				}
			},
			companyRank: {
				...this.props.companyRank,
				setConfig:{
					...this.props.companyRank.setConfig
				}
			},
			menu: {
				...this.props.menu,
				setConfig:{
					...this.props.menu.setConfig
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				}
			},
			rank: {
				...this.props.rank,
				setConfig:{
					...this.props.rank.setConfig
				}
			},
			team: {
				...this.props.team,
				setConfig:{
					...this.props.team.setConfig
				}
			},
			menuPersonSubInfo: {
				...this.props.menuPersonSubInfo,
				setConfig:{
					...this.props.menuPersonSubInfo.setConfig
				}
			},

		};
	}
}
