//GeneratorFlag:[NoChange]
import { BaseBankPayment } from '../base/data'
export default class BankPayment extends BaseBankPayment
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			bankPaymentId: {
				...this.props.bankPaymentId,
				setConfig:{
					...this.props.bankPaymentId.setConfig
				}
			},
			accountNumber: {
				...this.props.accountNumber,
				setConfig:{
					...this.props.accountNumber.setConfig
				}
			},
			shabaNumber: {
				...this.props.shabaNumber,
				setConfig:{
					...this.props.shabaNumber.setConfig
				}
			},
			userName: {
				...this.props.userName,
				setConfig:{
					...this.props.userName.setConfig
				}
			},
			password: {
				...this.props.password,
				setConfig:{
					...this.props.password.setConfig
				}
			},
			sendUrl: {
				...this.props.sendUrl,
				setConfig:{
					...this.props.sendUrl.setConfig
				}
			},
			postBackUrl: {
				...this.props.postBackUrl,
				setConfig:{
					...this.props.postBackUrl.setConfig
				}
			},
			additionalData: {
				...this.props.additionalData,
				setConfig:{
					...this.props.additionalData.setConfig
				}
			},
			storeId: {
				...this.props.storeId,
				setConfig:{
					...this.props.storeId.setConfig
				}
			},
			bankId: {
				...this.props.bankId,
				setConfig:{
					...this.props.bankId.setConfig
				}
			},
			bank: {
				...this.props.bank,
				setConfig:{
					...this.props.bank.setConfig
				}
			},
			store: {
				...this.props.store,
				setConfig:{
					...this.props.store.setConfig
				}
			},
			bankPaymentSubInfo: {
				...this.props.bankPaymentSubInfo,
				setConfig:{
					...this.props.bankPaymentSubInfo.setConfig
				}
			},

		};
	}
}
