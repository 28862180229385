//GeneratorFlag:[NoChange]
import ProductFeature from './product-feature';
import Culture from './culture';
import Feature from './feature';
import Category from './category';
import ProductStatus from './product-status';
import ProductSpecification from './product-specification';
import Product from './product';

export default {ProductFeature, Culture, Feature, Category, ProductStatus, ProductSpecification, Product}
export{ProductFeature, Culture, Feature, Category, ProductStatus, ProductSpecification, Product}
