<template>
	<div class="bg-light p-3 border-start border-danger border-3">
		<strong class="d-block">{{ $loc.fromResource('com.excel.fileIsEmpty') }}</strong>
		<div class="mt-3">
			<button type="button" @click="$emit('changeState','select')" class="btn btn-light border">
				{{ $loc.fromResource('com.excel.backToSelectFile') }}
			</button>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['changeState']
	}
</script>
