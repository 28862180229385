//GeneratorFlag:[NoChange]
import { BasePlanLineGetMyDirect } from '@/modules/shop/base/data/actions/plan-line/get-my-direct'
export default class PlanLineGetMyDirect extends BasePlanLineGetMyDirect
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			personFullName: {
				...this.props.personFullName,
				setConfig:{
					...this.props.personFullName.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
		};
	}
}

