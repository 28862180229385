//GeneratorFlag:[NoChange]
import data from './data'
import routes from './routes'
import services from './services'
import resources from './resources'

const peopleNet = {
	name: 'peopleNet',
	foldreName: 'people-net',
	data,
	routes,
	services,
	resources
}

export default peopleNet
export { data, routes, services, resources }

