//GeneratorFlag:[NoChange]
import { BasePersonCreaditTransactionType } from '../base/data'
export default class PersonCreaditTransactionType extends BasePersonCreaditTransactionType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personCreaditTransactionTypeId: {
				...this.props.personCreaditTransactionTypeId,
				setConfig:{
					...this.props.personCreaditTransactionTypeId.setConfig
				}
			},
			personCreaditTransactionTypeKey: {
				...this.props.personCreaditTransactionTypeKey,
				setConfig:{
					...this.props.personCreaditTransactionTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
