//GeneratorFlag:[NoChange]
import { BaseTeam } from '../base/data'
export default class Team extends BaseTeam
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			teamId: {
				...this.props.teamId,
				setConfig:{
					...this.props.teamId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			teamGenoString: {
				...this.props.teamGenoString,
				setConfig:{
					...this.props.teamGenoString.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},

		};
	}
}
