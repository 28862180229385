<template>
	<div class="Frc-popup">
		<div class="backdrop" @click="closePopup"></div>
		<div class="content" :style="{'max-width': width}">
			<div class="header" :style="{'max-width': width}">
				<strong class="back">
					<Frc-icon-bootstrap @click="closePopup" name="bi-x-circle me-3" size="20px" />
				</strong>
			</div>
			<div class="body">
				<div class="container">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		name: 'Popup',
		props: {
			name: {
				type: String,
				default: ''
			},

			width: {
				type: String,
				default: '600px'
			},

			color: {
				type: String,
				default: 'inherit'
			},

			close: {
				type: Function,
			}
		},

		data()
		{
			return {
				isShow:true
			}
		},

		methods: {

			closePopup()
			{
				if (typeof (this.close) == 'function')
				{
					return this.close();
				}

				this.isShow = false;
			}
		}
	}
</script>

<style scoped>

	.Frc-popup,
	.Frc-popup .backdrop,
	.Frc-popup .content,
	.Frc-popup .body {
		width: 100%;
		height: 100%;
	}

	.Frc-popup {
		top: 0;
		left: 0;
		z-index: 10;
		position: fixed;
	}
		.Frc-popup .backdrop {
			z-index: 11;
			position: fixed;
			background: rgba(0,0,0,0.5);
		}

		.Frc-popup .content {
			float: left;
			overflow-y: hidden;
			background: #f1f1f1;
			position: relative;
			z-index: 12;
			padding-top: 50px;
			animation: FrcPopupAnimation 0.3s linear 0s 1 normal forwards;
		}

		.Frc-popup .header {
			top: 0;
			left: 0;
			padding: 10px;
			width: 100%;
			height: 50px;
			position: fixed;
			border-bottom: #ddd solid 1px;
		}

			.Frc-popup .header .back {
				cursor: pointer;
			}

		.Frc-popup .body {
			float: left;
			width: 100%;
			padding: 10px;
			overflow-y: auto;
			max-height: calc(100% - 50px);
		}


	@keyframes FrcPopupAnimation {
		0% {
			opacity: 0;
			transform: translateX(-250px);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
</style>
