//GeneratorFlag:[NoChange]
import { BaseInvoiceGetInvoiceDetail } from '@/modules/shop/base/data/actions/invoice/get-invoice-detail'
export default class InvoiceGetInvoiceDetail extends BaseInvoiceGetInvoiceDetail
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			invoiceSerial: {
				...this.props.invoiceSerial,
				setConfig:{
					...this.props.invoiceSerial.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			paybleAmount: {
				...this.props.paybleAmount,
				setConfig:{
					...this.props.paybleAmount.setConfig
				}
			},
			totalAmont: {
				...this.props.totalAmont,
				setConfig:{
					...this.props.totalAmont.setConfig
				}
			},
			discountAmount: {
				...this.props.discountAmount,
				setConfig:{
					...this.props.discountAmount.setConfig
				}
			},
			paidAmount: {
				...this.props.paidAmount,
				setConfig:{
					...this.props.paidAmount.setConfig
				}
			},
			totalRemainAmount: {
				...this.props.totalRemainAmount,
				setConfig:{
					...this.props.totalRemainAmount.setConfig
				}
			},
			transportCostAmount: {
				...this.props.transportCostAmount,
				setConfig:{
					...this.props.transportCostAmount.setConfig
				}
			},
			packagingCostAmount: {
				...this.props.packagingCostAmount,
				setConfig:{
					...this.props.packagingCostAmount.setConfig
				}
			},
			taxAmount: {
				...this.props.taxAmount,
				setConfig:{
					...this.props.taxAmount.setConfig
				}
			},
			displayCheckoutDate: {
				...this.props.displayCheckoutDate,
				setConfig:{
					...this.props.displayCheckoutDate.setConfig
				}
			},
			invoiceStatuseName: {
				...this.props.invoiceStatuseName,
				setConfig:{
					...this.props.invoiceStatuseName.setConfig
				}
			},
			invoicePaymentTypeName: {
				...this.props.invoicePaymentTypeName,
				setConfig:{
					...this.props.invoicePaymentTypeName.setConfig
				}
			},
			storeName: {
				...this.props.storeName,
				setConfig:{
					...this.props.storeName.setConfig
				}
			},
			isConnected: {
				...this.props.isConnected,
				setConfig:{
					...this.props.isConnected.setConfig
				}
			},
			personAddressCountryName: {
				...this.props.personAddressCountryName,
				setConfig:{
					...this.props.personAddressCountryName.setConfig
				}
			},
			personAddressProvinceName: {
				...this.props.personAddressProvinceName,
				setConfig:{
					...this.props.personAddressProvinceName.setConfig
				}
			},
			personAddressCity: {
				...this.props.personAddressCity,
				setConfig:{
					...this.props.personAddressCity.setConfig
				}
			},
			reciverFirstName: {
				...this.props.reciverFirstName,
				setConfig:{
					...this.props.reciverFirstName.setConfig
				}
			},
			reciverLastName: {
				...this.props.reciverLastName,
				setConfig:{
					...this.props.reciverLastName.setConfig
				}
			},
			reciverMobile: {
				...this.props.reciverMobile,
				setConfig:{
					...this.props.reciverMobile.setConfig
				}
			},
			fullAddress: {
				...this.props.fullAddress,
				setConfig:{
					...this.props.fullAddress.setConfig
				}
			},
			plaque: {
				...this.props.plaque,
				setConfig:{
					...this.props.plaque.setConfig
				}
			},
			postalCode: {
				...this.props.postalCode,
				setConfig:{
					...this.props.postalCode.setConfig
				}
			},
			items: {
				...this.props.items,
				setConfig:{
					...this.props.items.setConfig
				}
			},
		};
	}
}

