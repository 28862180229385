//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { WalletService, WalletTransactionTypeService, WalletTransactionStatuseService } from '@/modules/financial/services'; 
export default class WalletTransaction extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'walletTransaction';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'financial.walletTransactionUpdate.self';
	props = {
		walletTransactionId: {
			primarykey: true,
			name: 'walletTransactionId',
			resourceKey: 'financial.walletTransactionUpdate.walletTransactionId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'financial.walletTransactionUpdate.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		walletId: {
			foreignKey: true,
			name: 'walletId',
			resourceKey: 'financial.walletTransactionUpdate.walletId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new WalletService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'walletId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		amount: {
			name: 'amount',
			resourceKey: 'financial.walletTransactionUpdate.amount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		refCode: {
			name: 'refCode',
			resourceKey: 'financial.walletTransactionUpdate.refCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		walletTransactionTypeId: {
			foreignKey: true,
			name: 'walletTransactionTypeId',
			resourceKey: 'financial.walletTransactionUpdate.walletTransactionTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new WalletTransactionTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'walletTransactionTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		walletTransactionStatuseId: {
			foreignKey: true,
			name: 'walletTransactionStatuseId',
			resourceKey: 'financial.walletTransactionUpdate.walletTransactionStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new WalletTransactionStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'walletTransactionStatuseId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'financial.walletTransactionUpdate.caption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		commitedDate: {
			name: 'commitedDate',
			resourceKey: 'financial.walletTransactionUpdate.commitedDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		displayCommitedDate: {
			name: 'displayCommitedDate',
			resourceKey: 'financial.walletTransactionUpdate.displayCommitedDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 16,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		fromResouce: {
			name: 'fromResouce',
			resourceKey: 'financial.walletTransactionUpdate.fromResouce',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}