
export default {
    "global": {
        "min": "Minimum",
        "max": "Maximum",
        "yes": "Yes",
        "no": "No",
        "continueRegistration": "To Continue Registration, Complete The Form Below",
        "aboutUs": "About Us",
        "personInfo": "Person Info Is",

        "login": "Login",
        "createAccont": "Create Accont",
        "back": "Back",
        "next": "Next",
        "save": "Save",
        "close": "Close",
        "contactUs": "Contact Us",
        "support": "Support:",
        "send": "Send",
        "category": "Category",
        "wait": "Please Wait...",
        "addToCart": "Add To Card",
        "removeFromCart": "Remove From Card",
        "notExist": "This Product Is Not Exists Now",
        "logOut": "Logout",
        "wallet": "Wallet",
        "search": "Search",
        "followUs": "Follow Us",
        "welcome": "WelCome"
    },
    "menu": {
        "name": "Content",
        "contactUs": "ContactUs",
        "aboutUs": "AboutUs",
        "dashbord": "Dashbord",
        "category": "Category",
        "login":"Login"
    },
    "views": {
        "layout": {
            "titleFRC": "Road Map",
            "homeSlider1FRC": "Coming soon",
            "homeSlider2FRC": "(Stock market)The stock market is one of the oldest and most common types of financial markets in the world.In this type of market, shares of certain companies are bought and sold publicly by different investors.The purpose of selling shares of a company is to increase the amount of capital and liquidity of companies in order to develop their business.When a company's shares are offered for sale in the stock market for the first time, an 'initial stock offering' or (IPO) is held and investors who believe in the activity of that company buy these shares. With the activity of the company and the provision of services or goods and the development of that business, the value of that company's shares will gradually increase and the investors will benefit in this way.In general, buying stocks is a simple task, but distinguishing the desired stocks from among hundreds of companies, as well as the right time to buy, is a relatively difficult task.Stocks are bought and sold on special platforms.Traders can operate in the stock market both in person at the stock exchange organization and offline using intermediary platforms called 'Brokers'.The broker provides the infrastructure for buyers and sellers to access the listed information.New York Stock Exchange(NYSE), Chicago Stock Exchange(CHX), Nasdaq and IRAs are some examples of the world's largest financial markets in the United States.",
            "homeSlider3FRC": "Bond Market'Bonds' are a type of securities that are provided to applicants by capitalists in the form of a loan with a specified period and interest rate.In other words, bonds can be considered a kind of agreement between the lender and the borrower, in which all the terms of the loan are stated.This type of securities is mostly issued by large companies; But municipalities, states and some independent government organizations can also issue and sell bonds.The purpose of selling bonds is to provide necessary capital for various projects and operations.Since bond buyers become borrowers and debtors, this market is also called Debt Market, Credit Market or Fixed- income Market",
            "homeSlider4FRC": "Derivative MarketDerivatives or derivative assets are a contract between two or more traders on the value of one or a set of agreed assets such as stocks, commodities or...In the derivatives market, real assets are not bought and sold; In fact, what is bought and sold by traders is derived from the value of another asset such as a commodity, stock, index, or digital currency such as Bitcoin and follows its price.So a derivative asset alone has no intrinsic value and its price is linked and related to the value of another asset.By using these facilities, you can trade bilaterally on anything such as stocks, bonds, commodities, common currency of countries, interest rates, market index, etc.It means that you can profit from both rising and falling prices. ",
            "homeSlider5FRC": "Foreign exchange market'Foreign Exchange Market' or 'Foreign Exchange', which is also known by its shortened term, refers to a market where common currencies of countries such as dollars, euros, pounds, yen, crowns, francs, etc.are exchanged.The foreign exchange market is considered a decentralized market and consists of a set of computer systems and brokers around the world.Banks, advertising companies, central banks, capital management companies and hedge funds are among the most important players in the foreign exchange market.Also, brokers(the same brokers) and other investors and even ordinary people can operate in the foreign currency exchange market and exchange the currencies of different countries.",
            "homeSlider6FRC": "Commodity market is another type of financial market and as its name suggests, it is a place for buying and selling physical assets and goods.These assets are traded in various sectors including agricultural products, energy and fossil fuels, precious metals and soft commodities.These markets are also called(spot market); Because traders get real goods in exchange for money.It should be noted that most of the volume of transactions related to the commodity market actually takes place in the derivatives market.Because it is easier to trade in the derivatives market and traders will have less responsibilities.(Chicago Mercantile Exchange = CME) and the market(Intercontinental Exchange = ICE) are among the most famous commodity markets in America.",
            "homeSlider7FRC": "(Cryptocurrency Market)In the last decade, the world of technology has witnessed the birth and significant growth of the decentralized technology of 'Distributed Ledger'(DLT) and its widely used example, namely(Blockchain).This technology is the platform for the implementation of Cryptocurrency projects, or in other words, digital currencies, the most famous of which are Bitcoin and Ethereum.Today, there are more than ten thousand different projects in this field, and the coin, or the digital asset related to each project, is bought and sold in different exchanges that make up the cryptocurrency market.Digital currencies are not physical in nature and are only stored in digital wallets on the blockchain.On the other hand, most of the transactions in this field are done in centralized exchanges.Also, all types of option contracts and futures are offered in major digital currency exchanges.",
            "aboutUsFooter": "We and our partners store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device for personalised ads and content, ad and content measurement, and audience insights, as well as to develop and improve products. With your permissio",
            "aboutUsFRC": "The world of cryptocurrencies is always changing and evolving, and in this regard, various blockchain projects are always offered. The purpose of creating different cryptocurrencies is generally to create a functional network or system or to transfer money. FRC is also one of these new projects. FRC belongs to the future road business group, which started working with the aim of investing and developing various projects in the field of artificial intelligence. There are many ideas in people's minds that never become reality due to lack of necessary capital or lack of expertise. We are trying to support these ideas and make them profitable..",
            "category": " Product Category",
            "title": "Test Title",
            "titleInstall": "Installation Guide",
            "loremFa": "Lorem ipsum dolor sit amet. Qui repellat voluptatem ut rerum tempora sit officia porro est beatae atque sed porro sapiente. Vel placeat minima et corrupti provident et velit animi aut accusamus odio ut consequatur animi sit architecto voluptatibus. Sed porro ratione eum fugiat sunt At ullam facilis. Ab galisum veritatis in officia expedita aut ipsa consequuntur ut adipisci reiciendis. Aut numquam dolore ut culpa voluptatum aut ducimus dolore quo consectetur consequatur est nihil rerum id fugiat voluptatem. Ut rerum aperiam ut voluptas aliquam eum dolorem modi ea recusandae rerum aut optio nulla sit tenetur voluptatum. Aut architecto voluptate et perspiciatis voluptatem aut sequi enim et dolores nulla. Et eaque ipsa ab suscipit culpa hic magnam cumque vel voluptas cupiditate in expedita commodi? Est mollitia dolorem ea magnam voluptas ut officiis libero ea delectus aliquid. 33 illum Quis aut omnis voluptatem et nihil recusandae qui dolorum quia aut possimus repudiandae eum harum tenetur non earum delectus. Et excepturi consectetur et voluptas nihil et autem fuga ea atque blanditiis.  ",
            "loremFa2": "Lorem ipsum dolor sit amet. Id deleniti fugiat et minima nisi est quia rerum eum veritatis recusandae ut modi consequatur et autem accusantium sed Quis fugiat. In galisum illo ea nihil impedit sit dolorem corporis aut dignissimos deleniti aut omnis voluptates. ",
            "discriptionInstall": " You Can Install Test By Choosing Your Device Type To Have afster Access ",
            "android": "Android",
            "ios": "ios",
            "web": "Web",
            "installAnd": `
            <ul>
                <li> For Install Progressive Web Appliction In Android </li>
                <li>Go to Test.Org With Google Chrome Explorer</li>
                <li>Waiting For Show Installer Suggestion On Your Screen(This Step Need To Be Paitient And Keep Your Screen On While Download Completly Application In Backgournd)</li>
            </ul>`,
            "installIos": `
            <ul>
                <li> In Safari Explorer Going To Test.org </li>
                <li> Click On “Add To Home Screen”. </li>
                <li> Choose “Add” Button. </li>
                <li> FRC Icon Has Been Added To Your Apps Screen Now. Click On It To Open The App </li>
            </ul>`,
            "installWeb": `
            <ul>
                <li> For Install Progressive Web Appliction In Desktop </li>
                <li>Go To Test.Org With Google Chrome Explorer</li>
                <li> Waiting For Show Installer Suggestion On Your Explorer(This Step NeedTto Be Paitient For Download Completly Application In Backgournd)</li>
            </ul>`
        },
        "auth": {
            "AddressWalletDepisit": "Address Wallet Deposit",
            "hashTarakonesh": "Hash Taransaction",
            "AddressWallet": "Address Wallet",
            "InvestmentAmount": "Investment Amount",
            "codeEmail": "Please enter the code sent to your email",
            "userName": " User Name",
            "password": " Password",
            "repeatPassword": "Repeat Password",
            "forgetPassword": "Forget Password",
            "password": "PassWord",
            "repeatPassword": " Repeat Password ",
            "forgetPassword": "Forget Your PassWord?",
            "personCode": "Your Sponsor ID",
            "personInvoiceCode": "Your Sponsor ID For referential Plan(2)",
            "code": " Please Enter Your Code",
            "email": " Please Enter Your Email",
            "recovery": "Recovery",
            "recoverPassword": "Please Check Your Email And Click On The Link Sent To Recover Your Password",
            "enterPassword": "Please Enter Your New Password",
            "passwordRecoverySuccessfully": "Password Recovery Operation Completed Successfully",
            "end": "Login",
            "yourCode": "Your sponsor ID:",
            "backToHome": "Back To Home",
            "personInfo": "Sponsor Information is Correct?",
            "fillCreateAccount": "Fill Out The Form Below To Create An Account",
            "firstName": "First Name",
            "lastName": "Last Name",
            "selectGender": "Please Select Gender",
            "men": "Men",
            "femal": "Femal",
            "mobile": "Mobile",
            "emailTitle": "Email",
            "passportNo": "PassportNo",
            "confirmedPassword": "Confirmed Password",
            "cityOfResidence": "City ​​Of Residence",
            "heirName": "Please Enter Your Heir Name",
            "descriptionHeirName": "The Person Who Legally Owns Your Account After You.",
            "documentsRegistration": "Please Upload The Required Documents To Complete The Registration"
        },
        "page": {
            "discriptionContact": "If You Need Advice Or Encounter A Problem, Contact Us",
            "direct": "Contact To Admin",
            "email": "Email:",
            "firstName": "First Name",
            "lastName": "Last Name",
            "phone": "Phone",
            "emailPerson": "Email",
            "caption": "Caption",
            "price": "Price:",
            "inventory": "Inventory:",
            "colors": "Colors:",
            "captionProduct": "Caption Product"
        },
        "planTransactionGetReward": {
            "displayDate": "Date"
        },
        "dashbord": {
            "increasetransactions":"Trans",
            "removeContract":"Cancel Contract",
            "tickets":"Tickets",
            "displayDate": "Date",
            "totalCommision": "Total",
            "finalCommision": "Final",
            "statusName": "Status",
            "typeName": "Type",
            "myReward": "My Reward",
            "profit":"Profit",
            "information": "Information",
            "secuirtyCode": "Security Code",
            "walletAddress": "Wallet Address",
            "walletName": "Wallet Name",
            "myWallet": "My Wallet",
            "myContracts": "My Contracts",
            "chartGeno": "Genology",
            "tree": "Tree Organization",
            "towallet": "To This Wallet",
            "fromWallet": "From This Wallet",
            "PromotionInvestmentWallet": "Promotion Of Investment Wallet",
            "wallet": "Wallet",
            "amountValue": "Amount Value",
            "cashWithdrawalWallet": "Cash Withdrawal Wallet",
            "name": "First Name",
            "marketerId": "Marketer Id",
            "memberId":"MemberID",
            "charge": "Wallet Amount",
            "tokenCount":"Token Count",
            "InvestmentAmount": "Investment Amount",
            "account": "Account",
            "dashbord": "Dashbord",
            "information": "Information",
            "changePass": "Change PassWord",
            "IncreaseInvestmentWallet": "Increase Investment",
            "TokenPurchase":"Token Purchase",
            "withdrawWallet": "Withdraw Wallet",
            "transactionDate": "Transaction Date",
            "team": "Team",
            "directSell": "Direct Sell",
            "order": "Order",
            "myOrders": "My Orders",
            "award": "Award",
            "conect": "Invoice Transfer",
            "voacher": "Voacher",
            "listVoacher": "List Voacher",
            "passwordRecovery": "Password Recovery",
            "enterPass": "Enter PassWord",
            "repeatPass": "Repeat PassWord",
            "creditValue": "Credit Value",
            "amountWithdraw": "Amount for withdrawal",
            "refCode": "Ref Code",
            "caption": "Caption",
            "myCreadit": "My Creadit Transaction",
            "directSellInfo": "Direct Sales Information",
            "peopleRegister": "The Number Of People You Can Register",
            "awardList": "Award List",
            "conectOther": "Conect To Other",
            "selectInvoice": "Select Invoice",
            "select person": "Select Person",
            "yourAccountConfirmed": "Your account is pending. Please wait until the manager approves",
            "descriptionMainWallet": `
            <ul class="list-group">
                                <li>Credit Value: The Amount of dollars you have recharged (from the Zain Cash application)</li>
                                <li>Ref Code: The Refrence code given to you For Tracking(from the Zain Cash application)</li>
                                <li>Transaction Date: Date of deposit to the company account</li>
                                <li>Caption: Your description for the system approving admin</li>
                            </ul>`,
            "descriptionTransaction": `
            <ul class="list-group">
							<li>Caption: A description Of Your Transaction</li>
							<li>Ref Code: The Refrence code given to you For Tracking</li>
							<li>Date: Date Of Record</li>
							<li>Value: The amount of your transaction is in dollars($)</li>
							<li>
								Statuse:<br />
								Types of your transaction status:<br />
								1. (Awaiting approval) if it is awaiting approval (you must wait until it is approved by the administrator)<br />
								2. (Approved) if it is approved by the manager or your transaction system
							</li>
							<li>
								Type:<br />
								Types of your transaction type: 2 modes are included here<br />
								1. (increase) if your transaction type is account deposit<br />
								2. (Decrease) if your transaction type is withdrawal to account
							</li>
						</ul> `,
            "descriptionDirectSales": `
            <ul class="list-group">
							<li>Person name: The Name and Family who registered through your FRCCode</li>
							<li>Person code: The PoarisCode who registered through your FRCCode</li>
							<li>Total Sell: Total sales That Registered</li>
							<li>Total Score: Total Score That Registered</li>
							<li>SavedValue: Amount stored in your savings account in dollars($)</li>
						</ul>`,
            "descriptionCreadit": `
            <ul class="list-group">
            <li>
                Payment Type:<br/>
                The type of payment includes 2 modes:<br />
                1. (By Credit): when you pay the invoice from your online wallet<br />
                2. (online Payment) when you connect to the payment portal online and pay the invoice amount
            </li>
            <li>Store: The store or branch where your purchase was registered</li>
            <li>Description: Description of the invoice registered by you</li>
            <li>
                Status:<br />
                Your invoice status includes 2 modes:<br />
                1. (paid) when you have paid your invoice amount (via online payment or payment from your online wallet)<br />
                2. (Unpaid): In this case, your invoice has not been paid. You must pay your invoice as soon as possible, otherwise, your invoice will be deleted
            </li>
            <li>
                Total Payble:<br />
                Payable amount: the final amount that you have to pay (after applying financial items such as system discounts, taxes, etc.)
            </li>
            <li>Total Remain: The remaining amount of your invoice that has not yet been paid</li>
            <li>Total Amount: The sum total of the invoice without applying financial items</li>
            <li>Unit Currency: The currency registered for your invoice</li>
            <li>Transport Cost: The transport cost of Your Invoice</li>
            <li>Serial: It is a unique field and is a very important option for your follow-up</li>
        </ul>`,
            "descriptionAward": `
                        <ul class="list-group">
							<li>Total Commision: The total amount of your commission calculated by the system (this is not the final amount to be paid to you)</li>
							<li>Final commision:The final amount of your commission that will be paid to you</li>
							<li>Line1: Total sales recorded in your largest branch</li>
							<li>Line2: Total sales recorded in your second largest branch</li>
							<li>Date: Date of calculation of your commission by the system</li>
						</ul>`,
            "descriptionWallet": `
                        <ul class="list-group">
									<li>
                                    Invoice Selection:<br/>
                                    In This Drop-Down List, You Will See The List Of Factors That Can Be Connected To Other Branches<br />
                                    If There Is No Option To Choose, It Means that you do not have an invoice that meets the transfer conditions. 
									</li>
									<li>
                                    Person Selection:<br />
                                    In this drop-down list, you will have the list of people who have registered in the system with your code (only one person can be selected by you)<br />
                                    If you connect an invoice to someone, that invoice will be removed from the list of transferable invoices (but it will be in the list of my orders).<br />
                                    Person selection: In this drop-down list, you will have the list of people who have registered 
									</li>
								</ul>`,
            "descriptionVoacher": `
                        <ul class="list-group">
                                   <li>Final Voacher: Voucher Amount Calculated On Specified Date And Assigned To You</li>
                                    <li>Date: Date Of Calculation Of Your Voucher</li>
                        </ul>`
        }
    },
    "errors": {
        "minimumWalletWithdraw": "Minimum Amount to Withdraw is 20 USDT But Your Credit is: ",
        "invalidHash": "The Entered Hash Is Not Correct.Transaction Hash have 64 Character",
        "more": "The Input Phrase Exceeds The Limit",
        "succes": "The Desired Operation Was Completed Successfully",
        "secuirtyCodeRequired": "SecurityCode Is Required",
        "walletAddressRequired": "Wallet Address Is Required",
        "walletNameRequired": "Wallet Name Is Required",
        "emailInvalid": "Email Is Invalid",
        "investmentAmount": "Investment Amount Reqired",
        "userNameLength": "Username Must Be More Than 6 Characters",
        "userNameRequired": " User Name is required",
        "passRequired": 'Password Is Required',
        "investmentAmountMinMax": "The Investment Amount Should Be Between 60 And 1.010 $",
        "lengthMore": "More Characters  Than Allowed",
        "length": "Enter More Than 6 Characters",
        "invalidCodeSponsor": "invalid Sponsor Id",
        "invalidCode": "invalid Code",
        "notValidateCode": "not Validate Code",
        "waitApproval": "Please Wait For The Administrator's Approval",
        "creditRequired": "credit Value Is Required",
        "fromSourceWalletRequired": "Wallet Is Required",
        "toSourceWalletRequired": "Destination Wllet Is Required",
        "refRequired": "Ref Code Is Required",
        "transactionDatRrequired": "Transaction Date IS Rrequired ",
        "notLogged": "You Are Not Logged",
        "currentPassRequired": "Current Password Is Required",
        "newPassRequired": "New Password Is Required",
        "confirmedPassRequired": "Password Confirmation Is Required",
        "invalideCurrentPass": "Password Is Invalid",
        "notAccessChangePassword": "You Do Not Have Access To This Permision",
        "passWordRepetitionNotSame": "Repeated Password Is Invalid",
        "personRequid": "Person Is Required",
        "invoiceRequid": "Invoice Is Required",
        "transferSuccessful": "Transfer Done",
        "paymentEncounteredError": "Your Payment Encountered An Error",
        "pleaseChargeYourAccount": "please Charge Your Account",
        "cahrgeAccont": "Charge The Account",
        "nameIsRequired": "FirstName Is Required",
        "lastNameIsRequired": "LastName Is Required",
        "mobilRequired": "Mobil Is Required",
        "emeilRequired": "Email Is Required",
        "passportNoRequired": "PassportNo Is Required",
        "phoneNumberIsRequired": "phone Number Is Required",
        "provinceIsRequired": "Province Is Required",
        "cityIsRequired": "City Is Required",
        "postalCodeIsRequired": "postal Code Is Required",
        "personGenderldRequired": "Person Genderld is Required",
        "plaqueIsRequired": "Plaque Is Required",
        "addressIsRequired": "Address Is Required",
        "personCodeRequired": "Person Code Is Required",
        "heirNameRequired": "heir Name Is Required",
        "invalidPersonCode": "Invalid Person Code",
        "passwordRequired": "Password Is Required",
        "confirmedPasswordRequired": "ConfirmedPassword Is Required",
        "passwordAndConfirmedPasswordNotMatch": "Password And Confirmed Password Not Match",
        "passportImageFileRequired": "Passport Image File Is Required",
        "representativeNotFound": "representative Not Found",
        "youHaveAlreadyRegisteredWithThisProfile": "You Have Already Registered With This Profile",
        "invalidFile": "The File Is Invalid",
        "personCode": "SponsorID is Required"


    },
    "card": {
        "card": "Card",
        "userInformation": "User Information",
        "invoicePreview": "Invoice Preview",
        "payment": "Payment",
        "shoppingEmpty": "Your Shopping Cart Is Empty",
        "returnMainPage": "Return To Main Page",
        "yourShoppingCart": "Your Shopping Cart",
        "Items": "Items",
        "productName": "product Name:",
        "count": "count:",
        "price": "Price:",
        "color": "Color:",
        "storeName": "Store Name:",
        "inventory": "Inventory:",
        "delivery": "Delivery:",
        "freeDelivery": "Free Delivery",
        "priceQuantity": "Price Quantity:",
        "completingCart": "Completing Cart",
        "numberShoppingCart": "Number Shopping Cart:",
        "totalShoppingCart": "Total Shopping Cart:",
        "shippingCostDescription": "The Shipping Cost Is Calculated Based On The Address, Delivery Time, Weight And Volume Of Your Shipment.",
        "name": "Name:",
        "lastName": "Last Name",
        "phoneNumber": "Phone Number:",
        "address": "Address",
        "plaque": "Plaque",
        "postalCode": "Postal Code",
        "Selected": "Selected",
        "Select": "Select",
        "addNewAddress": "Add New Address",
        "receiverInformation": "Receiver Information",
        "explanationDescribe": "If Your Invoice Needs More Explanations For Us, Enter It In The Box Below",
        "goodsPurchased": "Number Of Goods Purchased:",
        "Item": "Item",
        "postType": "Post Type:",
        "normalDelivery": "Normal Delivery",
        "shippingCost": "Shipping Cost",
        "free": "Free",
        "nameProduct": "Product Name:",
        "secendPrice": "Secend Price:",
        "returnToCart": "Return To Cart",
        "finalPayment": "Final Payment",
        "personCode": "Person Code",
        "customerInformation": "Customer Information",
        "mobile": "Mobile:",
        "address": "Address",
        "invoiceInformation": "Invoice Information",
        "paybleAmount": "Payble Amount:",
        "totalAmont": "Total Amount:",
        "discountAmount": "Discount Amount",
        "paidAmount": "Paid Amount:",
        "totalRemainAmount": "Total Remain Amount:",
        "transportCostAmount": "Transport Cost Amount:",
        "packagingCostAmount": "Packaging Cost Amount:",
        "taxAmount": "Tax Amount:",
        "shopInformation": "Shop Information",
        "serialFactor": "Serial Factor:",
        "invoicePaymentTypeName": "Invoice Payment Type Name:",
        "orderList": "Order List",
        "titleProductName": "Product Name",
        "productColor": "Product Color",
        "titleSerialFactor": "Serial Factor",
        "productCount": "Product Count",
        "basePrice": "Base Price",
        "totalPrice": "Total Price",
        "paymentSuccessfullyCompleted": "Your Payment Has Been Successfully Completed",
        "showInvoice": "Show Invoice",
        "dashbord": "Dashbord",
        "shop": "Shop",
        "firstName": "First Name",
        "lastName": "Last Name",
        "phoneNumber": "Phone Number",
        "receiverAddress": "Receiver Address",
        "provinceOfResidence": "Province Of Residence",
        "cityOfResidence": "City Of Residence",
        "enterTheAddress": "Enter The Address",
        "titlePostalCode": "Postal Code",
        "titlePlaque": "Plaque",
        "add": "Add"
    },
}