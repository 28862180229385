//GeneratorFlag:[NoChange]
import { BaseProvince } from '../base/data'
export default class Province extends BaseProvince
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			provinceId: {
				...this.props.provinceId,
				setConfig:{
					...this.props.provinceId.setConfig
				}
			},
			countryId: {
				...this.props.countryId,
				setConfig:{
					...this.props.countryId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			country: {
				...this.props.country,
				setConfig:{
					...this.props.country.setConfig
				}
			},
			provinceSubInfo: {
				...this.props.provinceSubInfo,
				setConfig:{
					...this.props.provinceSubInfo.setConfig
				}
			},

		};
	}
}
