//GeneratorFlag:[NoChange]
export default {
	path: 'wallet-with-draw',
	name: 'financial.walletWithDraw.layout',
	redirect: to => {
		return {name: 'financial.walletWithDraw.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/wallet-with-draw"],
		resourceKey: 'financial.views.walletWithDraw.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[WalletWithDrawCreate]"
		{
			path: 'create',
			name: 'financial.walletWithDraw.create',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.create',
				clientAccessKeys:["/financial/wallet-with-draw/create"],
				serverAccessKeys:["/financial/wallet-with-draw/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletWithDrawCreateImport]"
		{
			path: 'create-import',
			name: 'financial.walletWithDraw.createImport',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.createImport',
				clientAccessKeys:["/financial/wallet-with-draw/create-import"],
				serverAccessKeys:["/financial/wallet-with-draw/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletWithDrawDelete]"
		{
			path: 'delete',
			name: 'financial.walletWithDraw.delete',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.delete',
				clientAccessKeys:["/financial/wallet-with-draw/delete"],
				serverAccessKeys:["/financial/wallet-with-draw/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletWithDrawDetail]"
		{
			path: 'detail',
			name: 'financial.walletWithDraw.detail',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.detail',
				clientAccessKeys:["/financial/wallet-with-draw/detail"],
				serverAccessKeys:["/financial/wallet-with-draw/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletWithDrawList]"
		{
			path: 'list',
			name: 'financial.walletWithDraw.list',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.list',
				clientAccessKeys:["/financial/wallet-with-draw/list"],
				serverAccessKeys:["/financial/wallet-with-draw/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletWithDrawUpdate]"
		{
			path: 'update',
			name: 'financial.walletWithDraw.update',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.update',
				clientAccessKeys:["/financial/wallet-with-draw/update"],
				serverAccessKeys:["/financial/wallet-with-draw/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}