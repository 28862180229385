//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonAppPurchaseService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(personAppPurchaseId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:personAppPurchaseId
		}

		return await _server.get('/people-net/person-app-purchase/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/people-net/person-app-purchase/save', params || {})
	};

	async delete(personAppPurchaseId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:personAppPurchaseId
		}

		return await _server.delete('/people-net/person-app-purchase/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/people-net/person-app-purchase/list', {'params':params})
	};
}
