
export default {
	"name": "Marketer",
	"ext": {
		"common": {
			"fromDate": "From Date",
			"toDate": "To Date",
		}
	},
	"views": {
		"action": {
			"layout": "Operation",
			"treeGeno": "Tree Genology",
			"ordGeno": "Chart Genology",
		}
	}
}