//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { TicketingPerson, TicketStatuse } from '@/modules/ticketing/data/ticket/list'; 
import { PersonService } from '@/modules/people-net/services'; 
import { TicketStatuseService } from '@/modules/ticketing/services'; 
export default class Ticket extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'ticket';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'ticketing.ticketList.self';
	props = {
		ticketId: {
			primarykey: true,
			name: 'ticketId',
			resourceKey: 'ticketing.ticketList.ticketId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'ticketing.ticketList.personId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'firstName',
				valueKey: 'personId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personInfo: {
			name: 'personInfo',
			resourceKey: 'ticketing.ticketList.personInfo',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		email: {
			name: 'email',
			resourceKey: 'ticketing.ticketList.email',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 150,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		ticketStatuseId: {
			foreignKey: true,
			name: 'ticketStatuseId',
			resourceKey: 'ticketing.ticketList.ticketStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new TicketStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'ticketStatuseId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		title: {
			name: 'title',
			resourceKey: 'ticketing.ticketList.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		lastUpdate: {
			name: 'lastUpdate',
			resourceKey: 'ticketing.ticketList.lastUpdate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		displayLastUpdate: {
			name: 'displayLastUpdate',
			resourceKey: 'ticketing.ticketList.displayLastUpdate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 15,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		person: {
			name: 'person',
			resourceKey: 'ticketing.ticketList.person.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new TicketingPerson(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{firstName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		ticketStatuse: {
			name: 'ticketStatuse',
			resourceKey: 'ticketing.ticketList.ticketStatuse.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new TicketStatuse(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		ticketSubInfo: {
			name: 'ticketSubInfo',
			resourceKey: 'ticketing.ticketList.ticketSubInfo',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}