import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import Localization from '../localization'
export default class Router {
    constructor(modules) {
        this.modules = modules || [];
        this.loc = new Localization();
        this.layout = store.state.app.layout

        this.routes = [
            {
                path: '/',
                component: () => import('@/views/default/shareds/layout.vue'),
                meta: {
                    requiresAuth: false,
                    resourceKey: 'page.Frc',

                    menuConfig: {
                        isVisible: true,
                        isExpand: true,
                        isBlank: true,
                        alwaysIsExpand: true,
                        iconName: 'bi-layout-wtf',
                    }

                },
                children: [],
            },
            {
                path: '/chart-geno',
                component: () => import('@/views/default/dashbord/team/genology.vue'),
                meta: {
                    resourceKey: 'page.genology',
                }
            },
            {
                path: '/auth',
                component: () => import('@/views/default/auth/Auth.vue'),
                meta: {
                    requiresAuth: false,
                    resourceKey: 'page.auth',
                    menuConfig: {
                        visible: false
                    }
                },
                children: [

                    {
                        path: '/auth/auth-login',
                        component: () => import('@/views/default/auth/auth-login.vue'),
                        meta: {
                            resourceKey: 'page.authLogin',
                        }

                    },

                    {
                        path: '/auth/auth-register',
                        component: () => import('@/views/default/auth/auth-register.vue'),
                        meta: {
                            resourceKey: 'page.register',

                        }
                    },
                    {
                        path: '/auth/auth-get-code',
                        component: () => import('@/views/default/auth/auth-get-code.vue'),
                        meta: {
                            resourceKey: 'page.getCode',
                            menuConfig: {
                                visible: false
                            }
                        }
                    },
                    {
                        path: '/auth/auth-forget-password',
                        component: () => import('@/views/default/auth/auth-forget-password.vue'),
                        meta: {
                            resourceKey: 'page.forgetPass',
                            menuConfig: {
                                visible: false
                            }
                        }
                    }


                ],
            },

            {
                path: '/dashbord',
                component: () => import("@/views/default/dashbord/dashbord.vue"),
                meta: {
                    resourceKey: 'page.dashboard',

                    requiresAuth: true,
                    menuConfig: {
                        visible: false
                    }
                },
                children: [
                    {
                        path: '/dashbord/pending',
                        component: () => import('@/views/default/dashbord/pendinguser.vue'),
                        meta: {
                            resourceKey: 'page.pending',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-information',
                        component: () => import('@/views/default/dashbord/account/information.vue'),
                        meta: {
                            resourceKey: 'page.informatio',
                        }

                    },
                    {
                        path: '/dashbord/dashbord-person-info',
                        component: () => import('@/views/default/dashbord/account/person-info.vue'),
                        meta: {
                            resourceKey: 'page.personInfo',
                        }

                    },
                    {
                        path: '/dashbord/dashbord-my-contracts',
                        component: () => import('@/views/default/dashbord/account/my-contracts.vue'),
                        meta: {
                            resourceKey: 'page.myContracts',
                        }

                    },
                    {
                        path: '/dashbord/dashbord-person-change-password',
                        component: () => import('@/views/default/dashbord/account/change-password.vue'),
                        meta: {
                            resourceKey: 'page.changPassword',
                        }
                    },
                    {
                        path: '/dashbord/Tickets',
                        component: () => import('@/views/default/dashbord/account/Tickets.vue'),
                        meta: {
                            resourceKey: 'page.tickets',
                        }
                    },
                    {
                        path: '/dashbord/cancelContract',
                        component: () => import('@/views/default/dashbord/account/cancelContract.vue'),
                        meta: {
                            resourceKey: 'page.removeContract',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-person-information',
                        component: () => import('@/views/default/dashbord/account/information.vue'),
                        meta: {
                            resourceKey: 'page.information',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-increase-investment-wallet',
                        component: () => import('@/views/default/dashbord/wallet/increase-investment-wallet.vue'),
                        meta: {
                            resourceKey: 'page.increaseInvestmentWallet',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-my-wallet',
                        component: () => import('@/views/default/dashbord/wallet/my-wallet.vue'),
                        meta: {
                            resourceKey: 'page.myWallet',
                        }
                    },
                    {
                        path: '/dashbord/wallet/transactions',
                        component: () => import('@/views/default/dashbord/wallet/transactions.vue'),
                        meta: {
                            resourceKey: 'page.increasetransactions',
                        }
                    },
                    {
                        path: '/dashbord/wallet/charge-wallet',
                        component: () => import('@/views/default/dashbord/wallet/charge-wallet.vue'),
                        meta: {
                            resourceKey: 'page.increasetransactions',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-reward-wallet',
                        component: () => import('@/views/default/dashbord/wallet/reward-wallet.vue'),
                        meta: {
                            resourceKey: 'page.myReward',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-withdraw-wallet',
                        component: () => import('@/views/default/dashbord/wallet/withdraw-wallet.vue'),
                        meta: {
                            resourceKey: 'page.withdrawWallet',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-wallet-transactions',
                        component: () => import('@/views/default/dashbord/wallet/transactions.vue'),
                        meta: {
                            resourceKey: 'page.transaction',
                        }
                    },
                    {
                        path: '/dashbord/dashbord-order-conect-to-other',
                        component: () => import('@/views/default/dashbord/order/conect-to-other.vue'),
                        meta: {
                            resourceKey: 'page.order',
                        }
                    },

                    {
                        path: '/dashbord/comming-soon',
                        component: () => import('@/views/default/dashbord/comming-soon.vue'),
                        meta: {
                            resourceKey: 'page.commingSoon',
                        }
                    },
                    {
                        path: '/dashbord/change-image',
                        component: () => import('@/views/default/dashbord/change-image.vue'),
                        meta: {
                            resourceKey: 'page.changeImage',
                        }
                    },
                    {
                        path: '/dashbord/chat-geno',
                        component: () => import('@/views/default/dashbord/team/genology.vue'),
                        meta: {
                            resourceKey: 'page.genology',
                        }
                    },
                    {
                        path: '/dashbord/tree-geno',
                        component: () => import('@/views/default/dashbord/team/tree.vue'),
                        meta: {
                            resourceKey: 'page.tree',
                        }
                    },


                ],
            },
            
             

 
            {
                path: '/aboutUs',
                component: () => import("@/views/default/page/about.vue"),
                meta: {
                    requiresAuth: false,
                    resourceKey: 'page.aboutUs',

                    menuConfig: {
                        visible: false
                    }
                },
            },
            {
                path: '/contact',
                component: () => import("@/views/default/page/contact.vue"),
                meta: {
                    requiresAuth: false,
                    resourceKey: 'page.contact',

                    menuConfig: {
                        visible: false
                    }
                },
            },
            {
                name: 'NotFound',
                path: '/:catchAll(.*)',
                component: () => import('@/views/NotFound'),
                meta: {
                    requiresAuth: false,
                    resourceKey: 'page.notFound',

                    menuConfig: {
                        visible: false
                    }
                }
            }
        ]
        for (var module of modules) {
            for (var route of module.routes) {
                this.routes[0].children.push(route)
            }
        }
    }
    create() {
        const router = createRouter({
            history: createWebHistory(),
            routes: this.routes
        })

        router.beforeEach((to, from, next) => {
            document.title =
                this.loc.fromResource(to.meta.resourceKey) + ' | ' +
                this.loc.fromResource('global.applicationName');


            if (to.matched.some(record => record.meta.requiresAuth)) {
                if (store.state.client.token == undefined) {
                    next({
                        path: "/auth/auth-login",
                        query: { redirect: to.fullPath }
                    });

                    return;
                }
            }
            next()
        })

        return router
    }
}
