//GeneratorFlag:[NoChange]
export default {
	path: 'product-category',
	name: 'shop.productCategory.layout',
	redirect: to => {
		return {name: 'shop.productCategory.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/product-category"],
		resourceKey: 'shop.views.productCategory.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductCategoryDelete]"
		{
			path: 'delete',
			name: 'shop.productCategory.delete',
			component: () => import('@/modules/shop/views/product-category/product-category-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productCategory.delete',
				clientAccessKeys:["/shop/product-category/delete"],
				serverAccessKeys:["/shop/product-category/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCategoryDetail]"
		{
			path: 'detail',
			name: 'shop.productCategory.detail',
			component: () => import('@/modules/shop/views/product-category/product-category-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productCategory.detail',
				clientAccessKeys:["/shop/product-category/detail"],
				serverAccessKeys:["/shop/product-category/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCategoryList]"
		{
			path: 'list',
			name: 'shop.productCategory.list',
			component: () => import('@/modules/shop/views/product-category/product-category-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productCategory.list',
				clientAccessKeys:["/shop/product-category/list"],
				serverAccessKeys:["/shop/product-category/list","/shop/category/list","/shop/product/list","/shop/culture/list","/shop/product-status/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCategoryCreate]"
		{
			path: 'create',
			name: 'shop.productCategory.create',
			component: () => import('@/modules/shop/views/product-category/product-category-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productCategory.create',
				clientAccessKeys:["/shop/product-category/create"],
				serverAccessKeys:["/shop/product-category/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCategoryImport]"
		{
			path: 'import',
			name: 'shop.productCategory.import',
			component: () => import('@/modules/shop/views/product-category/product-category-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productCategory.import',
				clientAccessKeys:["/shop/product-category/import"],
				serverAccessKeys:["/shop/product-category/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCategoryUpdate]"
		{
			path: 'update',
			name: 'shop.productCategory.update',
			component: () => import('@/modules/shop/views/product-category/product-category-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productCategory.update',
				clientAccessKeys:["/shop/product-category/update"],
				serverAccessKeys:["/shop/product-category/save","/shop/category/list","/shop/product/list","/shop/culture/list","/shop/product-status/list","/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






