<template>
	<div class="row mb-1 mb-md-2">
		<div :for="prop.name" class="col-4 col-md-3">
			<form-label :use-validation="useValidation" :prop="prop" />
		</div>
		<div class="col-8 col-md-9">
			<slot></slot>
			<form-error :prop="prop" />
		</div>
	</div>
</template>

<script>
	import formError from './form-error'
	import formLabel from './form-label'

	export default {

		components: {
			'form-error': formError,
			'form-label': formLabel
		},

		props:
		{
			'prop': {
				type: Object
			},

			'useValidation': {
				type: Boolean,
				default: true
			}
		}
	}
</script>
