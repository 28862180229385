//GeneratorFlag:[NoChange]
export default {
	path: 'document-type',
	name: 'financial.baseSys.documentType.layout',
	redirect: to => {
		return {name: 'financial.documentType.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/base-sys/document-type"],
		resourceKey: 'financial.views.documentType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DocumentTypeDetail]"
		{
			path: 'detail',
			name: 'financial.documentType.detail',
			component: () => import('@/modules/financial/views/document-type/document-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentType.detail',
				clientAccessKeys:["/financial/base-sys/document-type/detail"],
				serverAccessKeys:["/financial/document-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentTypeList]"
		{
			path: 'list',
			name: 'financial.documentType.list',
			component: () => import('@/modules/financial/views/document-type/document-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentType.list',
				clientAccessKeys:["/financial/base-sys/document-type/list"],
				serverAccessKeys:["/financial/document-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}