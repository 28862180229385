//GeneratorFlag:[NoChange]
import { BaseProductStore } from '../base/data'
export default class ProductStore extends BaseProductStore
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productStoreId: {
				...this.props.productStoreId,
				setConfig:{
					...this.props.productStoreId.setConfig
				}
			},
			productDetailId: {
				...this.props.productDetailId,
				setConfig:{
					...this.props.productDetailId.setConfig
				}
			},
			storeId: {
				...this.props.storeId,
				setConfig:{
					...this.props.storeId.setConfig
				}
			},
			firstPrice: {
				...this.props.firstPrice,
				setConfig:{
					...this.props.firstPrice.setConfig
				}
			},
			secondStorePrice: {
				...this.props.secondStorePrice,
				setConfig:{
					...this.props.secondStorePrice.setConfig
				}
			},
			productStoreInventory: {
				...this.props.productStoreInventory,
				setConfig:{
					...this.props.productStoreInventory.setConfig
				}
			},
			productScore: {
				...this.props.productScore,
				setConfig:{
					...this.props.productScore.setConfig
				}
			},
			maxSellCount: {
				...this.props.maxSellCount,
				setConfig:{
					...this.props.maxSellCount.setConfig
				}
			},
			minSellCount: {
				...this.props.minSellCount,
				setConfig:{
					...this.props.minSellCount.setConfig
				}
			},
			productDetail: {
				...this.props.productDetail,
				setConfig:{
					...this.props.productDetail.setConfig
				}
			},
			store: {
				...this.props.store,
				setConfig:{
					...this.props.store.setConfig
				}
			},
			productStoreSubInfo: {
				...this.props.productStoreSubInfo,
				setConfig:{
					...this.props.productStoreSubInfo.setConfig
				}
			},

		};
	}
}
