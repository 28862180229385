//GeneratorFlag:[NoChange]
import { BasePersonMobileValidationValidateMobile } from '@/modules/people-net/base/data/actions/person-mobile-validation/validate-mobile'
export default class PersonMobileValidationValidateMobile extends BasePersonMobileValidationValidateMobile
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			mobile: {
				...this.props.mobile,
				setConfig:{
					...this.props.mobile.setConfig
				}
			},
			countryCode: {
				...this.props.countryCode,
				setConfig:{
					...this.props.countryCode.setConfig
				}
			},
			validateCode: {
				...this.props.validateCode,
				setConfig:{
					...this.props.validateCode.setConfig
				}
			},
		};
	}
}

