//GeneratorFlag:[NoChange]
import { BasePersonCheckUpline } from '@/modules/people-net/base/data/actions/person/check-upline'
export default class PersonCheckUpline extends BasePersonCheckUpline
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			isValid: {
				...this.props.isValid,
				setConfig:{
					...this.props.isValid.setConfig
				}
			},
			uplineInfo: {
				...this.props.uplineInfo,
				setConfig:{
					...this.props.uplineInfo.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
		};
	}
}

