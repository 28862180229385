//GeneratorFlag:[NoChange]
import { BaseGetMyListInvoiceProductItemMessage } from '@/modules/shop/base/data/actions/invoice/get-my-list'
export default class GetMyListInvoiceProductItemMessage extends BaseGetMyListInvoiceProductItemMessage
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			productName: {
				...this.props.productName,
				setConfig:{
					...this.props.productName.setConfig
				}
			},
			porductCount: {
				...this.props.porductCount,
				setConfig:{
					...this.props.porductCount.setConfig
				}
			},
			productMonetaryFeatureTitle: {
				...this.props.productMonetaryFeatureTitle,
				setConfig:{
					...this.props.productMonetaryFeatureTitle.setConfig
				}
			},
			productColorTitle: {
				...this.props.productColorTitle,
				setConfig:{
					...this.props.productColorTitle.setConfig
				}
			},
			description: {
				...this.props.description,
				setConfig:{
					...this.props.description.setConfig
				}
			},
		};
	}
}

