//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Product, ProductDocType } from '../../data'; 

import { ProductService, ProductDocTypeService } from '../../services'; 

export default class ProductDocFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'productDoc';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productDoc.self';
	props = {
		productId: {
			foreignKey: true,
			name: 'productId',
			resourceKey: 'shop.productDoc.productId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Product(),
			data: async (params) =>
			{
				const service =
					new ProductService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productId',
				isMultiple: true,
				patterns: [],
			}
		},
		docAddress: {
			name: 'docAddress',
			resourceKey: 'shop.productDoc.docAddress',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'shop.productDoc.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		caption: {
			name: 'caption',
			resourceKey: 'shop.productDoc.caption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		productDocTypeId: {
			foreignKey: true,
			name: 'productDocTypeId',
			resourceKey: 'shop.productDoc.productDocTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new ProductDocType(),
			data: async (params) =>
			{
				const service =
					new ProductDocTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productDocTypeId',
				isMultiple: true,
				patterns: [],
			}
		},

	};
}
