//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Category, Culture, ProductStatus, ProductSpecification } from '@/modules/shop/data/product-doc/save'; 
import { CultureService, CategoryService, ProductStatusService } from '@/modules/shop/services'; 
export default class BaseProduct extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'product';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productDocSave.self';
	props = {
		productId: {
			primarykey: true,
			name: 'productId',
			resourceKey: 'shop.productDocSave.product.productId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productKey: {
			name: 'productKey',
			resourceKey: 'shop.productDocSave.product.productKey',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		cultureId: {
			foreignKey: true,
			name: 'cultureId',
			resourceKey: 'shop.productDocSave.product.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Culture(),
			data: async (params) =>
			{
				const service =
					new CultureService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'cultureId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		categoryId: {
			foreignKey: true,
			name: 'categoryId',
			resourceKey: 'shop.productDocSave.product.categoryId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new Category(),
			data: async (params) =>
			{
				const service =
					new CategoryService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'categoryId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		identifire: {
			name: 'identifire',
			resourceKey: 'shop.productDocSave.product.identifire',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'shop.productDocSave.product.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: ['required'],
			}
		},
		sellCount: {
			name: 'sellCount',
			resourceKey: 'shop.productDocSave.product.sellCount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		caption: {
			name: 'caption',
			resourceKey: 'shop.productDocSave.product.caption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				patterns: [],
			}
		},
		firstPrice: {
			name: 'firstPrice',
			resourceKey: 'shop.productDocSave.product.firstPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		totalProductInventory: {
			name: 'totalProductInventory',
			resourceKey: 'shop.productDocSave.product.totalProductInventory',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productCode: {
			name: 'productCode',
			resourceKey: 'shop.productDocSave.product.productCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: [],
			}
		},
		imagePath: {
			name: 'imagePath',
			resourceKey: 'shop.productDocSave.product.imagePath',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productStatusId: {
			foreignKey: true,
			name: 'productStatusId',
			resourceKey: 'shop.productDocSave.product.productStatusId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new ProductStatus(),
			data: async (params) =>
			{
				const service =
					new ProductStatusService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productStatusId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productMonetaryFeatureTitle: {
			name: 'productMonetaryFeatureTitle',
			resourceKey: 'shop.productDocSave.product.productMonetaryFeatureTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: [],
			}
		},
		isOrigin: {
			name: 'isOrigin',
			resourceKey: 'shop.productDocSave.product.isOrigin',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		category: {
			name: 'category',
			resourceKey: 'shop.productDocSave.product.category.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Category(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		culture: {
			name: 'culture',
			resourceKey: 'shop.productDocSave.product.culture.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Culture(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productStatus: {
			name: 'productStatus',
			resourceKey: 'shop.productDocSave.product.productStatus.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductStatus(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productSpecification: {
			name: 'productSpecification',
			resourceKey: 'shop.productDocSave.product.productSpecification.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductSpecification(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

