//GeneratorFlag:[NoChange]
import { BaseProductInvoiceItem } from '@/modules/shop/base/data/actions/invoice/get-invoice-detail'
export default class ProductInvoiceItem extends BaseProductInvoiceItem
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			productName: {
				...this.props.productName,
				setConfig:{
					...this.props.productName.setConfig
				}
			},
			productColorHex: {
				...this.props.productColorHex,
				setConfig:{
					...this.props.productColorHex.setConfig
				}
			},
			productColorName: {
				...this.props.productColorName,
				setConfig:{
					...this.props.productColorName.setConfig
				}
			},
			productCount: {
				...this.props.productCount,
				setConfig:{
					...this.props.productCount.setConfig
				}
			},
			basePrice: {
				...this.props.basePrice,
				setConfig:{
					...this.props.basePrice.setConfig
				}
			},
			totalPrice: {
				...this.props.totalPrice,
				setConfig:{
					...this.props.totalPrice.setConfig
				}
			},
		};
	}
}

