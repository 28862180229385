//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Menu, Company, Team, Rank, CompanyRank } from '../../data'; 

import { MenuService, CompanyService, TeamService, RankService, CompanyRankService } from '../../services'; 

export default class MenuPersonFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'menuPerson';
	schema = ENUMS.SCHEMA_TYPE.BASEINFO;
	resourceKey = 'peopleNet.menuPerson.self';
	props = {
		menuId: {
			foreignKey: true,
			name: 'menuId',
			resourceKey: 'peopleNet.menuPerson.menuId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new Menu(),
			data: async (params) =>
			{
				const service =
					new MenuService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'moduleName',
				valueKey: 'menuId',
				isMultiple: true,
				patterns: [],
			}
		},
		titleMenu: {
			name: 'titleMenu',
			resourceKey: 'peopleNet.menuPerson.titleMenu',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		companyId: {
			foreignKey: true,
			name: 'companyId',
			resourceKey: 'peopleNet.menuPerson.companyId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Company(),
			data: async (params) =>
			{
				const service =
					new CompanyService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'companyId',
				isMultiple: true,
				patterns: [],
			}
		},
		teamId: {
			foreignKey: true,
			name: 'teamId',
			resourceKey: 'peopleNet.menuPerson.teamId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Team(),
			data: async (params) =>
			{
				const service =
					new TeamService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'teamId',
				isMultiple: true,
				patterns: [],
			}
		},
		rankId: {
			foreignKey: true,
			name: 'rankId',
			resourceKey: 'peopleNet.menuPerson.rankId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new Rank(),
			data: async (params) =>
			{
				const service =
					new RankService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'rankId',
				isMultiple: true,
				patterns: [],
			}
		},
		companyRankId: {
			foreignKey: true,
			name: 'companyRankId',
			resourceKey: 'peopleNet.menuPerson.companyRankId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new CompanyRank(),
			data: async (params) =>
			{
				const service =
					new CompanyRankService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'companyRankId',
				isMultiple: true,
				patterns: [],
			}
		},

	};
}
