//GeneratorFlag:[NoChange]
export default {
	path: 'wallet-transaction-type',
	name: 'financial.baseSys.walletTransactionType.layout',
	redirect: to => {
		return {name: 'financial.walletTransactionType.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/base-sys/wallet-transaction-type"],
		resourceKey: 'financial.views.walletTransactionType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[WalletTransactionTypeDetail]"
		{
			path: 'detail',
			name: 'financial.walletTransactionType.detail',
			component: () => import('@/modules/financial/views/wallet-transaction-type/wallet-transaction-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransactionType.detail',
				clientAccessKeys:["/financial/base-sys/wallet-transaction-type/detail"],
				serverAccessKeys:["/financial/wallet-transaction-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionTypeList]"
		{
			path: 'list',
			name: 'financial.walletTransactionType.list',
			component: () => import('@/modules/financial/views/wallet-transaction-type/wallet-transaction-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransactionType.list',
				clientAccessKeys:["/financial/base-sys/wallet-transaction-type/list"],
				serverAccessKeys:["/financial/wallet-transaction-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}