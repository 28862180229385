<template>
	<Frc-content width="800px">
		<div class="row">
			<div class="col-12">
				<div class="bg-white p-2 mb-2">
					<div class="row mb-2 m-0">
						<div class="col-12 col-lg-6 border bg-light pt-2 pb-2">
							<label v-once for="fromDate" class="col-12 mb-2">
								<span>{{$loc.fromResource(form.fromDate.resourceKey)}}</span>
								<span v-if="isRequired('fromDate')" class="text-danger"> * </span>
							</label>
							<div class="col-12">
								<input type="date" class="form-control" v-model="form.fromDate.value"/>
								<small class="text-danger" v-if="form.fromDate.errors.length > 0">
									{{getFirstError('fromDate')}}
								</small>
							</div>
						</div>
						<div class="col-12 col-lg-6 border bg-light pt-2 pb-2">
							<label v-once for="toDate" class="col-12 mb-2">
								<span>{{$loc.fromResource(form.toDate.resourceKey)}}</span>
								<span v-if="isRequired('toDate')" class="text-danger"> * </span>
							</label>
							<div class="col-12">
								<input type="date" class="form-control" v-model="form.toDate.value"/>
								<small class="text-danger" v-if="form.toDate.errors.length > 0">
									{{getFirstError('toDate')}}
								</small>
							</div>
						</div>
					</div>
					<div class="m-0">
						<div class="col-12 pt-3 pb-2">
							<button class="btn btn-light border" @click="applyFilter">
								<span>Search</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<person-tree ref="PersonTreeRef" :fromDate="form.fromDate.value" :toDate="form.toDate.value" />
	</Frc-content>
</template>
<script>
	import { ENUMS } from '@/core/data'
	import PersonTree from './tree/person-tree'
	export default {

		components: {
			'person-tree': PersonTree
		},

		data()
		{
			return {
				ver: 0,
				form: {
					fromDate: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shopMarketer.ext.common.fromDate',
						patterns: ['persianDate'],
						errors: [],
						value: null,
					},
					toDate: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shopMarketer.ext.common.toDate',
						patterns: ['persianDate'],
						errors: [],
						value: null,
					},
				}
			}
		},

		methods: {
			isRequired(name)
			{
				return this.form[name].patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}

				this.form[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.form[name].isChanged)
				{
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.form[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey, {
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			applyFilter()
			{
				this.$refs.PersonTreeRef.init()
			}

		}
	}
</script>
