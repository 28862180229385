//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Rank, CompanyRank, Company, PersonStatuse, PersonGender, Team } from '../../data'; 

import { RankService, CompanyRankService, CompanyService, PersonStatuseService, PersonGenderService, TeamService } from '../../services'; 

export default class PersonFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'person';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'peopleNet.person.self';
	props = {
		firstName: {
			name: 'firstName',
			resourceKey: 'peopleNet.person.firstName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		lastName: {
			name: 'lastName',
			resourceKey: 'peopleNet.person.lastName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		nationalNo: {
			name: 'nationalNo',
			resourceKey: 'peopleNet.person.nationalNo',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 10,
				isUnicode: false,
				patterns: [],
			}
		},
		passportNo: {
			name: 'passportNo',
			resourceKey: 'peopleNet.person.passportNo',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnicode: false,
				patterns: [],
			}
		},
		genoString: {
			name: 'genoString',
			resourceKey: 'peopleNet.person.genoString',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnicode: false,
				patterns: [],
			}
		},
		rankId: {
			foreignKey: true,
			name: 'rankId',
			resourceKey: 'peopleNet.person.rankId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new Rank(),
			data: async (params) =>
			{
				const service =
					new RankService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'rankId',
				isMultiple: true,
				patterns: [],
			}
		},
		companyRankId: {
			foreignKey: true,
			name: 'companyRankId',
			resourceKey: 'peopleNet.person.companyRankId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new CompanyRank(),
			data: async (params) =>
			{
				const service =
					new CompanyRankService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'companyRankId',
				isMultiple: true,
				patterns: [],
			}
		},
		companyId: {
			foreignKey: true,
			name: 'companyId',
			resourceKey: 'peopleNet.person.companyId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Company(),
			data: async (params) =>
			{
				const service =
					new CompanyService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'companyId',
				isMultiple: true,
				patterns: [],
			}
		},
		mobile: {
			name: 'mobile',
			resourceKey: 'peopleNet.person.mobile',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: ['mobile'],
			}
		},
		email: {
			name: 'email',
			resourceKey: 'peopleNet.person.email',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				patterns: [],
			}
		},
		personStauseId: {
			foreignKey: true,
			name: 'personStauseId',
			resourceKey: 'peopleNet.person.personStauseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonStatuse(),
			data: async (params) =>
			{
				const service =
					new PersonStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personStauseId',
				isMultiple: true,
				patterns: [],
			}
		},
		personGenderId: {
			foreignKey: true,
			name: 'personGenderId',
			resourceKey: 'peopleNet.person.personGenderId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonGender(),
			data: async (params) =>
			{
				const service =
					new PersonGenderService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personGenderId',
				isMultiple: true,
				patterns: [],
			}
		},
		teamId: {
			foreignKey: true,
			name: 'teamId',
			resourceKey: 'peopleNet.person.teamId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Team(),
			data: async (params) =>
			{
				const service =
					new TeamService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'teamId',
				isMultiple: true,
				patterns: [],
			}
		},
		personCode: {
			name: 'personCode',
			resourceKey: 'peopleNet.person.personCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},

	};
}
