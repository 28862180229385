//GeneratorFlag:[NoChange]
export default {
	path: 'military-type',
	name: 'peopleNet.militaryType.layout',
	redirect: to => {
		return {name: 'peopleNet.militaryType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.militaryType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.militaryType.list',
			component: () => import('@/modules/people-net/views/military-type/military-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.militaryType.create',
			component: () => import('@/modules/people-net/views/military-type/military-type-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.militaryType.import',
			component: () => import('@/modules/people-net/views/military-type/military-type-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.militaryType.update',
			component: () => import('@/modules/people-net/views/military-type/military-type-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.militaryType.delete',
			component: () => import('@/modules/people-net/views/military-type/military-type-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.militaryType.detail',
			component: () => import('@/modules/people-net/views/military-type/military-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
