//GeneratorFlag:[NoChange]
import cityRoute from './city-route';
import companyRoute from './company-route';
import companyRankRoute from './company-rank-route';
import countryRoute from './country-route';
import cultureRoute from './culture-route';
import genderTypeRoute from './gender-type-route';
import maritalTypeRoute from './marital-type-route';
import menuRoute from './menu-route';
import menuPersonRoute from './menu-person-route';
import militaryTypeRoute from './military-type-route';
import nationalityTypeRoute from './nationality-type-route';
import personRoute from './person-route';
import personAppPurchaseRoute from './person-app-purchase-route';
import personAppPurchaseStatuseRoute from './person-app-purchase-statuse-route';
import personAppPurchaseTypeRoute from './person-app-purchase-type-route';
import personCreditTransactionRoute from './person-credit-transaction-route';
import personCreditTransactionStatuseRoute from './person-credit-transaction-statuse-route';
import personCreditTransactionTypeRoute from './person-credit-transaction-type-route';
import personDocumentRoute from './person-document-route';
import personDocumentTypeRoute from './person-document-type-route';
import personGenderRoute from './person-gender-route';
import personStatuseRoute from './person-statuse-route';
import provinceRoute from './province-route';
import rankRoute from './rank-route';
import teamRoute from './team-route';

const routes = [{
	path: 'people-net',
	name: 'peopleNet',
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.name',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-layers-half',
		}
	},
	children: [
		personRoute, personAppPurchaseRoute, personCreditTransactionRoute, personDocumentRoute,
		{
			path: 'base-info',
			name: 'peopleNet.baseInfo',
			component: () => import('@/modules/people-net/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'page.baseInfo',
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [cityRoute, companyRoute, companyRankRoute, countryRoute, menuRoute, menuPersonRoute, militaryTypeRoute, nationalityTypeRoute, provinceRoute, rankRoute, teamRoute]
		},

		{
			path: 'base-sys',
			name: 'peopleNet.baseSys',
			component: () => import('@/modules/people-net/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'page.baseSys',
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [cultureRoute, genderTypeRoute, maritalTypeRoute, personAppPurchaseStatuseRoute, personAppPurchaseTypeRoute, personCreditTransactionStatuseRoute, personCreditTransactionTypeRoute, personDocumentTypeRoute, personGenderRoute, personStatuseRoute]
		}
	]
}]
export default routes
export{cityRoute, companyRoute, companyRankRoute, countryRoute, cultureRoute, genderTypeRoute, maritalTypeRoute, menuRoute, menuPersonRoute, militaryTypeRoute, nationalityTypeRoute, personRoute, personAppPurchaseRoute, personAppPurchaseStatuseRoute, personAppPurchaseTypeRoute, personCreditTransactionRoute, personCreditTransactionStatuseRoute, personCreditTransactionTypeRoute, personDocumentRoute, personDocumentTypeRoute, personGenderRoute, personStatuseRoute, provinceRoute, rankRoute, teamRoute}
