//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonAddressService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(personAddressId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:personAddressId
		}

		return await _server.get('/shop/person-address/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/shop/person-address/save', params || {})
	};

	async delete(personAddressId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:personAddressId
		}

		return await _server.delete('/shop/person-address/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/shop/person-address/list', {'params':params})
	};
}
