//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Bank, BankPaymentStatuse, Store } from '@/modules/shop/data/bank-payment/list'; 
import { StoreService, BankService, BankPaymentStatuseService } from '@/modules/shop/services'; 
export default class BankPayment extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'bankPayment';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.bankPaymentList.self';
	props = {
		bankPaymentId: {
			primarykey: true,
			name: 'bankPaymentId',
			resourceKey: 'shop.bankPaymentList.bankPaymentId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		accountNumber: {
			name: 'accountNumber',
			resourceKey: 'shop.bankPaymentList.accountNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 150,
				patterns: [],
			}
		},
		shabaNumber: {
			name: 'shabaNumber',
			resourceKey: 'shop.bankPaymentList.shabaNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: [],
			}
		},
		userName: {
			name: 'userName',
			resourceKey: 'shop.bankPaymentList.userName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: [],
			}
		},
		password: {
			name: 'password',
			resourceKey: 'shop.bankPaymentList.password',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.PASSWORD,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.PASSWORD,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				patterns: [],
			}
		},
		sendUrl: {
			name: 'sendUrl',
			resourceKey: 'shop.bankPaymentList.sendUrl',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				patterns: [],
			}
		},
		postBackUrl: {
			name: 'postBackUrl',
			resourceKey: 'shop.bankPaymentList.postBackUrl',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				patterns: [],
			}
		},
		additionalData: {
			name: 'additionalData',
			resourceKey: 'shop.bankPaymentList.additionalData',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: [],
			}
		},
		storeId: {
			foreignKey: true,
			name: 'storeId',
			resourceKey: 'shop.bankPaymentList.storeId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Store(),
			data: async (params) =>
			{
				const service =
					new StoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'storeName',
				valueKey: 'storeId',
				isUnicode: false,
				patterns: [],
			}
		},
		bankId: {
			foreignKey: true,
			name: 'bankId',
			resourceKey: 'shop.bankPaymentList.bankId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Bank(),
			data: async (params) =>
			{
				const service =
					new BankService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'bankId',
				isUnicode: false,
				patterns: [],
			}
		},
		bankPaymentStatuseId: {
			foreignKey: true,
			name: 'bankPaymentStatuseId',
			resourceKey: 'shop.bankPaymentList.bankPaymentStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new BankPaymentStatuse(),
			data: async (params) =>
			{
				const service =
					new BankPaymentStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'bankPaymentStatuseId',
				isUnicode: false,
				patterns: [],
			}
		},
		fromRange: {
			name: 'fromRange',
			resourceKey: 'shop.bankPaymentList.fromRange',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		toRange: {
			name: 'toRange',
			resourceKey: 'shop.bankPaymentList.toRange',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		bank: {
			name: 'bank',
			resourceKey: 'shop.bankPaymentList.bank.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Bank(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		bankPaymentStatuse: {
			name: 'bankPaymentStatuse',
			resourceKey: 'shop.bankPaymentList.bankPaymentStatuse.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BankPaymentStatuse(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		store: {
			name: 'store',
			resourceKey: 'shop.bankPaymentList.store.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Store(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{storeName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		bankPaymentSubInfo: {
			name: 'bankPaymentSubInfo',
			resourceKey: 'shop.bankPaymentList.bankPaymentSubInfo',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

