//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Team, Company } from '../../data'; 

import { TeamService, CompanyService } from '../../services'; 

export default class BaseRank extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'rank';
	schema = ENUMS.SCHEMA_TYPE.BASE_INFO;
	resourceKey = 'peopleNet.rank.self';
	props = {
		rankId: {
			primarykey: true,
			name: 'rankId',
			resourceKey: 'peopleNet.rank.rankId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		orderRank: {
			name: 'orderRank',
			resourceKey: 'peopleNet.rank.orderRank',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'peopleNet.rank.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: ['required'],
			}
		},
		teamId: {
			foreignKey: true,
			name: 'teamId',
			resourceKey: 'peopleNet.rank.teamId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Team(),
			data: async (params) =>
			{
				const service =
					new TeamService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'teamId',
				patterns: ['required'],
			}
		},
		companyId: {
			foreignKey: true,
			name: 'companyId',
			resourceKey: 'peopleNet.rank.companyId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Company(),
			data: async (params) =>
			{
				const service =
					new CompanyService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'companyId',
				patterns: ['required'],
			}
		},
		status: {
			name: 'status',
			resourceKey: 'peopleNet.rank.status',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		company: {
			name: 'company',
			resourceKey: 'peopleNet.rank.company',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Company(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		team: {
			name: 'team',
			resourceKey: 'peopleNet.rank.team',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Team(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		rankSubInfo: {
			name: 'rankSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
