<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { WalletCurrency, WalletCurrencyFilter } from '@/modules/financial/data/wallet-currency/list'
	import { WalletCurrencyService } from '@/modules/financial/services'
	export default {
		computed:
		{
			entity()
			{
				return WalletCurrency;
			},
			
			filterEntity()
			{
				return WalletCurrencyFilter;
			},

			service()
			{
				return new WalletCurrencyService();
			},

			filterData()
			{
				const walletCurrencyId =
					this.$route.query['walletCurrencyId'];

				if (walletCurrencyId)
				{
					return {
						'walletCurrencyId': walletCurrencyId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>