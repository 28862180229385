<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { ProductDetail, ProductDetailFilter } from '../../data/product-detail/list'
	import { ProductDetailService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return ProductDetail;
			},
			
			filterEntity()
			{
				return ProductDetailFilter;
			},

			service()
			{
				return new ProductDetailService();
			},

			filterData()
			{
				const productDetailId =
					this.$route.query['productDetailId'];

				if (productDetailId)
				{
					return {
						'productDetailId': productDetailId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'shop.productDetail.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'shop.productDetail.import',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'shop.productDetail.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'shop.productDetail.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'shop.productDetail.detail',
							resourceKey: 'shop.views.productDetail.detail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
