//GeneratorFlag:[NoChange]
import Bank from './bank';
import BankPayment from './bank-payment';
import Category from './category';
import City from './city';
import Company from './company';
import CompanyRank from './company-rank';
import Country from './country';
import Culture from './culture';
import Feature from './feature';
import FeatureItem from './feature-item';
import Invoice from './invoice';
import InvoicePaymentType from './invoice-payment-type';
import InvoiceProduct from './invoice-product';
import InvoiceStatuse from './invoice-statuse';
import InvoiceTransaction from './invoice-transaction';
import InvoiceTransactionType from './invoice-transaction-type';
import Person from './person';
import PersonAddress from './person-address';
import PersonCreaditTransactionType from './person-creadit-transaction-type';
import PlanLine from './plan-line';
import PlanPrecent from './plan-precent';
import PlanSetting from './plan-setting';
import Product from './product';
import ProductCategory from './product-category';
import ProductColor from './product-color';
import ProductDetail from './product-detail';
import ProductDoc from './product-doc';
import ProductDocType from './product-doc-type';
import ProductFeature from './product-feature';
import ProductMonetaryFeature from './product-monetary-feature';
import ProductSpecification from './product-specification';
import ProductStatus from './product-status';
import ProductStore from './product-store';
import ProductTransportCostType from './product-transport-cost-type';
import Province from './province';
import Rank from './rank';
import ShopPerson from './shop-person';
import Store from './store';
import Team from './team';

export default {Bank, BankPayment, Category, City, Company, CompanyRank, Country, Culture, Feature, FeatureItem, Invoice, InvoicePaymentType, InvoiceProduct, InvoiceStatuse, InvoiceTransaction, InvoiceTransactionType, Person, PersonAddress, PersonCreaditTransactionType, PlanLine, PlanPrecent, PlanSetting, Product, ProductCategory, ProductColor, ProductDetail, ProductDoc, ProductDocType, ProductFeature, ProductMonetaryFeature, ProductSpecification, ProductStatus, ProductStore, ProductTransportCostType, Province, Rank, ShopPerson, Store, Team}
export{Bank, BankPayment, Category, City, Company, CompanyRank, Country, Culture, Feature, FeatureItem, Invoice, InvoicePaymentType, InvoiceProduct, InvoiceStatuse, InvoiceTransaction, InvoiceTransactionType, Person, PersonAddress, PersonCreaditTransactionType, PlanLine, PlanPrecent, PlanSetting, Product, ProductCategory, ProductColor, ProductDetail, ProductDoc, ProductDocType, ProductFeature, ProductMonetaryFeature, ProductSpecification, ProductStatus, ProductStore, ProductTransportCostType, Province, Rank, ShopPerson, Store, Team}
