//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class WalletWithDrawService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[WalletWithDraw.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet-with-draw/create', params || { });
	};

	//GeneratorFlag:[WalletWithDraw.Delete]
	async delete(walletWithDrawId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:walletWithDrawId
		}
	
		return await _server.delete('/financial/wallet-with-draw/delete', {'params':params})
	};

	//GeneratorFlag:[WalletWithDraw.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet-with-draw/detail', {'params':params});
	};

	//GeneratorFlag:[WalletWithDraw.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet-with-draw/list', {'params':params});
	};

	//GeneratorFlag:[WalletWithDraw.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet-with-draw/update', params || { });
	};

	//GeneratorFlag:[WalletWithDraw.AskForMoney]
	async askForMoney(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet-with-draw/ask-for-money', params || { });
	};

	//GeneratorFlag:[WalletWithDraw.Cancel]
	async cancel(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet-with-draw/cancel', params || { });
	};

	//GeneratorFlag:[WalletWithDraw.MyList]
	async myList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet-with-draw/my-list', params || { });
	};

	//GeneratorFlag:[WalletWithDraw.TotalCommitedWithdraw]
	async totalCommitedWithdraw(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet-with-draw/total-commited-withdraw', {'params':params});
	};

	//GeneratorFlag:[WalletWithDraw.WatingList]
	async watingList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet-with-draw/wating-list', {'params':params});
	};

//GeneratorFlag:[NewAction]
}