//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class CultureFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'cultureFilter';
	schema = ENUMS.SCHEMA_TYPE.BASE_SYS;
	resourceKey = 'global.filters';
	props = {
		title: {
			name: 'title',
			resourceKey: 'financial.cultureList.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		cultureSymbol: {
			name: 'cultureSymbol',
			resourceKey: 'financial.cultureList.cultureSymbol',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 10,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		cultureFlagImagePath: {
			name: 'cultureFlagImagePath',
			resourceKey: 'financial.cultureList.cultureFlagImagePath',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		towSymbolCulture: {
			name: 'towSymbolCulture',
			resourceKey: 'financial.cultureList.towSymbolCulture',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 3,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		currency: {
			name: 'currency',
			resourceKey: 'financial.cultureList.currency',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		weightUnit: {
			name: 'weightUnit',
			resourceKey: 'financial.cultureList.weightUnit',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		widthUnit: {
			name: 'widthUnit',
			resourceKey: 'financial.cultureList.widthUnit',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		lengthUnit: {
			name: 'lengthUnit',
			resourceKey: 'financial.cultureList.lengthUnit',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		heightUnit: {
			name: 'heightUnit',
			resourceKey: 'financial.cultureList.heightUnit',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		standardTimeZoneId: {
			name: 'standardTimeZoneId',
			resourceKey: 'financial.cultureList.standardTimeZoneId',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: ['time'],
			},
			errors: [],
			value: null
		},
	};
}