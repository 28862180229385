//GeneratorFlag:[NoChange]
import ProductCategory from './product-category';
import Culture from './culture';
import Category from './category';
import ProductStatus from './product-status';
import ProductSpecification from './product-specification';
import Product from './product';

export default {ProductCategory, Culture, Category, ProductStatus, ProductSpecification, Product}
export{ProductCategory, Culture, Category, ProductStatus, ProductSpecification, Product}
