//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class BaseProductCategoryFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'productCategoryFilter';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'global.filters';
	props = {
		categoryId: {
			name: 'categoryId',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.categoryId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		cultureId: {
			name: 'cultureId',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productId: {
			name: 'productId',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.productId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productDetailId: {
			name: 'productDetailId',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.productDetailId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		imagePath: {
			name: 'imagePath',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.imagePath',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		basePrice: {
			name: 'basePrice',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.basePrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		secondPrice: {
			name: 'secondPrice',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.secondPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		categoryName: {
			name: 'categoryName',
			resourceKey: 'shop.productCategoryGetProductCategoryListAction.categoryName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

