<template>

	<div v-if="isError && !loading ">
		<span style="font-size:large;cursor:pointer" @click="getOptions">
			<i class="bi bi-arrow-clockwise"></i> Try Again
		</span>
	</div>
	<div v-else>
		<v-select v-if="isMultiSelect2==0"
				  dir="rtl"
				  v-model="value"
				  :loading="loading"
				  :reduce="o => o.value"
				  :options="options"
				  :clearable="false"
				  label="text"
				  :multiple="false"
				  @option:selected="onHandelChange"></v-select>

		<v-select v-else
				  dir="rtl"
				  v-model="value"
				  :loading="loading"
				  :reduce="o => o.value"
				  :options="options"
				  :clearable="false"
				  label="text"
				  :multiple="true"
				  @option:selected="onHandelChange"></v-select>
	</div>
</template>
<script>
	import vSelect from "vue-select";
	import "vue-select/dist/vue-select.css";



	export default {
		emits: ['change', 'keyup'],
		components: {
			'v-select': vSelect
		},

		props: {
			tagNameId: {
				type: String
			},
			moduleName: {
				type: String
			},
			serviceName: {
				type: String
			},
			actionName: {
				type: String
			},
			payload: {
				type: Object
			},
			textKey: {
				type: String
			},

			valueKey: {
				type: String
			},
			value: {
				type: Number
			},
			isMultiSelect: {
				type: Boolean
			},
			isMultiSelect2: {
				type: Number
			}


		},

		data() {
			return {
				query: '',
				value: null,
				options: [],
				loading: false,
				isError: false
			}
		},

		async beforeMount() {
			await this.getOptions()
			//this.value = this.value
		},

		methods: {

			async getOptions() {
				var options = []

				var textKey = this.textKey;

				var params = {
					start: 0, length: 50, filter: this.payload
				}
				//console.log(params);
				//if (this.query)
				//{
				//	params.filter[textKey] = this.query
				//}


				//if (parentProperty)
				//{
				//	if (!parentProperty.value)
				//	{
				//		this.options = options
				//		return;
				//	}

				//	params.filter[parentProperty.name] = parentProperty.value
				//}


				try {

					this.loading = true;
					this.isError = false;
					//const service = require(`@/modules/` + this.moduleName + `/services/` + this.serviceName + `-service.js`).default
					const service = require(`@/modules/${this.moduleName}/services/${this.serviceName}-service.js`).default
					const res = await new service()[this.actionName](params);
					if (res.status == 200 && res.data.items) {
						for (var item of res.data.items) {
							options.push({
								text: item[this.textKey],
								value: item[this.valueKey]
							});
						}
					}

				} catch (e) {
					this.isError = true;
					this.loading = false;
					console.log('form-select', e)
				}

				this.loading = false;
				this.options = options
			},

			async searchChange(query) {

				this.query = query
				await this.getOptions()
			},

			onHandelChange(payload) {
				var _value;
				if (payload.length != undefined) {
					var _value = [];
					for (var i = 0; i < payload.length; i++) {
						_value.push(payload[i].value)
					}
				}
				else {
					_value = payload.value
				}

				this.$emit('change', _value, _value == null, this.tagNameId)
			},
		},

		async mounted() {
			var self = this
			self.$emitter.on('formChange', async (prop) => {
				var parentProperty = this.prop.parentProperty;
				if (parentProperty && prop.name == parentProperty.name) {
					this.value = null
					this.onHandelChange(null)
					await this.getOptions()
				}
			})
		}
	}
</script>
