//GeneratorFlag:[NoChange]
export default {
	path: 'menu-person',
	name: 'peopleNet.menuPerson.layout',
	redirect: to => {
		return {name: 'peopleNet.menuPerson.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.menuPerson.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.menuPerson.list',
			component: () => import('@/modules/people-net/views/menu-person/menu-person-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menuPerson.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.menuPerson.create',
			component: () => import('@/modules/people-net/views/menu-person/menu-person-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menuPerson.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.menuPerson.import',
			component: () => import('@/modules/people-net/views/menu-person/menu-person-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menuPerson.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.menuPerson.update',
			component: () => import('@/modules/people-net/views/menu-person/menu-person-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menuPerson.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.menuPerson.delete',
			component: () => import('@/modules/people-net/views/menu-person/menu-person-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menuPerson.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.menuPerson.detail',
			component: () => import('@/modules/people-net/views/menu-person/menu-person-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.menuPerson.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
