//GeneratorFlag:[NoChange]
import { BaseInvoicePaymentType } from '../base/data'
export default class InvoicePaymentType extends BaseInvoicePaymentType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			invoicePaymentTypeId: {
				...this.props.invoicePaymentTypeId,
				setConfig:{
					...this.props.invoicePaymentTypeId.setConfig
				}
			},
			invoicePaymentTypeKey: {
				...this.props.invoicePaymentTypeKey,
				setConfig:{
					...this.props.invoicePaymentTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},

		};
	}
}
