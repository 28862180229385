//GeneratorFlag:[NoChange]
export default {
	path: 'wallet-transaction',
	name: 'financial.walletTransaction.layout',
	redirect: to => {
		return {name: 'financial.walletTransaction.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/wallet-transaction"],
		resourceKey: 'financial.views.walletTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[WalletTransactionCreate]"
		{
			path: 'create',
			name: 'financial.walletTransaction.create',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.create',
				clientAccessKeys:["/financial/wallet-transaction/create"],
				serverAccessKeys:["/financial/wallet-transaction/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionCreateImport]"
		{
			path: 'create-import',
			name: 'financial.walletTransaction.createImport',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.createImport',
				clientAccessKeys:["/financial/wallet-transaction/create-import"],
				serverAccessKeys:["/financial/wallet-transaction/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionDelete]"
		{
			path: 'delete',
			name: 'financial.walletTransaction.delete',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.delete',
				clientAccessKeys:["/financial/wallet-transaction/delete"],
				serverAccessKeys:["/financial/wallet-transaction/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionDetail]"
		{
			path: 'detail',
			name: 'financial.walletTransaction.detail',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.detail',
				clientAccessKeys:["/financial/wallet-transaction/detail"],
				serverAccessKeys:["/financial/wallet-transaction/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionList]"
		{
			path: 'list',
			name: 'financial.walletTransaction.list',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.list',
				clientAccessKeys:["/financial/wallet-transaction/list"],
				serverAccessKeys:["/financial/wallet-transaction/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionUpdate]"
		{
			path: 'update',
			name: 'financial.walletTransaction.update',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.update',
				clientAccessKeys:["/financial/wallet-transaction/update"],
				serverAccessKeys:["/financial/wallet-transaction/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[WalletTransactionMyTransactionList]"
		{
			path: 'my-transaction-list',
			name: 'financial.walletTransaction.myTransactionList',
			component: () => import('@/modules/financial/views/wallet-transaction/wallet-transaction-my-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletTransaction.myTransactionList',
				clientAccessKeys:["/financial/wallet-transaction/my-transaction-list"],
				serverAccessKeys:["/financial/wallet-transaction/my-transaction-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]







	]
}