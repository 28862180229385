<template>
	<div class="d-inline-block me-1 mb-2">
		<popper class="popper-light" arrow>
			<button class="btn bg-light border-0" v-bind:disabled="isDisable">
				<Frc-icon-bootstrap :name="action.iconName" size="15px" />
				<span class="ps-2">
					{{ $loc.fromResource(action.resourceKey) }}
				</span>
			</button>
			<template #content>
				<div v-for="(child,i) in action.children" :key="i">
					<a class="cursor-pointer" @click="onClick(child)">
						<Frc-icon-bootstrap :name="child.iconName" size="13px" />
						<span class="ps-2 pe-5">
							{{ $loc.fromResource(child.resourceKey) }}
						</span>
					</a>
				</div>
			</template>
		</popper>
	</div>
</template>
<script>
	export default {

		props:
		{
			action: {
				type: Object
			},
			isDisable: {
				type: Boolean,
				default: false
			}
		},

		methods:
		{
			onClick(action)
			{
				this.$emit('selectAction', action)
			}
		}
	}
</script>
