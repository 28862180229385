//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class InvoiceService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Invoice.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice/create', params || { });
	};

	//GeneratorFlag:[Invoice.Delete]
	async delete(invoiceId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:invoiceId
		}
	
		return await _server.delete('/shop/invoice/delete', {'params':params})
	};

	//GeneratorFlag:[Invoice.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/detail', {'params':params});
	};

	//GeneratorFlag:[Invoice.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/list', {'params':params});
	};

	//GeneratorFlag:[Invoice.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice/update', params || { });
	};

	//GeneratorFlag:[Invoice.AmountSum]
	async amountSum(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/amount-sum', {'params':params});
	};

	//GeneratorFlag:[Invoice.ConnectToOther]
	async connectToOther(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice/connect-to-other', params || { });
	};

	//GeneratorFlag:[Invoice.GetContracts]
	async getContracts(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/get-contracts', {'params':params});
	};

	//GeneratorFlag:[Invoice.GetInvoiceDetail]
	async getInvoiceDetail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/get-invoice-detail', {'params':params});
	};

	//GeneratorFlag:[Invoice.GetMyInvoiceCurrentWeek]
	async getMyInvoiceCurrentWeek(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/get-my-invoice-current-week', {'params':params});
	};

	//GeneratorFlag:[Invoice.GetMyList]
	async getMyList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/get-my-list', {'params':params});
	};

	//GeneratorFlag:[Invoice.GetMyTeamSellByWeek]
	async getMyTeamSellByWeek(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/get-my-team-sell-by-week', {'params':params});
	};

	//GeneratorFlag:[Invoice.Register]
	async register(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/invoice/register', params || { });
	};

	//GeneratorFlag:[Invoice.UplineFactorInfo]
	async uplineFactorInfo(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/invoice/upline-factor-info', {'params':params});
	};

	//GeneratorFlag:[Invoice.GetDashboardData]
	async getDashboardData(params, config) {
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server =
			this.server(config);

		return await _server.get('/shop/invoice/get-dashboard-data', { 'params': params });
	};
//GeneratorFlag:[NewAction]
}