<template>
	<button v-if="isLoading" disabled>
		{{ $loc.fromResource('system.pleaseWaite') }}
	</button>
	<button v-else>
		<slot></slot>
	</button>
</template>
<script>
	export default {
		props: {
			isLoading:
			{
				type: Boolean,
				default: false
			},
		}
	}
</script>
