//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { ProductDetail, Store } from '../../data'; 

import { ProductDetailService, StoreService } from '../../services'; 

export default class ProductStoreFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'productStore';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productStore.self';
	props = {
		productDetailId: {
			foreignKey: true,
			name: 'productDetailId',
			resourceKey: 'shop.productStore.productDetailId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new ProductDetail(),
			data: async (params) =>
			{
				const service =
					new ProductDetailService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'adminDisplayName',
				valueKey: 'productDetailId',
				isMultiple: true,
				patterns: [],
			}
		},
		storeId: {
			foreignKey: true,
			name: 'storeId',
			resourceKey: 'shop.productStore.storeId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Store(),
			data: async (params) =>
			{
				const service =
					new StoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'storeName',
				valueKey: 'storeId',
				isMultiple: true,
				patterns: [],
			}
		},
		firstPrice: {
			name: 'firstPrice',
			resourceKey: 'shop.productStore.firstPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		maxSellCount: {
			name: 'maxSellCount',
			resourceKey: 'shop.productStore.maxSellCount',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		minSellCount: {
			name: 'minSellCount',
			resourceKey: 'shop.productStore.minSellCount',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},

	};
}
