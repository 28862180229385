//GeneratorFlag:[NoChange]
import { BaseInvoiceRegister } from '@/modules/shop/base/data/actions/invoice/register'
export default class InvoiceRegister extends BaseInvoiceRegister
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			disountCode: {
				...this.props.disountCode,
				setConfig:{
					...this.props.disountCode.setConfig
				}
			},
			invoiceProductItems: {
				...this.props.invoiceProductItems,
				setConfig:{
					...this.props.invoiceProductItems.setConfig
				}
			},
			invoicePaymentTypeId: {
				...this.props.invoicePaymentTypeId,
				setConfig:{
					...this.props.invoicePaymentTypeId.setConfig
				}
			},
			storeId: {
				...this.props.storeId,
				setConfig:{
					...this.props.storeId.setConfig
				}
			},
			description: {
				...this.props.description,
				setConfig:{
					...this.props.description.setConfig
				}
			},
			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			additionalData: {
				...this.props.additionalData,
				setConfig:{
					...this.props.additionalData.setConfig
				}
			},
			totalPayble: {
				...this.props.totalPayble,
				setConfig:{
					...this.props.totalPayble.setConfig
				}
			},
			postBackUrl: {
				...this.props.postBackUrl,
				setConfig:{
					...this.props.postBackUrl.setConfig
				}
			},
			transportCost: {
				...this.props.transportCost,
				setConfig:{
					...this.props.transportCost.setConfig
				}
			},
			personAddressId: {
				...this.props.personAddressId,
				setConfig:{
					...this.props.personAddressId.setConfig
				}
			},
			invoiceSerial: {
				...this.props.invoiceSerial,
				setConfig:{
					...this.props.invoiceSerial.setConfig
				}
			},
		};
	}
}

