//GeneratorFlag:[NoChange]
import { BasePersonCreditTransactionType } from '../base/data'
export default class PersonCreditTransactionType extends BasePersonCreditTransactionType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personCreditTransactionTypeId: {
				...this.props.personCreditTransactionTypeId,
				setConfig:{
					...this.props.personCreditTransactionTypeId.setConfig
				}
			},
			personCreditTransactionTypeKey: {
				...this.props.personCreditTransactionTypeKey,
				setConfig:{
					...this.props.personCreditTransactionTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},

		};
	}
}
