//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { ProductDetail, Store } from '../../data'; 

import { ProductDetailService, StoreService } from '../../services'; 

export default class BaseProductStore extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'productStore';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productStore.self';
	props = {
		productStoreId: {
			primarykey: true,
			name: 'productStoreId',
			resourceKey: 'shop.productStore.productStoreId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		productDetailId: {
			foreignKey: true,
			name: 'productDetailId',
			resourceKey: 'shop.productStore.productDetailId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new ProductDetail(),
			data: async (params) =>
			{
				const service =
					new ProductDetailService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'adminDisplayName',
				valueKey: 'productDetailId',
				patterns: ['required'],
			}
		},
		storeId: {
			foreignKey: true,
			name: 'storeId',
			resourceKey: 'shop.productStore.storeId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Store(),
			data: async (params) =>
			{
				const service =
					new StoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'storeName',
				valueKey: 'storeId',
				patterns: ['required'],
			}
		},
		firstPrice: {
			name: 'firstPrice',
			resourceKey: 'shop.productStore.firstPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		secondStorePrice: {
			name: 'secondStorePrice',
			resourceKey: 'shop.productStore.secondStorePrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		productStoreInventory: {
			name: 'productStoreInventory',
			resourceKey: 'shop.productStore.productStoreInventory',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		productScore: {
			name: 'productScore',
			resourceKey: 'shop.productStore.productScore',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		maxSellCount: {
			name: 'maxSellCount',
			resourceKey: 'shop.productStore.maxSellCount',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		minSellCount: {
			name: 'minSellCount',
			resourceKey: 'shop.productStore.minSellCount',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: ['required'],
			}
		},
		productDetail: {
			name: 'productDetail',
			resourceKey: 'shop.productStore.productDetail',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductDetail(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{adminDisplayName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		store: {
			name: 'store',
			resourceKey: 'shop.productStore.store',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Store(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{storeName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		productStoreSubInfo: {
			name: 'productStoreSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
