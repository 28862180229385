//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Province } from '../../data'; 

import { ProvinceService } from '../../services'; 

export default class BaseCity extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'city';
	schema = ENUMS.SCHEMA_TYPE.BASE_INFO;
	resourceKey = 'peopleNet.city.self';
	props = {
		cityId: {
			primarykey: true,
			name: 'cityId',
			resourceKey: 'peopleNet.city.cityId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		title: {
			name: 'title',
			resourceKey: 'peopleNet.city.title',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		provinceId: {
			foreignKey: true,
			name: 'provinceId',
			resourceKey: 'peopleNet.city.provinceId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new Province(),
			data: async (params) =>
			{
				const service =
					new ProvinceService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'provinceId',
				patterns: [],
			}
		},
		province: {
			name: 'province',
			resourceKey: 'peopleNet.city.province',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Province(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
