//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Invoice, ProductStore } from '../../data'; 

import { InvoiceService, ProductStoreService } from '../../services'; 

export default class InvoiceProductFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'invoiceProduct';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.invoiceProduct.self';
	props = {
		invoiceId: {
			foreignKey: true,
			name: 'invoiceId',
			resourceKey: 'shop.invoiceProduct.invoiceId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Invoice(),
			data: async (params) =>
			{
				const service =
					new InvoiceService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'displayCheckoutDate',
				valueKey: 'invoiceId',
				isMultiple: true,
				patterns: [],
			}
		},
		count: {
			name: 'count',
			resourceKey: 'shop.invoiceProduct.count',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		basePrice: {
			name: 'basePrice',
			resourceKey: 'shop.invoiceProduct.basePrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		totalPrice: {
			name: 'totalPrice',
			resourceKey: 'shop.invoiceProduct.totalPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		productStoreId: {
			foreignKey: true,
			name: 'productStoreId',
			resourceKey: 'shop.invoiceProduct.productStoreId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new ProductStore(),
			data: async (params) =>
			{
				const service =
					new ProductStoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: 'productStoreId',
				isMultiple: true,
				patterns: [],
			}
		},

	};
}
