//GeneratorFlag:[NoChange]
import { BasePerson } from '../base/data'
export default class Person extends BasePerson
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			firstName: {
				...this.props.firstName,
				setConfig:{
					...this.props.firstName.setConfig
				}
			},
			lastName: {
				...this.props.lastName,
				setConfig:{
					...this.props.lastName.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			genoString: {
				...this.props.genoString,
				setConfig:{
					...this.props.genoString.setConfig
				}
			},
			rankId: {
				...this.props.rankId,
				setConfig:{
					...this.props.rankId.setConfig
				}
			},
			companyRankId: {
				...this.props.companyRankId,
				setConfig:{
					...this.props.companyRankId.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},
			mobile: {
				...this.props.mobile,
				setConfig:{
					...this.props.mobile.setConfig
				}
			},
			email: {
				...this.props.email,
				setConfig:{
					...this.props.email.setConfig
				}
			},
			personStauseId: {
				...this.props.personStauseId,
				setConfig:{
					...this.props.personStauseId.setConfig
				}
			},
			personGenderId: {
				...this.props.personGenderId,
				setConfig:{
					...this.props.personGenderId.setConfig
				}
			},
			teamId: {
				...this.props.teamId,
				setConfig:{
					...this.props.teamId.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
			levelOfHead: {
				...this.props.levelOfHead,
				setConfig:{
					...this.props.levelOfHead.setConfig
				}
			},
			planLinePerson: {
				...this.props.planLinePerson,
				setConfig:{
					...this.props.planLinePerson.setConfig
				}
			},

		};
	}
}
