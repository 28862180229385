//GeneratorFlag:[NoChange]
import { BasePersonUpdateBalance } from '@/modules/people-net/base/data/actions/person/update-balance'
export default class PersonUpdateBalance extends BasePersonUpdateBalance
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			encryptedValue: {
				...this.props.encryptedValue,
				setConfig:{
					...this.props.encryptedValue.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			isDone: {
				...this.props.isDone,
				setConfig:{
					...this.props.isDone.setConfig
				}
			},
			description: {
				...this.props.description,
				setConfig:{
					...this.props.description.setConfig
				}
			},
			returnMessage: {
				...this.props.returnMessage,
				setConfig:{
					...this.props.returnMessage.setConfig
				}
			},
		};
	}
}

