//GeneratorFlag:[NoChange]
import { BaseProductColor } from '../base/data'
export default class ProductColor extends BaseProductColor
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productColorId: {
				...this.props.productColorId,
				setConfig:{
					...this.props.productColorId.setConfig
				}
			},
			productColorKey: {
				...this.props.productColorKey,
				setConfig:{
					...this.props.productColorKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			colorHexaCode: {
				...this.props.colorHexaCode,
				setConfig:{
					...this.props.colorHexaCode.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},
			culture: {
				...this.props.culture,
				setConfig:{
					...this.props.culture.setConfig
				}
			},
			productColorSubInfo: {
				...this.props.productColorSubInfo,
				setConfig:{
					...this.props.productColorSubInfo.setConfig
				}
			},

		};
	}
}
