//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { Person } from '@/modules/people-net/data'; 

import { PersonService } from '@/modules/people-net/services'; 

export default class PersonDocumentFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'personDocument';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'peopleNet.personDocument.self';
	props = {
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'peopleNet.personDocument.personId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Person(),
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'firstName',
				valueKey: 'personId',
				isMultiple: true,
				patterns: [],
			}
		},
		base64FileString: {
			name: 'base64FileString',
			resourceKey: 'peopleNet.personDocument.base64FileString',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		addressFile: {
			name: 'addressFile',
			resourceKey: 'peopleNet.personDocument.addressFile',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},
		fullAddressFile: {
			name: 'fullAddressFile',
			resourceKey: 'peopleNet.personDocument.fullAddressFile',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				patterns: [],
			}
		},

	};
}
