<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { PersonAppPurchaseType } from '../../data'
	import { PersonAppPurchaseTypeFilter } from '../../data/filters'
	import { PersonAppPurchaseTypeService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return PersonAppPurchaseType;
			},
			
			filterEntity()
			{
				return PersonAppPurchaseTypeFilter;
			},

			service()
			{
				return new PersonAppPurchaseTypeService();
			},

			filterData()
			{
				const personAppPurchaseTypeId =
					this.$route.query['personAppPurchaseTypeId'];

				if (personAppPurchaseTypeId)
				{
					return {
						'personAppPurchaseTypeId': personAppPurchaseTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
