//GeneratorFlag:[NoChange]
import { BaseProductDoc } from '../base/data'
export default class ProductDoc extends BaseProductDoc
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productDocId: {
				...this.props.productDocId,
				setConfig:{
					...this.props.productDocId.setConfig
				}
			},
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			docAddress: {
				...this.props.docAddress,
				setConfig:{
					...this.props.docAddress.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			productDocTypeId: {
				...this.props.productDocTypeId,
				setConfig:{
					...this.props.productDocTypeId.setConfig
				}
			},
			product: {
				...this.props.product,
				setConfig:{
					...this.props.product.setConfig
				}
			},
			productDocType: {
				...this.props.productDocType,
				setConfig:{
					...this.props.productDocType.setConfig
				}
			},
			productDocSubInfo: {
				...this.props.productDocSubInfo,
				setConfig:{
					...this.props.productDocSubInfo.setConfig
				}
			},

		};
	}
}
