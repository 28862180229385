//GeneratorFlag:[NoChange]
import { BasePersonRegister } from '@/modules/people-net/base/data/actions/person/register'
export default class PersonRegister extends BasePersonRegister
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			firstName: {
				...this.props.firstName,
				setConfig:{
					...this.props.firstName.setConfig
				}
			},
			lastName: {
				...this.props.lastName,
				setConfig:{
					...this.props.lastName.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			nationalNo: {
				...this.props.nationalNo,
				setConfig:{
					...this.props.nationalNo.setConfig
				}
			},
			passportNo: {
				...this.props.passportNo,
				setConfig:{
					...this.props.passportNo.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},
			countryId: {
				...this.props.countryId,
				setConfig:{
					...this.props.countryId.setConfig
				}
			},
			mobile: {
				...this.props.mobile,
				setConfig:{
					...this.props.mobile.setConfig
				}
			},
			email: {
				...this.props.email,
				setConfig:{
					...this.props.email.setConfig
				}
			},
			personGenderId: {
				...this.props.personGenderId,
				setConfig:{
					...this.props.personGenderId.setConfig
				}
			},
			cityId: {
				...this.props.cityId,
				setConfig:{
					...this.props.cityId.setConfig
				}
			},
			password: {
				...this.props.password,
				setConfig:{
					...this.props.password.setConfig
				}
			},
			tokenType: {
				...this.props.tokenType,
				setConfig:{
					...this.props.tokenType.setConfig
				}
			},
			expirationDate: {
				...this.props.expirationDate,
				setConfig:{
					...this.props.expirationDate.setConfig
				}
			},
			token: {
				...this.props.token,
				setConfig:{
					...this.props.token.setConfig
				}
			},
		};
	}
}

