const en = {

	service: {
		hub: "Central serveice",
		shop: "Online store",
		cms: "Content management",
		financial: "Financial serveice",
		peopleNet: 'People service',
		shopMarketer: 'Marketer',
		creditPay: "Credit pay",
		automation:"Automation"
	},

	global: {
		applicationName: 'FRC',
		signIn: 'Sign in',
		logOut: 'logout',
		save: 'Save',
		ApplyFilters: 'Apply Filters',
		excelKey: 'Excel Key',
		operations: 'Operations',
		mathOperators: 'Math Operations',
		RelOperators: 'Relational Operations',
		logicOperators: 'Logical Operations',
		copyright: 'Powerd By The First World'
	},

	system: {
		pleaseWaite: 'Please Wait ...',
		downloading: 'Downloading ... Please Wait',
		uploading: 'Uploading ... Please Wait',
		progressing: 'system in ... Please Wait',
		notfound: 'Data is Empty . No Record Found'
	},

	router: {
		back: 'Back',
	},

	status: {
		errorLen: 'Error Count',
		successLen: 'successful number',
		errorShort: 'operation failed',
		successShort: 'successful operation',
		error: 'The operation encountered an error',
		ok: 'Mission accomplished.',
		notFound: "Not found",
		unKnownError: "Unknown error",
		unableDelete_Relation: "Due to the relationship with other parts of the system, it is not possible to delete it",
		unavailableService:"The service is not available",
		invalidPrimaryKey: 'The ID is invalid',
		invalidArgument: 'The information entered is not correct.',
		alreadyExists: 'The entered information is duplicated.',
		networkError: 'Error connecting to the server! This error may have occurred because you are not connected to the Internet',
		noResponse: "The server is being updated. Please try in a few more minutes"
	},


	action: {
		create: 'Create',
		update: 'Edit',
		filter: 'Filter',
		import: 'Import',
		export: 'Export',
		detail: 'Show detail',
		exportExcel: 'Export by excel',
		importFromExcel: 'Import from excel',
		history: 'History',
		importData: 'Import data ',
		delete: 'Delete',
		deleteData: 'Delete data',
		back: 'Back',
		search: 'Search',
		searchPoint: 'Search ...',
		more: 'More'
	},


	page: {
		Frc: 'FRC Token',
		tickets:"Tickets"
	},

	error: {
		chackForm: 'Please check and fix the form errors first.',
		required: 'field {label} is Required',
		invalid: 'field {label} invalid',
		digit: 'field {label} It must be of type number without leading 0',
		number: 'field {label} It must be of type number',
	},

	data: {
		uniquekey:'unique key',
		subInfo: {
			self: 'Further information',
			creatorId: 'Creator ID',
			creatorIp: 'ip Creator',
			creatorName: 'Name of Creator',
			createDate: 'Create Date/Millady',
			createPersianDate: 'Create Date/',
			createTime: 'Create Time',
			modifierId: 'Editor ID',
			modifierIp: 'ip Editor',
			modifierName: 'Name of Editor',
			modifyDate: 'Edit Date/Millady',
			modifyPersianDate: 'Edit Date/',
			modifyTime: 'Modify Time',
			summary: 'by {creatorName} in {createPersianDate}'
		},

	},
	com: {
		excel: {
			chooseFile: 'choose File',
			backToSelectFile: 'Back',
			review: 'review',
			reviewLength: 'review Count',
			NumberOfReviewsPerformed: 'NumberOfReviews Performed',
			reviewMessage: 'If there are no errors in the information after rechecking, the system will enter the information entry section.',
			fileIsEmpty: 'The selected file has no data!',
			downloadSampleFile: 'Get the raw file',
			fileProcessing: 'The system is processing the file, please wait.',
			fileHasError: `<strong>The file contains invalid information</strong>
					<p>Through the table below, you can correct all invalid and erroneous information without needing to edit the Excel file.</br> The error record numbers are as follows :</p>`,

			warning: `<p>In order to enter information through an Excel file, pay attention to the following points.</p>
				<ul>
					<li>First, download the raw file.</li>
					<li>Enter the desired data in the raw file.</li>
					<li>field Mandatory fields are marked with an asterisk (*).</li>
					<li>Do not change record number one of the raw file.</li>
					<li>The raw file may have more than one sheet, the information of each part is placed in its own sheet.</li>
				</ul>`,
		},

		table: {
			filterResult: 'Filter on {totalfilters} The row is done.',
			pageInfo: 'Page {currentPage} From {totalPages}',
		},

		task: {
			repeat: 'repeat',
			skip: 'skip',
			replace: 'replace',
			cancel: 'Cancel',
			doTasks: 'perform operations',
			cancelTasks: 'Cancel the operation',
			errorContent: 'Error Content',
			taskRowNumberError: 'Record Number {index} An error was encountered.',
			tasksFailedLenght: 'Number of failed records',
			tasksSucceedLenght: 'Number of successful records',
			applyToAllCases: 'This choice should be repeated for all subsequent cases with the same error',
			progressing: 'The system is operating, please wait.',
			tasksCompletedWithError: 'It ended with an error.',
			tasksCompletedSuccess: 'The operation was completed successfully.',
			warning: `<strong>Information below direction {label} it's been chosen.</strong>
				<p>If you are sure of performing the above operation, click on the button to perform the operation.</p>`,
		}
	}
}

export default en
