//GeneratorFlag:[NoChange]
import { BaseProductMonetaryFeatureInfo } from '@/modules/shop/base/data/actions/product-store/get-product-info'
export default class ProductMonetaryFeatureInfo extends BaseProductMonetaryFeatureInfo
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			productMonetaryFeatureId: {
				...this.props.productMonetaryFeatureId,
				setConfig:{
					...this.props.productMonetaryFeatureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			firstPrice: {
				...this.props.firstPrice,
				setConfig:{
					...this.props.firstPrice.setConfig
				}
			},
			secondPrice: {
				...this.props.secondPrice,
				setConfig:{
					...this.props.secondPrice.setConfig
				}
			},
			productColorId: {
				...this.props.productColorId,
				setConfig:{
					...this.props.productColorId.setConfig
				}
			},
			productStoreInventory: {
				...this.props.productStoreInventory,
				setConfig:{
					...this.props.productStoreInventory.setConfig
				}
			},
		};
	}
}

