//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { ProductService, ProductColorService, ProductMonetaryFeatureService, CultureService, ProductStatusService } from '@/modules/shop/services'; 
export default class BaseProductDetail extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'productDetail';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productStoreList.self';
	props = {
		productDetailId: {
			primarykey: true,
			name: 'productDetailId',
			resourceKey: 'shop.productStoreList.productDetail.productDetailId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productId: {
			foreignKey: true,
			name: 'productId',
			resourceKey: 'shop.productStoreList.productDetail.productId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productId',
				isUnicode: false,
				patterns: [],
			}
		},
		productColorId: {
			foreignKey: true,
			name: 'productColorId',
			resourceKey: 'shop.productStoreList.productDetail.productColorId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productColorId',
				isUnicode: false,
				patterns: [],
			}
		},
		productMonetaryFeatureId: {
			foreignKey: true,
			name: 'productMonetaryFeatureId',
			resourceKey: 'shop.productStoreList.productDetail.productMonetaryFeatureId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productMonetaryFeatureId',
				isUnicode: false,
				patterns: [],
			}
		},
		adminDisplayName: {
			name: 'adminDisplayName',
			resourceKey: 'shop.productStoreList.productDetail.adminDisplayName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 200,
				patterns: [],
			}
		},
		cultureId: {
			foreignKey: true,
			name: 'cultureId',
			resourceKey: 'shop.productStoreList.productDetail.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'cultureId',
				isUnicode: false,
				patterns: [],
			}
		},
		isDefaultProduct: {
			name: 'isDefaultProduct',
			resourceKey: 'shop.productStoreList.productDetail.isDefaultProduct',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productStatusId: {
			foreignKey: true,
			name: 'productStatusId',
			resourceKey: 'shop.productStoreList.productDetail.productStatusId',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'productStatusId',
				isUnicode: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

