//GeneratorFlag:[NoChange]
import { BasePersonMobileValidationValidateCode } from '@/modules/people-net/base/data/actions/person-mobile-validation/validate-code'
export default class PersonMobileValidationValidateCode extends BasePersonMobileValidationValidateCode
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			personMobileValidationServiceId: {
				...this.props.personMobileValidationServiceId,
				setConfig:{
					...this.props.personMobileValidationServiceId.setConfig
				}
			},
			validationCode: {
				...this.props.validationCode,
				setConfig:{
					...this.props.validationCode.setConfig
				}
			},
			validationResult: {
				...this.props.validationResult,
				setConfig:{
					...this.props.validationResult.setConfig
				}
			},
		};
	}
}

