<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-excel-import :key="ver" :entity="entity" :task="import" @close="reset" @cancel="reset" />
</template>
<script>
	import { Team } from '../../data'
	import { TeamService } from '../../services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed:
		{
			entity()
			{
				return Team;
			},

			service()
			{
				return new TeamService();
			}
		},
		methods:
		{
			async import(payload)
			{
				return await this.service.save(payload, {HSR:false, HER:false});
			},

			reset()
			{
				this.ver = this.ver + 1;
			}
		}
	}
</script>
