//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class CountryService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(countryId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:countryId
		}

		return await _server.get('/people-net/country/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/people-net/country/save', params || {})
	};

	async delete(countryId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:countryId
		}

		return await _server.delete('/people-net/country/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/people-net/country/list', {'params':params})
	};
}
