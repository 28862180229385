<template>
	<popper class="popper-light" arrow>
		<small class="pointer text-danger truncate"> {{ getFirstErrorText() }} [...]</small>
		<template #content>
			<div class="w-100 pe-3" style="max-height:200px; overflow:auto;">
				<table class="table">
					<tr v-for="(error,i) in data.errors" :key="i">
						<th>
							<small> {{ i + 1 }}. {{ getErrorText(error) }}</small>
						</th>
					</tr>
				</table>
			</div>
		</template>
	</popper>
</template>
<script>
	export default {
		props: {
			data: Object
		},

		methods: {
			getErrorText(error)
			{
				const label =
					this.$loc.fromResource(this.data.resourceKey);

				return this.$loc.fromResource(
					error.resourceKey, {
						label: label
				})
			},

			getFirstErrorText()
			{
				return this.getErrorText(this.data.errors[0])
			}
		}
	}
</script>
