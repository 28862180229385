//GeneratorFlag:[NoChange]
import BaseCityService from './base-city-service';
import BaseCompanyService from './base-company-service';
import BaseCompanyRankService from './base-company-rank-service';
import BaseCountryService from './base-country-service';
import BaseCultureService from './base-culture-service';
import BaseGenderTypeService from './base-gender-type-service';
import BaseMaritalTypeService from './base-marital-type-service';
import BaseMenuService from './base-menu-service';
import BaseMenuPersonService from './base-menu-person-service';
import BaseMilitaryTypeService from './base-military-type-service';
import BaseNationalityTypeService from './base-nationality-type-service';
import BasePersonService from './base-person-service';
import BasePersonAccessService from './base-person-access-service';
import BasePersonAppPurchaseService from './base-person-app-purchase-service';
import BasePersonAppPurchaseStatuseService from './base-person-app-purchase-statuse-service';
import BasePersonAppPurchaseTypeService from './base-person-app-purchase-type-service';
import BasePersonCreditTransactionService from './base-person-credit-transaction-service';
import BasePersonCreditTransactionStatuseService from './base-person-credit-transaction-statuse-service';
import BasePersonCreditTransactionTypeService from './base-person-credit-transaction-type-service';
import BasePersonDetailService from './base-person-detail-service';
import BasePersonDocumentService from './base-person-document-service';
import BasePersonDocumentTypeService from './base-person-document-type-service';
import BasePersonGenderService from './base-person-gender-service';
import BasePersonStatuseService from './base-person-statuse-service';
import BaseProvinceService from './base-province-service';
import BaseRankService from './base-rank-service';
import BaseTeamService from './base-team-service';
 
export default {BaseCityService, BaseCompanyService, BaseCompanyRankService, BaseCountryService, BaseCultureService, BaseGenderTypeService, BaseMaritalTypeService, BaseMenuService, BaseMenuPersonService, BaseMilitaryTypeService, BaseNationalityTypeService, BasePersonService, BasePersonAccessService, BasePersonAppPurchaseService, BasePersonAppPurchaseStatuseService, BasePersonAppPurchaseTypeService, BasePersonCreditTransactionService, BasePersonCreditTransactionStatuseService, BasePersonCreditTransactionTypeService, BasePersonDetailService, BasePersonDocumentService, BasePersonDocumentTypeService, BasePersonGenderService, BasePersonStatuseService, BaseProvinceService, BaseRankService, BaseTeamService}
export{BaseCityService, BaseCompanyService, BaseCompanyRankService, BaseCountryService, BaseCultureService, BaseGenderTypeService, BaseMaritalTypeService, BaseMenuService, BaseMenuPersonService, BaseMilitaryTypeService, BaseNationalityTypeService, BasePersonService, BasePersonAccessService, BasePersonAppPurchaseService, BasePersonAppPurchaseStatuseService, BasePersonAppPurchaseTypeService, BasePersonCreditTransactionService, BasePersonCreditTransactionStatuseService, BasePersonCreditTransactionTypeService, BasePersonDetailService, BasePersonDocumentService, BasePersonDocumentTypeService, BasePersonGenderService, BasePersonStatuseService, BaseProvinceService, BaseRankService, BaseTeamService}
