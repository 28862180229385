//GeneratorFlag:[NoChange]
import { BaseInvoiceTransaction } from '../base/data'
export default class InvoiceTransaction extends BaseInvoiceTransaction
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			invoiceTransactionId: {
				...this.props.invoiceTransactionId,
				setConfig:{
					...this.props.invoiceTransactionId.setConfig
				}
			},
			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			amount: {
				...this.props.amount,
				setConfig:{
					...this.props.amount.setConfig
				}
			},
			createdDate: {
				...this.props.createdDate,
				setConfig:{
					...this.props.createdDate.setConfig
				}
			},
			displayCreatedDate: {
				...this.props.displayCreatedDate,
				setConfig:{
					...this.props.displayCreatedDate.setConfig
				}
			},
			invoiceTransactionTypeId: {
				...this.props.invoiceTransactionTypeId,
				setConfig:{
					...this.props.invoiceTransactionTypeId.setConfig
				}
			},
			invoice: {
				...this.props.invoice,
				setConfig:{
					...this.props.invoice.setConfig
				}
			},
			invoiceTransactionType: {
				...this.props.invoiceTransactionType,
				setConfig:{
					...this.props.invoiceTransactionType.setConfig
				}
			},
			invoiceTransactionSubInfo: {
				...this.props.invoiceTransactionSubInfo,
				setConfig:{
					...this.props.invoiceTransactionSubInfo.setConfig
				}
			},

		};
	}
}
