//GeneratorFlag:[NoChange]
import { BaseShopPerson } from '../base/data'
export default class ShopPerson extends BaseShopPerson
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			fullName: {
				...this.props.fullName,
				setConfig:{
					...this.props.fullName.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			genoString: {
				...this.props.genoString,
				setConfig:{
					...this.props.genoString.setConfig
				}
			},
			rankName: {
				...this.props.rankName,
				setConfig:{
					...this.props.rankName.setConfig
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				}
			},

		};
	}
}
