//GeneratorFlag:[NoChange]
import City from './city';
import Company from './company';
import CompanyRank from './company-rank';
import Country from './country';
import Culture from './culture';
import GenderType from './gender-type';
import MaritalType from './marital-type';
import Menu from './menu';
import MenuPerson from './menu-person';
import MilitaryType from './military-type';
import NationalityType from './nationality-type';
import Person from './person';
import PersonAccess from './person-access';
import PersonAppPurchase from './person-app-purchase';
import PersonAppPurchaseStatuse from './person-app-purchase-statuse';
import PersonAppPurchaseType from './person-app-purchase-type';
import PersonCreditTransaction from './person-credit-transaction';
import PersonCreditTransactionStatuse from './person-credit-transaction-statuse';
import PersonCreditTransactionType from './person-credit-transaction-type';
import PersonDetail from './person-detail';
import PersonDocument from './person-document';
import PersonDocumentType from './person-document-type';
import PersonGender from './person-gender';
import PersonStatuse from './person-statuse';
import Province from './province';
import Rank from './rank';
import Team from './team';

export default {City, Company, CompanyRank, Country, Culture, GenderType, MaritalType, Menu, MenuPerson, MilitaryType, NationalityType, Person, PersonAccess, PersonAppPurchase, PersonAppPurchaseStatuse, PersonAppPurchaseType, PersonCreditTransaction, PersonCreditTransactionStatuse, PersonCreditTransactionType, PersonDetail, PersonDocument, PersonDocumentType, PersonGender, PersonStatuse, Province, Rank, Team}
export{City, Company, CompanyRank, Country, Culture, GenderType, MaritalType, Menu, MenuPerson, MilitaryType, NationalityType, Person, PersonAccess, PersonAppPurchase, PersonAppPurchaseStatuse, PersonAppPurchaseType, PersonCreditTransaction, PersonCreditTransactionStatuse, PersonCreditTransactionType, PersonDetail, PersonDocument, PersonDocumentType, PersonGender, PersonStatuse, Province, Rank, Team}
