<template>
	<div class="bg-light p-3">
		<div class="d-block">
			<strong class="text-danger" v-if="failed > 0">
				{{ $loc.fromResource('com.task.tasksCompletedWithError') }}
			</strong>
			<strong v-else>
				{{ $loc.fromResource('com.task.tasksCompletedSuccess') }}
			</strong>
		</div>
		<div class="d-block bg-white p-3 mt-3" v-if="failed > 0">
			<label class="d-block mb-1">
				{{ $loc.fromResource('com.task.tasksSucceedLenght') }} : {{succeed }}
			</label>
			<label class="d-block">
				{{ $loc.fromResource('com.task.tasksFailedLenght') }} : {{ failed }}
			</label>
		</div>
		<div class="mt-3">
			<button type="button" @click="$emit('close')" class="btn btn-light border">
				{{ $loc.fromResource('action.back') }}
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		emits:['close'],
		props: {
			'total': {
				type: Number,
				default: 0,
			},

			'succeed': {
				type: Number,
				default: 0,
			},

			'failed': {
				type: Number,
				default: 0,
			},
		}
	}

</script>
