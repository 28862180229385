
<template>
	<div v-html="$loc.fromResource('com.excel.warning')"></div>
	<div class="mt-3 mb-3 tex-center">
		<button type="button" @click="getSample" class="btn btn-light border me-2">
			{{ $loc.fromResource('com.excel.downloadSampleFile') }}
		</button>
		<div class="btn btn-dark ps-5 pe-5 text-light border position-relative">
			<span>{{ $loc.fromResource('com.excel.chooseFile') }}</span>
			<input class="form-control" type="file" v-on:change="selectFile" accept=".xlsx" />
		</div>
	</div>
</template>
<script>
	import XLSX from 'xlsx'
	export default {
		emits: ['select'],

		props: {
			'entity': {
				type: Function
			}
		},

		methods: {

			selectFile(e) {

				const file = e.target.files[0];
				const allowType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

				if (file.type == allowType) {
					this.$emit('select', file)
				}
			},

			getPropName(prop) {

				const name = this.$loc.fromResource(prop.resourceKey);
				const star = prop.setConfig.patterns.includes('required') ? '*' : ''

				return name + star;
			},

			getSheets(entity,masterPropety) {
				return new Promise(async (resolve, reject) => {

					var sheets = [], item = {
						localName: this.$loc.fromResource(entity.resourceKey),
						name: entity.name,
						headers: {},
						keys: {}
					};


					for (const key in entity.props)
					{
						var prop = entity.props[key];

						if (prop.setConfig.canUseInExcel)
						{
							if (prop.type != 'object') {

								item.headers[prop.name] = this.getPropName(prop);

								if (!masterPropety)
								{
									item.keys[prop.name] = entity.name + '.' + prop.name;
								}
								else
								{
									item.keys[prop.name] = masterPropety.name + '.' + prop.name;
								}

								continue;
							}

							var relationSheets = await this.getSheets(prop.entity, prop);
							sheets.push(relationSheets[0])
						}
					}

					sheets.push(item);
					resolve(sheets);

				});
			},


			async getSample() {

				const excel = XLSX;
				const excelUtils = XLSX.utils;
				const sheets = await this.getSheets(new this.entity());

				var wb = excelUtils.book_new()
				for (const item of (sheets || []).reverse()) {
					excelUtils.book_append_sheet(
						wb, excelUtils.json_to_sheet([item.keys, item.headers], {
							skipHeader: true
						}),
						item.localName
					)
				}

				wb.Workbook = {
					Views: [{ RTL: true }]
				}

				excel.writeFile(wb, sheets[0].name + '.xlsx')
			}
		}
	}
</script>
<style>
	.Frc-excel-parser input[type='file'] {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
</style>
