//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'
import toolbar from './toolbar'

const ticketing = {
	name: 'ticketing',
	foldreName: 'ticketing',
	routes,
	services,
	resources,
	toolbar
}

export default ticketing
export { routes, services, resources, toolbar }