//GeneratorFlag:[NoChange]
import { BaseInvoiceGetMyTeamSellByWeek } from '@/modules/shop/base/data/actions/invoice/get-my-team-sell-by-week'
export default class InvoiceGetMyTeamSellByWeek extends BaseInvoiceGetMyTeamSellByWeek
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			fromDisplayDate: {
				...this.props.fromDisplayDate,
				setConfig:{
					...this.props.fromDisplayDate.setConfig
				}
			},
			toDisplayDate: {
				...this.props.toDisplayDate,
				setConfig:{
					...this.props.toDisplayDate.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			rowID: {
				...this.props.rowID,
				setConfig:{
					...this.props.rowID.setConfig
				}
			},
			personName: {
				...this.props.personName,
				setConfig:{
					...this.props.personName.setConfig
				}
			},
			personCode: {
				...this.props.personCode,
				setConfig:{
					...this.props.personCode.setConfig
				}
			},
			totalSell: {
				...this.props.totalSell,
				setConfig:{
					...this.props.totalSell.setConfig
				}
			},
			totalScore: {
				...this.props.totalScore,
				setConfig:{
					...this.props.totalScore.setConfig
				}
			},
			savedValue: {
				...this.props.savedValue,
				setConfig:{
					...this.props.savedValue.setConfig
				}
			},
		};
	}
}

