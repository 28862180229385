//GeneratorFlag:[NoChange]
export default {
	path: 'product',
	name: 'shop.product.layout',
	redirect: to => {
		return {name: 'shop.product.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/product"],
		resourceKey: 'shop.views.product.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductDelete]"
		{
			path: 'delete',
			name: 'shop.product.delete',
			component: () => import('@/modules/shop/views/product/product-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.delete',
				clientAccessKeys:["/shop/product/delete"],
				serverAccessKeys:["/shop/product/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDetail]"
		{
			path: 'detail',
			name: 'shop.product.detail',
			component: () => import('@/modules/shop/views/product/product-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.detail',
				clientAccessKeys:["/shop/product/detail"],
				serverAccessKeys:["/shop/product/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductSpecificationDetail]"
		{
			path: 'product-specification-detail',
			name: 'shop.product.productSpecificationDetail',
			component: () => import('@/modules/shop/views/product/product-specification-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.productSpecificationDetail',
				clientAccessKeys:["/shop/product/product-specification-detail"],
				serverAccessKeys:["/shop/product-specification/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductList]"
		{
			path: 'list',
			name: 'shop.product.list',
			component: () => import('@/modules/shop/views/product/product-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.list',
				clientAccessKeys:["/shop/product/list"],
				serverAccessKeys:["/shop/product/list","/shop/culture/list","/shop/category/list","/shop/product-status/list","/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductCreate]"
		{
			path: 'create',
			name: 'shop.product.create',
			component: () => import('@/modules/shop/views/product/product-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.create',
				clientAccessKeys:["/shop/product/create"],
				serverAccessKeys:["/shop/product/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductImport]"
		{
			path: 'import',
			name: 'shop.product.import',
			component: () => import('@/modules/shop/views/product/product-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.import',
				clientAccessKeys:["/shop/product/import"],
				serverAccessKeys:["/shop/product/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductUpdate]"
		{
			path: 'update',
			name: 'shop.product.update',
			component: () => import('@/modules/shop/views/product/product-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.update',
				clientAccessKeys:["/shop/product/update"],
				serverAccessKeys:["/shop/product/save","/shop/product/list","/shop/culture/list","/shop/category/list","/shop/product-status/list","/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductSpecificationUpdate]"
		{
			path: 'product-specification-update',
			name: 'shop.product.productSpecificationUpdate',
			component: () => import('@/modules/shop/views/product/product-specification-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.product.productSpecificationUpdate',
				clientAccessKeys:["/shop/product/product-specification-update"],
				serverAccessKeys:["/shop/product-specification/save","/shop/product/list","/shop/culture/list","/shop/category/list","/shop/product-status/list","/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]








	]
}








