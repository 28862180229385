//GeneratorFlag:[NoChange]
import { BaseInvoiceProductItem } from '@/modules/shop/base/data/actions/invoice/register'
export default class InvoiceProductItem extends BaseInvoiceProductItem
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			porductCount: {
				...this.props.porductCount,
				setConfig:{
					...this.props.porductCount.setConfig
				}
			},
			productMonetaryFeatureId: {
				...this.props.productMonetaryFeatureId,
				setConfig:{
					...this.props.productMonetaryFeatureId.setConfig
				}
			},
			productColorId: {
				...this.props.productColorId,
				setConfig:{
					...this.props.productColorId.setConfig
				}
			},
			description: {
				...this.props.description,
				setConfig:{
					...this.props.description.setConfig
				}
			},
		};
	}
}

