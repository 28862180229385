//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class CategoryService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Category.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/category/create', params || { });
	};

	//GeneratorFlag:[Category.Delete]
	async delete(categoryId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:categoryId
		}
	
		return await _server.delete('/shop/category/delete', {'params':params})
	};

	//GeneratorFlag:[Category.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/category/detail', {'params':params});
	};

	//GeneratorFlag:[Category.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/category/list', {'params':params});
	};

	//GeneratorFlag:[Category.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/category/update', params || { });
	};

	//GeneratorFlag:[Category.ListAction]
	async listAction(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/category/list-action', params || { });
	};

//GeneratorFlag:[NewAction]
}