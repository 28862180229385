<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { Ticket, TicketFilter } from '@/modules/ticketing/data/ticket/my-list'
	import { TicketService } from '@/modules/ticketing/services'
	export default {
		computed:
		{
			entity()
			{
				return Ticket;
			},
			
			filterEntity()
			{
				return TicketFilter;
			},

			service()
			{
				return new TicketService();
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				try
				{
					return await this.service.myList(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>