const fa = {

	service: {
		hub:"زیرسیستم مرکزی",
		peopleNet:"زیرسیستم اشخاص",
		shop:"زیرسیستم فروشگاه آنلاین",
		cms:"زیر سیستم مدیرت محتوا",
		guard:"زیرسیستم نگهبانی",
		financial: "زیرسیتم مالی",
		creditPay:"زیرسیستم پرداخت اعتباری",
		shopMarketer:"زیرسیستم بازاریاب",
		automation:"زیرسیستم اتوماسیون",
	},

	global: {
		applicationName: 'نرم افزار کاوه',
		signIn: 'ورود به سیستم',
		logOut: 'خروج از سیستم',
		save: 'ذخیره اطلاعات',
		ApplyFilters: 'اعمال فیلترها',
		excelKey: 'کلید اکسل',
		operations: 'عملگرها',
		mathOperators: 'عملگرهای ریاضی',
		RelOperators: 'عملگرهای رابطه‌ای',
		logicOperators: 'عملگرهای منطقی',
		copyright: 'طراحی و تولید توسط  گروه نرم افزاری کاوه',
		filters: "فیلترها",
		fromDate: "از تاریخ",
		toDate: "تا تاریخ",
		fromTime: "از ساعت",
		toTime: "تا ساعت",
		date:"تاریخ"
	},

	system: {
		pleaseWaite: 'لطفا کمی صبر کنید ...',
		downloading: 'سیستم درحال دریافت اطلاعات است، لطفا منتظر بمانید.',
		uploading: 'سیستم درحال ارسال اطلاعات است، لطفا منتظر بمانید.',
		progressing: 'سیستم درحال انجام عملیات است، لطفا منتظر بمانید.',
		notfound: 'اطلاعاتی جهت نمایش یافت نشد!',
		dataError: 'دریافت اطلاعات با خطا مواجه شد! لطفا با پشتیبان سیستم تماس حاصل فرمایید.',
		wellcome: "به سیستم یکپارچه سازمانی کاوه خوش آمدید. </br> این سیستم با تکنولوژی وب سایت پیشرونده تولید شده است و امکان نصب بر روی انواع سیستم عامل ها را دارد."
	},

	layout: {
		default: 'قالب پیشفرض',
		minimal:'قالب مینیمال'
	},

	router: {
		back: 'بازگشت',
	},

	status: {
		errorLen: 'تعداد خطا',
		successLen: 'تعداد موفق',
		errorShort: 'عملیات ناموفق',
		successShort: 'عملیات موفق',
		error: 'عملیات با خطا مواجه شد.',
		ok: 'عملیات با موفقیت انجام شد.',
		notFound: 'مقداری یافت نشد',
		unknown: 'خطای داخلی در سرور! لطفا با پشتیبان سیستم تماس حاصل فرمایید.',
		internal:"خطای داخلی",
		deleteRelationError: "به علت وجود رابطه با بخش های دیگر سیستم امکان حذف وجود ندارد",
		unavailableService: "سرویس در دسترس نیست",
		unauthorized:"شما به این بخش دسترسی ندارید و یا جلسه کاری شما پایان یافته است، لطفا دوباره وارد سیستم شوید.",
		unavailable:"سرویس در دسترس نیست",
		invalidPrimaryKey: 'شناسه نامعتبر است',
		invalidArgument: 'اطلاعات وارد شده صحیح نیست',
		alreadyExists: 'اطلاعات وارد شده تکراری است.',
		permissionDenied:'مجوز دسترسی نامعتبر است.',
		noResponse:'سرور در دسترس نیست! هیچ پاسخی برای درخواست دریافت نشد.',
		serverError: 'خطای داخلی در سرور! لطفا با پشتیبان سیستم تماس حاصل فرمایید.',
		badRequest: 'درخواست نامعتبر است! لطفا با پشتیبان سیستم تماس حاصل فرمایید.',
		networkError: 'خطا در برقرای ارتباط با سرور! این خطا ممکن است به دلیل قطع بودن ارتباط شما با اینترنت رخ داده باشد',
	},


	action: {
		create: 'افزودن',
		save: 'ذخیره اطلاعات',
		responce: 'پاسخ',
		sendResponce:'ارسال پاسخ',
		createNewNode:'افزودن شاخه جدید',
		update: 'ویرایش',
		filter: 'فیلتر',
		import: 'ورود اطلاعات',
		export: 'خروج اطلاعات',
		exportExcel: 'خروج به صورت اکسل',
		importFromExcel: 'ورود از طریق اکسل',
		history: 'مشاهده تاریخچه',
		importData: 'ورود اطلاعات',
		delete: 'حذف',
		deleteData: 'حذف اطلاعات',
		back: 'بازگشت',
		search: 'جستجو',
		searchPoint: 'جستجو ...',
		more: 'بیشتر',
		applyFilter: 'اعمال فیلتر',
		toggleFilter:'بستن/بازکردن فیلتر',
		showList: 'مشاهده لیست',
	},

	page: {
		Frc: 'نرم افزار کاوه',
		home: 'خانه',
		profile: 'پروفایل',
		changePass: 'تغییر کلمه عبور',
		notFound: 'صفحه ای یافت نشد',
		cpanel: 'نرم افزار کاوه',
		baseInformation: 'اطلاعات پایه سیستم',
		auth: 'ورود به سیستم',
		dashboard: 'داشبورد',
		baseInfo: "مدیریت اطلاعات پایه",
		baseSys: "اطلاعات سیستمی",
		service: 'سرویس ها',
		cycles: 'چرخه ها',
		accessDenied:'عدم دسترسی'
	},

	error: {
		chackForm: 'لطفا ابتدا خطاهای فرم را بررسی  و رفع نمایید.',
		required: 'فیلد {label} اجباری است',
		invalid: 'فیلد {label} نامعتبر است',
		digit: 'فیلد {label} باید از نوع عدد بدون 0 اولیه باشد',
		number: 'فیلد {label} باید از نوع عدد باشد',
		stringLength: 'فیلد {label} از حداکثر تعداد مجاز کاراکتر بیشتر وارد شده است',
		noUnicodeString: 'فقط کاراکترهای و اعداد انگلیسی مجاز است',
		byteOutOfRange: 'بیشترین مقدار مجاز برای این فیلد 255 است',
		int16OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 32,767 است',
		uint16OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 65,535 است',
		int32OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 2,147,483,647 است',
		uint32OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 4,294,967,295 است',
		int64OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 9,223,372,036,854,775,807 است',
		uint64OutOfRange: 'بیشترین مقدار مجاز برای این فیلد 18,446,744,073,709,551,615 است',
	},

	data: {
		uniquekey:'کلید یکتا',
		subInfo: {
			self: 'اطلاعات تکمیلی',
			creatorId: 'شناسه ایجاد کننده',
			creatorIp: 'ip ایجاد کننده',
			creatorName: 'نام ایجاد کننده',
			createDate: 'تاریخ ایجاد/میلادی',
			displayCreateDate: 'تاریخ ایجاد/شمسی',
			createTime: 'زمان ایجاد',
			modifierId: 'شناسه ویرایش کننده',
			modifierIp: 'ip ویرایش کننده',
			modifierName: 'نام ویرایش کننده',
			modifyDate: 'تاریخ ویرایش/میلادی',
			displayModifyDate: 'تاریخ ویرایش/شمسی',
			modifyTime: 'زمان ویرایش',
			summary: 'توسط {creatorName} در {displayCreateDate}'
		},

		userAgent: {
			self: 'اطلاعات کاربری',
			userName: 'نام کاربری',
			password: 'کلمه عبور',
		},

		history: {
			self: 'تاریخچه',
			entityValue: 'تغییرات',
			historyName: 'نوع ثبت',
			fullName: 'نام کاربر',
			id: 'شناسه کاربر',
			ip: 'ایپی کاربر',
		},

		historyTime: {
			self: 'مشخصات زمانی',
			createDate: 'تاریخ/میلادی',
			createPersianDate: 'تاریخ/شمسی',
			createTime: 'ساعت',
		}
	},

	com: {
		excel: {
			chooseFile: 'انتخاب فایل',
			backToSelectFile: 'بازگشت',
			review: 'بررسی مجدد',
			reviewLength: 'تعداد بررسی ها',
			NumberOfReviewsPerformed: 'تعداد بررسی های انجام شده',
			reviewMessage: 'در صورتی که بعد از بررسی مجدد هیچ خطایی در اطلاعات وجود نداشته باشد سیستم وارد بخش ورود اطلاعات میشود.',
			fileIsEmpty: 'فایل انتخاب شده فاقد دیتا است!',
			downloadSampleFile: 'دریافت فایل خام',
			fileProcessing: 'سیستم در حال پردازش فایل است، لطفا منتظر باشید.',
			fileHasError: `<strong class="text-danger d-block mb-2">فایل دارای اطلاعات نامعتبر است</strong>
					<p>از طریق جدول زیر میتوانید بدون نیاز به ویرایش فایل اکسل، تمامی اطلاعات نامعتبر و دارای خطا را تصحیح نمایید.</br> شماره رکورد خطا ها به شرح زیر است :</p>`,

			warning: `<p>جهت ورود اطلاعات از طریق فایل اکسل به نکات زیر دقت فرمایید.</p>
				<ul>
					<li>ابتدا فایل خام را دانلود نمایید.</li>
					<li>دیتای مورد نظر خود را درون فایل خام وارد نمایید.</li>
					<li>فیلد های الزامی با علات ستاره (*) مشخص شده اند.</li>
					<li>رکورد شماره یک فایل خام را تغییر ندهید.</li>
					<li>فایل خام ممکن است بیش از یک شیت داشته باشد که اطلاعات هر قسمت درون شیت مخصوص خود قرار میگیرد.</li>
				</ul>`,
		},

		table: {
			filterResult: 'فیلتر بر روی {totalfilters} ردیف انجام شد.',
			pageInfo: 'صفحه {currentPage} از {totalPages}',
		},

		task: {
			repeat: 'تکرار دوباره',
			skip: 'رد شدن',
			replace: 'جایگزین کردن',
			cancel: 'لغو کامل عملیات',
			doTasks: 'انجام عملیات',
			cancelTasks: 'لغو عملیات',
			errorContent: 'محتوای خطا',
			taskRowNumberError: 'رکورد شماره {index} با خطا مواجه شد.',
			tasksFailedLenght: 'تعداد رکوردهای ناموفق',
			tasksSucceedLenght: 'تعداد رکوردهای موفق',
			applyToAllCases: 'این انتخاب برای تمامی موارد بعدی با خطای مشابه تکرار شود',
			progressing: 'سیستم درحال انجام عملیات است، لطفا منتظر بمانید.',
			tasksCompletedWithError: 'عملیات همراه با خطا به پایان رسید.',
			tasksCompletedSuccess: 'عملیات با موفقیت به پایان رسید.',

			warning: `<strong>اطلاعات زیر جهت {label} انتخاب شده است.</strong>
				<p>درصورتی که از انجام عملیات فوق اطمینان دارید بر روی دکمه انجام عملیات کلیک نمایید.</p>`,
		},

		uploader: {
			chooseMessage: "فایل خود را با این قسمت بکشید و رها کنید و یا روی دکمه انتخاب فایل کلیک نمایید.",
			chooseButton: "انتخاب فایل",
			addFile:"افزودن فایل جدید",
			count: "تعداد {label} فایل انتخاب شده است",
			allowExtensions:"فرمت های قابل قبول",
			errors: {
				invalidType: "فرمت فایل {label} نامعتبر است",
				duplicate:"فایل {label} قبلا انتخا شده است"
			}
		},

		common: {
			cd: {
				warning: `<strong>کاربر گرامی شما در حال انجام عملیات حذف هستید!</strong>
				<p>درصورتی که از انجام عملیات فوق اطمینان دارید بر روی دکمه حذف اطلاعات کلیک نمایید.</p>`,
				ok: 'حذف اطلاعات',
				cancel: 'لغو و بازگشت',
			}
		},

		form: {
			checkbox: {
				yes:'بله',
				no:'خیر'
			}
		},

		camera: {
			open: 'اتصال به دوربین',
			close: 'قطع اتصال دوربین',
			take: 'ثبت تصویر'
		}
	}
}

export default fa
