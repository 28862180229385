//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonAppPurchaseStatuseService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(personAppPurchaseStatuseId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:personAppPurchaseStatuseId
		}

		return await _server.get('/people-net/person-app-purchase-statuse/find', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/people-net/person-app-purchase-statuse/list', {'params':params})
	};
}
