<template>
	<div class="Frc-collapse">
		<div class="header" @click="onHandelClick">
			<Frc-icon-bootstrap size="14px" v-bind:name="getIconName()" class="me-2" />
			<slot name="header"></slot>
		</div>
		<div class="body" v-if="open">
			<slot name="body"></slot>
		</div>
	</div>
</template>
<script>

	export default {
		props: {
			collapsed: {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				open: this.collapsed
			}
		},

		computed:
		{
			direction() {
				return this.$store.state.app.direction
			},
		},

		methods:
		{
			getIconName()
			{
				if (this.direction == 'rtl') {
					return this.open ? 'bi-arrow-down' : 'bi-arrow-left'
				}

				return this.open ? 'bi-arrow-down' : 'bi-arrow-right'
			},

			onHandelClick()
			{
				if (!this.collapsed) { this.open = !this.open }
			}
		}

	}
</script>

<style scoped>
	.Frc-collapse {
		width: 100%;
		height: auto;
		float: left;
	}

		.Frc-collapse .header,
		.Frc-collapse .body {
			float: left;
			width: 100%;
			min-height: 40px;
		}

		.Frc-collapse .header {
			height: 30px;
			padding-top: 10px;
			cursor: pointer;
			user-select: none;
			font-weight: bold;
			margin-top: 3px;
			transition: all .3s;
		}

			.Frc-collapse .header * {
				margin-left: 10px;
			}

		.Frc-collapse .body {
			padding: 20px 0;
		}
</style>
