<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Product, ProductFilter } from '../../data/product/list'
	import { ProductService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return Product;
			},
			
			filterEntity()
			{
				return ProductFilter;
			},

			service()
			{
				return new ProductService();
			},

			filterData()
			{
				const productId =
					this.$route.query['productId'];

				if (productId)
				{
					return {
						'productId': productId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'shop.product.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'shop.product.import',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						resourceKey: 'action.update',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'shop.product.productSpecificationUpdate',
								resourceKey: 'shop.views.product.ProductSpecificationUpdate',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'shop.product.update',
								resourceKey: 'shop.views.product.update',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							}
						],
					},

					{
						routeName: 'shop.product.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'shop.product.productSpecificationDetail',
								resourceKey: 'shop.views.product.productSpecificationDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
