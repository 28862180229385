<template>
	<component :is="getComponent"  :data="data"/>
	<table-cell-errors v-if="showCellErrors" :data="data" />
</template>
<script>
	import tableCellText from './table-cell-text'
	import tableCellPopup from './table-cell-popup'
	import tableCellErrors from './table-cell-errors'

	export default {
		components:
		{
			'table-cell-text': tableCellText,
			'table-cell-popup': tableCellPopup,
			'table-cell-errors': tableCellErrors,
		},

		props: {

			data: Object,
			showError: {
				type: Boolean,
				default: false
			}
		},

		computed: {

			showCellErrors()
			{
				return this.showError && (this.data.errors || []).length > 0
			},

			getComponent()
			{
				switch (this.data.type)
				{
					case "object":
						return "table-cell-popup";

					default:
						return "table-cell-text";
				}
			}
		},
	}
</script>
