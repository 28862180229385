<template>
    <form-tree-ul :root="root">
        <form-tree-li v-for="(node,index) in nodes"
                      v-on:change="onHandelChange"
                      :checkType="checkTypeLocal"
                      :key="index"
                      :node="node">
            <form-tree v-if="node.hasChild" v-on:change="onHandelChange" :items="node.children" :root="false" :model="model" />
        </form-tree-li>
    </form-tree-ul>
</template>

<script>
import formTreeUl from './form-tree-ul'
import formTreeLi from './form-tree-li'

export default {
  emits: ['change'],
  components: {
    'form-tree-ul': formTreeUl,
    'form-tree-li': formTreeLi
  },

  props:
        {
          model: Object,

          items: Array,

          checkType: {
            type: String,
            default: 'checkbox'
          },

          root: {
            type: Boolean,
            default: true
          }
        },

  data () {
    return {
      nodes: [],
      values: [],
      checked: [],
      checkTypeLocal: null,
      modelLocal: this.model
    }
  },

  beforeMount () {
    if (this.root && this.model.value) { this.values = JSON.parse(this.modelLocal.value) }

    this.checkTypeLocal = this.model.form.checkType || this.checkType
    this.initNodes()
  },

  methods:
        {
          initNodes () {
            var nodes = []
            var self = this
            var data = this.items || this.model.data

            if ((this.model.server || {}).ep) {
              console.log(this.model.server.ep)
            } else if (data && data.length > 0) {
              for (const item of data) {
                var checked = (item.checked != undefined ? item.checked : false)
                var expand = (item.expand != undefined ? item.expand : false)

                nodes.push({
                  id: btoa(item[self.model.form.valueKey]),
                  text: item[self.model.form.textKey],
                  value: item[self.model.form.valueKey],
                  hasChild: item.hasChild,
                  children: item.children,
                  checked: checked,
                  expand: expand
                })
              }

              self.nodes =
                        this.prepareNodes(nodes,
                          (typeof (this.modelLocal.value) === 'string'
                            ? JSON.parse(this.modelLocal.value) : []))
            }
          },

          prepareNodes (nodes, value) {
            var prepare = (nodes) => {
              for (var node of nodes) {
                if (value.indexOf(node.value) > -1) { node.checked = true }

                if ((node.children || []).length > 0) { prepare(node.children) }
              }

              return nodes
            }

            return prepare(nodes)
          },

          onHandelChange (node) {
            if (!this.root) { this.$emit('change', node) } else {
              var i = this.values.indexOf(node.value)
              if (i == -1 && node.checked) this.values.push(node.value)
              if (i > -1 && !node.checked) this.values.splice(i, 1)

              this.$emit('change', JSON.stringify(this.values))
            }
          }
        }
}
</script>
<style>
    .Frc-form-tree {
        border: #ced4da solid 1px;
        border-radius: 4px;
        list-style: none;
        padding: 10px 5px 10px 10px;
        height: 200px;
        overflow: auto;
        resize: vertical;
        user-select: none;
    }

        .Frc-form-tree ul {
            list-style: none;
            padding-right: 20px;
        }

        .Frc-form-tree .node:hover > .meta {
            background: #f8f8f8;
        }

        .Frc-form-tree .node .meta {
            width: 100%;
            padding: 2px 0;
        }

            .Frc-form-tree .node .meta .arrow {
                display: inline-block;
                width: 16px;
            }
</style>
