//GeneratorFlag:[NoChange]
import { BasePersonDocumentType } from '../base/data'
export default class PersonDocumentType extends BasePersonDocumentType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personDocumentTypeId: {
				...this.props.personDocumentTypeId,
				setConfig:{
					...this.props.personDocumentTypeId.setConfig
				}
			},
			personDocumentTypeKey: {
				...this.props.personDocumentTypeKey,
				setConfig:{
					...this.props.personDocumentTypeKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			isOrigin: {
				...this.props.isOrigin,
				setConfig:{
					...this.props.isOrigin.setConfig
				}
			},

		};
	}
}
