//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Person } from '@/modules/people-net/data'; 
import { InvoiceStatuse, InvoicePaymentType, Store, PersonAddress } from '../../data'; 

import { PersonService } from '@/modules/people-net/services'; 
import { InvoiceStatuseService, InvoicePaymentTypeService, StoreService, PersonAddressService } from '../../services'; 

export default class BaseInvoice extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'invoice';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.invoice.self';
	props = {
		invoiceId: {
			primarykey: true,
			name: 'invoiceId',
			resourceKey: 'shop.invoice.invoiceId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'shop.invoice.personId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Person(),
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: false,
				textKey: 'personName',
				valueKey: 'personId',
				patterns: [],
			}
		},
		totalAmount: {
			name: 'totalAmount',
			resourceKey: 'shop.invoice.totalAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		discountAmount: {
			name: 'discountAmount',
			resourceKey: 'shop.invoice.discountAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		paybleAmount: {
			name: 'paybleAmount',
			resourceKey: 'shop.invoice.paybleAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		paidAmount: {
			name: 'paidAmount',
			resourceKey: 'shop.invoice.paidAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		totalRemainAmount: {
			name: 'totalRemainAmount',
			resourceKey: 'shop.invoice.totalRemainAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		invoiceStatuseId: {
			foreignKey: true,
			name: 'invoiceStatuseId',
			resourceKey: 'shop.invoice.invoiceStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new InvoiceStatuse(),
			data: async (params) =>
			{
				const service =
					new InvoiceStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'invoiceStatuseId',
				patterns: [],
			}
		},
		createdDate: {
			name: 'createdDate',
			resourceKey: 'shop.invoice.createdDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		transportCostAmount: {
			name: 'transportCostAmount',
			resourceKey: 'shop.invoice.transportCostAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		packagingCostAmount: {
			name: 'packagingCostAmount',
			resourceKey: 'shop.invoice.packagingCostAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		taxAmount: {
			name: 'taxAmount',
			resourceKey: 'shop.invoice.taxAmount',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		checkoutDate: {
			name: 'checkoutDate',
			resourceKey: 'shop.invoice.checkoutDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		displayCheckoutDate: {
			name: 'displayCheckoutDate',
			resourceKey: 'shop.invoice.displayCheckoutDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		invoicePaymentTypeId: {
			foreignKey: true,
			name: 'invoicePaymentTypeId',
			resourceKey: 'shop.invoice.invoicePaymentTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new InvoicePaymentType(),
			data: async (params) =>
			{
				const service =
					new InvoicePaymentTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'invoicePaymentTypeId',
				patterns: [],
			}
		},
		storeId: {
			foreignKey: true,
			name: 'storeId',
			resourceKey: 'shop.invoice.storeId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Store(),
			data: async (params) =>
			{
				const service =
					new StoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'storeName',
				valueKey: 'storeId',
				patterns: [],
			}
		},
		personAddressId: {
			foreignKey: true,
			name: 'personAddressId',
			resourceKey: 'shop.invoice.personAddressId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new PersonAddress(),
			data: async (params) =>
			{
				const service =
					new PersonAddressService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'addressTitle',
				valueKey: 'personAddressId',
				patterns: [],
			}
		},
		invoiceSerial: {
			name: 'invoiceSerial',
			resourceKey: 'shop.invoice.invoiceSerial',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		isConnected: {
			name: 'isConnected',
			resourceKey: 'shop.invoice.isConnected',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		invoicePaymentType: {
			name: 'invoicePaymentType',
			resourceKey: 'shop.invoice.invoicePaymentType',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new InvoicePaymentType(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceStatuse: {
			name: 'invoiceStatuse',
			resourceKey: 'shop.invoice.invoiceStatuse',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new InvoiceStatuse(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		person: {
			name: 'person',
			resourceKey: 'shop.invoice.person',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Person(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personAddress: {
			name: 'personAddress',
			resourceKey: 'shop.invoice.personAddress',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonAddress(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{addressTitle}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		store: {
			name: 'store',
			resourceKey: 'shop.invoice.store',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Store(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{storeName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		invoiceSubInfo: {
			name: 'invoiceSubInfo',
			resourceKey: 'data.subInfo.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		shopPerson: {
			name: 'shopPerson',
			resourceKey: 'shop.invoice.shopPerson',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Person(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
