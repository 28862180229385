
const DISPLAY_TYPE =
{
	TEXT: 'text',
	IMAGE: 'image',
	LINK: 'link',
	POPUP: 'popup'
};


export default Object.freeze(DISPLAY_TYPE);
