//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Person } from '@/modules/people-net/data'; 
import { PersonCreditTransactionType, PersonCreditTransactionStatuse } from '../../data'; 

import { PersonService } from '@/modules/people-net/services'; 
import { PersonCreditTransactionTypeService, PersonCreditTransactionStatuseService } from '../../services'; 

export default class BasePersonCreditTransaction extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'personCreditTransaction';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'peopleNet.personCreditTransaction.self';
	props = {
		personCreditTransactionId: {
			primarykey: true,
			name: 'personCreditTransactionId',
			resourceKey: 'peopleNet.personCreditTransaction.personCreditTransactionId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'peopleNet.personCreditTransaction.personId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Person(),
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,
				canUseInExcel: false,
				textKey: 'firstName',
				valueKey: 'personId',
				patterns: [],
			}
		},
		personCreditTransactionTypeId: {
			foreignKey: true,
			name: 'personCreditTransactionTypeId',
			resourceKey: 'peopleNet.personCreditTransaction.personCreditTransactionTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonCreditTransactionType(),
			data: async (params) =>
			{
				const service =
					new PersonCreditTransactionTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personCreditTransactionTypeId',
				patterns: [],
			}
		},
		personCreditTransactionStatuseId: {
			foreignKey: true,
			name: 'personCreditTransactionStatuseId',
			resourceKey: 'peopleNet.personCreditTransaction.personCreditTransactionStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new PersonCreditTransactionStatuse(),
			data: async (params) =>
			{
				const service =
					new PersonCreditTransactionStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'personCreditTransactionStatuseId',
				patterns: [],
			}
		},
		caption: {
			name: 'caption',
			resourceKey: 'peopleNet.personCreditTransaction.caption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 250,
				patterns: [],
			}
		},
		transactionRefCode: {
			name: 'transactionRefCode',
			resourceKey: 'peopleNet.personCreditTransaction.transactionRefCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 50,
				patterns: [],
			}
		},
		displayTransactionDate: {
			name: 'displayTransactionDate',
			resourceKey: 'peopleNet.personCreditTransaction.displayTransactionDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 10,
				isUnicode: false,
				patterns: [],
			}
		},
		transactionDate: {
			name: 'transactionDate',
			resourceKey: 'peopleNet.personCreditTransaction.transactionDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		creditValue: {
			name: 'creditValue',
			resourceKey: 'peopleNet.personCreditTransaction.creditValue',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		personCommiter: {
			name: 'personCommiter',
			resourceKey: 'peopleNet.personCreditTransaction.personCommiter',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,
				canUseInExcel: true,
				patterns: [],
			}
		},
		commiterDescription: {
			name: 'commiterDescription',
			resourceKey: 'peopleNet.personCreditTransaction.commiterDescription',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,
				canUseInExcel: true,
						stringLength: 10,
				isUnicode: false,
				patterns: [],
			}
		},
		person: {
			name: 'person',
			resourceKey: 'peopleNet.personCreditTransaction.person',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Person(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{firstName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personCreditTransactionStatuse: {
			name: 'personCreditTransactionStatuse',
			resourceKey: 'peopleNet.personCreditTransaction.personCreditTransactionStatuse',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonCreditTransactionStatuse(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		personCreditTransactionType: {
			name: 'personCreditTransactionType',
			resourceKey: 'peopleNet.personCreditTransaction.personCreditTransactionType',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new PersonCreditTransactionType(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
