//GeneratorFlag:[NoChange]
import BaseBank from './base-bank';
import BaseBankPayment from './base-bank-payment';
import BaseCategory from './base-category';
import BaseCity from './base-city';
import BaseCompany from './base-company';
import BaseCompanyRank from './base-company-rank';
import BaseCountry from './base-country';
import BaseCulture from './base-culture';
import BaseFeature from './base-feature';
import BaseFeatureItem from './base-feature-item';
import BaseInvoice from './base-invoice';
import BaseInvoicePaymentType from './base-invoice-payment-type';
import BaseInvoiceProduct from './base-invoice-product';
import BaseInvoiceStatuse from './base-invoice-statuse';
import BaseInvoiceTransaction from './base-invoice-transaction';
import BaseInvoiceTransactionType from './base-invoice-transaction-type';
import BasePerson from './base-person';
import BasePersonAddress from './base-person-address';
import BasePersonCreaditTransactionType from './base-person-creadit-transaction-type';
import BasePlanLine from './base-plan-line';
import BasePlanPrecent from './base-plan-precent';
import BasePlanSetting from './base-plan-setting';
import BaseProduct from './base-product';
import BaseProductCategory from './base-product-category';
import BaseProductColor from './base-product-color';
import BaseProductDetail from './base-product-detail';
import BaseProductDoc from './base-product-doc';
import BaseProductDocType from './base-product-doc-type';
import BaseProductFeature from './base-product-feature';
import BaseProductMonetaryFeature from './base-product-monetary-feature';
import BaseProductSpecification from './base-product-specification';
import BaseProductStatus from './base-product-status';
import BaseProductStore from './base-product-store';
import BaseProductTransportCostType from './base-product-transport-cost-type';
import BaseProvince from './base-province';
import BaseRank from './base-rank';
import BaseShopPerson from './base-shop-person';
import BaseStore from './base-store';
import BaseTeam from './base-team';

export default {BaseBank, BaseBankPayment, BaseCategory, BaseCity, BaseCompany, BaseCompanyRank, BaseCountry, BaseCulture, BaseFeature, BaseFeatureItem, BaseInvoice, BaseInvoicePaymentType, BaseInvoiceProduct, BaseInvoiceStatuse, BaseInvoiceTransaction, BaseInvoiceTransactionType, BasePerson, BasePersonAddress, BasePersonCreaditTransactionType, BasePlanLine, BasePlanPrecent, BasePlanSetting, BaseProduct, BaseProductCategory, BaseProductColor, BaseProductDetail, BaseProductDoc, BaseProductDocType, BaseProductFeature, BaseProductMonetaryFeature, BaseProductSpecification, BaseProductStatus, BaseProductStore, BaseProductTransportCostType, BaseProvince, BaseRank, BaseShopPerson, BaseStore, BaseTeam}
export{BaseBank, BaseBankPayment, BaseCategory, BaseCity, BaseCompany, BaseCompanyRank, BaseCountry, BaseCulture, BaseFeature, BaseFeatureItem, BaseInvoice, BaseInvoicePaymentType, BaseInvoiceProduct, BaseInvoiceStatuse, BaseInvoiceTransaction, BaseInvoiceTransactionType, BasePerson, BasePersonAddress, BasePersonCreaditTransactionType, BasePlanLine, BasePlanPrecent, BasePlanSetting, BaseProduct, BaseProductCategory, BaseProductColor, BaseProductDetail, BaseProductDoc, BaseProductDocType, BaseProductFeature, BaseProductMonetaryFeature, BaseProductSpecification, BaseProductStatus, BaseProductStore, BaseProductTransportCostType, BaseProvince, BaseRank, BaseShopPerson, BaseStore, BaseTeam}
