<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { WalletWithDrawStatuse, WalletWithDrawStatuseFilter } from '@/modules/financial/data/wallet-with-draw-statuse/list'
	import { WalletWithDrawStatuseService } from '@/modules/financial/services'
	export default {
		computed:
		{
			entity()
			{
				return WalletWithDrawStatuse;
			},
			
			filterEntity()
			{
				return WalletWithDrawStatuseFilter;
			},

			service()
			{
				return new WalletWithDrawStatuseService();
			},

			filterData()
			{
				const walletWithDrawStatuseId =
					this.$route.query['walletWithDrawStatuseId'];

				if (walletWithDrawStatuseId)
				{
					return {
						'walletWithDrawStatuseId': walletWithDrawStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>