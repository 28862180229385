<template>
	<div class="d-inline-block me-1 mb-2">
		<popper class="popper-light" arrow>
			<button class="btn bg-base-color-dark border-0">
				<Frc-icon-bootstrap name="bi-download" size="15px" />
			</button>
			<template #content>
				<a class="cursor-pointer" @click="$emit('exportExcel')">
					<Frc-icon-bootstrap name="bi-file-earmark-excel" size="15px" />
					<small class="ps-2 pe-5">
						{{ $loc.fromResource('action.exportExcel') }}
					</small>
				</a>
			</template>
		</popper>
	</div>
</template>
