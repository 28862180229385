<!--GeneratorFlag:[NoChange]-->
<template>
	<div class="custom-form">
		<div class="mb-3 row">
			<label v-once for="productId" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.productId.resourceKey)}}</span>
				<span v-if="isRequired('productId')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.productId.value" @keyup="onHandelKeyUp('productId')" @change="onHandelChange('productId')" dir="ltr" type="number" class="form-control" id="productId" autocomplete="off">
				<small class="text-danger" v-if="form.productId.errors.length > 0">
					{{getFirstError('productId')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="productKey" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.productKey.resourceKey)}}</span>
				<span v-if="isRequired('productKey')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<select class="form-select" v-bind:class="{'is-loading': productListIsLoading}" v-model="form.productKey.value" @change="onHandelChange('productKey')" id="productKey">
					<option v-for="(item,index) in productList" :value="item.productKey" :key="index">
						{{item.title}}
					</option>
				</select>
				<small class="text-danger" v-if="form.productKey.errors.length > 0">
					{{getFirstError('productKey')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="cultureId" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.cultureId.resourceKey)}}</span>
				<span v-if="isRequired('cultureId')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<select class="form-select" v-bind:class="{'is-loading': cultureListIsLoading}" v-model="form.cultureId.value" @change="onHandelChange('cultureId')" id="cultureId">
					<option v-for="(item,index) in cultureList" :value="item.cultureId" :key="index">
						{{item.title}}
					</option>
				</select>
				<small class="text-danger" v-if="form.cultureId.errors.length > 0">
					{{getFirstError('cultureId')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="categoryId" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.categoryId.resourceKey)}}</span>
				<span v-if="isRequired('categoryId')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<select class="form-select" v-bind:class="{'is-loading': categoryListIsLoading}" v-model="form.categoryId.value" @change="onHandelChange('categoryId')" id="categoryId">
					<option v-for="(item,index) in categoryList" :value="item.categoryId" :key="index">
						{{item.title}}
					</option>
				</select>
				<small class="text-danger" v-if="form.categoryId.errors.length > 0">
					{{getFirstError('categoryId')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="title" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.title.resourceKey)}}</span>
				<span v-if="isRequired('title')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.title.value" @keyup="onHandelKeyUp('title')" @change="onHandelChange('title')" dir="rtl" type="text" class="form-control" id="title" autocomplete="off">
				<small class="text-danger" v-if="form.title.errors.length > 0">
					{{getFirstError('title')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="caption" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.caption.resourceKey)}}</span>
				<span v-if="isRequired('caption')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.caption.value" @keyup="onHandelKeyUp('caption')" @change="onHandelChange('caption')" dir="rtl" type="text" class="form-control" id="caption" autocomplete="off">
				<small class="text-danger" v-if="form.caption.errors.length > 0">
					{{getFirstError('caption')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="firstPrice" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.firstPrice.resourceKey)}}</span>
				<span v-if="isRequired('firstPrice')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.firstPrice.value" @keyup="onHandelKeyUp('firstPrice')" @change="onHandelChange('firstPrice')" dir="ltr" type="number" class="form-control" id="firstPrice" autocomplete="off">
				<small class="text-danger" v-if="form.firstPrice.errors.length > 0">
					{{getFirstError('firstPrice')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="productCode" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.productCode.resourceKey)}}</span>
				<span v-if="isRequired('productCode')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.productCode.value" @keyup="onHandelKeyUp('productCode')" @change="onHandelChange('productCode')" dir="ltr" type="text" class="form-control" id="productCode" autocomplete="off">
				<small class="text-danger" v-if="form.productCode.errors.length > 0">
					{{getFirstError('productCode')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="imagePath" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.imagePath.resourceKey)}}</span>
				<span v-if="isRequired('imagePath')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.imagePath.value" @keyup="onHandelKeyUp('imagePath')" @change="onHandelChange('imagePath')" dir="ltr" type="text" class="form-control" id="imagePath" autocomplete="off">
				<small class="text-danger" v-if="form.imagePath.errors.length > 0">
					{{getFirstError('imagePath')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="productStatusId" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.productStatusId.resourceKey)}}</span>
				<span v-if="isRequired('productStatusId')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<select class="form-select" v-bind:class="{'is-loading': productStatusListIsLoading}" v-model="form.productStatusId.value" @change="onHandelChange('productStatusId')" id="productStatusId">
					<option v-for="(item,index) in productStatusList" :value="item.productStatusId" :key="index">
						{{item.title}}
					</option>
				</select>
				<small class="text-danger" v-if="form.productStatusId.errors.length > 0">
					{{getFirstError('productStatusId')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="productMonetaryFeatureTitle" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.productMonetaryFeatureTitle.resourceKey)}}</span>
				<span v-if="isRequired('productMonetaryFeatureTitle')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.productMonetaryFeatureTitle.value" @keyup="onHandelKeyUp('productMonetaryFeatureTitle')" @change="onHandelChange('productMonetaryFeatureTitle')" dir="rtl" type="text" class="form-control" id="productMonetaryFeatureTitle" autocomplete="off">
				<small class="text-danger" v-if="form.productMonetaryFeatureTitle.errors.length > 0">
					{{getFirstError('productMonetaryFeatureTitle')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="productSpecification" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.productSpecification.resourceKey)}}</span>
				<span v-if="isRequired('productSpecification')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.productSpecification.value" @keyup="onHandelKeyUp('productSpecification')" @change="onHandelChange('productSpecification')" dir="rtl" type="form" class="form-control" id="productSpecification" autocomplete="off">
				<small class="text-danger" v-if="form.productSpecification.errors.length > 0">
					{{getFirstError('productSpecification')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="details" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.details.resourceKey)}}</span>
				<span v-if="isRequired('details')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.details.value" @keyup="onHandelKeyUp('details')" @change="onHandelChange('details')" dir="rtl" type="form" class="form-control" id="details" autocomplete="off">
				<small class="text-danger" v-if="form.details.errors.length > 0">
					{{getFirstError('details')}}
				</small>
			</div>
		</div>

		<div class="mb-3 row">
			<label v-once for="images" class="col-4 col-md-3">
				<span>{{$loc.fromResource(form.images.resourceKey)}}</span>
				<span v-if="isRequired('images')" class="text-danger"> * </span>
			</label>
			<div class="col-8 col-md-9">
				<input v-model="form.images.value" @keyup="onHandelKeyUp('images')" @change="onHandelChange('images')" dir="rtl" type="form" class="form-control" id="images" autocomplete="off">
				<small class="text-danger" v-if="form.images.errors.length > 0">
					{{getFirstError('images')}}
				</small>
			</div>
		</div>


	</div>
</template>
<script>
	import { ENUMS } from '@/core/data'
	import { validator } from '@/plugins'
	import { ProductService } from '@/modules/shop/services'; 
	import { CultureService, CategoryService, ProductStatusService } from '@/modules/shop/services'; 

	export default {
		props:{
			useValidator:{
				type: Boolean,
				default: true
			}
		},

		data()
		{
			return{
				productList: [],
				cultureList: [],
				categoryList: [],
				productStatusList: [],
					
				productListIsLoading: true,
				cultureListIsLoading: true,
				categoryListIsLoading: true,
				productStatusListIsLoading: true,
					
				form:{
					productId: {
						type: ENUMS.PROP_TYPE.INT32,
						resourceKey: 'shop.productRegister.productId',
						patterns: [],
						errors: [],
						value: null,
					},
					productKey: {
						type: ENUMS.PROP_TYPE.INT32,
						resourceKey: 'shop.productRegister.productKey',
						patterns: ['required'],
						errors: [],
						value: null,
					},
					cultureId: {
						type: ENUMS.PROP_TYPE.BYTE,
						resourceKey: 'shop.productRegister.cultureId',
						patterns: ['required'],
						errors: [],
						value: null,
					},
					categoryId: {
						type: ENUMS.PROP_TYPE.INT16,
						resourceKey: 'shop.productRegister.categoryId',
						patterns: ['required'],
						errors: [],
						value: null,
					},
					title: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shop.productRegister.title',
						stringLength: 50,
						isUnicode: true,
						patterns: ['required'],
						errors: [],
						value: null,
					},
					caption: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shop.productRegister.caption',
						stringLength: 0,
						isUnicode: true,
						patterns: [],
						errors: [],
						value: null,
					},
					firstPrice: {
						type: ENUMS.PROP_TYPE.DOUBLE,
						resourceKey: 'shop.productRegister.firstPrice',
						patterns: ['required'],
						errors: [],
						value: null,
					},
					productCode: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shop.productRegister.productCode',
						stringLength: 50,
						isUnicode: true,
						patterns: [],
						errors: [],
						value: null,
					},
					imagePath: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shop.productRegister.imagePath',
						stringLength: 0,
						isUnicode: false,
						patterns: ['required'],
						errors: [],
						value: null,
					},
					productStatusId: {
						type: ENUMS.PROP_TYPE.BYTE,
						resourceKey: 'shop.productRegister.productStatusId',
						patterns: ['required'],
						errors: [],
						value: null,
					},
					productMonetaryFeatureTitle: {
						type: ENUMS.PROP_TYPE.STRING,
						resourceKey: 'shop.productRegister.productMonetaryFeatureTitle',
						stringLength: 50,
						isUnicode: true,
						patterns: [],
						errors: [],
						value: null,
					},
					productSpecification: {
						type: ENUMS.PROP_TYPE.OBJECT,
						resourceKey: 'shop.productRegister.productSpecification',
						patterns: ['required'],
						errors: [],
						value: null,
					},
					details: {
						type: ENUMS.PROP_TYPE.ARRAY,
						resourceKey: 'shop.productRegister.details',
						patterns: ['required'],
						errors: [],
						value: [],
					},
					images: {
						type: ENUMS.PROP_TYPE.ARRAY,
						resourceKey: 'shop.productRegister.images',
						patterns: ['required'],
						errors: [],
						value: [],
					},
					
				}
			}
		},

		async beforeMount()
		{
			this.productList = await this.getProductList();
			this.cultureList = await this.getCultureList();
			this.categoryList = await this.getCategoryList();
			this.productStatusList = await this.getProductStatusList();
					
		},

		methods:
		{
			async getProductList()
			{
				var items = [];	
				var service = new ProductService();
				
				try
				{
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });
				
					if (res.status == 200)
					{
						items = res.data.items || [];
					}
				
				} catch (e){}
			
				this.productListIsLoading = false;
				return items;
			},

			async getCultureList()
			{
				var items = [];	
				var service = new CultureService();
				
				try
				{
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });
				
					if (res.status == 200)
					{
						items = res.data.items || [];
					}
				
				} catch (e){}
			
				this.cultureListIsLoading = false;
				return items;
			},

			async getCategoryList()
			{
				var items = [];	
				var service = new CategoryService();
				
				try
				{
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });
				
					if (res.status == 200)
					{
						items = res.data.items || [];
					}
				
				} catch (e){}
			
				this.categoryListIsLoading = false;
				return items;
			},

			async getProductStatusList()
			{
				var items = [];	
				var service = new ProductStatusService();
				
				try
				{
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });
				
					if (res.status == 200)
					{
						items = res.data.items || [];
					}
				
				} catch (e){}
			
				this.productStatusListIsLoading = false;
				return items;
			},



			isRequired(name)
			{
				return this.form[name].patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}

				this.form[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.form[name].isChanged)
				{
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.form[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey,{
							label: this.$loc.fromResource(prop.resourceKey),
						}
					)
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.form) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.form);
				for (var i = 0; i < keys.length; i++)
				{
					value[keys[i]] = this.form[keys[i]].value
				}

				return value
			}
		}
	}
</script>
