//GeneratorFlag:[NoChange]
import { BasePlanTransactionGetVoacherAwards } from '@/modules/shop/base/data/actions/plan-transaction/get-voacher-awards'
export default class PlanTransactionGetVoacherAwards extends BasePlanTransactionGetVoacherAwards
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			finalVoacher: {
				...this.props.finalVoacher,
				setConfig:{
					...this.props.finalVoacher.setConfig
				}
			},
			date: {
				...this.props.date,
				setConfig:{
					...this.props.date.setConfig
				}
			},
		};
	}
}

