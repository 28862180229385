<template>
	<div class="d-inline-block me-1 mb-2">
		<button class="btn bg-light border-0" @click="onClick" v-bind:disabled="isDisable">
			<Frc-icon-bootstrap :color="iconColor" :name="action.iconName" size="15px" />
			<span class="ps-2">
				{{ $loc.fromResource(action.resourceKey) }}
			</span>
		</button>
	</div>
</template>
<script>
	export default {

		props:
		{
			action: {
				type: Object
			},
			isDisable: {
				type: Boolean,
				default: false
			}
		},

		computed: {
			iconColor()
			{
				if (this.action.color)
				{
					return this.action.color;
				}

				if (this.action.routeName)
				{
					//if (this.action.routeName.includes("delete"))
					//{
					//	return "#dc3545"
					//}

					//if (this.action.routeName.includes("create"))
					//{
					//	return "#dddddd"
					//}

					//if (this.action.routeName.includes("import"))
					//{
					//	return "#dddddd"
					//}

					//if (this.action.routeName.includes("update"))
					//{
					//	return "#FF9800"
					//}
				}


				return "inherit"
			}
		},

		methods:
		{
			onClick()
			{
				this.$emit('selectAction', this.action)
			}
		}
	}
</script>

