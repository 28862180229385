//GeneratorFlag:[NoChange]
export default {
	path: 'ticket-department',
	name: 'ticketing.baseInfo.ticketDepartment.layout',
	redirect: to => {
		return {name: 'ticketing.ticketDepartment.list'};
	},
	component: () => import('@/modules/ticketing/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ticketing/base-info/ticket-department"],
		resourceKey: 'ticketing.views.ticketDepartment.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TicketDepartmentCreate]"
		{
			path: 'create',
			name: 'ticketing.ticketDepartment.create',
			component: () => import('@/modules/ticketing/views/ticket-department/ticket-department-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketDepartment.create',
				clientAccessKeys:["/ticketing/base-info/ticket-department/create"],
				serverAccessKeys:["/ticketing/ticket-department/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDepartmentCreateImport]"
		{
			path: 'create-import',
			name: 'ticketing.ticketDepartment.createImport',
			component: () => import('@/modules/ticketing/views/ticket-department/ticket-department-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketDepartment.createImport',
				clientAccessKeys:["/ticketing/base-info/ticket-department/create-import"],
				serverAccessKeys:["/ticketing/ticket-department/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDepartmentDelete]"
		{
			path: 'delete',
			name: 'ticketing.ticketDepartment.delete',
			component: () => import('@/modules/ticketing/views/ticket-department/ticket-department-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketDepartment.delete',
				clientAccessKeys:["/ticketing/base-info/ticket-department/delete"],
				serverAccessKeys:["/ticketing/ticket-department/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDepartmentDetail]"
		{
			path: 'detail',
			name: 'ticketing.ticketDepartment.detail',
			component: () => import('@/modules/ticketing/views/ticket-department/ticket-department-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketDepartment.detail',
				clientAccessKeys:["/ticketing/base-info/ticket-department/detail"],
				serverAccessKeys:["/ticketing/ticket-department/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDepartmentList]"
		{
			path: 'list',
			name: 'ticketing.ticketDepartment.list',
			component: () => import('@/modules/ticketing/views/ticket-department/ticket-department-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketDepartment.list',
				clientAccessKeys:["/ticketing/base-info/ticket-department/list"],
				serverAccessKeys:["/ticketing/ticket-department/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDepartmentUpdate]"
		{
			path: 'update',
			name: 'ticketing.ticketDepartment.update',
			component: () => import('@/modules/ticketing/views/ticket-department/ticket-department-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketDepartment.update',
				clientAccessKeys:["/ticketing/base-info/ticket-department/update"],
				serverAccessKeys:["/ticketing/ticket-department/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}