//GeneratorFlag:[NoChange]
export default {
	path: 'document',
	name: 'financial.document.layout',
	redirect: to => {
		return {name: 'financial.document.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/document"],
		resourceKey: 'financial.views.document.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DocumentCreate]"
		{
			path: 'create',
			name: 'financial.document.create',
			component: () => import('@/modules/financial/views/document/document-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.document.create',
				clientAccessKeys:["/financial/document/create"],
				serverAccessKeys:["/financial/document/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentCreateImport]"
		{
			path: 'create-import',
			name: 'financial.document.createImport',
			component: () => import('@/modules/financial/views/document/document-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.document.createImport',
				clientAccessKeys:["/financial/document/create-import"],
				serverAccessKeys:["/financial/document/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentDelete]"
		{
			path: 'delete',
			name: 'financial.document.delete',
			component: () => import('@/modules/financial/views/document/document-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.document.delete',
				clientAccessKeys:["/financial/document/delete"],
				serverAccessKeys:["/financial/document/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentDetail]"
		{
			path: 'detail',
			name: 'financial.document.detail',
			component: () => import('@/modules/financial/views/document/document-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.document.detail',
				clientAccessKeys:["/financial/document/detail"],
				serverAccessKeys:["/financial/document/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentList]"
		{
			path: 'list',
			name: 'financial.document.list',
			component: () => import('@/modules/financial/views/document/document-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.document.list',
				clientAccessKeys:["/financial/document/list"],
				serverAccessKeys:["/financial/document/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentUpdate]"
		{
			path: 'update',
			name: 'financial.document.update',
			component: () => import('@/modules/financial/views/document/document-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.document.update',
				clientAccessKeys:["/financial/document/update"],
				serverAccessKeys:["/financial/document/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}