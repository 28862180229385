//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonCreditTransactionService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonCreditTransaction.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-credit-transaction/create', params || { });
	};

	//GeneratorFlag:[PersonCreditTransaction.Delete]
	async delete(personCreditTransactionId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:personCreditTransactionId
		}
	
		return await _server.delete('/people-net/person-credit-transaction/delete', {'params':params})
	};

	//GeneratorFlag:[PersonCreditTransaction.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-credit-transaction/detail', {'params':params});
	};

	//GeneratorFlag:[PersonCreditTransaction.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-credit-transaction/list', {'params':params});
	};

	//GeneratorFlag:[PersonCreditTransaction.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-credit-transaction/update', params || { });
	};

	//GeneratorFlag:[PersonCreditTransaction.Charge]
	async charge(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-credit-transaction/charge', params || { });
	};

	//GeneratorFlag:[PersonCreditTransaction.MyList]
	async myList(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/people-net/person-credit-transaction/my-list', {'params':params});
	};

//GeneratorFlag:[NewAction]
}