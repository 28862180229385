//GeneratorFlag:[NoChange]
import { BasePersonDocument } from '../base/data'
export default class PersonDocument extends BasePersonDocument
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personDocumentId: {
				...this.props.personDocumentId,
				setConfig:{
					...this.props.personDocumentId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			personDocumentTypeId: {
				...this.props.personDocumentTypeId,
				setConfig:{
					...this.props.personDocumentTypeId.setConfig
				}
			},
			base64FileString: {
				...this.props.base64FileString,
				setConfig:{
					...this.props.base64FileString.setConfig
				}
			},
			addressFile: {
				...this.props.addressFile,
				setConfig:{
					...this.props.addressFile.setConfig
				}
			},
			fullAddressFile: {
				...this.props.fullAddressFile,
				setConfig:{
					...this.props.fullAddressFile.setConfig
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				}
			},
			personDocumentType: {
				...this.props.personDocumentType,
				setConfig:{
					...this.props.personDocumentType.setConfig
				}
			},
			personDocumentSubInfo: {
				...this.props.personDocumentSubInfo,
				setConfig:{
					...this.props.personDocumentSubInfo.setConfig
				}
			},

		};
	}
}
