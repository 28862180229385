//GeneratorFlag:[NoChange]
export default {
	path: 'ticket',
	name: 'ticketing.ticket.layout',
	redirect: to => {
		return {name: 'ticketing.ticket.list'};
	},
	component: () => import('@/modules/ticketing/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ticketing/ticket"],
		resourceKey: 'ticketing.views.ticket.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TicketCreate]"
		{
			path: 'create',
			name: 'ticketing.ticket.create',
			component: () => import('@/modules/ticketing/views/ticket/ticket-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.create',
				clientAccessKeys:["/ticketing/ticket/create"],
				serverAccessKeys:["/ticketing/ticket/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketCreateImport]"
		{
			path: 'create-import',
			name: 'ticketing.ticket.createImport',
			component: () => import('@/modules/ticketing/views/ticket/ticket-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.createImport',
				clientAccessKeys:["/ticketing/ticket/create-import"],
				serverAccessKeys:["/ticketing/ticket/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDelete]"
		{
			path: 'delete',
			name: 'ticketing.ticket.delete',
			component: () => import('@/modules/ticketing/views/ticket/ticket-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.delete',
				clientAccessKeys:["/ticketing/ticket/delete"],
				serverAccessKeys:["/ticketing/ticket/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketDetail]"
		{
			path: 'detail',
			name: 'ticketing.ticket.detail',
			component: () => import('@/modules/ticketing/views/ticket/ticket-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.detail',
				clientAccessKeys:["/ticketing/ticket/detail"],
				serverAccessKeys:["/ticketing/ticket/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketList]"
		{
			path: 'list',
			name: 'ticketing.ticket.list',
			component: () => import('@/modules/ticketing/views/ticket/ticket-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.list',
				clientAccessKeys:["/ticketing/ticket/list"],
				serverAccessKeys:["/ticketing/ticket/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketUpdate]"
		{
			path: 'update',
			name: 'ticketing.ticket.update',
			component: () => import('@/modules/ticketing/views/ticket/ticket-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.update',
				clientAccessKeys:["/ticketing/ticket/update"],
				serverAccessKeys:["/ticketing/ticket/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketMyList]"
		{
			path: 'my-list',
			name: 'ticketing.ticket.myList',
			component: () => import('@/modules/ticketing/views/ticket/ticket-my-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.myList',
				clientAccessKeys:["/ticketing/ticket/my-list"],
				serverAccessKeys:["/ticketing/ticket/my-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]







	]
}