//GeneratorFlag:[NoChange]
import { BaseProductStatus } from '../base/data'
export default class ProductStatus extends BaseProductStatus
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productStatusId: {
				...this.props.productStatusId,
				setConfig:{
					...this.props.productStatusId.setConfig
				}
			},
			productStatusKey: {
				...this.props.productStatusKey,
				setConfig:{
					...this.props.productStatusKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},

		};
	}
}
