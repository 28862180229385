<template>
	<input v-bind:id="prop.name"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelChange"
		   v-model="value"
		   type="number"
		   class="form-control"
		   autocomplete="off" />
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			if (this.prop.value) { this.value = this.prop.value }
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp(e) {
				if (e.key === 'Enter' || e.keyCode === 13) {
					this.$emit('submit')
				}
				else {
					this.$emit('keyup', this.value)
				}
			}
		}
	}
</script>
