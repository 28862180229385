//GeneratorFlag:[NoChange]
import ProductStore from './product-store';
import Culture from './culture';
import Product from './product';
import ProductColor from './product-color';
import ProductMonetaryFeature from './product-monetary-feature';
import ProductStatus from './product-status';
import ProductDetail from './product-detail';
import Store from './store';

export default {ProductStore, Culture, Product, ProductColor, ProductMonetaryFeature, ProductStatus, ProductDetail, Store}
export{ProductStore, Culture, Product, ProductColor, ProductMonetaryFeature, ProductStatus, ProductDetail, Store}
