//GeneratorFlag:[NoChange]
import { BaseInvoiceTransactionService } from '../base/services'
export default class InvoiceTransactionService extends BaseInvoiceTransactionService
{
	constructor()
	{
		super();
	}

//GeneratorFlag:[NewAction]
}
