//GeneratorFlag:[NoChange]
export default {
	path: 'document-submit-type',
	name: 'financial.baseSys.documentSubmitType.layout',
	redirect: to => {
		return {name: 'financial.documentSubmitType.list'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/base-sys/document-submit-type"],
		resourceKey: 'financial.views.documentSubmitType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DocumentSubmitTypeDetail]"
		{
			path: 'detail',
			name: 'financial.documentSubmitType.detail',
			component: () => import('@/modules/financial/views/document-submit-type/document-submit-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentSubmitType.detail',
				clientAccessKeys:["/financial/base-sys/document-submit-type/detail"],
				serverAccessKeys:["/financial/document-submit-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DocumentSubmitTypeList]"
		{
			path: 'list',
			name: 'financial.documentSubmitType.list',
			component: () => import('@/modules/financial/views/document-submit-type/document-submit-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.documentSubmitType.list',
				clientAccessKeys:["/financial/base-sys/document-submit-type/list"],
				serverAccessKeys:["/financial/document-submit-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}