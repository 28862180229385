 
export default {
	"name": "بازاریاب",
	"ext": {
		"common": {
			"fromDate":"از تاریخ",
			"toDate":"تا تاریخ",
		}
	},
	"views": {
		"action": {
			"layout": "عملیات",
			"treeGeno":"ژنولوژی درختی",
			"ordGeno":"ژنولوژی نموداری",
		}
	}
}
