//GeneratorFlag:[NoChange]
export default {
	path: 'person-pre-transaction',
	name: 'shop.personPreTransaction.layout',
	redirect: to => {
		return {name: 'shop.personPreTransaction.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/person-pre-transaction"],
		resourceKey: 'shop.views.personPreTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonPreTransactionDelete]"
		{
			path: 'delete',
			name: 'shop.personPreTransaction.delete',
			component: () => import('@/modules/shop/views/person-pre-transaction/person-pre-transaction-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransaction.delete',
				clientAccessKeys:["/shop/person-pre-transaction/delete"],
				serverAccessKeys:["/shop/person-pre-transaction/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonPreTransactionDetail]"
		{
			path: 'detail',
			name: 'shop.personPreTransaction.detail',
			component: () => import('@/modules/shop/views/person-pre-transaction/person-pre-transaction-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransaction.detail',
				clientAccessKeys:["/shop/person-pre-transaction/detail"],
				serverAccessKeys:["/shop/person-pre-transaction/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonPreTransactionList]"
		{
			path: 'list',
			name: 'shop.personPreTransaction.list',
			component: () => import('@/modules/shop/views/person-pre-transaction/person-pre-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransaction.list',
				clientAccessKeys:["/shop/person-pre-transaction/list"],
				serverAccessKeys:["/shop/person-pre-transaction/list","/shop/person-pre-transaction-statuse/list","/shop/person/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonPreTransactionCreate]"
		{
			path: 'create',
			name: 'shop.personPreTransaction.create',
			component: () => import('@/modules/shop/views/person-pre-transaction/person-pre-transaction-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransaction.create',
				clientAccessKeys:["/shop/person-pre-transaction/create"],
				serverAccessKeys:["/shop/person-pre-transaction/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonPreTransactionImport]"
		{
			path: 'import',
			name: 'shop.personPreTransaction.import',
			component: () => import('@/modules/shop/views/person-pre-transaction/person-pre-transaction-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransaction.import',
				clientAccessKeys:["/shop/person-pre-transaction/import"],
				serverAccessKeys:["/shop/person-pre-transaction/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonPreTransactionUpdate]"
		{
			path: 'update',
			name: 'shop.personPreTransaction.update',
			component: () => import('@/modules/shop/views/person-pre-transaction/person-pre-transaction-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransaction.update',
				clientAccessKeys:["/shop/person-pre-transaction/update"],
				serverAccessKeys:["/shop/person-pre-transaction/save","/shop/person-pre-transaction-statuse/list","/shop/person/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






