import store from '@/core/store'
export default class localization
{
	constructor() {
	}

	fromResource(path, value) {

		if (!path)
		{
			return '';
		}

		var lang = store.state.app.language;
		var current = store.state.app.resource[lang];

		if (current) {
			path.split('.').forEach(function (p) {
				current = (current[p] != undefined) ? current[p] : path
			})

			if (current && typeof value === 'object' && !Array.isArray(value)) {
				for (const param in value) { current = current.replace(new RegExp('{' + param + '}', 'g'), value[param]) }
			}
		}

		if (lang == 'fa')
		{
			return persianJs(current || path).halfSpace().toString()
		}

		return current || path
	}
}
