//GeneratorFlag:[NoChange]
import BaseCity from './base-city';
import BaseCompany from './base-company';
import BaseCompanyRank from './base-company-rank';
import BaseCountry from './base-country';
import BaseCulture from './base-culture';
import BaseGenderType from './base-gender-type';
import BaseMaritalType from './base-marital-type';
import BaseMenu from './base-menu';
import BaseMenuPerson from './base-menu-person';
import BaseMilitaryType from './base-military-type';
import BaseNationalityType from './base-nationality-type';
import BasePerson from './base-person';
import BasePersonAccess from './base-person-access';
import BasePersonAppPurchase from './base-person-app-purchase';
import BasePersonAppPurchaseStatuse from './base-person-app-purchase-statuse';
import BasePersonAppPurchaseType from './base-person-app-purchase-type';
import BasePersonCreditTransaction from './base-person-credit-transaction';
import BasePersonCreditTransactionStatuse from './base-person-credit-transaction-statuse';
import BasePersonCreditTransactionType from './base-person-credit-transaction-type';
import BasePersonDetail from './base-person-detail';
import BasePersonDocument from './base-person-document';
import BasePersonDocumentType from './base-person-document-type';
import BasePersonGender from './base-person-gender';
import BasePersonStatuse from './base-person-statuse';
import BaseProvince from './base-province';
import BaseRank from './base-rank';
import BaseTeam from './base-team';

export default {BaseCity, BaseCompany, BaseCompanyRank, BaseCountry, BaseCulture, BaseGenderType, BaseMaritalType, BaseMenu, BaseMenuPerson, BaseMilitaryType, BaseNationalityType, BasePerson, BasePersonAccess, BasePersonAppPurchase, BasePersonAppPurchaseStatuse, BasePersonAppPurchaseType, BasePersonCreditTransaction, BasePersonCreditTransactionStatuse, BasePersonCreditTransactionType, BasePersonDetail, BasePersonDocument, BasePersonDocumentType, BasePersonGender, BasePersonStatuse, BaseProvince, BaseRank, BaseTeam}
export{BaseCity, BaseCompany, BaseCompanyRank, BaseCountry, BaseCulture, BaseGenderType, BaseMaritalType, BaseMenu, BaseMenuPerson, BaseMilitaryType, BaseNationalityType, BasePerson, BasePersonAccess, BasePersonAppPurchase, BasePersonAppPurchaseStatuse, BasePersonAppPurchaseType, BasePersonCreditTransaction, BasePersonCreditTransactionStatuse, BasePersonCreditTransactionType, BasePersonDetail, BasePersonDocument, BasePersonDocumentType, BasePersonGender, BasePersonStatuse, BaseProvince, BaseRank, BaseTeam}
