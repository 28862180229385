//GeneratorFlag:[NoChange]
import bankRoute from './bank-route'; //GeneratorFlag:[Bank]
import bankPaymentRoute from './bank-payment-route'; //GeneratorFlag:[BankPayment]
import bankPaymentStatuseRoute from './bank-payment-statuse-route'; //GeneratorFlag:[BankPaymentStatuse]
import categoryRoute from './category-route'; //GeneratorFlag:[Category]
import cultureRoute from './culture-route'; //GeneratorFlag:[Culture]
import featureRoute from './feature-route'; //GeneratorFlag:[Feature]
import featureItemRoute from './feature-item-route'; //GeneratorFlag:[FeatureItem]
import invoiceRoute from './invoice-route'; //GeneratorFlag:[Invoice]
import invoicePaymentTypeRoute from './invoice-payment-type-route'; //GeneratorFlag:[InvoicePaymentType]
import invoiceStatuseRoute from './invoice-statuse-route'; //GeneratorFlag:[InvoiceStatuse]
import invoiceTransactionTypeRoute from './invoice-transaction-type-route'; //GeneratorFlag:[InvoiceTransactionType]
import personAddressRoute from './person-address-route'; //GeneratorFlag:[PersonAddress]
import personCreaditTransactionTypeRoute from './person-creadit-transaction-type-route'; //GeneratorFlag:[PersonCreaditTransactionType]
import personPreTransactionRoute from './person-pre-transaction-route'; //GeneratorFlag:[PersonPreTransaction]
import personPreTransactionStatuseRoute from './person-pre-transaction-statuse-route'; //GeneratorFlag:[PersonPreTransactionStatuse]
import planPrecentRoute from './plan-precent-route'; //GeneratorFlag:[PlanPrecent]
import planSettingRoute from './plan-setting-route'; //GeneratorFlag:[PlanSetting]
import productCategoryRoute from './product-category-route'; //GeneratorFlag:[ProductCategory]
import productColorRoute from './product-color-route'; //GeneratorFlag:[ProductColor]
import productRoute from './product-route'; //GeneratorFlag:[Product]
import productDetailRoute from './product-detail-route'; //GeneratorFlag:[ProductDetail]
import productDocRoute from './product-doc-route'; //GeneratorFlag:[ProductDoc]
import productDocTypeRoute from './product-doc-type-route'; //GeneratorFlag:[ProductDocType]
import productFeatureRoute from './product-feature-route'; //GeneratorFlag:[ProductFeature]
import productMonetaryFeatureRoute from './product-monetary-feature-route'; //GeneratorFlag:[ProductMonetaryFeature]
import productStatusRoute from './product-status-route'; //GeneratorFlag:[ProductStatus]
import productStoreRoute from './product-store-route'; //GeneratorFlag:[ProductStore]
import productTransportCostTypeRoute from './product-transport-cost-type-route'; //GeneratorFlag:[ProductTransportCostType]
import storeRoute from './store-route'; //GeneratorFlag:[Store]
import storeProfitRateRoute from './store-profit-rate-route'; //GeneratorFlag:[StoreProfitRate]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'shop',
	name: 'shop',
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/shop'],
		serverAccessKeys:[],
		resourceKey: 'service.shop',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-layers-half',
		}
	},
	children: [
bankPaymentRoute, invoiceRoute, personAddressRoute, personPreTransactionRoute, productCategoryRoute, productRoute, productDetailRoute, productDocRoute, productFeatureRoute, productMonetaryFeatureRoute, productStoreRoute, storeRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BaseInfoLayout]
		{
			path: 'base-info',
			name: 'shop.baseInfo',
			component: () => import('@/modules/shop/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.layout.baseInfo',
				clientAccessKeys:['/shop/base-info'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			bankRoute, categoryRoute, featureRoute, featureItemRoute, planPrecentRoute, planSettingRoute, productColorRoute, storeProfitRateRoute, //GeneratorFlag:[NewBaseInfoChild]
			]
		},
		//GeneratorFlag:[BaseSysLayout]
		{
			path: 'base-sys',
			name: 'shop.baseSys',
			component: () => import('@/modules/shop/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.layout.baseSys',
				clientAccessKeys:['/shop/base-sys'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			bankPaymentStatuseRoute, cultureRoute, invoicePaymentTypeRoute, invoiceStatuseRoute, invoiceTransactionTypeRoute, personCreaditTransactionTypeRoute, personPreTransactionStatuseRoute, productDocTypeRoute, productStatusRoute, productTransportCostTypeRoute, //GeneratorFlag:[NewBaseSysChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
bankRoute, bankPaymentRoute, bankPaymentStatuseRoute, categoryRoute, cultureRoute, featureRoute, featureItemRoute, invoiceRoute, invoicePaymentTypeRoute, invoiceStatuseRoute, invoiceTransactionTypeRoute, personAddressRoute, personCreaditTransactionTypeRoute, personPreTransactionRoute, personPreTransactionStatuseRoute, planPrecentRoute, planSettingRoute, productCategoryRoute, productColorRoute, productRoute, productDetailRoute, productDocRoute, productDocTypeRoute, productFeatureRoute, productMonetaryFeatureRoute, productStatusRoute, productStoreRoute, productTransportCostTypeRoute, storeRoute, storeProfitRateRoute, //GeneratorFlag:[NewExport]
}
































