//GeneratorFlag:[NoChange]
import { BasePersonDetail } from '../base/data'
export default class PersonDetail extends BasePersonDetail
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			cityId: {
				...this.props.cityId,
				setConfig:{
					...this.props.cityId.setConfig
				}
			},
			city: {
				...this.props.city,
				setConfig:{
					...this.props.city.setConfig
				}
			},

		};
	}
}
