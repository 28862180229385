import * as persianJs from '@/lib/persian'
import * as persianDate from '@/lib/persian-date'

persianDate.toLocale('en');

export default {
	date: function () {

		if (arguments.length == 1 && typeof (arguments[0]) == 'string')
		{
			const date =
				arguments[0].split('/');

			const year = Number(date[0])
			const month = Number(date[1])
			const day = Number(date[2])

			return new persianDate([year, month, day])
		}

		return new persianDate(...arguments)
	},

	time: function() {
		const date = new Date();
		return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
	},

	tools: function (){
		return persianJs
	}
}
