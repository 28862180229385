//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { Culture, Product, ProductColor, ProductMonetaryFeature, ProductStatus } from '@/modules/shop/data/product-detail/find'; 
import { ProductService, ProductColorService, ProductMonetaryFeatureService, CultureService, ProductStatusService } from '@/modules/shop/services'; 
export default class ProductDetail extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'productDetail';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productDetailFind.self';
	props = {
		productDetailId: {
			primarykey: true,
			name: 'productDetailId',
			resourceKey: 'shop.productDetailFind.productDetailId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productId: {
			foreignKey: true,
			name: 'productId',
			resourceKey: 'shop.productDetailFind.productId',
			type: ENUMS.PROP_TYPE.INT32,
			entity: new Product(),
			data: async (params) =>
			{
				const service =
					new ProductService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'productId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productColorId: {
			foreignKey: true,
			name: 'productColorId',
			resourceKey: 'shop.productDetailFind.productColorId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new ProductColor(),
			data: async (params) =>
			{
				const service =
					new ProductColorService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'productColorId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productMonetaryFeatureId: {
			foreignKey: true,
			name: 'productMonetaryFeatureId',
			resourceKey: 'shop.productDetailFind.productMonetaryFeatureId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new ProductMonetaryFeature(),
			data: async (params) =>
			{
				const service =
					new ProductMonetaryFeatureService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'productMonetaryFeatureId',
				isUnicode: false,
				patterns: [],
			}
		},
		adminDisplayName: {
			name: 'adminDisplayName',
			resourceKey: 'shop.productDetailFind.adminDisplayName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				stringLength: 200,
				patterns: [],
			}
		},
		cultureId: {
			foreignKey: true,
			name: 'cultureId',
			resourceKey: 'shop.productDetailFind.cultureId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new Culture(),
			data: async (params) =>
			{
				const service =
					new CultureService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'cultureId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		isDefaultProduct: {
			name: 'isDefaultProduct',
			resourceKey: 'shop.productDetailFind.isDefaultProduct',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productStatusId: {
			foreignKey: true,
			name: 'productStatusId',
			resourceKey: 'shop.productDetailFind.productStatusId',
			type: ENUMS.PROP_TYPE.BYTE,
			entity: new ProductStatus(),
			data: async (params) =>
			{
				const service =
					new ProductStatusService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'productStatusId',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		culture: {
			name: 'culture',
			resourceKey: 'shop.productDetailFind.culture.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Culture(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		product: {
			name: 'product',
			resourceKey: 'shop.productDetailFind.product.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Product(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productColor: {
			name: 'productColor',
			resourceKey: 'shop.productDetailFind.productColor.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductColor(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productMonetaryFeature: {
			name: 'productMonetaryFeature',
			resourceKey: 'shop.productDetailFind.productMonetaryFeature.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductMonetaryFeature(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			}
		},
		productStatus: {
			name: 'productStatus',
			resourceKey: 'shop.productDetailFind.productStatus.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductStatus(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		productDetailSubInfo: {
			name: 'productDetailSubInfo',
			resourceKey: 'shop.productDetailFind.productDetailSubInfo',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: ['required'],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

