//GeneratorFlag:[NoChange]
export default {
	"name":"peopleNet",
	"views": {
		"city": {
			"layout": "cities management",
			"list": "list of cities",
			"create": "create new city",
			"import": "import cities",
			"update": "update city",
			"detail": "detail of city",
			"delete": "delete city",
		},
		"company": {
			"layout": "companies management",
			"list": "list of companies",
			"create": "create new company",
			"import": "import companies",
			"update": "update company",
			"detail": "detail of company",
			"delete": "delete company",
		},
		"companyRank": {
			"layout": "company ranks management",
			"list": "list of company ranks",
			"create": "create new company rank",
			"import": "import company ranks",
			"update": "update company rank",
			"detail": "detail of company rank",
			"delete": "delete company rank",
		},
		"country": {
			"layout": "countries management",
			"list": "list of countries",
			"create": "create new country",
			"import": "import countries",
			"update": "update country",
			"detail": "detail of country",
			"delete": "delete country",
		},
		"culture": {
			"layout": "culture",
			"list": "list of culture",
		},
		"genderType": {
			"layout": "gender type",
			"list": "list of gender type",
		},
		"maritalType": {
			"layout": "marital type",
			"list": "list of marital type",
		},
		"menu": {
			"layout": "menus management",
			"list": "list of menus",
			"create": "create new menu",
			"import": "import menus",
			"update": "update menu",
			"detail": "detail of menu",
			"delete": "delete menu",
		},
		"menuPerson": {
			"layout": "menu persons management",
			"list": "list of menu persons",
			"create": "create new menu person",
			"import": "import menu persons",
			"update": "update menu person",
			"detail": "detail of menu person",
			"delete": "delete menu person",
		},
		"militaryType": {
			"layout": "military types management",
			"list": "list of military types",
			"create": "create new military type",
			"import": "import military types",
			"update": "update military type",
			"detail": "detail of military type",
			"delete": "delete military type",
		},
		"nationalityType": {
			"layout": "nationality types management",
			"list": "list of nationality types",
			"create": "create new nationality type",
			"import": "import nationality types",
			"update": "update nationality type",
			"detail": "detail of nationality type",
			"delete": "delete nationality type",
		},
		"person": {
			"layout": "people management",
			"list": "list of people",
			"create": "create new person",
			"import": "import people",
			"update": "update person",
			"detail": "detail of person",
			"delete": "delete person",
			"personAccessDetail": "detail of person access",
			"personDetailDetail": "detail of person detail",
			"updatePersonAccess": "update person access",
			"updatePersonDetail": "update person detail",
		},
		"personAppPurchase": {
			"layout": "person app purchases management",
			"list": "list of person app purchases",
			"create": "create new person app purchase",
			"import": "import person app purchases",
			"update": "update person app purchase",
			"detail": "detail of person app purchase",
			"delete": "delete person app purchase",
		},
		"personAppPurchaseStatuse": {
			"layout": "person app purchase statuse",
			"list": "list of person app purchase statuse",
		},
		"personAppPurchaseType": {
			"layout": "person app purchase type",
			"list": "list of person app purchase type",
		},
		"personCreditTransaction": {
			"layout": "person credit transactions management",
			"list": "list of person credit transactions",
			"create": "create new person credit transaction",
			"import": "import person credit transactions",
			"update": "update person credit transaction",
			"detail": "detail of person credit transaction",
			"delete": "delete person credit transaction",
		},
		"personCreditTransactionStatuse": {
			"layout": "person credit transaction statuse",
			"list": "list of person credit transaction statuse",
		},
		"personCreditTransactionType": {
			"layout": "person credit transaction type",
			"list": "list of person credit transaction type",
		},
		"personDocument": {
			"layout": "person documents management",
			"list": "list of person documents",
			"create": "create new person document",
			"import": "import person documents",
			"update": "update person document",
			"detail": "detail of person document",
			"delete": "delete person document",
		},
		"personDocumentType": {
			"layout": "person document type",
			"list": "list of person document type",
		},
		"personGender": {
			"layout": "person gender",
			"list": "list of person gender",
		},
		"personStatuse": {
			"layout": "person statuse",
			"list": "list of person statuse",
		},
		"province": {
			"layout": "provinces management",
			"list": "list of provinces",
			"create": "create new province",
			"import": "import provinces",
			"update": "update province",
			"detail": "detail of province",
			"delete": "delete province",
		},
		"rank": {
			"layout": "ranks management",
			"list": "list of ranks",
			"create": "create new rank",
			"import": "import ranks",
			"update": "update rank",
			"detail": "detail of rank",
			"delete": "delete rank",
		},
		"team": {
			"layout": "teams management",
			"list": "list of teams",
			"create": "create new team",
			"import": "import teams",
			"update": "update team",
			"detail": "detail of team",
			"delete": "delete team",
		},

//GeneratorFlag:[NewView]
	},
	"accessToken": {
		"self": "accessToken",
		"accessTokenId": "access token id",
		"tokenGuid": "token guid",
		"tokenType": "token type",
		"expirationDate": "expiration date",
		"token": "token",
		"isValid": "is valid",
		"personId": "person id",
		"teamId": "team id",
		"companyId": "company id",
		"rankId": "rank id",
		"companyRankId": "company rank id",
		"explorerName": "explorer name",
		"deviceName": "device name",
		"createDateTime": "create date time",
		"displayDateTime": "display date time",
		"person": "person",

	},
	"city": {
		"self": "city",
		"cityId": "city id",
		"title": "title",
		"provinceId": "province id",
		"province": "province",
		"personDetails": "person details",

	},
	"company": {
		"self": "company",
		"companyId": "company id",
		"title": "title",
		"companySubInfo": "company sub info",
		"menuPeople": "menu people",
		"people": "people",
		"ranks": "ranks",
		"teams": "teams",

	},
	"companyRank": {
		"self": "companyRank",
		"companyRankId": "company rank id",
		"companyRankKey": "company rank key",
		"cultureId": "culture id",
		"title": "title",
		"companyId": "company id",
		"culture": "culture",
		"companyRankSubInfo": "company rank sub info",
		"menuPeople": "menu people",
		"people": "people",

	},
	"country": {
		"self": "country",
		"countryId": "country id",
		"title": "title",
		"countryCode": "country code",
		"flagUrl": "flag url",
		"provinces": "provinces",

	},
	"culture": {
		"self": "culture",
		"cultureId": "culture id",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
		"companyRanks": "company ranks",
		"menus": "menus",
		"personAppPurchaseStatuses": "person app purchase statuses",
		"personAppPurchaseTypes": "person app purchase types",
		"personCreditTransactionStatuses": "person credit transaction statuses",
		"personCreditTransactionTypes": "person credit transaction types",
		"personGenders": "person genders",
		"personStatuses": "person statuses",

	},
	"genderType": {
		"self": "genderType",
		"genderTypeId": "gender type id",
		"title": "title",
		"caption": "caption",

	},
	"maritalType": {
		"self": "maritalType",
		"maritalTypeId": "marital type id",
		"title": "title",
		"caption": "caption",

	},
	"menu": {
		"self": "menu",
		"menuId": "menu id",
		"cultureId": "culture id",
		"parentId": "parent id",
		"moduleName": "module name",
		"title": "title",
		"menuOrder": "menu order",
		"iconUrl": "icon url",
		"menuUrl": "menu url",
		"isVisible": "is visible",
		"accessKey": "access key",
		"culture": "culture",
		"parent": "parent",
		"menuSubInfo": "menu sub info",
		"inverseParent": "inverse parent",
		"menuPeople": "menu people",

	},
	"menuPerson": {
		"self": "menuPerson",
		"menuPersonId": "menu person id",
		"menuId": "menu id",
		"titleMenu": "title menu",
		"companyId": "company id",
		"teamId": "team id",
		"rankId": "rank id",
		"companyRankId": "company rank id",
		"personId": "person id",
		"company": "company",
		"companyRank": "company rank",
		"menu": "menu",
		"person": "person",
		"rank": "rank",
		"team": "team",
		"menuPersonSubInfo": "menu person sub info",

	},
	"militaryType": {
		"self": "militaryType",
		"militaryTypeId": "military type id",
		"militaryTypeKey": "military type key",
		"title": "title",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",

	},
	"nationalityType": {
		"self": "nationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",

	},
	"person": {
		"self": "person",
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"parentId": "parent id",
		"genoString": "geno string",
		"rankId": "rank id",
		"companyRankId": "company rank id",
		"companyId": "company id",
		"mobile": "mobile",
		"email": "email",
		"personStauseId": "person stause id",
		"personGenderId": "person gender id",
		"teamId": "team id",
		"personCode": "person code",
		"levelOfHead": "level of head",
		"creditValue": "credit value",
		"company": "company",
		"companyRank": "company rank",
		"personGender": "person gender",
		"personStause": "person stause",
		"rank": "rank",
		"team": "team",
		"personAccess": "person access",
		"personDetail": "person detail",
		"personSubInfo": "person sub info",
		"accessTokens": "access tokens",
		"menuPeople": "menu people",
		"personCreditTransactions": "person credit transactions",
		"personDocuments": "person documents",

	},
	"personAccess": {
		"self": "personAccess",
		"personId": "person id",
		"userName": "user name",
		"password": "password",
		"person": "person",

	},
	"personAppPurchase": {
		"self": "personAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse id",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type id",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": "person app purchase statuse",
		"personAppPurchaseType": "person app purchase type",
		"personAppPurchaseSubInfo": "person app purchase sub info",

	},
	"personAppPurchaseStatuse": {
		"self": "personAppPurchaseStatuse",
		"personAppPurchaseStatuseId": "person app purchase statuse id",
		"personAppPurchaseStatuseKey": "person app purchase statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"culture": "culture",
		"personAppPurchases": "person app purchases",

	},
	"personAppPurchaseType": {
		"self": "personAppPurchaseType",
		"personAppPurchaseTypeId": "person app purchase type id",
		"personAppPurchaseTypeKey": "person app purchase type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"culture": "culture",
		"personAppPurchases": "person app purchases",

	},
	"personCreditTransaction": {
		"self": "personCreditTransaction",
		"personCreditTransactionId": "person credit transaction id",
		"personId": "person id",
		"personCreditTransactionTypeId": "person credit transaction type id",
		"personCreditTransactionStatuseId": "person credit transaction statuse id",
		"caption": "caption",
		"transactionRefCode": "Ref Code",
		"displayTransactionDate": "Date",
		"transactionDate": "Date",
		"creditValue": "Value",
		"personCommiter": "Commiter",
		"commiterDescription": "Description",
		"person": "person",
		"personCreditTransactionStatuse": "Statuse",
		"personCreditTransactionType": "Type",

	},
	"personCreditTransactionStatuse": {
		"self": "personCreditTransactionStatuse",
		"personCreditTransactionStatuseId": "person credit transaction statuse id",
		"personCreditTransactionStatuseKey": "person credit transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"culture": "culture",
		"personCreditTransactions": "person credit transactions",

	},
	"personCreditTransactionType": {
		"self": "personCreditTransactionType",
		"personCreditTransactionTypeId": "person credit transaction type id",
		"personCreditTransactionTypeKey": "person credit transaction type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"culture": "culture",
		"personCreditTransactions": "person credit transactions",

	},
	"personDetail": {
		"self": "personDetail",
		"personId": "person id",
		"cityId": "city id",
		"city": "city",
		"person": "person",

	},
	"personDocument": {
		"self": "personDocument",
		"personDocumentId": "person document id",
		"personId": "person id",
		"personDocumentTypeId": "person document type id",
		"base64FileString": "base64 file string",
		"addressFile": "address file",
		"fullAddressFile": "full address file",
		"person": "person",
		"personDocumentType": "person document type",
		"personDocumentSubInfo": "person document sub info",

	},
	"personDocumentType": {
		"self": "personDocumentType",
		"personDocumentTypeId": "person document type id",
		"personDocumentTypeKey": "person document type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"personDocuments": "person documents",

	},
	"personGender": {
		"self": "personGender",
		"personGenderId": "person gender id",
		"personGenderKey": "person gender key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"culture": "culture",
		"people": "people",

	},
	"personMobileValidation": {
		"self": "personMobileValidation",
		"personMobileValidationId": "person mobile validation id",
		"mobile": "mobile",
		"email": "email",
		"validateCode": "validate code",
		"createDateTime": "create date time",
		"isExpire": "is expire",
		"isValidated": "is validated",
		"expireDateTime": "expire date time",
		"ipAddress": "ip address",

	},
	"personStatuse": {
		"self": "personStatuse",
		"personStatuseId": "person statuse id",
		"personStatuseKey": "person statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
		"culture": "culture",
		"people": "people",

	},
	"province": {
		"self": "province",
		"provinceId": "province id",
		"countryId": "country id",
		"title": "title",
		"country": "country",
		"provinceSubInfo": "province sub info",
		"cities": "cities",

	},
	"rank": {
		"self": "rank",
		"rankId": "rank id",
		"orderRank": "order rank",
		"title": "title",
		"teamId": "team id",
		"companyId": "company id",
		"status": "status",
		"company": "company",
		"team": "team",
		"rankSubInfo": "rank sub info",
		"menuPeople": "menu people",
		"people": "people",

	},
	"team": {
		"self": "team",
		"teamId": "team id",
		"title": "title",
		"teamGenoString": "team geno string",
		"companyId": "company id",
		"company": "company",
		"teamSubInfo": "team sub info",
		"menuPeople": "menu people",
		"people": "people",
		"ranks": "ranks",

	},

	//GeneratorFlag:[PersonAccess.ChangePassword] {
	"personAccessChangePassword": {
		"self": "personAccessChangePassword",
		"personId": "person id",
		"currentPassword": "current password",
		"newPassword": "new password",
		"confirmedPassword": "confirmed password",
		"result": "result",

	},
	//GeneratorFlag:[PersonAppPurchase.CheckCredit] {
	"personAppPurchaseCheckCredit": {
		"self": "personAppPurchaseCheckCredit",
		"isValid": "is valid",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",

	},
	//GeneratorFlag:[Person.CheckUpline] {
	"personCheckUpline": {
		"self": "personCheckUpline",
		"isValid": "is valid",
		"uplineInfo": "upline info",
		"personCode": "person code",

	},
	//GeneratorFlag:[PersonAccess.Generate] {
	"personAccessGenerate": {
		"self": "personAccessGenerate",
		"userName": "user name",
		"password": "password",
		"tokenType": "token type",
		"expirationDate": "expiration date",
		"token": "token",
		"profileUrl": "profile url",
		"rankName": "rank name",
		"parentName": "parent name",
		"rankLogo": "rank logo",

	},
	//GeneratorFlag:[MenuPerson.GetListByUser] {
	"menuPersonGetListByUser": {
		"self": "menuPersonGetListByUser",
		"menuItems": "menu items",

	},
	//GeneratorFlag:[MenuPersonGetListByUser.MenuItem] {
	"menuItem": {
		"self": "menuItem",
		"menuId": "menu id",
		"accessKey": "access key",
		"parentId": "parent id",
		"title": "title",
		"titleMenu": "title menu",
		"menuOrder": "menu order",
		"iconUrl": "icon url",
		"menuUrl": "menu url",
		"isVisible": "is visible",

	},
	//GeneratorFlag:[Person.GetMyTeamList] {
	"personGetMyTeamList": {
		"self": "personGetMyTeamList",
		"firstName": "first name",
		"lastName": "last name",
		"parentId": "parent id",
		"parentName": "parent name",
		"levelGenerationOfMe": "level generation of me",
		"mobile": "mobile",
		"personId": "person id",
		"personCode": "person code",

	},
	//GeneratorFlag:[Person.GetPersonByData] {
	"personGetPersonByData": {
		"self": "personGetPersonByData",
		"firstName": "first name",
		"lastName": "last name",
		"parentId": "parent id",
		"parentName": "parent name",
		"levelGenerationOfMe": "level generation of me",
		"mobile": "mobile",
		"personId": "person id",
		"personCode": "person code",

	},
	//GeneratorFlag:[Person.Register] {
	"personRegister": {
		"self": "personRegister",
		"firstName": "first name",
		"lastName": "last name",
		"parentId": "parent id",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"companyId": "company id",
		"countryId": "country id",
		"mobile": "mobile",
		"email": "email",
		"personGenderId": "person gender id",
		"cityId": "city id",
		"password": "password",
		"tokenType": "token type",
		"expirationDate": "expiration date",
		"token": "token",

	},
	//GeneratorFlag:[Person.RegisterShop] {
	"personRegisterShop": {
		"self": "personRegisterShop",
		"firstName": "first name",
		"lastName": "last name",
		"parentId": "parent id",
		"nationalNo": "national no",
		"passportNo": "passport no",
		"companyId": "company id",
		"countryId": "country id",
		"mobile": "mobile",
		"email": "email",
		"personGenderId": "person gender id",
		"cityId": "city id",
		"password": "password",
		"factorNumber": "factor number",
		"passportImageFile": "passport image file",
		"parentIdCode": "parent id code",
		"tokenType": "token type",
		"expirationDate": "expiration date",
		"token": "token",

	},
	//GeneratorFlag:[PersonMobileValidation.ValidateCode] {
	"personMobileValidationValidateCode": {
		"self": "personMobileValidationValidateCode",
		"personMobileValidationServiceId": "person mobile validation service id",
		"validationCode": "validation code",
		"validationResult": "validation result",

	},
	//GeneratorFlag:[PersonMobileValidation.ValidateMobile] {
	"personMobileValidationValidateMobile": {
		"self": "personMobileValidationValidateMobile",
		"mobile": "mobile",
		"countryCode": "country code",
		"validateCode": "validate code",

	},
//GeneratorFlag:[NewEntity]












}












