//GeneratorFlag:[NoChange]
export default {
	path: 'category',
	name: 'shop.category.layout',
	redirect: to => {
		return {name: 'shop.category.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/BaseInfo/category"],
		resourceKey: 'shop.views.category.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[CategoryDelete]"
		{
			path: 'delete',
			name: 'shop.category.delete',
			component: () => import('@/modules/shop/views/category/category-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.category.delete',
				clientAccessKeys:["/shop/BaseInfo/category/delete"],
				serverAccessKeys:["/shop/category/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CategoryDetail]"
		{
			path: 'detail',
			name: 'shop.category.detail',
			component: () => import('@/modules/shop/views/category/category-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.category.detail',
				clientAccessKeys:["/shop/BaseInfo/category/detail"],
				serverAccessKeys:["/shop/category/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CategoryList]"
		{
			path: 'list',
			name: 'shop.category.list',
			component: () => import('@/modules/shop/views/category/category-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.category.list',
				clientAccessKeys:["/shop/BaseInfo/category/list"],
				serverAccessKeys:["/shop/category/list","/shop/culture/list","/shop/category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CategoryCreate]"
		{
			path: 'create',
			name: 'shop.category.create',
			component: () => import('@/modules/shop/views/category/category-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.category.create',
				clientAccessKeys:["/shop/BaseInfo/category/create"],
				serverAccessKeys:["/shop/category/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CategoryImport]"
		{
			path: 'import',
			name: 'shop.category.import',
			component: () => import('@/modules/shop/views/category/category-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.category.import',
				clientAccessKeys:["/shop/BaseInfo/category/import"],
				serverAccessKeys:["/shop/category/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CategoryUpdate]"
		{
			path: 'update',
			name: 'shop.category.update',
			component: () => import('@/modules/shop/views/category/category-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.category.update',
				clientAccessKeys:["/shop/BaseInfo/category/update"],
				serverAccessKeys:["/shop/category/save","/shop/culture/list","/shop/category/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






