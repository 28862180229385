//GeneratorFlag:[NoChange]
import { BasePersonAccess } from '../base/data'
export default class PersonAccess extends BasePersonAccess
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			userName: {
				...this.props.userName,
				setConfig:{
					...this.props.userName.setConfig
				}
			},
			password: {
				...this.props.password,
				setConfig:{
					...this.props.password.setConfig
				}
			},

		};
	}
}
