<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-content :loading="loading" width="800px">
		<Frc-form v-if="temp" :key="ver" :entity="entity" :task="submit" :value="temp" />
	</Frc-content>
</template>
<script>
	import { Person } from '../../data'
	import { PersonService } from '../../services'
	export default {
		data()
		{
			return {
				ver: 0,
				temp: null,
				loading: true
			}
		},
		computed:
		{
			entity()
			{
				return Person
			},
			service()
			{
				return new PersonService()
			},
		},
		async beforeMount()
		{
			await this.init()
		},
		methods:
		{
			async init()
			{
				this.temp = await this.getDataFromService();
				if (this.temp == undefined)
				{
					this.$router.go(-1)
				}
			},
			async getDataFromService()
			{
				var result;
				const temp = await this.getDataFromTemp();

				if (temp)
				{
					try
					{
						this.loading = true;
						const res = await this.service.find(temp['personId'],{HSR: false, HER: false});

						if ((res.data || {}).status == 'NotFound')
						{
							result = {
								'personId': temp['personId']
							}

						} else result = (res.data || {}).meta

					} catch (e) { console.log(e) }
				}

				this.loading = false;
				return result;
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async submit(payload)
			{
				const res = 
					await this.service.save(payload);

				if (res.status == 200)
				{
					this.$router.go(-1)
				}
			}
		}
	}
</script>
