<template>
	<div class="d-inline-block me-1 mb-2">
		<button class="btn bg-base-color-dark border-0" @click="isOpen = !isOpen">
			<Frc-icon-bootstrap name="bi-funnel" size="15px" />

		</button>
		<button v-if="length > 0" class="btn bg-warning border-0" @click="reset()">
			<Frc-icon-bootstrap name="bi-eraser" size="15px" />
			<span class="badge text-dark bg-light ms-2 rounded-pill">
				{{ length }}
			</span>
		</button>
	</div>
	<Frc-popup v-if="isOpen" :close="() => isOpen = false">
		<Frc-form :entity="entity"
				   :validation="false"
				   :value="filterValue"
				   :task="(x) => filterChange(x)"
				   submit-resource-key="global.ApplyFilters" />
	</Frc-popup>
</template>
<script>
	export default {
		emits: ['filterChange'],

		props:
		{
			'filterEntity': Function
		},

		data()
		{
			return {
				length: 0,
				isOpen:false,
				filterValue: null,
				entity: this.filterEntity
			}
		},

		methods:
		{
			filterChange(payload)
			{
				this.isOpen = false;
				this.filterValue = payload
				this.length = Object.keys(payload || {}).length;
				this.$emit('filterChange', payload)
			},

			reset() {
				this.filterValue = null;
				this.filterChange(null)
			}
		}
	}
</script>
<style scoped>
	.filter-box {
		width: 400px;
		margin-right: 10px;
	}
</style>
