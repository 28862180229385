import { createStore } from 'vuex'
import app from './modules/app'
import temp from './modules/temp'
import client from './modules/client'
import server from './modules/server'
import automation from './modules/automation'

export default createStore({
	modules: {
		app, temp, client, server, automation
	},
})


