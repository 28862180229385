const SCHEMA_TYPE =
{
	MAIN: 'main',
	MAIN_INFO: 'mainInfo',
	BASE_INFO: 'baseInfo',
	SUB_INFO: 'subInfo',
	BASE_SYS: 'baseSys',
	WRAPPER: 'wrapper'
};


export default Object.freeze(SCHEMA_TYPE);
