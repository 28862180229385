//GeneratorFlag:[NoChange]
import { BaseMenuItem } from '@/modules/people-net/base/data/actions/menu-person/get-list-by-user'
export default class MenuItem extends BaseMenuItem
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			menuId: {
				...this.props.menuId,
				setConfig:{
					...this.props.menuId.setConfig
				}
			},
			accessKey: {
				...this.props.accessKey,
				setConfig:{
					...this.props.accessKey.setConfig
				}
			},
			parentId: {
				...this.props.parentId,
				setConfig:{
					...this.props.parentId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			titleMenu: {
				...this.props.titleMenu,
				setConfig:{
					...this.props.titleMenu.setConfig
				}
			},
			menuOrder: {
				...this.props.menuOrder,
				setConfig:{
					...this.props.menuOrder.setConfig
				}
			},
			iconUrl: {
				...this.props.iconUrl,
				setConfig:{
					...this.props.iconUrl.setConfig
				}
			},
			menuUrl: {
				...this.props.menuUrl,
				setConfig:{
					...this.props.menuUrl.setConfig
				}
			},
			isVisible: {
				...this.props.isVisible,
				setConfig:{
					...this.props.isVisible.setConfig
				}
			},
		};
	}
}

