//GeneratorFlag:[NoChange]
export default {
	path: 'company',
	name: 'peopleNet.company.layout',
	redirect: to => {
		return {name: 'peopleNet.company.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.company.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.company.list',
			component: () => import('@/modules/people-net/views/company/company-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.company.create',
			component: () => import('@/modules/people-net/views/company/company-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.company.import',
			component: () => import('@/modules/people-net/views/company/company-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.company.update',
			component: () => import('@/modules/people-net/views/company/company-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.company.delete',
			component: () => import('@/modules/people-net/views/company/company-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.company.detail',
			component: () => import('@/modules/people-net/views/company/company-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
