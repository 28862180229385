//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonValidationService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonValidation.Validate]
	async validate(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-validation/validate', params || { });
	};

	//GeneratorFlag:[PersonValidation.ValidateCode]
	async validateCode(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/people-net/person-validation/validate-code', params || { });
	};

//GeneratorFlag:[NewAction]
}