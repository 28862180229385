//GeneratorFlag:[NoChange]
export default {
	path: 'marital-type',
	name: 'peopleNet.maritalType.layout',
	redirect: to => {
		return {name: 'peopleNet.maritalType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.maritalType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.maritalType.list',
			component: () => import('@/modules/people-net/views/marital-type/marital-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.maritalType.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
	]
}
