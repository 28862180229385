//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class BasePersonAccessChangePassword extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'personAccessChangePassword';
	schema = ENUMS.SCHEMA_TYPE.ACTION;
	resourceKey = 'peopleNet.personAccessChangePassword.self';
	props = {
		personId: {
			name: 'personId',
			resourceKey: 'peopleNet.personAccessChangePassword.personId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: [],
			}
		},
		currentPassword: {
			name: 'currentPassword',
			resourceKey: 'peopleNet.personAccessChangePassword.currentPassword',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.PASSWORD,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.PASSWORD,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: ['required'],
			}
		},
		newPassword: {
			name: 'newPassword',
			resourceKey: 'peopleNet.personAccessChangePassword.newPassword',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.PASSWORD,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.PASSWORD,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: ['required'],
			}
		},
		confirmedPassword: {
			name: 'confirmedPassword',
			resourceKey: 'peopleNet.personAccessChangePassword.confirmedPassword',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.PASSWORD,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.PASSWORD,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				patterns: ['required'],
			}
		},
		result: {
			name: 'result',
			resourceKey: 'peopleNet.personAccessChangePassword.result',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKBOX,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				patterns: ['required'],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}

