//GeneratorFlag:[NoChange]
import { BasePersonGetCredit } from '@/modules/people-net/base/data/actions/person/get-credit'
export default class PersonGetCredit extends BasePersonGetCredit
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			creditValue: {
				...this.props.creditValue,
				setConfig:{
					...this.props.creditValue.setConfig
				}
			},
			currency: {
				...this.props.currency,
				setConfig:{
					...this.props.currency.setConfig
				}
			},
		};
	}
}

