//GeneratorFlag:[NoChange]
export default {
	path: 'plan-precent',
	name: 'shop.planPrecent.layout',
	redirect: to => {
		return {name: 'shop.planPrecent.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/BaseInfo/plan-precent"],
		resourceKey: 'shop.views.planPrecent.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanPrecentDelete]"
		{
			path: 'delete',
			name: 'shop.planPrecent.delete',
			component: () => import('@/modules/shop/views/plan-precent/plan-precent-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planPrecent.delete',
				clientAccessKeys:["/shop/BaseInfo/plan-precent/delete"],
				serverAccessKeys:["/shop/plan-precent/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanPrecentDetail]"
		{
			path: 'detail',
			name: 'shop.planPrecent.detail',
			component: () => import('@/modules/shop/views/plan-precent/plan-precent-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planPrecent.detail',
				clientAccessKeys:["/shop/BaseInfo/plan-precent/detail"],
				serverAccessKeys:["/shop/plan-precent/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanPrecentList]"
		{
			path: 'list',
			name: 'shop.planPrecent.list',
			component: () => import('@/modules/shop/views/plan-precent/plan-precent-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planPrecent.list',
				clientAccessKeys:["/shop/BaseInfo/plan-precent/list"],
				serverAccessKeys:["/shop/plan-precent/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanPrecentCreate]"
		{
			path: 'create',
			name: 'shop.planPrecent.create',
			component: () => import('@/modules/shop/views/plan-precent/plan-precent-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planPrecent.create',
				clientAccessKeys:["/shop/BaseInfo/plan-precent/create"],
				serverAccessKeys:["/shop/plan-precent/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanPrecentImport]"
		{
			path: 'import',
			name: 'shop.planPrecent.import',
			component: () => import('@/modules/shop/views/plan-precent/plan-precent-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planPrecent.import',
				clientAccessKeys:["/shop/BaseInfo/plan-precent/import"],
				serverAccessKeys:["/shop/plan-precent/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanPrecentUpdate]"
		{
			path: 'update',
			name: 'shop.planPrecent.update',
			component: () => import('@/modules/shop/views/plan-precent/plan-precent-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planPrecent.update',
				clientAccessKeys:["/shop/BaseInfo/plan-precent/update"],
				serverAccessKeys:["/shop/plan-precent/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






