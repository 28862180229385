<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Person } from '../../data'
	import { PersonFilter } from '../../data/filters'
	import { PersonService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return Person;
			},
			
			filterEntity()
			{
				return PersonFilter;
			},

			service()
			{
				return new PersonService();
			},

			filterData()
			{
				const personId =
					this.$route.query['personId'];

				if (personId)
				{
					return {
						'personId': personId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'peopleNet.person.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'peopleNet.person.import',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						resourceKey: 'action.update',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'peopleNet.person.personAccess.update',
								resourceKey: 'peopleNet.views.person.updatePersonAccess',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'peopleNet.person.personDetail.update',
								resourceKey: 'peopleNet.views.person.updatePersonDetail',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'peopleNet.person.update',
								resourceKey: 'peopleNet.views.person.update',
								iconName: 'bi-pen',
								activeMode: 'single',
								dataTransferMode: 'store',
							}
						],
					},

					{
						routeName: 'peopleNet.person.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [
							{
								routeName: 'peopleNet.person.personAccess.detail',
								resourceKey: 'peopleNet.views.person.personAccessDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},
							{
								routeName: 'peopleNet.person.personDetail.detail',
								resourceKey: 'peopleNet.views.person.personDetailDetail',
								iconName: 'bi-list-columns',
								activeMode: 'single',
								dataTransferMode: 'store',
							},

						]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
