//GeneratorFlag:[NoChange]
import actionRoute from './action-route';

//GeneratorFlag:[NewImport]
const routes = [{
	path: 'shop-marketer',
	name: 'shop-marketer',
	component: () => import('@/modules/shop-marketer/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys: ['/shop-marketer'],
		serverAccessKeys:[],
		resourceKey: 'service.shopMarketer',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-layers-half',
		}
	},
	children: [
		actionRoute
	]
}]
export default routes
export
{
	actionRoute
}




























