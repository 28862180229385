//GeneratorFlag:[NoChange]
import { BaseNationalityType } from '../base/data'
export default class NationalityType extends BaseNationalityType
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			nationalityTypeId: {
				...this.props.nationalityTypeId,
				setConfig:{
					...this.props.nationalityTypeId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			nationalityTypeSubInfo: {
				...this.props.nationalityTypeSubInfo,
				setConfig:{
					...this.props.nationalityTypeSubInfo.setConfig
				}
			},

		};
	}
}
