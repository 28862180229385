//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { DocumentCategory, DocumentSubmitType, DocumentType, FinancialPerson } from '@/modules/financial/data/document/detail'; 
import { PersonService } from '@/modules/people-net/services'; 
import { DocumentTypeService, DocumentSubmitTypeService, DocumentCategoryService } from '@/modules/financial/services'; 
export default class Document extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'document';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'financial.documentDetail.self';
	props = {
		documentId: {
			primarykey: true,
			name: 'documentId',
			resourceKey: 'financial.documentDetail.documentId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		documentDate: {
			name: 'documentDate',
			resourceKey: 'financial.documentDetail.documentDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		documentTime: {
			name: 'documentTime',
			resourceKey: 'financial.documentDetail.documentTime',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TIME_PICKER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['time', 'required'],
			},
			errors: [],
			value: null
		},
		documentPersianDate: {
			name: 'documentPersianDate',
			resourceKey: 'financial.documentDetail.documentPersianDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 10,
				isUnicode: false,
				isUnique: false,
				patterns: ['persianDate', 'required'],
			},
			errors: [],
			value: null
		},
		amount: {
			name: 'amount',
			resourceKey: 'financial.documentDetail.amount',
			type: ENUMS.PROP_TYPE.INT64,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		descriptionKey: {
			name: 'descriptionKey',
			resourceKey: 'financial.documentDetail.descriptionKey',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 20,
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		documentDescription: {
			name: 'documentDescription',
			resourceKey: 'financial.documentDetail.documentDescription',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 250,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'financial.documentDetail.personId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'fullName',
				valueKey: 'personId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		documentTypeId: {
			foreignKey: true,
			name: 'documentTypeId',
			resourceKey: 'financial.documentDetail.documentTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new DocumentTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'documentTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		documentSubmitTypeId: {
			foreignKey: true,
			name: 'documentSubmitTypeId',
			resourceKey: 'financial.documentDetail.documentSubmitTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new DocumentSubmitTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'documentSubmitTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		documentCategoryId: {
			foreignKey: true,
			name: 'documentCategoryId',
			resourceKey: 'financial.documentDetail.documentCategoryId',
			type: ENUMS.PROP_TYPE.INT16,
			data: async (params) =>
			{
				const service =
					new DocumentCategoryService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'documentCategoryId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		documentCategory: {
			name: 'documentCategory',
			resourceKey: 'financial.documentDetail.documentCategory.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new DocumentCategory(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		documentSubmitType: {
			name: 'documentSubmitType',
			resourceKey: 'financial.documentDetail.documentSubmitType.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new DocumentSubmitType(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		documentType: {
			name: 'documentType',
			resourceKey: 'financial.documentDetail.documentType.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new DocumentType(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		person: {
			name: 'person',
			resourceKey: 'financial.documentDetail.person.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new FinancialPerson(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{fullName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		documentSubInfo: {
			name: 'documentSubInfo',
			resourceKey: 'financial.documentDetail.documentSubInfo',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
	};
}