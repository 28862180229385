//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'

const shop = {
	name: 'shopMarketer',
	foldreName: 'shop-marketer',
	routes,
	services,
	resources
}

export default shop
export { routes, services, resources }

