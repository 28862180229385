//GeneratorFlag:[NoChange]
import { BasePlanLine } from '../base/data'
export default class PlanLine extends BasePlanLine
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			savedValue: {
				...this.props.savedValue,
				setConfig:{
					...this.props.savedValue.setConfig
				}
			},
			savedPerson: {
				...this.props.savedPerson,
				setConfig:{
					...this.props.savedPerson.setConfig
				}
			},
			savedVoacher: {
				...this.props.savedVoacher,
				setConfig:{
					...this.props.savedVoacher.setConfig
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				}
			},
			savedPersonNavigation: {
				...this.props.savedPersonNavigation,
				setConfig:{
					...this.props.savedPersonNavigation.setConfig
				}
			},

		};
	}
}
