const security =
{
	encode: (val) => {
		var number = '0x'
		var length = val.length
		for (var i = 0; i < length; i++) { number += val.charCodeAt(i).toString(16) }
		return number
	},

	decode: (val) => {
		var string = ''
		val = val.slice(2)
		var length = val.length
		for (var i = 0; i < length;) {
			var code = val.slice(i, i += 2)
			string += String.fromCharCode(parseInt(code, 16))
		}

		return string
	}
}

export default security
