//GeneratorFlag:[NoChange]
import ProductDetail from './product-detail';
import Culture from './culture';
import Category from './category';
import ProductStatus from './product-status';
import ProductSpecification from './product-specification';
import Product from './product';
import ProductColor from './product-color';
import ProductMonetaryFeature from './product-monetary-feature';

export default {ProductDetail, Culture, Category, ProductStatus, ProductSpecification, Product, ProductColor, ProductMonetaryFeature}
export{ProductDetail, Culture, Category, ProductStatus, ProductSpecification, Product, ProductColor, ProductMonetaryFeature}
