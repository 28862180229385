//GeneratorFlag:[NoChange]
import { BaseCompanyRank } from '../base/data'
export default class CompanyRank extends BaseCompanyRank
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			companyRankId: {
				...this.props.companyRankId,
				setConfig:{
					...this.props.companyRankId.setConfig
				}
			},
			companyRankKey: {
				...this.props.companyRankKey,
				setConfig:{
					...this.props.companyRankKey.setConfig
				}
			},
			cultureId: {
				...this.props.cultureId,
				setConfig:{
					...this.props.cultureId.setConfig
				}
			},
			title: {
				...this.props.title,
				setConfig:{
					...this.props.title.setConfig
				}
			},
			companyId: {
				...this.props.companyId,
				setConfig:{
					...this.props.companyId.setConfig
				}
			},

		};
	}
}
