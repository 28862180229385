<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-task-manager v-if="temp" :entity="entity" :task="delete" :data="temp" :label="label" @close="reset" @cancel="reset" />
</template>
<script>
	import { Menu } from '../../data'
	import { MenuService } from '../../services'
	export default {
		data()
		{
			return {
				temp: null
			}
		},
		computed:
		{
			entity()
			{
				return Menu;
			},

			service()
			{
				return new MenuService();
			},

			label()
			{
				return this.$loc.fromResource('action.delete');
			}
		},
		async beforeMount()
		{
			await this.init();
		},
		methods:
		{
			async init()
			{
				const store = this.$store;
				const router = this.$route;
				var temp = await store.dispatch('temp/findByQuery', router.query);
				if (temp)
				{
					this.temp = temp.value;
				}
				else this.$router.go(-1);
			},

			async delete(payload)
			{
				return await this.service.delete(payload['menuId'], {HSR:false, HER:false});
			},
			
			reset()
			{
				this.$router.go(-1);
			}
		}
	}
</script>
