//GeneratorFlag:[NoChange]
export default {
	path: 'invoice-transaction',
	name: 'shop.invoiceTransaction.layout',
	redirect: to => {
		return {name: 'shop.invoiceTransaction.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'shop.views.invoiceTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'shop.invoiceTransaction.list',
			component: () => import('@/modules/shop/views/invoice-transaction/invoice-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransaction.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'shop.invoiceTransaction.create',
			component: () => import('@/modules/shop/views/invoice-transaction/invoice-transaction-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransaction.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'shop.invoiceTransaction.import',
			component: () => import('@/modules/shop/views/invoice-transaction/invoice-transaction-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransaction.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'shop.invoiceTransaction.update',
			component: () => import('@/modules/shop/views/invoice-transaction/invoice-transaction-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransaction.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'shop.invoiceTransaction.delete',
			component: () => import('@/modules/shop/views/invoice-transaction/invoice-transaction-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransaction.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'shop.invoiceTransaction.detail',
			component: () => import('@/modules/shop/views/invoice-transaction/invoice-transaction-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceTransaction.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
