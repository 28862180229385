//GeneratorFlag:[NoChange]
import { BaseProductFeature } from '../base/data'
export default class ProductFeature extends BaseProductFeature
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			productFeatureId: {
				...this.props.productFeatureId,
				setConfig:{
					...this.props.productFeatureId.setConfig
				}
			},
			featureId: {
				...this.props.featureId,
				setConfig:{
					...this.props.featureId.setConfig
				}
			},
			stringValue: {
				...this.props.stringValue,
				setConfig:{
					...this.props.stringValue.setConfig
				}
			},
			floatValue: {
				...this.props.floatValue,
				setConfig:{
					...this.props.floatValue.setConfig
				}
			},
			intValue: {
				...this.props.intValue,
				setConfig:{
					...this.props.intValue.setConfig
				}
			},
			productId: {
				...this.props.productId,
				setConfig:{
					...this.props.productId.setConfig
				}
			},
			feature: {
				...this.props.feature,
				setConfig:{
					...this.props.feature.setConfig
				}
			},
			product: {
				...this.props.product,
				setConfig:{
					...this.props.product.setConfig
				}
			},
			productFeatureSubInfo: {
				...this.props.productFeatureSubInfo,
				setConfig:{
					...this.props.productFeatureSubInfo.setConfig
				}
			},

		};
	}
}
