//GeneratorFlag:[NoChange]
import { BaseInvoiceConnectToOther } from '@/modules/shop/base/data/actions/invoice/connect-to-other'
export default class InvoiceConnectToOther extends BaseInvoiceConnectToOther
{
	constructor(arg)
	{
		super(arg);
		this.props = {
			...this.props,
			invoiceId: {
				...this.props.invoiceId,
				setConfig:{
					...this.props.invoiceId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				}
			},
			result: {
				...this.props.result,
				setConfig:{
					...this.props.result.setConfig
				}
			},
			message: {
				...this.props.message,
				setConfig:{
					...this.props.message.setConfig
				}
			},
		};
	}
}

