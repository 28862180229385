//GeneratorFlag:[NoChange]
import { BasePersonCreditTransaction } from '../base/data'
export default class PersonCreditTransaction extends BasePersonCreditTransaction
{
	constructor(arg)
	{
		super(arg);

		this.props = {
			...this.props,

			personCreditTransactionId: {
				...this.props.personCreditTransactionId,
				setConfig:{
					...this.props.personCreditTransactionId.setConfig
				}
			},
			personId: {
				...this.props.personId,
				setConfig:{
					...this.props.personId.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			personCreditTransactionTypeId: {
				...this.props.personCreditTransactionTypeId,
				setConfig:{
					...this.props.personCreditTransactionTypeId.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			personCreditTransactionStatuseId: {
				...this.props.personCreditTransactionStatuseId,
				setConfig:{
					...this.props.personCreditTransactionStatuseId.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			caption: {
				...this.props.caption,
				setConfig:{
					...this.props.caption.setConfig
				}
			},
			transactionRefCode: {
				...this.props.transactionRefCode,
				setConfig:{
					...this.props.transactionRefCode.setConfig
				},
				
			},
			displayTransactionDate: {
				...this.props.displayTransactionDate,
				setConfig:{
					...this.props.displayTransactionDate.setConfig
				}
			},
			transactionDate: {
				...this.props.transactionDate,
				setConfig:{
					...this.props.transactionDate.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			creditValue: {
				...this.props.creditValue,
				setConfig:{
					...this.props.creditValue.setConfig
				}
			},
			personCommiter: {
				...this.props.personCommiter,
				setConfig:{
					...this.props.personCommiter.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			commiterDescription: {
				...this.props.commiterDescription,
				setConfig:{
					...this.props.commiterDescription.setConfig
				},
				display: {
					isVisible: true,
				}
			},
			person: {
				...this.props.person,
				setConfig:{
					...this.props.person.setConfig
				},
				display: {
					isVisible: false,
				}
			},
			personCreditTransactionStatuse: {
				...this.props.personCreditTransactionStatuse,
				setConfig:{
					...this.props.personCreditTransactionStatuse.setConfig
				}
			},
			personCreditTransactionType: {
				...this.props.personCreditTransactionType,
				setConfig:{
					...this.props.personCreditTransactionType.setConfig
				}
			},

		};
	}
}
