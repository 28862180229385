<!--GeneratorFlag:[NoChange]-->
<template>
	<Frc-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Country } from '../../data'
	import { CountryFilter } from '../../data/filters'
	import { CountryService } from '../../services'
	export default {
		computed:
		{
			entity()
			{
				return Country;
			},
			
			filterEntity()
			{
				return CountryFilter;
			},

			service()
			{
				return new CountryService();
			},

			filterData()
			{
				const countryId =
					this.$route.query['countryId'];

				if (countryId)
				{
					return {
						'countryId': countryId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'peopleNet.country.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'peopleNet.country.import',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'peopleNet.country.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'peopleNet.country.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'peopleNet.country.detail',
							resourceKey: 'peopleNet.views.country.detail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
