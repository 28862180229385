//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class FeatureService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	async find(featureId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:featureId
		}

		return await _server.get('/shop/feature/find', {'params':params})
	};

	async save(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		return await _server.post('/shop/feature/save', params || {})
	};

	async delete(featureId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;

		const _server = 
			this.server(config);

		const params = {
			id:featureId
		}

		return await _server.delete('/shop/feature/delete', {'params':params})
	};

	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);

		return await _server.get('/shop/feature/list', {'params':params})
	};
}
