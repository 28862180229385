//GeneratorFlag:[NoChange]
export default {
	path: 'store',
	name: 'shop.store.layout',
	redirect: to => {
		return {name: 'shop.store.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/store"],
		resourceKey: 'shop.views.store.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[StoreDelete]"
		{
			path: 'delete',
			name: 'shop.store.delete',
			component: () => import('@/modules/shop/views/store/store-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.store.delete',
				clientAccessKeys:["/shop/store/delete"],
				serverAccessKeys:["/shop/store/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreDetail]"
		{
			path: 'detail',
			name: 'shop.store.detail',
			component: () => import('@/modules/shop/views/store/store-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.store.detail',
				clientAccessKeys:["/shop/store/detail"],
				serverAccessKeys:["/shop/store/find"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreList]"
		{
			path: 'list',
			name: 'shop.store.list',
			component: () => import('@/modules/shop/views/store/store-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.store.list',
				clientAccessKeys:["/shop/store/list"],
				serverAccessKeys:["/shop/store/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreCreate]"
		{
			path: 'create',
			name: 'shop.store.create',
			component: () => import('@/modules/shop/views/store/store-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.store.create',
				clientAccessKeys:["/shop/store/create"],
				serverAccessKeys:["/shop/store/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreImport]"
		{
			path: 'import',
			name: 'shop.store.import',
			component: () => import('@/modules/shop/views/store/store-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.store.import',
				clientAccessKeys:["/shop/store/import"],
				serverAccessKeys:["/shop/store/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[StoreUpdate]"
		{
			path: 'update',
			name: 'shop.store.update',
			component: () => import('@/modules/shop/views/store/store-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.store.update',
				clientAccessKeys:["/shop/store/update"],
				serverAccessKeys:["/shop/store/save"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}






