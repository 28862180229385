<template>
    <div class="card bg-light justify-content-center pt-3 pb-3">
        <div class="card-body">
			<div class="d-flex align-items-center">
				<Frc-icon-bootstrap color="red" name="bi-info-circle me-3" />
				<span>{{ $loc.fromResource('system.dataError')}}</span>
			</div>
        </div>
    </div>
</template>
