//GeneratorFlag:[NoChange]
export default {
	path: 'ticket-statuse',
	name: 'ticketing.baseSys.ticketStatuse.layout',
	redirect: to => {
		return {name: 'ticketing.ticketStatuse.list'};
	},
	component: () => import('@/modules/ticketing/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ticketing/base-sys/ticket-statuse"],
		resourceKey: 'ticketing.views.ticketStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TicketStatuseDetail]"
		{
			path: 'detail',
			name: 'ticketing.ticketStatuse.detail',
			component: () => import('@/modules/ticketing/views/ticket-statuse/ticket-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketStatuse.detail',
				clientAccessKeys:["/ticketing/base-sys/ticket-statuse/detail"],
				serverAccessKeys:["/ticketing/ticket-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TicketStatuseList]"
		{
			path: 'list',
			name: 'ticketing.ticketStatuse.list',
			component: () => import('@/modules/ticketing/views/ticket-statuse/ticket-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticketStatuse.list',
				clientAccessKeys:["/ticketing/base-sys/ticket-statuse/list"],
				serverAccessKeys:["/ticketing/ticket-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}