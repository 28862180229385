//GeneratorFlag:[NoChange]
export default {
	path: 'team',
	name: 'peopleNet.team.layout',
	redirect: to => {
		return {name: 'peopleNet.team.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		resourceKey: 'peopleNet.views.team.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
		{
			path: 'list',
			name: 'peopleNet.team.list',
			component: () => import('@/modules/people-net/views/team/team-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.list',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'create',
			name: 'peopleNet.team.create',
			component: () => import('@/modules/people-net/views/team/team-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.create',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'import',
			name: 'peopleNet.team.import',
			component: () => import('@/modules/people-net/views/team/team-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.import',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'update',
			name: 'peopleNet.team.update',
			component: () => import('@/modules/people-net/views/team/team-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.update',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'delete',
			name: 'peopleNet.team.delete',
			component: () => import('@/modules/people-net/views/team/team-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.delete',
				menuConfig: {
					isVisible: false
				}
			}
		},
		{
			path: 'detail',
			name: 'peopleNet.team.detail',
			component: () => import('@/modules/people-net/views/team/team-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.detail',
				menuConfig: {
					isVisible: false
				}
			}
		},


	]
}
