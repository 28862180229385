//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { City } from '@/modules/people-net/data'; 

import { CityService } from '@/modules/people-net/services'; 

export default class BasePersonDetail extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}

	name = 'personDetail';
	schema = ENUMS.SCHEMA_TYPE.MAIN_INFO;
	resourceKey = 'peopleNet.personDetail.self';
	props = {
		personId: {
			primarykey: true,
			name: 'personId',
			resourceKey: 'peopleNet.personDetail.personId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,
				canUseInExcel: false,
				patterns: [],
			}
		},
		cityId: {
			foreignKey: true,
			name: 'cityId',
			resourceKey: 'peopleNet.personDetail.cityId',
			type: ENUMS.PROP_TYPE.INT16,
			entity: new City(),
			data: async (params) =>
			{
				const service =
					new CityService();
				return await service.list(params);
			},
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,
				canUseInExcel: true,
				textKey: 'title',
				valueKey: 'cityId',
				patterns: [],
			}
		},
		city: {
			name: 'city',
			resourceKey: 'peopleNet.personDetail.city',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new City(),
			display: {
				isVisible: true,
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{title}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,
				canUseInExcel: false,
				patterns: [],
			}
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}

	};
}
